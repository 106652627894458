import { isRunTime } from "src/utils/isRunTime";
import request from "src/utils/request";
import {setToLocalStorage, getFromLocalStorage} from "src/utils/localStorage";

function getToastbar(_toastbar) {
    return request({
        url: `${process.env.GATSBY_CONNECT_URL}/flamingo/toast-bar?client=${process.env.GATSBY_CONNECT_CLIENT}`,
        method: "GET"
    })
}

export function getCurrentToastbarState(language, toastbar, defaultLanguage = "en-US") {
    return new Promise((resolve, reject) => {
        getToastbar(toastbar)
        .then(res => {
            if (!res.data.data || res.data.data.length === 0) return resolve(["hidden"]);
            const ontoastbar = res.data.data.find(t => t.id == toastbar.id)
            if (!ontoastbar) return resolve(["hidden"]);
            if (ontoastbar.visible === false) return resolve(["hidden"]);
            try {
                let text = ontoastbar.translations.find(t => t.languages_code === language)?.text;
                if(!text) text = ontoastbar.translations.find(t => t.languages_code === defaultLanguage)?.text;
                return resolve(["visible", text, ontoastbar.text_color?.value, ontoastbar.background_color?.value])
            } catch (err) {
                return resolve(["hidden"]);
            }
        })
    });
}

export function getTextHash(text) {
    if(!text || typeof text !== "string") return "";
    return text.length + "s" + text.split("").map(x => x.charCodeAt(0)).reduce((total, x)=>x+total)
}

export function isAlreadyClosed(text) {
    const hash = getTextHash(text);
    const shash = isRunTime() ? getFromLocalStorage('toastbarh') || 'unhashed' : 'buildtime';
    return shash === hash;
}

export function markAsClosed(text) {
    const hash = getTextHash(text);
    setToLocalStorage('toastbarh', hash);
}