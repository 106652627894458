import React from "react";

import FooterCommonController from "../footer.v1.common";

export default class FooterTwoColumns extends FooterCommonController<TFooterTwoColumns> {

    private get hasRightPane() {
        return this.hasNewsletter || this.hasStores() || this.hasCTA();
    }

    private get hasNewsletter(): boolean {
        return this.props.footer.with_newsletter_subscription === true;
    }

    private renderFirstColumn() {
        return this.renderColumn(
            this.props.footer.two_columns_translations,
            'first_column_title',
            this.props.footer.first_column_items
        );
    }

    private renderSecondColumn() {
        return this.renderColumn(
            this.props.footer.two_columns_translations,
            'second_column_title',
            this.props.footer.second_column_items
        );
    }


    render(): React.ReactNode {
        return <div style={this.style as any}>
        <div className="w-full py-8 lg:py-12 px-4 xl:px-0 bg-[color:var(--background-color)]" style={this.mainBackgroundStyle}>
            <div className="max-w-7xl mx-auto flex flex-col items-center gap-12">
                <div className="w-full flex flex-col lg:grid lg:grid-cols-3 gap-8 xl:gap-6">

                    <div className={`${this.hasRightPane ? '' : 'col-span-2'} flex flex-col gap-6 items-center lg:items-start`}>
                        {this.renderLogo()}
                        {this.renderSupportingText(false)}
                        {this.renderSocialMedia()}
                    </div>

                    <div className="grid grid-cols-2 md:flex md:flex-row gap-8 text-[color:var(--link-labels-color)]">
                        {this.renderFirstColumn()}
                        {this.renderSecondColumn()}
                    </div>

                    {this.hasRightPane ?
                    <div className="flex flex-col gap-8 xl:gap-6">
                        {this.renderNewsletter('left')}
                        {this.renderAppStores('left', 'left')}
                        {this.renderCTA('left')}
                    </div> : null}                   
                </div>
            </div>
        </div>

        {/* logos grid section */}
        <div className="w-full bg-[color:var(--logo-slider-background-color)]">
            <div className="max-w-7xl mx-auto flex flex-col items-center ">
                {this.renderLogosGrid()}
            </div>
        </div>

        {/* legal section */}
        <div className="w-full bg-[color:var(--legal-background-color)]">
            <div className="max-w-7xl mx-auto flex flex-col items-center">
                {this.renderBottomText()}
            </div>
        </div>
        
        </div>
    }
}