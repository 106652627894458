import * as React from "react"

const ChevronDownSDS = (props: any) => (
  <svg
    width={21}
    height={20}
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.242 7.293a1 1 0 0 1 1.415 0l3.293 3.293 3.292-3.293a1 1 0 0 1 1.415 1.414l-4 4a1 1 0 0 1-1.415 0l-4-4a1 1 0 0 1 0-1.414Z"
      fill="currentColor"
    />
  </svg>
)

export default ChevronDownSDS
