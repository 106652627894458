import * as React from "react"

const Scanner = (props: any) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32" 
    xmlns="http://www.w3.org/2000/svg"
  >
    <path 
      fill="currentColor"
      d="M10.8148 4.9C11.3119 4.9 11.7148 4.49706 11.7148 4C11.7148 3.50294 11.3119 3.1 10.8148 3.1V4.9ZM3.1 10.8148C3.1 11.3119 3.50294 11.7148 4 11.7148C4.49706 11.7148 4.9 11.3119 4.9 10.8148H3.1ZM27.1 10.8148C27.1 11.3119 27.503 11.7148 28 11.7148C28.4971 11.7148 28.9 11.3119 28.9 10.8148H27.1ZM21.1852 3.1C20.6882 3.1 20.2852 3.50294 20.2852 4C20.2852 4.49706 20.6882 4.9 21.1852 4.9V3.1ZM21.1852 27.1C20.6882 27.1 20.2852 27.503 20.2852 28C20.2852 28.4971 20.6882 28.9 21.1852 28.9V27.1ZM28.9 21.1852C28.9 20.6882 28.4971 20.2852 28 20.2852C27.503 20.2852 27.1 20.6882 27.1 21.1852H28.9ZM4.9 21.1852C4.9 20.6882 4.49706 20.2852 4 20.2852C3.50294 20.2852 3.1 20.6882 3.1 21.1852H4.9ZM10.8148 28.9C11.3119 28.9 11.7148 28.4971 11.7148 28C11.7148 27.503 11.3119 27.1 10.8148 27.1V28.9ZM10.8148 3.1H7.25926V4.9H10.8148V3.1ZM7.25926 3.1C4.96216 3.1 3.1 4.96216 3.1 7.25926H4.9C4.9 5.95628 5.95628 4.9 7.25926 4.9V3.1ZM3.1 7.25926V10.8148H4.9V7.25926H3.1ZM28.9 10.8148V7.25926H27.1V10.8148H28.9ZM28.9 7.25926C28.9 4.96216 27.0379 3.1 24.7408 3.1V4.9C26.0438 4.9 27.1 5.95628 27.1 7.25926H28.9ZM24.7408 3.1H21.1852V4.9H24.7408V3.1ZM21.1852 28.9H24.7408V27.1H21.1852V28.9ZM24.7408 28.9C27.0379 28.9 28.9 27.0379 28.9 24.7408H27.1C27.1 26.0438 26.0438 27.1 24.7408 27.1V28.9ZM28.9 24.7408V21.1852H27.1V24.7408H28.9ZM3.1 21.1852V24.7408H4.9V21.1852H3.1ZM3.1 24.7408C3.1 27.0379 4.96216 28.9 7.25926 28.9V27.1C5.95628 27.1 4.9 26.0438 4.9 24.7408H3.1ZM7.25926 28.9H10.8148V27.1H7.25926V28.9Z" 
    />
    <path 
      d="M30.6667 16.0006L1.33332 16.0007" 
      strokeWidth="1.8" 
      strokeLinecap="round" 
      strokeLinejoin="round"
      stroke="currentColor"
    />
  </svg>

)

export default Scanner;
