/**
 * 
 * let users = {
 *   'meetu': { 'salary': 36000, 'active': true },
 *   'teetu': { 'salary': 40000, 'active': false },
 *   'seetu': { 'salary': 10000, 'active': true }
 * };
 * 
 * const found_elem = findKey(users, function (o) { return o.salary < 40000; }); // 'meetu' <- found
 * 
 * @param object 
 * @param tester 
 * @returns 
 */
export function findKey( object: Record<string, any> , tester: (value: any) => boolean ): string | undefined {

    for (const key in object) {
        if (Object.prototype.hasOwnProperty.call(object, key)) {
            const element = object[key];
            if (tester(element)) return key;
        }
    }

}