import * as React from "react"
const DoubleBlackDiamondNew = (props:any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22}
    height={22}
    viewBox="0 0 22 22"
    fill="none"
    {...props}
  >
    <g fill="#242426" clipPath="url(#a)">
      <path d="M5.86 5.096.486 10.828a.708.708 0 0 0 0 .955l5.374 5.732a.606.606 0 0 0 .896 0l5.373-5.732a.708.708 0 0 0 0-.955L6.756 5.096a.606.606 0 0 0-.896 0Z" />
      <path d="m15.243 5.096-5.374 5.732a.708.708 0 0 0 0 .955l5.374 5.732a.606.606 0 0 0 .896 0l5.373-5.732a.708.708 0 0 0 0-.955L16.14 5.096a.606.606 0 0 0-.896 0Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h22v22H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default DoubleBlackDiamondNew
