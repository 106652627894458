import { navigate } from "gatsby";

import { isRunTime } from "./isRunTime";

const redirectTo = (path, options = {}) => {
  if(isRunTime() && options?.native && options?.native === true) {
    return window.location.href = path;
  }

  return navigate(path, options);
};

const handleRefresh = () => {
  let path = '';
  if(isRunTime()) {
    path = window.location.pathname + window.location.search;
  }

  return redirectTo(path, { native: true });
}

export { redirectTo, handleRefresh };