import React from "react";

export default function Filter(props: any) {
  return (
    <svg {...props} width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_19726_17022)">
      <path d="M10.6097 15.1633C9.23122 15.1633 8.0743 14.1787 7.80353 12.874H1.10815C0.812761 12.874 0.541992 12.6279 0.541992 12.3079C0.541992 11.9879 0.788146 11.7417 1.10815 11.7417H7.80353C8.0743 10.4617 9.23122 9.47712 10.6097 9.47712C11.9881 9.47712 13.1451 10.4617 13.4158 11.7663H14.8928C15.1881 11.7663 15.4589 12.0125 15.4589 12.3325C15.4589 12.6525 15.2128 12.8987 14.8928 12.8987H13.4158C13.1451 14.1787 11.9881 15.1633 10.6097 15.1633ZM10.6097 10.5848C9.64968 10.5848 8.86199 11.3725 8.86199 12.3079C8.86199 13.2433 9.64968 14.031 10.6097 14.031C11.5697 14.031 12.3574 13.2433 12.3574 12.3079C12.3574 11.3725 11.5697 10.5848 10.6097 10.5848ZM6.27738 10.831C4.89892 10.831 3.74199 9.84635 3.47122 8.54173H1.10815C0.812761 8.54173 0.541992 8.29558 0.541992 7.97558C0.541992 7.68019 0.788146 7.40942 1.10815 7.40942H3.47122C3.74199 6.10481 4.89892 5.12019 6.27738 5.12019C7.65584 5.12019 8.81276 6.10481 9.08353 7.40942H14.9174C15.2128 7.40942 15.4835 7.65558 15.4835 7.97558C15.4835 8.27096 15.2374 8.54173 14.9174 8.54173H9.05892C8.81276 9.84635 7.65584 10.831 6.27738 10.831ZM6.27738 6.27712C5.31738 6.27712 4.52968 7.06481 4.52968 8.00019C4.52968 8.96019 5.31738 9.72327 6.27738 9.72327C7.23738 9.72327 8.02507 8.93558 8.02507 8.00019C8.02507 7.04019 7.23738 6.27712 6.27738 6.27712ZM12.6035 6.52327C11.2251 6.52327 10.0681 5.53865 9.79738 4.23404H1.10815C0.812761 4.23404 0.541992 3.98788 0.541992 3.66788C0.541992 3.34788 0.788146 3.10173 1.10815 3.10173H9.79738C10.0681 1.79712 11.2251 0.8125 12.6035 0.8125C14.1789 0.8125 15.4589 2.0925 15.4589 3.64327C15.4589 5.19404 14.1789 6.52327 12.6035 6.52327ZM12.6035 1.94481C11.6435 1.94481 10.8558 2.7325 10.8558 3.66788C10.8558 4.60327 11.6435 5.39096 12.6035 5.39096C13.5635 5.39096 14.3512 4.60327 14.3512 3.66788C14.3512 2.7325 13.5635 1.94481 12.6035 1.94481Z" fill="currentColor"/>
      </g>
      <defs>
        <clipPath id="clip0_19726_17022">
          <rect width="16" height="15.7538" fill="white" transform="translate(0 0.123047)"/>
        </clipPath>
      </defs>
    </svg>
  );
}


