import React from "react";
import { ApplicationContext } from "src/context";

type CookiesManagerProps = {
    /** language */
    language: string;

    /** cookie yes account id */
    cookieYesId?: string;

    /** cookie information */
    cookieInfo?: boolean;
}

export default class CookiesManager extends React.Component<CookiesManagerProps> {

    /** @ts-ignore */
    context!: React.ContextType<typeof ApplicationContext>;
    static contextType = ApplicationContext;

    /** adds cookie yes integration into the webpage. */
    static addCookieYesIntegration(cookieYesId: string) {
         if (document.getElementById('cookieyes')) return;
        const scriptElem = document.createElement('script');
        scriptElem.setAttribute('id', 'cookieyes');
        scriptElem.setAttribute('type', 'text/javascript');
        scriptElem.setAttribute('src', `https://cdn-cookieyes.com/client_data/${cookieYesId}/script.js`);
        document.body.appendChild(scriptElem);
    }

    /** adds cookie info integration into the webpage */
    static addCookieInformationIntegration(culture: string) {
        if (document.getElementById('CookieConsent')) return;
        const scriptElem = document.createElement('script');
        scriptElem.setAttribute('id', 'CookieConsent');
        scriptElem.setAttribute('type', 'text/javascript');
        scriptElem.setAttribute('async', 'true');
        scriptElem.setAttribute('data-culture', culture);
        scriptElem.setAttribute('src', 'https://policy.app.cookieinformation.com/uc.js');
        document.head.appendChild(scriptElem);
    }

    /** this component should only update when appcontext ready */
    shouldComponentUpdate(_nextProps: Readonly<CookiesManagerProps>, _nextState: Readonly<{}>, nextContext: React.ContextType<typeof ApplicationContext>): boolean {
        if (this.context.ready !== nextContext.ready) return true;
        return false;
    }

    /** when this component is updated, add the cookies if not in app browser  */
    componentDidUpdate(_prevProps: Readonly<CookiesManagerProps>, _prevState: Readonly<{}>, snapshot?: any): void {
        // if not in in-app, add cookies management:
        if (!this.context.isInApp) this.setupCookieManagement();
    }

    /** add all cookie integrations here:  */
    setupCookieManagement() {
        if (process.env.DISABLE_COOKIE_MANAGEMENT === 'true') {
            console.log('Cookie management disabled by environment variable');
            return;
        }

        if (typeof this.props.cookieYesId === "string" && this.props.cookieYesId.length > 0) CookiesManager.addCookieYesIntegration(this.props.cookieYesId);
        if (this.props.cookieInfo === true) CookiesManager.addCookieInformationIntegration((this.props.language || "en-US").split("-")[0]);
    }

    /** this component does not render any element. */
    render(): React.ReactNode {
        return null;
    }
}