import React from "react";

import Link from "src/components/link";

import FooterCommonController from "../footer.v1.common";


import { getLanguageField } from "src/utils/language";
import { getSlug } from "src/utils/slugs";

export default class FooterSimpleCentered extends FooterCommonController<TFooterSimpleCentered> {

    renderFooterLinks() {
        return (this.props.footer.first_column_items || []).map(({footer_link_item_id}, index) => {
            const label = getLanguageField(footer_link_item_id.translations, this.props.language, 'label', false) as string;
            const href = footer_link_item_id.link_type === 'external'
                ? getLanguageField(footer_link_item_id.link_translations, this.props.language, 'link_href') as string
                : getSlug(this.props.language, this.props.defaultLanguage, footer_link_item_id.link_page.translations);
            return <Link key={'footer_link_' + index} className="generalText" title={label} href={href}>{label}</Link>
        })
    }

    render(): React.ReactNode {
        return <div style={this.style as any}>
        <div className="w-full py-8 lg:py-12 px-4 xl:px-0 text-[color:var(--text-color)] bg-[color:var(--background-color)]" style={this.mainBackgroundStyle}>

            <div className="max-w-7xl mx-auto flex flex-col items-center gap-6 justify-center xl:justify-start">
                {this.renderLogo()}
                {this.renderSupportingText(true)}
                {this.renderSocialMedia("flex xl:hidden")}
            </div>
            
            <div className="max-w-7xl mx-auto flex flex-col items-center gap-8 mt-8 xl:mt-6">
                <div className="flex flex-row flex-wrap justify-center gap-4 xl:gap-6 generalText text-[color:var(--link-labels-color)]">
                    {this.renderFooterLinks()}
                </div>
                {this.renderSocialMedia("hidden xl:flex")}
                {this.renderNewsletter()}
                {this.renderAppStores()}
                {this.renderCTA()}
            </div>

        </div>

        {/* logos grid section */}
        <div className="w-full bg-[color:var(--logo-slider-background-color)]">
            <div className="max-w-7xl mx-auto flex flex-col items-center ">
                {this.renderLogosGrid()}
            </div>
        </div>

        {/* legal section */}
        <div className="w-full bg-[color:var(--legal-background-color)]">
            <div className="max-w-7xl mx-auto flex flex-col items-center">
                {this.renderBottomText()}
            </div>
        </div>

        </div>
    }
}