import * as React from "react"
const BlackDiamondNew = (props:any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22}
    height={22}
    viewBox="0 0 22 22"
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="#242426"
        d="M9.984 2.306 2.46 10.331a.99.99 0 0 0 0 1.338l7.524 8.025c.346.369.907.369 1.254 0l7.523-8.025a.99.99 0 0 0 0-1.338l-7.523-8.025a.848.848 0 0 0-1.254 0Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h22v22H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default BlackDiamondNew
