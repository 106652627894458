import React from 'react';
import { GatsbyImage, IGatsbyImageData, getImage } from "gatsby-plugin-image";
import ImageV2 from './image.v2';

type ImageProps = {
    className?: string;
    alt: string;
    image: any;
    imgStyle?: any;
    draggable?: boolean;
    useImageKit?: boolean;
    loading: "lazy" | "eager";
    onClick?: () => void;
}

class ImageV3 extends React.Component<ImageProps> {
    static defaultProps: Partial<ImageProps> = {
        className: "",
        loading: "lazy",
    }

    static assetsLocation = "https://public-assets.spotliodata.com/";

    static getImageUrl(
        src: string,
        useImageKit = false,
        width : number | string | undefined = "1920",
        height: number | string | undefined = undefined,
        imageFormat: string | undefined = undefined,
    ) {
        let prefix = "";
        if (src.match(/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}\.(?:.{3}|.{4})/)) prefix = ImageV3.assetsLocation;
        let fullsrc = prefix + src;
        if (!useImageKit) return fullsrc;
        let operations = [];
        if (width) operations.push(`w-${width}`); // resize width
        if (height) operations.push(`h-${height}`); // resize height
        if (width && height) operations.push('c-at_max'); // if resizing w and h, no cropping.
        if (imageFormat) operations.push('f-' + imageFormat);
        const transformation = operations.length ? '/tr:' + operations.join(",") : "";

        return `https://ik.imagekit.io/spotlio/fetch${transformation}/${fullsrc}`;
    }

    renderFallbackImage() {
        const fallback = this.props.image?.fallback || this.props.image?.filename_disk || this.props.image?.imageFile?.filename_disk || this.props.image?.imageFile || this.props.image;
        return <ImageV2
            style={this.props.imgStyle || { objectFit: "cover", objectPosition: "center center" }}
            src={fallback}
            alt={this.props.alt || ""}
            className={this.props.className}
            useImageKit={this.props.useImageKit}
            onClick={this.props.onClick}
        />
    }

    render() {
        const fallback = this.props.image?.fallback || this.props.image?.filename_disk || this.props.image?.imageFile?.filename_disk || this.props.image?.imageFile || this.props.image;
        const image: IGatsbyImageData | undefined = this.props.image?.filedata ? getImage(this.props.image.filedata) : undefined;
        if (!image && typeof fallback === "string") return this.renderFallbackImage();
        if (!image) return null;
        return <GatsbyImage
            image={image as IGatsbyImageData}
            imgStyle={this.props.imgStyle || { objectFit: "cover", objectPosition: "center center" }}
            alt={this.props.alt || ""}
            className={this.props.className}
            draggable={this.props.draggable}
            loading={this.props.loading}
            onClick={this.props.onClick}
        />;
    }
}

export default ImageV3;