/**
 * Convert custom padding to tailwind padding.
 *
 * @param {"none" | "small" | "medium" | "large"} measure size of padding
 * @param {"top" | "bottom"} position top or bottom
 * @returns tailwind padding
 */
export function convertPadding(measure, position) {
    if (position === 'bottom') {
        if (measure === 'large') return 'pb-18 lg:pb-24';
        if (measure === 'medium') return 'pb-12 lg:pb-16';
        if (measure === 'small') return 'pb-6 lg:pb-8';
        return 'pb-0';
    } else {
        if (measure === 'large') return 'pt-18 lg:pt-24';
        if (measure === 'medium') return 'pt-12 lg:pt-16';
        if (measure === 'small') return 'pt-6 lg:pt-8';
        return 'pt-0';
    }
}


/**
 * Convert custom margin to tailwind margin.
 *
 * @param {"none" | "small" | "medium" | "large"} measure size of margin
 * @param {"top" | "bottom"} position top or bottom
 * @returns tailwind margin
 */
export function convertMargin(measure, position) {
    if (position === 'bottom') {
        if (measure === 'large') return 'mb-18 lg:mb-24';
        if (measure === 'medium') return 'mb-12 lg:mb-16';
        if (measure === 'small') return 'mb-6 lg:mb-8';
        return 'mb-0';
    } else {
        if (measure === 'large') return 'mt-18 lg:mt-24';
        if (measure === 'medium') return 'mt-12 lg:mt-16';
        if (measure === 'small') return 'mt-6 lg:mt-8';
        return 'mt-0';
    }
}
