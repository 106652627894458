const ios = "PLATFORM_IOS_WEBVIEW";
const andr = "PLATFORM_ANDROID_WEBVIEW";

const checkDevice = () => {
  const checkOS = () => {
    const ua = navigator.userAgent;

    if (/android/i.test(ua)) {
      return (userAgent.isAndroid = true);
    } else if (/iPad|iPhone|iPod/.test(ua) || navigator.maxTouchPoints > 1) {
      userAgent.isIos = true;
    }
    return "Other";
  };

  let userAgent = {
    isIos: false,
    isAndroid: false,
    isDesktop: false,
    isMobile: false,
    device: "",
    screenRes: {
      width: 0,
      height: 0,
    },
  };

  const isMobile =
    window.matchMedia("only screen and (max-width: 760px)").matches &&
    "ontouchstart" in document.documentElement &&
    navigator.userAgent.match(/Mobi|iPad|iPhone/);

  if (isMobile) {
    userAgent.isMobile = true;
    checkOS();
  } else {
    userAgent.isDesktop = true;
  }

  const ua = navigator.userAgent;

  if (ua.match(/chrome|chromium|crios/i)) {
    userAgent.device = "chrome";
  } else if (ua.match(/firefox|fxios/i)) {
    userAgent.device = "firefox";
  } else if (ua.match(/safari/i)) {
    userAgent.device = "safari";
  } else if (ua.match(/opr\//i)) {
    userAgent.device = "opera";
  } else if (ua.match(/edg/i)) {
    userAgent.device = "edge";
  } else if (ua.match(/iPhone|iPad/i)) {
    userAgent.device = "app-webview"
  } else {
    userAgent.device = "other";
  }

  userAgent.screenRes.width = window.screen.availWidth;
  userAgent.screenRes.height = window.screen.availHeight;

  return userAgent;
};

const getPlatformType = () => {
  const lte9 = /constructor/i.test(window.HTMLElement);
  const idb = !!window.indexedDB;

  if (typeof window.JSInterface === "object") {
    return andr;
  } else if (
    (window.webkit && window.webkit.messageHandlers) ||
    !lte9 ||
    idb
  ) {
    return ios;
  } else {
    return false;
  }
};

export default checkDevice;

function isSafari() {
  try {
    var ischrome = /chrome/i.test(navigator.userAgent);
    var isandroid = /android/i.test(navigator.userAgent);
    var isSafari = /safari/i.test(navigator.userAgent);
    if (ischrome || isandroid) return false;
    return isSafari;
  } catch (err) {
    return false;
  }
}

export { getPlatformType, isSafari, ios, andr };