import React, { startTransition } from "react";
import { getCurrentToastbarState, isAlreadyClosed, markAsClosed } from "src/modules/toastBar/toastBar.service";
import Icon from "src/components/icon";
import { isRunTime } from "src/utils/isRunTime";
import { getLanguageField } from "src/utils/language";
import { convertToHTML } from "src/utils/string";
import "./toastBar.css";

type Props = {
  toastbar: TToastbar;
  language: string;
  scrolledDown: boolean;
  defaultLanguage: string;
}

type State = {
  visible: boolean;
  open: boolean;
  online: boolean;
  text: string;
  device: "desktop" | "mobile";
  top: number | undefined;
}

class ToastBarV2 extends React.Component<Props, State> {

  static DESKTOP_HEIGHT = 80;
  static MOBILE_HEIGHT = 64;
  static MOBILE_WIDTH = 1024;

  state: State = {
    visible: this.props.toastbar?.visible,
    online: this.props.toastbar?.online,
    open: false,
    text: getLanguageField(this.props.toastbar.translations, this.props.language, "text") as string,
    device: isRunTime() ? (window.innerWidth >= ToastBarV2.MOBILE_WIDTH ? "desktop" : "mobile") : "mobile",
    top: undefined,
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
    if (prevProps.scrolledDown !== this.props.scrolledDown) {
      setTimeout(() => this.updateState(this.state.device), 500);
    }
  }

  onResize = () => {
    if (this.state.device === 'desktop' && window.innerWidth < ToastBarV2.MOBILE_WIDTH) return this.updateState('mobile');
    if (this.state.device === 'mobile' && window.innerWidth >= ToastBarV2.MOBILE_WIDTH) return this.updateState('desktop');
  }

  updateState(device: "mobile" | "desktop") {
    const topbar = document.getElementById('topbar');
    if (topbar) {
        const rect = topbar.getBoundingClientRect()
        return this.setState({ device, top: rect.top + rect.height });
    }
    this.setState({ device });
  }

  fetchOnline = async () => {
    try {
      const [state, text] = await getCurrentToastbarState(this.props.language, this.props.toastbar, this.props.defaultLanguage);
      if (state === "hidden") return;
      if (state === 'visible' && text !== '') { this.setState({ text, visible: true }, this.open); }
    } catch (err) {}
  }

  getToastbarFromProps = () => {
    const { visible } = this.props.toastbar;
    const { text } = this.state;
    if (visible && text !== "") this.setState({ visible: true}, this.open);
    return;
  }

  componentDidMount() {
    window.addEventListener('resize', this.onResize);
    startTransition(() => {
      this.updateState(this.state.device);
      if (this.state.online) this.fetchOnline();
      else this.getToastbarFromProps();
    });
  }

  componentWillUnmount(): void {
    window.removeEventListener('resize', this.onResize);
  }

  shouldComponentUpdate(nextProps: Readonly<Props>, nextState: Readonly<State>, nextContext: any): boolean {
    const languageChanged = nextProps.language !== this.props.language
    if (languageChanged){
      this.setState({text: getLanguageField(this.props.toastbar.translations, nextProps.language, "text") as string})
    }
    return true;
  }

  open() {
    const text = this.state.text;
    const alreadySeen = isAlreadyClosed(text);
    if (alreadySeen) return;
    this.setState({open: true});
  }

  close() {
    markAsClosed(this.state.text);
    this.setState({open: false});
  }

  public get height() {
    if (this.state.device === "mobile") return ToastBarV2.MOBILE_HEIGHT;
    return ToastBarV2.DESKTOP_HEIGHT;
  }

  public get color() {
    return this.props.toastbar.text_color?.value || '#282828';
  }

  public get backgroundColor() {
    return this.props.toastbar.background_color?.value || '#ffffff';
  }

  render() {
    if (!this.state.visible ||! this.state.text) return null;

    const style = {
        '--hyperlink-text-color': this.props.toastbar.hyperlink_text_color?.value,
        '--hyperlink-hover-color': this.props.toastbar.hyperlink_hover_color?.value,
        transition: "max-height 0.3s, top 0.3s",
        maxHeight: this.state.open ? "200px" : "0px",
        color: this.color,
        backgroundColor: this.backgroundColor,
        top: this.state.top,
    };

    return (
      <div className={`overflow-hidden sticky z-20`} style={style}>
        <div className="toastbar max-w-7xl mx-auto flex items-start font-semibold px-6 py-4 lg:px-10 space-x-4 text-sm sm:text-lg">
          <div className="flex-1 text-center toastbarText">
            {convertToHTML(this.state.text)}
          </div>
          <div className="flex flex-row self-center">
            <button onClick={() => this.close()} aria-label="Close banner">
                <Icon icon="X" className="w-6 h-6" />
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default ToastBarV2;