import * as React from "react"

const ClosedStatus = (props: any) => (
  <svg
    width={23}
    height={22}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x={0.5} width={22} height={22} rx={11} fill="#E10000" />
    <path
      d="m16.167 6.333-9.334 9.334m0-9.334 9.334 9.334"
      stroke="#fff"
      strokeWidth={1.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default ClosedStatus
