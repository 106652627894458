import * as React from "react"

const Chairlift = (props: any) => (
  <svg
    width={22}
    height={22}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18.333 2.75 3.667 6.417M16.311 18.333h-4.085c-2.708 0-4.904-2.157-4.904-4.818V10.53h4.495V5.022"
      stroke="currentColor"
      strokeWidth={1.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default Chairlift
