import React from "react";
import { Link as GatsbyLink } from "gatsby";

import DotsAnimation from "../dotsAnimation";

import { ApplicationContext } from "src/context";

type LinkProps = {
    /** link type */
    type?: "empty" | "external" | "internal";

    /** target attribute */
    target?: "_blank" | "_self";

    /** rel attribute */
    /** @default "external nofollow noopener noreferrer" when type=external */
    rel?: string;

    /** classname attribute */
    className?: string;

    /** title attribute */
    title?: string;

    /** href */
    href: string;

    /** link children nodes */
    children?: React.ReactNode;

    /** on click function */
    onClick?: ((event: React.MouseEvent<HTMLAnchorElement>) => void) |
              ((event: React.MouseEvent<HTMLButtonElement>) => void) |
              ((event: React.MouseEvent<HTMLDivElement>) => void);

    /** prevent hydration errors: <a> cannot appear as descendant of <a> */
    useDiv?: boolean;

    /** allow use of prop disabled (not compatible with <a>) */
    useButton?: boolean;

    /** ref to link element */
    linkRef?: React.RefObject<GatsbyLink<any>> |
            React.RefObject<HTMLAnchorElement> |
            React.RefObject<HTMLElement> |
            string;
   
    /** 
     * Only when internal link:
     * Used to declare that this link replaces the current URL in history with the target
     */
    replace?: boolean

    /**
     * Only when internal link: 
     * Used to pass state data to the linked page.
     * The linked page will have a `location` prop containing a nested `state` object structure containing the passed data.
     */ 
    state?: object;

    /** styles */
    style?: object;

    /* disabled state (i.e not filled data) */
    disabled?: boolean;

    /* loading state (i.e not filled data) */
    loading?: boolean;
}   

/**
 * Link component
 */
export default class Link extends React.Component<LinkProps> {

    /** default props when not defined */
    static defaultProps: Partial<LinkProps> = {
        className: "",
        rel: "external nofollow noopener noreferrer",
    }

    /** context: ApplicationContext */
    static contextType = ApplicationContext;
    /** @ts-ignore */
    context!: React.ContextType<typeof ApplicationContext>;

    /** If the url does not provide protocol / host, it is an internal link */
    isLocalUrl() {
        try {
            new URL(this.props.href);
            return false;
        } catch (err) {
            return true;
        }
    }

    /** determines link type, internal or external */
    getLinkType() {
        const {type, href} = this.props;
        if (this.isLocalUrl()) return 'internal';
        if (type) return type;
        if(!href || typeof href === 'undefined') return 'empty';
        if (
            href.indexOf("https://") >= 0 ||
            href.indexOf("http://") >= 0 ||
            href.indexOf("tel:") >= 0 ||
            href.indexOf("mailto:") >= 0 ||
            (this.props.target && this.props.target === "_blank")
        ) {
            return "external";
        }
        return "internal";
    }

    /** replaces {{variables}} from hrefs with global context */
    replaceVariables = (substring: string, match: string, position: BigInteger, fullmatch: string) => {
        const lowercase = match.toLowerCase();
        switch (lowercase) {
            case "currency": return this.context.currency || "";
            default: return "";
        }
    }

    /** get href with replaced variables */
    getHref(): string {
        if (!this.context.ready) return this.props.href || "";
        const replaced = (this.props.href || "").toString()
            .replace(/\{\{(.*?)\}\}/ig, this.replaceVariables);
        return replaced;
    }

    /** removes accents and other strange characters from links */
    getNormalizedLink(): string {
        const normalized = this.getHref()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .replace(/[^\w\s#?_\-&=,/\\]/gi, "");
        return normalized;
    }

    /**
     * handle click and stop propagation.
     */
    handleClick = (event: React.MouseEvent<any>) => {
        if (this.props.onClick) { this.props.onClick(event); }
        event.stopPropagation();
    };

    /**
     * render internal links with GatsbyLink
     */
    renderInternalLink(): React.ReactNode {
        return <GatsbyLink
            onClick={this.handleClick}
            className={this.props.className}
            to={this.getNormalizedLink()}
            title={this.props.title}
            state={this.props.state}
            ref={this.props.linkRef as any}
            style={this.props.style}
        >
            {this.props.children}
        </GatsbyLink>
    }

    /**
     * render external links with <a>
     */
    renderExternalLink(): React.ReactNode {
        return <a
                title={this.props.title}
                href={this.getHref()}
                className={this.props.className}
                onClick={this.handleClick}
                target={this.props.target || "_blank"}
                ref={this.props.linkRef as any}
                rel={this.props.rel}
                style={this.props.style}
            >
            {this.props.loading ? <DotsAnimation className="w-6 h-6 text-current"/> : this.props.children}
        </a>
    }

    /** renderDivElement: prevent hydration errors: <a> cannot appear as descendant of <a> */
    renderDivElement(): React.ReactNode {
        return <div 
            className={this.props.className}
            onClick={!(this.props.disabled) ? this.handleClick : undefined}
            style={this.props.style}
        >
            {this.props.loading ? <DotsAnimation className="w-6 h-6 text-current"/> : this.props.children}
        </div>;
    }

    /** renderDivElement: prevent hydration errors: <a> cannot appear as descendant of <a> */
    renderButtonElement(): React.ReactNode {
        return (
            <button 
                disabled={this.props.disabled || this.props.loading}
                className={this.props.className}
                onClick={!(this.props.disabled) ? this.handleClick : undefined}
                style={this.props.style}
            >
                {this.props.loading ? <DotsAnimation className="w-6 h-6 text-current"/> : this.props.children}
            </button>
        )
    }

    /** main link render */
    render(): React.ReactNode {
        if (this.props.useDiv) return this.renderDivElement();
        if (this.props.useButton) return this.renderButtonElement();
        if (this.getLinkType() === 'internal') return this.renderInternalLink();
        return this.renderExternalLink();
    }
}