import React from "react";

export default function Cabin2(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={26}
      height={26}
      fill="none"
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.8}
        d="m1.8 8.289 11.2-6.5 11.2 6.5"
      />
      <path stroke="currentColor" strokeWidth={1.8} d="M5 24.039h16" />
      <rect
        width={5}
        height={10}
        x={10.5}
        y={14.039}
        stroke="currentColor"
        strokeWidth={1.8}
        rx={0.5}
      />
      <path
        stroke="currentColor"
        strokeWidth={1.8}
        d="M21.5 2.039H18a.5.5 0 0 0-.5.5v2"
      />
      <path
        stroke="currentColor"
        strokeWidth={1.8}
        d="M22 7.539v-5a.5.5 0 0 0-.5-.5H19M20 19.039h1.5a2.5 2.5 0 0 1 0 5H20M20 14.039h1.5a2.5 2.5 0 0 1 0 5H20M19.5 9.039H21a2.5 2.5 0 0 1 0 5h-1.5M6.5 9.039H5a2.5 2.5 0 0 0 0 5h1.5M6.5 14.039H5a2.5 2.5 0 0 0 0 5h1.5M6.5 19.039H5a2.5 2.5 0 0 0 0 5h1.5M15 19.039h6M22 6.039v3M4 7.039v2M5 14.039h16M5 9.039h16M5 19.039h6"
      />
    </svg>
  );
}
