import React from 'react';

import Link from '../link';

import PoweredBySpotlio from "../../../static/images/poweredBySpotlio"

import * as styles from "./styles.module.scss";

export default function Socket({backgroundColor= '#001d28', color = "#ffffff"}) {
    const config = {
        link: "https://www.spotlio.com/",
        label: "Powered by SPOTLIO",
    };

    return (
    <div className={styles.socketWrapper} style={{backgroundColor, color}}>
        <div className={styles.socket}>
            <Link href={config.link} title={config.label} target="_blank">
                <PoweredBySpotlio />
            </Link>
        </div>
    </div>
    );
}



