import React from "react";
import ToolbarCommon from "../toolbar/toolbar.common";

export default class MobileToolbar extends ToolbarCommon {

    public get style(): any {
        const {toolbar} = this.props;
        return {
            '--text-color': toolbar.text_color?.value,
            '--hover-color': toolbar.hover_color?.value,
            '--background': toolbar.background_color?.value,

            '--dropdown-background': toolbar.dropdown_menu_background_color?.value || '#fff',
            '--dropdown-active-color': toolbar.text_color?.value,
            '--dropdown-text-color': toolbar.unselected_item_color?.value,
            '--dropdown-hover-color': toolbar.hover_color?.value,
        }
    }

    get items() {
        return this.props.toolbar.items.filter(item => item.item.mobile_fixed_at_top === true).map((item, index) => this.renderToolbarItem(item, index));
    }

    public render() {
        if (!this.items.length) return null;
        const heightClass = this.props.scrolledDown ? 'h-[40px]' : 'h-[56px]';

        return <div id="toolbar" className={`flex lg:hidden sticky top-0 z-[61] px-[24px] lg:px-[40px] toolbar flex-row ${heightClass} bg-[color:var(--background)] ${this.shadow}`} style={this.style}>
            <div className="mx-auto w-full max-w-7xl flex flex-row items-center justify-end relative gap-6">
                {this.items}
            </div>
        </div>
    }
}