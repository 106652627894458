import React from "react";

export default class TikTokIcon extends React.Component<{ className?: string }> {
    render() {
        return <>
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={this.props.className}>
        <g clipPath="url(#clip0_19556_9761)">
        <path fillRule="evenodd" clipRule="evenodd" d="M13.9515 16.9241C13.8979 18.3881 12.6553 19.5636 11.1449 19.5636C10.8021 19.5636 10.4701 19.4994 10.1594 19.3926C10.4701 19.5101 10.8021 19.5636 11.1449 19.5636C12.666 19.5636 13.8979 18.3881 13.9515 16.9241V3.83352H16.4152C16.6509 5.07312 17.4115 6.14173 18.4612 6.81496C19.1897 7.28515 20.0681 7.55231 21 7.55231V8.27896V10.8223C19.2647 10.8223 17.6578 10.2773 16.3403 9.35827V15.9944C16.3403 19.3071 13.5766 22.0107 10.1701 22.0107C8.85255 22.0107 7.64209 21.6046 6.63516 20.9207C5.04978 19.8307 4 18.0354 4 15.9944C4 12.6817 6.76371 9.97807 10.1701 9.97807C10.4486 9.97807 10.7272 9.99944 11.0057 10.0315V10.8009C7.89918 10.8757 5.36043 13.1839 5.01764 16.144C5.36043 13.1946 7.89918 10.8757 11.0057 10.8009V13.3656C10.7379 13.2801 10.4594 13.2373 10.1701 13.2373C8.61689 13.2373 7.35287 14.4663 7.35287 15.9837C7.35287 17.0416 7.96345 17.9499 8.86326 18.4094C9.2489 18.6125 9.6988 18.73 10.1701 18.73C11.6912 18.73 12.9231 17.5546 12.9767 16.0906V3H16.3296C16.3296 3.27784 16.3617 3.55568 16.4045 3.83352H13.9515V16.9241Z" fill="currentColor"/>
        </g>
        <defs>
        <clipPath id="clip0_19556_9761">
        <rect width="17" height="19" fill="white" transform="translate(4 3)"/>
        </clipPath>
        </defs>
        </svg>
        </>
    }
}