import * as React from "react"
const Companion = (props:any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={11}
    height={12}
    fill="none"
    viewBox="0 0 11 12"
    {...props}
  >
    <g fill="currentColor">
      <path d="M3.09 8a1.273 1.273 0 1 1 0-2.545A1.273 1.273 0 0 1 3.09 8Zm0-1.98a.707.707 0 1 0 0 1.414.707.707 0 0 0 0-1.414Z" />
      <path
        fillOpacity={0.2}
        d="M3.09 8a1.273 1.273 0 1 1 0-2.545A1.273 1.273 0 0 1 3.09 8Zm0-1.98a.707.707 0 1 0 0 1.414.707.707 0 0 0 0-1.414Z"
      />
      <path
        fillOpacity={0.2}
        d="M3.09 8a1.273 1.273 0 1 1 0-2.545A1.273 1.273 0 0 1 3.09 8Zm0-1.98a.707.707 0 1 0 0 1.414.707.707 0 0 0 0-1.414Z"
      />
      <path
        fillOpacity={0.2}
        d="M3.09 8a1.273 1.273 0 1 1 0-2.545A1.273 1.273 0 0 1 3.09 8Zm0-1.98a.707.707 0 1 0 0 1.414.707.707 0 0 0 0-1.414Z"
      />
      <path
        fillOpacity={0.2}
        d="M3.09 8a1.273 1.273 0 1 1 0-2.545A1.273 1.273 0 0 1 3.09 8Zm0-1.98a.707.707 0 1 0 0 1.414.707.707 0 0 0 0-1.414Z"
      />
      <path
        fillOpacity={0.2}
        d="M3.09 8a1.273 1.273 0 1 1 0-2.545A1.273 1.273 0 0 1 3.09 8Zm0-1.98a.707.707 0 1 0 0 1.414.707.707 0 0 0 0-1.414Z"
      />
      <path
        fillOpacity={0.2}
        d="M3.09 8a1.273 1.273 0 1 1 0-2.545A1.273 1.273 0 0 1 3.09 8Zm0-1.98a.707.707 0 1 0 0 1.414.707.707 0 0 0 0-1.414Z"
      />
      <path
        fillOpacity={0.2}
        d="M3.09 8a1.273 1.273 0 1 1 0-2.545A1.273 1.273 0 0 1 3.09 8Zm0-1.98a.707.707 0 1 0 0 1.414.707.707 0 0 0 0-1.414Z"
      />
      <path
        fillOpacity={0.2}
        d="M3.09 8a1.273 1.273 0 1 1 0-2.545A1.273 1.273 0 0 1 3.09 8Zm0-1.98a.707.707 0 1 0 0 1.414.707.707 0 0 0 0-1.414Z"
      />
      <path
        fillOpacity={0.2}
        d="M3.09 8a1.273 1.273 0 1 1 0-2.545A1.273 1.273 0 0 1 3.09 8Zm0-1.98a.707.707 0 1 0 0 1.414.707.707 0 0 0 0-1.414Z"
      />
      <path d="M7.455 2.91a1.455 1.455 0 1 1 0-2.91 1.455 1.455 0 0 1 0 2.91Zm0-2.425a.97.97 0 1 0 0 1.94.97.97 0 0 0 0-1.94Z" />
      <path
        fillOpacity={0.2}
        d="M7.455 2.91a1.455 1.455 0 1 1 0-2.91 1.455 1.455 0 0 1 0 2.91Zm0-2.425a.97.97 0 1 0 0 1.94.97.97 0 0 0 0-1.94Z"
      />
      <path
        fillOpacity={0.2}
        d="M7.455 2.91a1.455 1.455 0 1 1 0-2.91 1.455 1.455 0 0 1 0 2.91Zm0-2.425a.97.97 0 1 0 0 1.94.97.97 0 0 0 0-1.94Z"
      />
      <path
        fillOpacity={0.2}
        d="M7.455 2.91a1.455 1.455 0 1 1 0-2.91 1.455 1.455 0 0 1 0 2.91Zm0-2.425a.97.97 0 1 0 0 1.94.97.97 0 0 0 0-1.94Z"
      />
      <path
        fillOpacity={0.2}
        d="M7.455 2.91a1.455 1.455 0 1 1 0-2.91 1.455 1.455 0 0 1 0 2.91Zm0-2.425a.97.97 0 1 0 0 1.94.97.97 0 0 0 0-1.94Z"
      />
      <path
        fillOpacity={0.2}
        d="M7.455 2.91a1.455 1.455 0 1 1 0-2.91 1.455 1.455 0 0 1 0 2.91Zm0-2.425a.97.97 0 1 0 0 1.94.97.97 0 0 0 0-1.94Z"
      />
      <path
        fillOpacity={0.2}
        d="M7.455 2.91a1.455 1.455 0 1 1 0-2.91 1.455 1.455 0 0 1 0 2.91Zm0-2.425a.97.97 0 1 0 0 1.94.97.97 0 0 0 0-1.94Z"
      />
      <path
        fillOpacity={0.2}
        d="M7.455 2.91a1.455 1.455 0 1 1 0-2.91 1.455 1.455 0 0 1 0 2.91Zm0-2.425a.97.97 0 1 0 0 1.94.97.97 0 0 0 0-1.94Z"
      />
      <path
        fillOpacity={0.2}
        d="M7.455 2.91a1.455 1.455 0 1 1 0-2.91 1.455 1.455 0 0 1 0 2.91Zm0-2.425a.97.97 0 1 0 0 1.94.97.97 0 0 0 0-1.94Z"
      />
      <path
        fillOpacity={0.2}
        d="M7.455 2.91a1.455 1.455 0 1 1 0-2.91 1.455 1.455 0 0 1 0 2.91Zm0-2.425a.97.97 0 1 0 0 1.94.97.97 0 0 0 0-1.94Z"
      />
      <path d="M3.981 12a.665.665 0 0 1-.665-.665V10.27a.133.133 0 1 0-.266 0v1.065a.665.665 0 0 1-1.33 0V9.313s0-.008-.009-.01c-.008-.003-.01 0-.013 0l-.511.57A.683.683 0 0 1 .165 8.97l.273-.33a3.253 3.253 0 0 1 2.23-1.128l.49-.037h.025c.318 0 .673-.187.951-.5l.512-.574a.675.675 0 1 1 .989.918l-.989 1.055v2.96a.665.665 0 0 1-.665.666Zm-.798-2.395c.367 0 .665.298.665.665v1.065a.133.133 0 1 0 .266 0v-3.06c0-.068.026-.134.072-.183L5.25 6.954a.142.142 0 0 0 0-.202.154.154 0 0 0-.102-.037.141.141 0 0 0-.099.045l-.523.572c-.33.401-.812.646-1.33.676l-.482.037a2.725 2.725 0 0 0-1.862.945l-.28.325a.15.15 0 0 0 .225.2l.514-.566a.544.544 0 0 1 .94.364v2.022a.133.133 0 1 0 .266 0V10.27c0-.367.298-.665.666-.665Z" />
      <path
        fillOpacity={0.2}
        d="M3.981 12a.665.665 0 0 1-.665-.665V10.27a.133.133 0 1 0-.266 0v1.065a.665.665 0 0 1-1.33 0V9.313s0-.008-.009-.01c-.008-.003-.01 0-.013 0l-.511.57A.683.683 0 0 1 .165 8.97l.273-.33a3.253 3.253 0 0 1 2.23-1.128l.49-.037h.025c.318 0 .673-.187.951-.5l.512-.574a.675.675 0 1 1 .989.918l-.989 1.055v2.96a.665.665 0 0 1-.665.666Zm-.798-2.395c.367 0 .665.298.665.665v1.065a.133.133 0 1 0 .266 0v-3.06c0-.068.026-.134.072-.183L5.25 6.954a.142.142 0 0 0 0-.202.154.154 0 0 0-.102-.037.141.141 0 0 0-.099.045l-.523.572c-.33.401-.812.646-1.33.676l-.482.037a2.725 2.725 0 0 0-1.862.945l-.28.325a.15.15 0 0 0 .225.2l.514-.566a.544.544 0 0 1 .94.364v2.022a.133.133 0 1 0 .266 0V10.27c0-.367.298-.665.666-.665Z"
      />
      <path
        fillOpacity={0.2}
        d="M3.981 12a.665.665 0 0 1-.665-.665V10.27a.133.133 0 1 0-.266 0v1.065a.665.665 0 0 1-1.33 0V9.313s0-.008-.009-.01c-.008-.003-.01 0-.013 0l-.511.57A.683.683 0 0 1 .165 8.97l.273-.33a3.253 3.253 0 0 1 2.23-1.128l.49-.037h.025c.318 0 .673-.187.951-.5l.512-.574a.675.675 0 1 1 .989.918l-.989 1.055v2.96a.665.665 0 0 1-.665.666Zm-.798-2.395c.367 0 .665.298.665.665v1.065a.133.133 0 1 0 .266 0v-3.06c0-.068.026-.134.072-.183L5.25 6.954a.142.142 0 0 0 0-.202.154.154 0 0 0-.102-.037.141.141 0 0 0-.099.045l-.523.572c-.33.401-.812.646-1.33.676l-.482.037a2.725 2.725 0 0 0-1.862.945l-.28.325a.15.15 0 0 0 .225.2l.514-.566a.544.544 0 0 1 .94.364v2.022a.133.133 0 1 0 .266 0V10.27c0-.367.298-.665.666-.665Z"
      />
      <path
        fillOpacity={0.2}
        d="M3.981 12a.665.665 0 0 1-.665-.665V10.27a.133.133 0 1 0-.266 0v1.065a.665.665 0 0 1-1.33 0V9.313s0-.008-.009-.01c-.008-.003-.01 0-.013 0l-.511.57A.683.683 0 0 1 .165 8.97l.273-.33a3.253 3.253 0 0 1 2.23-1.128l.49-.037h.025c.318 0 .673-.187.951-.5l.512-.574a.675.675 0 1 1 .989.918l-.989 1.055v2.96a.665.665 0 0 1-.665.666Zm-.798-2.395c.367 0 .665.298.665.665v1.065a.133.133 0 1 0 .266 0v-3.06c0-.068.026-.134.072-.183L5.25 6.954a.142.142 0 0 0 0-.202.154.154 0 0 0-.102-.037.141.141 0 0 0-.099.045l-.523.572c-.33.401-.812.646-1.33.676l-.482.037a2.725 2.725 0 0 0-1.862.945l-.28.325a.15.15 0 0 0 .225.2l.514-.566a.544.544 0 0 1 .94.364v2.022a.133.133 0 1 0 .266 0V10.27c0-.367.298-.665.666-.665Z"
      />
      <path
        fillOpacity={0.2}
        d="M3.981 12a.665.665 0 0 1-.665-.665V10.27a.133.133 0 1 0-.266 0v1.065a.665.665 0 0 1-1.33 0V9.313s0-.008-.009-.01c-.008-.003-.01 0-.013 0l-.511.57A.683.683 0 0 1 .165 8.97l.273-.33a3.253 3.253 0 0 1 2.23-1.128l.49-.037h.025c.318 0 .673-.187.951-.5l.512-.574a.675.675 0 1 1 .989.918l-.989 1.055v2.96a.665.665 0 0 1-.665.666Zm-.798-2.395c.367 0 .665.298.665.665v1.065a.133.133 0 1 0 .266 0v-3.06c0-.068.026-.134.072-.183L5.25 6.954a.142.142 0 0 0 0-.202.154.154 0 0 0-.102-.037.141.141 0 0 0-.099.045l-.523.572c-.33.401-.812.646-1.33.676l-.482.037a2.725 2.725 0 0 0-1.862.945l-.28.325a.15.15 0 0 0 .225.2l.514-.566a.544.544 0 0 1 .94.364v2.022a.133.133 0 1 0 .266 0V10.27c0-.367.298-.665.666-.665Z"
      />
      <path
        fillOpacity={0.2}
        d="M3.981 12a.665.665 0 0 1-.665-.665V10.27a.133.133 0 1 0-.266 0v1.065a.665.665 0 0 1-1.33 0V9.313s0-.008-.009-.01c-.008-.003-.01 0-.013 0l-.511.57A.683.683 0 0 1 .165 8.97l.273-.33a3.253 3.253 0 0 1 2.23-1.128l.49-.037h.025c.318 0 .673-.187.951-.5l.512-.574a.675.675 0 1 1 .989.918l-.989 1.055v2.96a.665.665 0 0 1-.665.666Zm-.798-2.395c.367 0 .665.298.665.665v1.065a.133.133 0 1 0 .266 0v-3.06c0-.068.026-.134.072-.183L5.25 6.954a.142.142 0 0 0 0-.202.154.154 0 0 0-.102-.037.141.141 0 0 0-.099.045l-.523.572c-.33.401-.812.646-1.33.676l-.482.037a2.725 2.725 0 0 0-1.862.945l-.28.325a.15.15 0 0 0 .225.2l.514-.566a.544.544 0 0 1 .94.364v2.022a.133.133 0 1 0 .266 0V10.27c0-.367.298-.665.666-.665Z"
      />
      <path
        fillOpacity={0.2}
        d="M3.981 12a.665.665 0 0 1-.665-.665V10.27a.133.133 0 1 0-.266 0v1.065a.665.665 0 0 1-1.33 0V9.313s0-.008-.009-.01c-.008-.003-.01 0-.013 0l-.511.57A.683.683 0 0 1 .165 8.97l.273-.33a3.253 3.253 0 0 1 2.23-1.128l.49-.037h.025c.318 0 .673-.187.951-.5l.512-.574a.675.675 0 1 1 .989.918l-.989 1.055v2.96a.665.665 0 0 1-.665.666Zm-.798-2.395c.367 0 .665.298.665.665v1.065a.133.133 0 1 0 .266 0v-3.06c0-.068.026-.134.072-.183L5.25 6.954a.142.142 0 0 0 0-.202.154.154 0 0 0-.102-.037.141.141 0 0 0-.099.045l-.523.572c-.33.401-.812.646-1.33.676l-.482.037a2.725 2.725 0 0 0-1.862.945l-.28.325a.15.15 0 0 0 .225.2l.514-.566a.544.544 0 0 1 .94.364v2.022a.133.133 0 1 0 .266 0V10.27c0-.367.298-.665.666-.665Z"
      />
      <path
        fillOpacity={0.2}
        d="M3.981 12a.665.665 0 0 1-.665-.665V10.27a.133.133 0 1 0-.266 0v1.065a.665.665 0 0 1-1.33 0V9.313s0-.008-.009-.01c-.008-.003-.01 0-.013 0l-.511.57A.683.683 0 0 1 .165 8.97l.273-.33a3.253 3.253 0 0 1 2.23-1.128l.49-.037h.025c.318 0 .673-.187.951-.5l.512-.574a.675.675 0 1 1 .989.918l-.989 1.055v2.96a.665.665 0 0 1-.665.666Zm-.798-2.395c.367 0 .665.298.665.665v1.065a.133.133 0 1 0 .266 0v-3.06c0-.068.026-.134.072-.183L5.25 6.954a.142.142 0 0 0 0-.202.154.154 0 0 0-.102-.037.141.141 0 0 0-.099.045l-.523.572c-.33.401-.812.646-1.33.676l-.482.037a2.725 2.725 0 0 0-1.862.945l-.28.325a.15.15 0 0 0 .225.2l.514-.566a.544.544 0 0 1 .94.364v2.022a.133.133 0 1 0 .266 0V10.27c0-.367.298-.665.666-.665Z"
      />
      <path
        fillOpacity={0.2}
        d="M3.981 12a.665.665 0 0 1-.665-.665V10.27a.133.133 0 1 0-.266 0v1.065a.665.665 0 0 1-1.33 0V9.313s0-.008-.009-.01c-.008-.003-.01 0-.013 0l-.511.57A.683.683 0 0 1 .165 8.97l.273-.33a3.253 3.253 0 0 1 2.23-1.128l.49-.037h.025c.318 0 .673-.187.951-.5l.512-.574a.675.675 0 1 1 .989.918l-.989 1.055v2.96a.665.665 0 0 1-.665.666Zm-.798-2.395c.367 0 .665.298.665.665v1.065a.133.133 0 1 0 .266 0v-3.06c0-.068.026-.134.072-.183L5.25 6.954a.142.142 0 0 0 0-.202.154.154 0 0 0-.102-.037.141.141 0 0 0-.099.045l-.523.572c-.33.401-.812.646-1.33.676l-.482.037a2.725 2.725 0 0 0-1.862.945l-.28.325a.15.15 0 0 0 .225.2l.514-.566a.544.544 0 0 1 .94.364v2.022a.133.133 0 1 0 .266 0V10.27c0-.367.298-.665.666-.665Z"
      />
      <path
        fillOpacity={0.2}
        d="M3.981 12a.665.665 0 0 1-.665-.665V10.27a.133.133 0 1 0-.266 0v1.065a.665.665 0 0 1-1.33 0V9.313s0-.008-.009-.01c-.008-.003-.01 0-.013 0l-.511.57A.683.683 0 0 1 .165 8.97l.273-.33a3.253 3.253 0 0 1 2.23-1.128l.49-.037h.025c.318 0 .673-.187.951-.5l.512-.574a.675.675 0 1 1 .989.918l-.989 1.055v2.96a.665.665 0 0 1-.665.666Zm-.798-2.395c.367 0 .665.298.665.665v1.065a.133.133 0 1 0 .266 0v-3.06c0-.068.026-.134.072-.183L5.25 6.954a.142.142 0 0 0 0-.202.154.154 0 0 0-.102-.037.141.141 0 0 0-.099.045l-.523.572c-.33.401-.812.646-1.33.676l-.482.037a2.725 2.725 0 0 0-1.862.945l-.28.325a.15.15 0 0 0 .225.2l.514-.566a.544.544 0 0 1 .94.364v2.022a.133.133 0 1 0 .266 0V10.27c0-.367.298-.665.666-.665Z"
      />
      <path d="M8.587 12a.767.767 0 0 1-.76-.774V7.938a.192.192 0 0 0-.191-.193.192.192 0 0 0-.19.193v3.288c0 .428-.341.774-.761.774a.767.767 0 0 1-.762-.774V4.392c0-.142.114-.258.254-.258s.254.116.254.258v6.834c0 .143.113.258.254.258.14 0 .253-.115.253-.258V7.938c0-.391.313-.709.698-.709.385 0 .698.318.698.71v3.287c0 .143.113.258.253.258s.254-.115.254-.258V4.392c0-.142.114-.258.254-.258s.253.116.253.258v2.45c0 .143.114.258.254.258s.254-.115.254-.258V4.244a.814.814 0 0 0-.806-.819H6.218a.814.814 0 0 0-.802.819v2.405a.256.256 0 0 1-.254.258.256.256 0 0 1-.253-.258V4.244c0-.735.585-1.332 1.309-1.335h2.829c.348 0 .683.14.93.39s.386.59.386.945v2.598c0 .251-.12.487-.321.632a.75.75 0 0 1-.694.098v3.654c0 .428-.34.774-.76.774Z" />
      <path
        fillOpacity={0.2}
        d="M8.587 12a.767.767 0 0 1-.76-.774V7.938a.192.192 0 0 0-.191-.193.192.192 0 0 0-.19.193v3.288c0 .428-.341.774-.761.774a.767.767 0 0 1-.762-.774V4.392c0-.142.114-.258.254-.258s.254.116.254.258v6.834c0 .143.113.258.254.258.14 0 .253-.115.253-.258V7.938c0-.391.313-.709.698-.709.385 0 .698.318.698.71v3.287c0 .143.113.258.253.258s.254-.115.254-.258V4.392c0-.142.114-.258.254-.258s.253.116.253.258v2.45c0 .143.114.258.254.258s.254-.115.254-.258V4.244a.814.814 0 0 0-.806-.819H6.218a.814.814 0 0 0-.802.819v2.405a.256.256 0 0 1-.254.258.256.256 0 0 1-.253-.258V4.244c0-.735.585-1.332 1.309-1.335h2.829c.348 0 .683.14.93.39s.386.59.386.945v2.598c0 .251-.12.487-.321.632a.75.75 0 0 1-.694.098v3.654c0 .428-.34.774-.76.774Z"
      />
      <path
        fillOpacity={0.2}
        d="M8.587 12a.767.767 0 0 1-.76-.774V7.938a.192.192 0 0 0-.191-.193.192.192 0 0 0-.19.193v3.288c0 .428-.341.774-.761.774a.767.767 0 0 1-.762-.774V4.392c0-.142.114-.258.254-.258s.254.116.254.258v6.834c0 .143.113.258.254.258.14 0 .253-.115.253-.258V7.938c0-.391.313-.709.698-.709.385 0 .698.318.698.71v3.287c0 .143.113.258.253.258s.254-.115.254-.258V4.392c0-.142.114-.258.254-.258s.253.116.253.258v2.45c0 .143.114.258.254.258s.254-.115.254-.258V4.244a.814.814 0 0 0-.806-.819H6.218a.814.814 0 0 0-.802.819v2.405a.256.256 0 0 1-.254.258.256.256 0 0 1-.253-.258V4.244c0-.735.585-1.332 1.309-1.335h2.829c.348 0 .683.14.93.39s.386.59.386.945v2.598c0 .251-.12.487-.321.632a.75.75 0 0 1-.694.098v3.654c0 .428-.34.774-.76.774Z"
      />
      <path
        fillOpacity={0.2}
        d="M8.587 12a.767.767 0 0 1-.76-.774V7.938a.192.192 0 0 0-.191-.193.192.192 0 0 0-.19.193v3.288c0 .428-.341.774-.761.774a.767.767 0 0 1-.762-.774V4.392c0-.142.114-.258.254-.258s.254.116.254.258v6.834c0 .143.113.258.254.258.14 0 .253-.115.253-.258V7.938c0-.391.313-.709.698-.709.385 0 .698.318.698.71v3.287c0 .143.113.258.253.258s.254-.115.254-.258V4.392c0-.142.114-.258.254-.258s.253.116.253.258v2.45c0 .143.114.258.254.258s.254-.115.254-.258V4.244a.814.814 0 0 0-.806-.819H6.218a.814.814 0 0 0-.802.819v2.405a.256.256 0 0 1-.254.258.256.256 0 0 1-.253-.258V4.244c0-.735.585-1.332 1.309-1.335h2.829c.348 0 .683.14.93.39s.386.59.386.945v2.598c0 .251-.12.487-.321.632a.75.75 0 0 1-.694.098v3.654c0 .428-.34.774-.76.774Z"
      />
      <path
        fillOpacity={0.2}
        d="M8.587 12a.767.767 0 0 1-.76-.774V7.938a.192.192 0 0 0-.191-.193.192.192 0 0 0-.19.193v3.288c0 .428-.341.774-.761.774a.767.767 0 0 1-.762-.774V4.392c0-.142.114-.258.254-.258s.254.116.254.258v6.834c0 .143.113.258.254.258.14 0 .253-.115.253-.258V7.938c0-.391.313-.709.698-.709.385 0 .698.318.698.71v3.287c0 .143.113.258.253.258s.254-.115.254-.258V4.392c0-.142.114-.258.254-.258s.253.116.253.258v2.45c0 .143.114.258.254.258s.254-.115.254-.258V4.244a.814.814 0 0 0-.806-.819H6.218a.814.814 0 0 0-.802.819v2.405a.256.256 0 0 1-.254.258.256.256 0 0 1-.253-.258V4.244c0-.735.585-1.332 1.309-1.335h2.829c.348 0 .683.14.93.39s.386.59.386.945v2.598c0 .251-.12.487-.321.632a.75.75 0 0 1-.694.098v3.654c0 .428-.34.774-.76.774Z"
      />
      <path
        fillOpacity={0.2}
        d="M8.587 12a.767.767 0 0 1-.76-.774V7.938a.192.192 0 0 0-.191-.193.192.192 0 0 0-.19.193v3.288c0 .428-.341.774-.761.774a.767.767 0 0 1-.762-.774V4.392c0-.142.114-.258.254-.258s.254.116.254.258v6.834c0 .143.113.258.254.258.14 0 .253-.115.253-.258V7.938c0-.391.313-.709.698-.709.385 0 .698.318.698.71v3.287c0 .143.113.258.253.258s.254-.115.254-.258V4.392c0-.142.114-.258.254-.258s.253.116.253.258v2.45c0 .143.114.258.254.258s.254-.115.254-.258V4.244a.814.814 0 0 0-.806-.819H6.218a.814.814 0 0 0-.802.819v2.405a.256.256 0 0 1-.254.258.256.256 0 0 1-.253-.258V4.244c0-.735.585-1.332 1.309-1.335h2.829c.348 0 .683.14.93.39s.386.59.386.945v2.598c0 .251-.12.487-.321.632a.75.75 0 0 1-.694.098v3.654c0 .428-.34.774-.76.774Z"
      />
      <path
        fillOpacity={0.2}
        d="M8.587 12a.767.767 0 0 1-.76-.774V7.938a.192.192 0 0 0-.191-.193.192.192 0 0 0-.19.193v3.288c0 .428-.341.774-.761.774a.767.767 0 0 1-.762-.774V4.392c0-.142.114-.258.254-.258s.254.116.254.258v6.834c0 .143.113.258.254.258.14 0 .253-.115.253-.258V7.938c0-.391.313-.709.698-.709.385 0 .698.318.698.71v3.287c0 .143.113.258.253.258s.254-.115.254-.258V4.392c0-.142.114-.258.254-.258s.253.116.253.258v2.45c0 .143.114.258.254.258s.254-.115.254-.258V4.244a.814.814 0 0 0-.806-.819H6.218a.814.814 0 0 0-.802.819v2.405a.256.256 0 0 1-.254.258.256.256 0 0 1-.253-.258V4.244c0-.735.585-1.332 1.309-1.335h2.829c.348 0 .683.14.93.39s.386.59.386.945v2.598c0 .251-.12.487-.321.632a.75.75 0 0 1-.694.098v3.654c0 .428-.34.774-.76.774Z"
      />
      <path
        fillOpacity={0.2}
        d="M8.587 12a.767.767 0 0 1-.76-.774V7.938a.192.192 0 0 0-.191-.193.192.192 0 0 0-.19.193v3.288c0 .428-.341.774-.761.774a.767.767 0 0 1-.762-.774V4.392c0-.142.114-.258.254-.258s.254.116.254.258v6.834c0 .143.113.258.254.258.14 0 .253-.115.253-.258V7.938c0-.391.313-.709.698-.709.385 0 .698.318.698.71v3.287c0 .143.113.258.253.258s.254-.115.254-.258V4.392c0-.142.114-.258.254-.258s.253.116.253.258v2.45c0 .143.114.258.254.258s.254-.115.254-.258V4.244a.814.814 0 0 0-.806-.819H6.218a.814.814 0 0 0-.802.819v2.405a.256.256 0 0 1-.254.258.256.256 0 0 1-.253-.258V4.244c0-.735.585-1.332 1.309-1.335h2.829c.348 0 .683.14.93.39s.386.59.386.945v2.598c0 .251-.12.487-.321.632a.75.75 0 0 1-.694.098v3.654c0 .428-.34.774-.76.774Z"
      />
      <path
        fillOpacity={0.2}
        d="M8.587 12a.767.767 0 0 1-.76-.774V7.938a.192.192 0 0 0-.191-.193.192.192 0 0 0-.19.193v3.288c0 .428-.341.774-.761.774a.767.767 0 0 1-.762-.774V4.392c0-.142.114-.258.254-.258s.254.116.254.258v6.834c0 .143.113.258.254.258.14 0 .253-.115.253-.258V7.938c0-.391.313-.709.698-.709.385 0 .698.318.698.71v3.287c0 .143.113.258.253.258s.254-.115.254-.258V4.392c0-.142.114-.258.254-.258s.253.116.253.258v2.45c0 .143.114.258.254.258s.254-.115.254-.258V4.244a.814.814 0 0 0-.806-.819H6.218a.814.814 0 0 0-.802.819v2.405a.256.256 0 0 1-.254.258.256.256 0 0 1-.253-.258V4.244c0-.735.585-1.332 1.309-1.335h2.829c.348 0 .683.14.93.39s.386.59.386.945v2.598c0 .251-.12.487-.321.632a.75.75 0 0 1-.694.098v3.654c0 .428-.34.774-.76.774Z"
      />
      <path
        fillOpacity={0.2}
        d="M8.587 12a.767.767 0 0 1-.76-.774V7.938a.192.192 0 0 0-.191-.193.192.192 0 0 0-.19.193v3.288c0 .428-.341.774-.761.774a.767.767 0 0 1-.762-.774V4.392c0-.142.114-.258.254-.258s.254.116.254.258v6.834c0 .143.113.258.254.258.14 0 .253-.115.253-.258V7.938c0-.391.313-.709.698-.709.385 0 .698.318.698.71v3.287c0 .143.113.258.253.258s.254-.115.254-.258V4.392c0-.142.114-.258.254-.258s.253.116.253.258v2.45c0 .143.114.258.254.258s.254-.115.254-.258V4.244a.814.814 0 0 0-.806-.819H6.218a.814.814 0 0 0-.802.819v2.405a.256.256 0 0 1-.254.258.256.256 0 0 1-.253-.258V4.244c0-.735.585-1.332 1.309-1.335h2.829c.348 0 .683.14.93.39s.386.59.386.945v2.598c0 .251-.12.487-.321.632a.75.75 0 0 1-.694.098v3.654c0 .428-.34.774-.76.774Z"
      />
    </g>
  </svg>
)
export default Companion
