const getLanguageField = require("../../utils/requireFunctions");

/**
 * Transforms HTML code module:
 *
 * @param {LogosGridGraphData} inputData
 * @param {string} language
 * @returns {LogosGridModuleProps} props
 */
module.exports = function transform(inputData, language) {
    return {
        name: inputData.name,
        alignment: inputData.alignment,
        anchor: getLanguageField(inputData.translations, language, 'anchor'),
        title: getLanguageField(inputData.translations, language, 'title'),
        subtitle: getLanguageField(inputData.translations, language, 'subtitle'),
        description: getLanguageField(inputData.translations, language, 'description', true, true),
        mode: inputData.mode || 'grid',

        padding_bottom: inputData.padding_bottom,
        padding_top: inputData.padding_top,
        rounded_corners: inputData.rounded_corners,
        width: inputData.width,

        logos: inputData.items.map((item) => { return { name: item.item.name, href: item.item.href, image: item.item.image }}),

        title_color: inputData.title_color?.value,
        subtitle_color: inputData.subtitle_color?.value,
        description_color: inputData.description_color?.value,
        background_color: inputData.background_color?.value,
        items_background_color: inputData.items_background_color?.value,
        hyperlink_text_color: inputData.hyperlink_text_color?.value,
        hyperlink_hover_color: inputData.hyperlink_hover_color?.value,
        boxed_background_color: inputData.boxed_background_color?.value,
        selected_bubble_indicator_color: inputData.selected_bubble_indicator_color?.value,
        unselected_bubble_indicator_color: inputData.unselected_bubble_indicator_color?.value,
        arrow_color: inputData.arrow_color?.value,
        arrow_bubble_color: inputData.arrow_bubble_color?.value,
    }
}