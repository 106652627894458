import * as React from "react"
const PregnantProhibited = (props:any) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={86} height={87} viewBox="0 0 86 87" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        fill="#FFF"
        fillRule="nonzero"
        d="M43 86.06c23.702-.039 42.888-19.278 42.86-42.98C85.832 19.378 66.602.184 42.9.2 19.198.217-.005 19.438 0 43.14a42.92 42.92 0 0 0 43 42.92"
      />
      <path
        fill="#010101"
        fillRule="nonzero"
        d="M66 61.64a29.9 29.9 0 0 1-7.92 11.9S53.9 79.2 38.38 78c-15.52-1.2-18.47-6.9-18.47-6.9-2-3.2-2.73-5.15-1.12-9.83C20.9 55.09 24.4 47.06 22 37.56 20.22 30.7 16.58 25 18.55 15.89A37 37 0 0 1 36 8.38a39.09 39.09 0 0 1 19.15 2.83c.19 2.68-1.23 5.42-6.23 6.37C61.87 28.11 72.53 45.45 66 61.64m-13.7 6c9.28-2 12.91-11.85 10.51-22C60.41 35.49 50.23 28.88 41 30.9c-9.23 2.02-12.41 11.45-10 21.57 2.41 10.12 12 17.22 21.27 15.2m2.36-26.16c-3.56-5.52-8.73-8.59-11.56-6.88a3.5 3.5 0 0 0-1.49 2.11c-.89 1.21-1.64 3.88-1.85 7-2.26-3.48-4.78-5.68-5.9-5.07-1.12.61-.28 4.55 2.12 8.66 2.4 4.11 5.49 7 6.63 6.2.89-.63 1.6-3.84 1.91-5.69a21.78 21.78 0 0 0 4.22 4.78 6 6 0 0 0-3 4.61 6.247 6.247 0 0 0 12.44 1.17 5.94 5.94 0 0 0-1.48-4.48c1.91-2.24 1.14-7.46-2-12.41"
      />
      <path
        fill="#ED1C24"
        d="M71.39 14.87A38.53 38.53 0 0 1 83 43.11a38.91 38.91 0 0 1-11.7 28.34A38.64 38.64 0 0 1 43 83.15 38.63 38.63 0 0 1 14.66 71.5 38.61 38.61 0 0 1 2.91 43.21a38.87 38.87 0 0 1 11.75-28.4A38.57 38.57 0 0 1 42.9 3.12a38.66 38.66 0 0 1 28.44 11.69l.05.06ZM68.75 64.4a32.63 32.63 0 0 0 7.44-22.77 32.27 32.27 0 0 0-9.65-22 32.63 32.63 0 0 0-22.16-9.82 32.29 32.29 0 0 0-22.72 7.51l47 47 .09.08ZM64.14 69 17 22a32.92 32.92 0 0 0-7.5 22.69 32.62 32.62 0 0 0 9.82 22.17 32 32 0 0 0 22 9.6A32.72 32.72 0 0 0 64 69h.14Z"
      />
    </g>
  </svg>
)
export default PregnantProhibited
