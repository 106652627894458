
import React from "react";

export default function Skiing(props: any) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_1126_6343)">
        <path d="M13.0847 1.5C12.7566 1.5 12.442 1.62771 12.21 1.85502C11.978 2.08234 11.8477 2.39065 11.8477 2.71213C11.8477 3.0336 11.978 3.34191 12.21 3.56923C12.442 3.79655 12.7566 3.92425 13.0847 3.92425C13.4128 3.92425 13.7274 3.79655 13.9594 3.56923C14.1914 3.34191 14.3217 3.0336 14.3217 2.71213C14.3217 2.39065 14.1914 2.08234 13.9594 1.85502C13.7274 1.62771 13.4128 1.5 13.0847 1.5V1.5Z" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10" />
        <path d="M1.33301 8.54974L12.1812 14.6455C12.1812 14.6455 13.5858 15.2601 14.333 14.2643" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M7.33826 4.67175L2.84863 1.50909M13.074 8.46913L10.611 6.62063L10.5974 4.20486C10.5943 3.69577 10.1243 3.30304 9.61398 3.40001C9.03319 3.51032 8.29592 3.76123 7.69657 4.32426C7.34526 4.65456 6.97105 5.04062 6.63891 5.39699C6.08472 5.99154 6.20038 6.92791 6.88384 7.37518L9.14885 8.85822C9.52738 9.1061 9.51192 9.6564 9.1204 9.88368L6.54551 11.3764L13.074 8.46913Z" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_1126_6343">
          <rect width="16" height="16" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>

  );
}
