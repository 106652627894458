import React from "react";

export default function CalendarIcon(props: any) {
    return (
        <svg {...props} width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5 1.8335V3.04562" stroke="currentColor" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M11 1.8335V3.04562" stroke="currentColor" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M2 6.68213H14" stroke="currentColor" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M2 5.65186C2 4.54729 2.89543 3.65186 4 3.65186H12C13.1046 3.65186 14 4.54729 14 5.65186V13.167C14 14.2716 13.1046 15.167 12 15.167H4C2.89543 15.167 2 14.2716 2 13.167V5.65186Z" stroke="currentColor" strokeWidth="1.8"/>
        </svg>
    );
}