import * as React from "react"
const Users = (props:any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={18}
    viewBox="0 0 16 18"
    fill="none"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.3}
      d="M11.57 15.18c2.016 0 2.632-.307 2.82-.489.075-.073.093-.182.115-.285l.447-2.102a2.152 2.152 0 0 0-2.1-2.603h-.925m-2.1-2.5a2.867 2.867 0 0 0 2.837-2.897 2.867 2.867 0 0 0-2.887-2.847M5.483 15.444c1.722 0 2.334-.32 3.134-.574.528-.168.888-.627 1.004-1.17l.296-1.396a2.152 2.152 0 0 0-2.1-2.603h-4.67a2.152 2.152 0 0 0-2.099 2.603l.297 1.396c.115.543.476 1.002 1.003 1.17.8.255 1.412.575 3.135.575ZM8.345 4.316a2.867 2.867 0 0 1-2.862 2.872A2.867 2.867 0 0 1 2.62 4.316a2.867 2.867 0 0 1 2.863-2.872 2.867 2.867 0 0 1 2.862 2.872Z"
    />
  </svg>
)
export default Users
