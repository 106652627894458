import * as React from "react"

const Magiccarpet = (props: any) => (
  <svg
    width={22}
    height={22}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#a)">
      <g clipPath="url(#b)">
        <g clipPath="url(#c)" fill="currentColor">
          <circle
            cx={4.59}
            cy={12.39}
            r={1.146}
            transform="rotate(-12.789 4.59 12.39)"
          />
          <circle
            cx={8.613}
            cy={11.477}
            transform="rotate(-12.789 8.613 11.477)"
            r={1.146}
          />
          <circle
            cx={12.635}
            cy={10.563}
            transform="rotate(-12.789 12.635 10.563)"
            r={1.146}
          />
          <circle
            cx={16.658}
            cy={9.65}
            transform="rotate(-12.789 16.658 9.65)"
            r={1.146}
          />
        </g>
        <path
          d="M16.963 6.245 3.558 9.23c-1.747.396-2.832 2.177-2.424 3.976.409 1.8 2.156 2.938 3.903 2.541l13.405-2.984c1.747-.397 2.832-2.177 2.423-3.977-.408-1.8-2.155-2.937-3.902-2.54Z"
          stroke="currentColor"
          strokeWidth={1.8}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h22v22H0z" />
      </clipPath>
      <clipPath id="b">
        <path
          fill="#fff"
          transform="rotate(-2.789 123.982 8.25)"
          d="M0 0h22v11H0z"
        />
      </clipPath>
      <clipPath id="c">
        <path
          fill="#fff"
          transform="rotate(-12.789 53.033 -8.598)"
          d="M0 0h15.406v2.407H0z"
        />
      </clipPath>
    </defs>
  </svg>
)

export default Magiccarpet
