import * as React from "react"
const GreenNew = (props:any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22}
    height={22}
    viewBox="0 0 22 22"
    fill="none"
    {...props}
  >
    <path
      fill="#31AC0D"
      d="M11 18.334a7.333 7.333 0 1 0 0-14.667 7.333 7.333 0 0 0 0 14.667Z"
    />
  </svg>
)
export default GreenNew
