import React from 'react';
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const Image = ({ src, alt, className, backgroundColor, width = "1920", imgStyle, ...restProps }) => {
    if(src && src.childImageSharp) {
        const image = getImage(src);

        return <GatsbyImage backgroundColor={backgroundColor} image={image} alt={alt} className={className} {...restProps} />
    }

    if(src && typeof src === "string" && src.toLowerCase().match(/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}\.(jpg|jfif|png|gif|jpeg|webp|svg|avif)$/)) {
        return <img style={imgStyle ? imgStyle : { objectFit: "cover", objectPosition: "center center"}} src={`https://ik.imagekit.io/spotlio/fetch/tr:w-${width}/https://public-assets.spotliodata.com/${src}`} alt={alt} className={className} {...restProps} />
    }

    return <img style={imgStyle} src={src} alt={alt} className={className} {...restProps} />
}


Image.getImageUrl = function(src, useImageKit = false, width = "1920") {
    if(useImageKit && src && typeof src === "string" && src.toLowerCase().match(/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}\.(jpg|jfif|png|gif|jpeg|webp)$/)) {
        return `https://ik.imagekit.io/spotlio/fetch/tr:w-${width}/https://public-assets.spotliodata.com/${src}`;
    }
    return `https://public-assets.spotliodata.com/${src}`;
}

Image.getImageSize = function (src, useImageKit = false, width = "1920") {
    return new Promise((resolve, reject) => {
        const image_url = Image.getImageUrl(src, useImageKit, width);
        const img = new window.Image();
        img.onload = () => { resolve({width: img.width, height: img.height}); };
        img.src = image_url;
    });
}

export default Image;