import * as React from "react"


const Package = (props: any) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
    <path fillRule="evenodd" clipRule="evenodd" d="M8.84402 1.46644L14.1774 4.13311C14.6305 4.35826 14.9171 4.82048 14.9174 5.32644V11.6798C14.9171 12.1857 14.6305 12.648 14.1774 12.8731L8.84402 15.5398C8.46844 15.7277 8.02626 15.7277 7.65068 15.5398L2.31735 12.8731C1.86468 12.645 1.58049 12.18 1.58402 11.6731V5.32644C1.58429 4.82048 1.87091 4.35826 2.32402 4.13311L7.65735 1.46644C8.03111 1.28072 8.47026 1.28072 8.84402 1.46644Z" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M1.79688 4.60645L8.25021 7.83311L14.7035 4.60645" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M8.25 15.6735V7.8335" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M4.91602 2.8335L11.5827 6.16683" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
)

export default Package