import React from "react";

export default function provisionalModuleDataBasedOnLanguage(language) {
  if (!language || language === "nb-NO" || language === "no-NO") {
    return {
      subtitle: "Ordre #[orderId]",
      securedLogo: <svg
          id="LOGO_G"
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          viewBox="0 0 32 32"
        >
          <path
            id="Path_10"
            data-name="Path 10"
            d="M22.64,23.1h0l-3.3,3.3H34.672A12.488,12.488,0,0,1,13.8,31.927c-.03-.03-.059-.063-.089-.094-.114-.116-.227-.232-.334-.351-.076-.084-.149-.17-.222-.257-.059-.068-.119-.136-.177-.2-.084-.1-.164-.207-.245-.312-.043-.056-.087-.11-.128-.166-.084-.112-.164-.227-.244-.342-.036-.051-.072-.1-.107-.154-.078-.117-.153-.236-.228-.355-.033-.054-.067-.106-.1-.159-.069-.116-.136-.234-.2-.352-.034-.06-.069-.121-.1-.182-.059-.111-.116-.225-.172-.338-.036-.071-.072-.142-.106-.214-.049-.1-.1-.207-.141-.312-.038-.085-.076-.169-.112-.255s-.074-.186-.11-.28-.078-.2-.113-.3c-.03-.082-.056-.164-.084-.247-.038-.113-.075-.227-.11-.341-.022-.074-.042-.147-.063-.221-.035-.123-.069-.247-.1-.372-.017-.069-.032-.137-.048-.206-.03-.129-.059-.259-.085-.39-.014-.07-.026-.14-.038-.21-.023-.129-.046-.258-.066-.387-.012-.081-.021-.163-.031-.243-.015-.119-.031-.239-.043-.359s-.017-.215-.025-.323-.016-.189-.021-.285q-.015-.307-.015-.618a12.488,12.488,0,0,1,21.318-8.83l2.483-2.484A16,16,0,0,0,6.629,23.1V23.1q0,.392.02.785c.006.124.018.247.027.371s.018.272.031.408c.015.156.036.311.055.465.013.1.024.205.039.307.025.167.055.333.084.5.016.089.031.178.048.267.033.168.071.335.11.5.02.087.038.174.06.26.041.161.085.321.13.481.027.093.052.186.079.279.045.148.093.294.142.44.035.1.069.209.106.313.047.13.1.259.147.388s.091.237.14.354.1.222.144.332c.058.133.117.264.179.4.045.094.092.186.138.279.072.143.144.287.22.429.043.079.088.157.132.236.084.15.168.3.258.447.043.071.088.14.131.21.094.151.188.3.288.45.046.069.094.135.141.2.1.145.2.289.308.431.055.075.114.147.171.221.1.131.2.262.307.39.077.094.158.185.237.277s.179.211.272.314c.151.165.3.326.461.485.028.028.054.058.083.086A16,16,0,0,0,38.629,23.1H22.64Z"
            transform="translate(-6.629 -7.097)"
            fill="#86d1d1"
          />
        </svg>,
      securedText: "Sikret av Gausta",
      sideCtaText: "Til betalingsdetaljer",
      sideTitle: "Total",
      sideText: "Herav 12% MVA",
      firstName: "Fornavn",
      lastName: "Etternavn",
      email: "E-post",
      phone: "Mobiltelefon",
      address: "Addresse",
      postalCode: "Postnummer",
      city: "Sted",
      country: "Land",
      prefix: "Landskode",
      welcome: "Velkommen",
      logout: "Logg ut",
      login: "Logg inn",
      isRegistered: "Allerede registrert bruker?",
      userInfo: "Brukerinformasjon",
      userAccount: "Registrer ny brukerkonto",
      repeatEmail: "Gjenta e-post",
      newPassword: "Velg passord",
      password: "Passord",
      totalSteps: 3,
      currentStep: 1,
      stepTitle: 'Fyll inn nødvendig info',
      logoutModalTitle: 'Er du sikker på at du vil logge ut?',
      cancelButtonText: 'Avbryt',
      logoutButtonText: 'Logg ut',
      forgotPassword: "Glemt passord?",
      newPasswordLabel: 'Nytt passord',
      newPasswordDescription: 'Opprette nytt passord',
      newPasswordDescription2: 'For å opprette et nytt passord, skriv inn e-postadressen din:',
      repeatPasswordLabel: 'Gjenta passord',
      resetPassword: 'Ditt nye passord er nå lagret!',
      buttonContinueText: 'Fortsett',
      temporaryPasswordLabel: 'Midlertidig passord',
      temporaryPasswordDescription: 'Et nytt midlertidig passord har nå blitt sendt til deg på følgende e-postadresse:',
      temporaryPasswordDescription2: 'Dersom denne e-postadressen er registrert, så vil du nå motta et midlertidig passord i din innboks. Vennligst skriv inn dette i feltet under:',
      belowPassword: 'Under kan du skrive inn et selvvalgt passord:',
      accountNotFound: "Beklager, men det ser ikke ut til at denne e-postadressen er linket til noen brukerkonto.",
      wrongPassword: "Beklager, men det ser ikke ut til at passordet er riktig",
      unexpectedError: "Unexpected error",
      defaultCountry: 'NOR',
      defaultPrefix: '+47',
      nextUrl: '/review',
      unfilledField: "Vennligst fyll ut dette feltet.",
      invalidEmail: "Den introduserte e-posten er ugyldig.",
      invalidRepeatEmail: "E-postene må være like.",
      invalidPostalCode: "Postnummeret er ugyldig.",
      invalidPhone: "Telefonnummeret er ugyldig.",
      emailExistsError: 'Det ser ut til at denne e-postadressen allerede er tilknyttet en eksisterende brukerkonto.',
      loginButton: 'Logg inn',
      temporaryPasswordError: 'Feil midlertidig passord',
      newPasswordMatchError: 'Passord stemmer ikke',
      showLogin: true,
      savingsTitle: "Besparelser",
    }
  }

  if (language === "en-US") {
    return {
      securedLogo: <svg
        id="LOGO_G"
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 32 32"
      >
        <path
          id="Path_10"
          data-name="Path 10"
          d="M22.64,23.1h0l-3.3,3.3H34.672A12.488,12.488,0,0,1,13.8,31.927c-.03-.03-.059-.063-.089-.094-.114-.116-.227-.232-.334-.351-.076-.084-.149-.17-.222-.257-.059-.068-.119-.136-.177-.2-.084-.1-.164-.207-.245-.312-.043-.056-.087-.11-.128-.166-.084-.112-.164-.227-.244-.342-.036-.051-.072-.1-.107-.154-.078-.117-.153-.236-.228-.355-.033-.054-.067-.106-.1-.159-.069-.116-.136-.234-.2-.352-.034-.06-.069-.121-.1-.182-.059-.111-.116-.225-.172-.338-.036-.071-.072-.142-.106-.214-.049-.1-.1-.207-.141-.312-.038-.085-.076-.169-.112-.255s-.074-.186-.11-.28-.078-.2-.113-.3c-.03-.082-.056-.164-.084-.247-.038-.113-.075-.227-.11-.341-.022-.074-.042-.147-.063-.221-.035-.123-.069-.247-.1-.372-.017-.069-.032-.137-.048-.206-.03-.129-.059-.259-.085-.39-.014-.07-.026-.14-.038-.21-.023-.129-.046-.258-.066-.387-.012-.081-.021-.163-.031-.243-.015-.119-.031-.239-.043-.359s-.017-.215-.025-.323-.016-.189-.021-.285q-.015-.307-.015-.618a12.488,12.488,0,0,1,21.318-8.83l2.483-2.484A16,16,0,0,0,6.629,23.1V23.1q0,.392.02.785c.006.124.018.247.027.371s.018.272.031.408c.015.156.036.311.055.465.013.1.024.205.039.307.025.167.055.333.084.5.016.089.031.178.048.267.033.168.071.335.11.5.02.087.038.174.06.26.041.161.085.321.13.481.027.093.052.186.079.279.045.148.093.294.142.44.035.1.069.209.106.313.047.13.1.259.147.388s.091.237.14.354.1.222.144.332c.058.133.117.264.179.4.045.094.092.186.138.279.072.143.144.287.22.429.043.079.088.157.132.236.084.15.168.3.258.447.043.071.088.14.131.21.094.151.188.3.288.45.046.069.094.135.141.2.1.145.2.289.308.431.055.075.114.147.171.221.1.131.2.262.307.39.077.094.158.185.237.277s.179.211.272.314c.151.165.3.326.461.485.028.028.054.058.083.086A16,16,0,0,0,38.629,23.1H22.64Z"
          transform="translate(-6.629 -7.097)"
          fill="#86d1d1"
        />
      </svg>,
      securedText: "Secured by Gausta",
      subtitle: "Order #[orderId]",
      sideCtaText: "Go to payment details",
      sideTitle: "Total",
      sideText: "Total VAT",
      firstName: "First Name",
      lastName: "Last Name",
      email: "Email",
      phone: "Mobile Phone",
      address: "Address",
      postalCode: "Postal Code",
      city: "City",
      country: "Country",
      prefix: "Prefix",
      welcome: "Welcome",
      logout: "Log out",
      login: "Login",
      isRegistered: "Already have an account?",
      userInfo: "Personal Information",
      userAccount: "Register a New User Account",
      repeatEmail: "Repeat Email",
      newPassword: "Choose a Password",
      password: "Password",
      totalSteps: 3,
      currentStep: 1,
      stepTitle: 'Fill in the necessary information',
      logoutModalTitle: 'Are you sure you want to log out',
      cancelButtonText: 'Cancel',
      logoutButtonText: 'Log out',
      forgotPassword: "Forgot your password?",
      newPasswordLabel: 'New password',
      newPasswordDescription: 'Create new password',
      newPasswordDescription2: 'To create a new password, enter your email address:',
      repeatPasswordLabel: 'Repeat Password',
      resetPassword: 'Your new password is now saved!',
      buttonContinueText: 'Continue',
      temporaryPasswordLabel: 'Temporary password',
      temporaryPasswordDescription: 'A new temporary password has now been sent to you at the following email address:',
      temporaryPasswordDescription2: 'If this email address is registered, you will now receive a temporary password in your inbox. Please enter this in the field below:',
      belowPassword: 'Below you can enter a password of your choice:',
      accountNotFound: "Sorry, but this email address does not appear to be linked to any user account.",
      wrongPassword: "Sorry, but the password does not appear to be correct",
      unexpectedError: "Unexpected error",
      defaultCountry: 'FRE',
      defaultPrefix: '+33',
      nextUrl: '/en-US/review',
      unfilledField: "Please fill in this field.",
      invalidEmail: "The entered email is invalid.",
      invalidRepeatEmail: "Emails must match.",
      invalidPostalCode: "The postal code is invalid.",
      invalidPhone: "The phone number is invalid.",
      emailExistsError: 'It appears that this email address is already associated with an existing user account.',
      loginButton: 'Log in',
      showLogin: true,
      temporaryPasswordError: 'Incorrect temporary password',
      newPasswordMatchError: "Passwords don't match",
      savingsTitle: "Savings",
    }
  }

  if (language === "fr-FR") {
    return {
      subtitle: "Commande #[orderId]",
      sideCtaText: "Accéder au paiement",
      sideTitle: "Total",
      sideText: "TVA",
      firstName: "Prénom",
      lastName: "Nom",
      email: "E-mail",
      phone: "Téléphone mobile",
      address: "Adresse",
      postalCode: "Code postal",
      city: "Ville",
      country: "Pays",
      prefix: "Préfixe",
      welcome: "Bienvenue",
      logout: "Se déconnecter",
      login: "Connexion",
      isRegistered: "Vous avez déjà un compte ?",
      userInfo: "Informations personnelles",
      userAccount: "Enregistrez un nouveau compte utilisateur",
      repeatEmail: "Confirmez votre e-mail",
      newPassword: "Choisissez un mot de passe",
      password: " Mot de passe",
      totalSteps: 3,
      currentStep: 1,
      stepTitle: 'Remplissez les informations nécessaires',
      logoutModalTitle: 'Êtes-vous sûr de vouloir vous déconnecter?',
      cancelButtonText: 'Annuler',
      logoutButtonText: 'Déconnexion',
      forgotPassword: "Mot de passe oublié?",
      newPasswordLabel: 'Nouveau mot de passe',
      newPasswordDescription: 'Créer un nouveau mot de passe',
      newPasswordDescription2: 'Pour créer un nouveau mot de passe, saisissez votre adresse e-mail :',
      repeatPasswordLabel: 'Répéter le mot de passe',
      resetPassword: 'Votre nouveau mot de passe est maintenant enregistré !',
      buttonContinueText: 'Continuer',
      temporaryPasswordLabel: 'Mot de passe temporaire',
      temporaryPasswordDescription: "Un nouveau mot de passe temporaire vous a été envoyé à l'adresse e-mail suivante :",
      temporaryPasswordDescription2: 'Si cette adresse e-mail est enregistrée, vous recevrez maintenant un mot de passe temporaire dans votre boîte de réception. Veuillez le saisir dans le champ ci-dessous :',
      belowPassword: 'Ci-dessous vous pouvez saisir un mot de passe de votre choix :',
      accountNotFound: "Désolé, mais cette adresse e-mail ne semble pas être liée à un compte d'utilisateur.",
      wrongPassword: "Désolé, mais le mot de passe ne semble pas correct",
      unexpectedError: "Erreur inattendue",
      defaultCountry: 'FRA',
      defaultPrefix: '+33',
      nextUrl: '/review',
      unfilledField: "Veuillez remplir ce champ.",
      invalidEmail: "L'e-mail introduit est invalide.",
      invalidRepeatEmail: "Les e-mails doivent être identiques.",
      invalidPostalCode: "Le code postal est invalide.",
      invalidPhone: "Le numéro de téléphone est invalide.",
      emailExistsError: 'Il semble que cette adresse e-mail soit déjà associée à un compte utilisateur existant.',
      loginButton: 'Se connecter',
      showLogin: true,
      temporaryPasswordError: 'Mot de passe temporaire incorrect',
      newPasswordMatchError: "Les mots de passe ne correspondent pas",
      savingsTitle: "Des économies"
    }
  }

  return {
    subtitle: "Ordre #[orderId]",
    sideCtaText: "Vers les détails de paiement",
    sideTitle: "Totale",
    sideText: "TVA totale",
    firstName: "Prénom",
    lastName: "Nom",
    email: "E-mail",
    phone: "Téléphone mobile",
    address: "Adresse",
    postalCode: "Code postal",
    city: "Ville",
    country: "Pays",
    prefix: "Préfixe",
    welcome: "Bienvenu",
    logout: "Se déconnecter",
    login: "Connexion",
    isRegistered: "Vous avez déjà un compte ?",
    userInfo: "Informations personnelles",
    userAccount: "Enregistrez un nouveau compte utilisateur",
    repeatEmail: "Répétez l'email",
    newPassword: "Choisissez un mot de passe",
    password: " Mot de passe",
    totalSteps: 3,
    currentStep: 1,
    stepTitle: 'Remplissez les informations nécessaires',
    logoutModalTitle: 'Are you sure you want to log out',
    cancelButtonText: 'Cancel',
    logoutButtonText: 'Log out',
    forgotPassword: "Forgot your password?",
    newPasswordLabel: 'New password',
    newPasswordDescription: 'Create new password',
    newPasswordDescription2: 'To create a new password, enter your email address:',
    repeatPasswordLabel: 'Repeat Password',
    resetPassword: 'Your new password is now saved!',
    buttonContinueText: 'Continue',
    temporaryPasswordLabel: 'Temporary password',
    temporaryPasswordDescription: 'A new temporary password has now been sent to you at the following email address:',
    temporaryPasswordDescription2: 'If this e-mail address is registered, you will now receive a temporary password in your inbox. Please enter this in the field below:',
    belowPassword: 'Below you can enter a password of your choice:',
    accountNotFound: "Sorry, but this email address does not appear to be linked to any user account.",
    wrongPassword: "Sorry, but the password does not appear to be correct",
    unexpectedError: "Unexpected error",
    defaultCountry: 'FRE  ',
    defaultPrefix: '+33',
    nextUrl: '/review',
    unfilledField: "Veuillez remplir ce champ.",
    invalidEmail: "L'e-mail introduit est invalide.",
    invalidRepeatEmail: "Les e-mails doivent être identiques.",
    invalidPostalCode: "Le code postal est invalide.",
    invalidPhone: "Le numéro de téléphone est invalide.",
    emailExistsError: 'Il semble que cette adresse e-mail soit déjà associée à un compte utilisateur existant.',
    loginButton: 'Se connecter',
    showLogin: true,
    temporaryPasswordError: 'Mot de passe temporaire incorrect',
    newPasswordMatchError: "Les mots de passe ne correspondent pas",
    savingsTitle: "Des économies"
  }
}