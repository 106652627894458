import * as React from "react"
const StrobeLights = (props:any) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={86} height={87} viewBox="0 0 86 87" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        fill="#FFF"
        fillRule="nonzero"
        d="M43 86.06c23.702-.039 42.888-19.278 42.86-42.98C85.832 19.378 66.602.184 42.9.2 19.198.217-.005 19.438 0 43.14a42.92 42.92 0 0 0 43 42.92"
      />
      <path
        fill="#231F20"
        d="M40.45 49.86c2.87.52 4.26-.33 6.62-1.55.31.07.93.72.9 1.06a10.06 10.06 0 0 1-7.73 1.85 4 4 0 0 0 .21-1.36"
      />
      <path
        stroke="#231F20"
        strokeWidth={0.01}
        d="M40.45 49.86c2.87.52 4.26-.33 6.62-1.55.31.07.93.72.9 1.06a10.06 10.06 0 0 1-7.73 1.85 4 4 0 0 0 .21-1.36Z"
      />
      <path fill="#231F20" d="M40.45 49.86h-.14.14" />
      <path stroke="#231F20" strokeWidth={0.01} d="M40.45 49.86h-.14.14Z" />
      <path
        fill="#231F20"
        d="M72.16 47.57H47.24a14.19 14.19 0 0 0 4.5-6.38h20.42a3.24 3.24 0 0 1 3.23 3.24 3.16 3.16 0 0 1-3.23 3.18"
      />
      <path
        stroke="#231F20"
        strokeWidth={0.01}
        d="M72.16 47.57H47.24a14.19 14.19 0 0 0 4.5-6.38h20.42a3.24 3.24 0 0 1 3.23 3.24 3.16 3.16 0 0 1-3.23 3.14Z"
      />
      <path
        fill="#231F20"
        d="m64.09 40-7.67-5.33c-2.3-1.7-2.59-.51-3.41 1.49-.84 3.23-3.25 8.55-6.07 10.6a11.84 11.84 0 0 1-3.6 1.66v-10c.91 0 1.8-1.06 2.46-2.12 1.56-2.59 2.61-5.16 4.2-7.3 2-2.8 5.31-2.79 8.05-.86l9.68 6.6a3.2 3.2 0 0 1 1.46 2.68A3.24 3.24 0 0 1 66 40.63a2.92 2.92 0 0 1-1.91-.63"
      />
      <path
        stroke="#231F20"
        strokeWidth={0.01}
        d="m64.09 40-7.67-5.33c-2.3-1.7-2.59-.51-3.41 1.49-.84 3.23-3.25 8.55-6.07 10.6a11.84 11.84 0 0 1-3.6 1.66v-10c.91 0 1.8-1.06 2.46-2.12 1.56-2.59 2.61-5.16 4.2-7.3 2-2.8 5.31-2.79 8.05-.86l9.68 6.6a3.2 3.2 0 0 1 1.46 2.68A3.24 3.24 0 0 1 66 40.63a2.92 2.92 0 0 1-1.91-.63Z"
      />
      <path
        fill="#231F20"
        d="M57.44 25c.05.2-.23 1.24-.5 1.31-4.27-1.91-6.08 0-7.76 1.83a1.37 1.37 0 0 1-.86-1A6.66 6.66 0 0 1 57.44 25"
      />
      <path
        stroke="#231F20"
        strokeWidth={0.01}
        d="M57.44 25c.05.2-.23 1.24-.5 1.31-4.27-1.91-6.08 0-7.76 1.83a1.37 1.37 0 0 1-.86-1A6.66 6.66 0 0 1 57.44 25Z"
      />
      <path
        fill="#231F20"
        d="m41.6 48.59-1-.05a2.78 2.78 0 0 0-4.5-2.3l-1.51 1.14v.52l1.13-.9c3.56-2.76 5.69 1.84 3.61 3.4l-7.94 6.45a2.13 2.13 0 0 1-2.53-.6L22 48.44v-7.78L32.19 33l2.3-5.81c1.21-2.92 6-1.39 4.89 1.85l-2.72 7.17-2.87 2.19h7.84l-.03 10.19Zm-3.69-4.18a3.16 3.16 0 0 0-2.57.65l-1.56 1.15c.89-1.68 2.94-3 4.13-1.8ZM33 48.56l-2.41 1.83-1.75-2.07 4.16.24Z"
      />
      <path
        stroke="#231F20"
        strokeWidth={0.01}
        d="m41.6 48.59-1-.05a2.78 2.78 0 0 0-4.5-2.3l-1.51 1.14v.52l1.13-.9c3.56-2.76 5.69 1.84 3.61 3.4l-7.94 6.45a2.13 2.13 0 0 1-2.53-.6L22 48.44v-7.78L32.19 33l2.3-5.81c1.21-2.92 6-1.39 4.89 1.85l-2.72 7.17-2.87 2.19h7.84l-.03 10.19Zm-3.69-4.18a3.16 3.16 0 0 0-2.57.65l-1.56 1.15c.89-1.68 2.94-3.04 4.13-1.8ZM33 48.56l-2.41 1.83-1.75-2.07 4.16.24Z"
      />
      <path
        fill="#231F20"
        d="M41.28 30.45a1.41 1.41 0 0 1-1-.44c-.07-.94 1.49-2.45-.54-4.33a1.72 1.72 0 0 1 .91-1c1.9 1.58 1.48 3.93.67 5.76"
      />
      <path
        stroke="#231F20"
        strokeWidth={0.01}
        d="M41.28 30.45a1.41 1.41 0 0 1-1-.44c-.07-.94 1.49-2.45-.54-4.33a1.72 1.72 0 0 1 .91-1c1.86 1.59 1.44 3.94.63 5.77Z"
      />
      <path
        fill="#231F20"
        d="M35.79 24.2a3.94 3.94 0 0 0-3 3.15 1.06 1.06 0 0 1-1.12-.35c.39-1.68 2-3.95 4.1-4a2 2 0 0 1 0 1.18"
      />
      <path
        stroke="#231F20"
        strokeWidth={0.01}
        d="M35.79 24.2a3.94 3.94 0 0 0-3 3.15 1.06 1.06 0 0 1-1.12-.35c.39-1.68 2-3.95 4.1-4a2 2 0 0 1 .02 1.2Z"
      />
      <path
        fill="#231F20"
        d="m25.14 35.74-2-.97-2.12 5.54.59-9.15 1.95 1.21 1.98-6.72z"
      />
      <path
        stroke="#231F20"
        strokeWidth={0.01}
        d="m25.14 35.74-2-.97-2.12 5.54.59-9.15 1.95 1.21 1.98-6.72z"
      />
      <path
        fill="#231F20"
        d="m23.86 63.25-1.95-7.07-2.09 1.58.95-10.11 1.08 5.44 1.68-1.07z"
      />
      <path
        stroke="#231F20"
        strokeWidth={0.01}
        d="m23.86 63.25-1.95-7.07-2.09 1.58.95-10.11 1.08 5.44 1.68-1.07z"
      />
      <path
        fill="#231F20"
        d="M15.63 39.11a5.13 5.13 0 1 1-5.12 5.13 5.15 5.15 0 0 1 5.12-5.13"
      />
      <path
        stroke="#231F20"
        strokeWidth={0.01}
        d="M15.63 39.11a5.13 5.13 0 1 1-5.12 5.13 5.15 5.15 0 0 1 5.12-5.13Z"
      />
      <path
        fill="#ED1C24"
        d="M71.39 14.87A38.53 38.53 0 0 1 83 43.11a38.91 38.91 0 0 1-11.7 28.34A38.64 38.64 0 0 1 43 83.15 38.63 38.63 0 0 1 14.66 71.5 38.61 38.61 0 0 1 2.91 43.21a38.87 38.87 0 0 1 11.75-28.4A38.57 38.57 0 0 1 42.9 3.12a38.66 38.66 0 0 1 28.44 11.69l.05.06ZM68.75 64.4a32.63 32.63 0 0 0 7.44-22.77 32.27 32.27 0 0 0-9.65-22 32.63 32.63 0 0 0-22.16-9.82 32.29 32.29 0 0 0-22.72 7.51l47 47 .09.08ZM64.14 69 17 22a32.92 32.92 0 0 0-7.5 22.69 32.62 32.62 0 0 0 9.82 22.17 32 32 0 0 0 22 9.6A32.72 32.72 0 0 0 64 69h.14Z"
      />
    </g>
  </svg>
)
export default StrobeLights
