import * as React from "react"
const PendulumRide = (props:any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      d="M7.888 3.333c-.7 0-1.268.578-1.268 1.288 0 .71.568 1.288 1.268 1.288.7 0 1.267-.577 1.267-1.288 0-.71-.568-1.288-1.267-1.288Zm9.172.493-.783.795H9.79c0 .227-.038.443-.109.644h5.963l-.525.533.446 1.349-3.823 3.883.554 1.52a.49.49 0 0 1 .188-.03c.26 0 .466.208.466.473a.47.47 0 0 1-.327.452l.505 1.399c.877-.45 1.736-1.11 2.576-1.962 4.765-4.84 1.607-8.755 1.575-8.794l-.218-.262ZM9.235 5.98a1.876 1.876 0 0 1-2.695.01L2.52 17.067c-.036.1-.02.205.04.292.059.087.153.141.257.141h.951a.317.317 0 0 0 .297-.211L7.888 6.956l3.823 10.333a.317.317 0 0 0 .297.211h.95a.313.313 0 0 0 .258-.14.318.318 0 0 0 .04-.293L9.235 5.98ZM7.888 8.626 7.57 9.5v3.964l-.783.795.258.222c.052.044 1.292 1.086 3.248 1.086.034 0 .066-.009.1-.01l-1.15-3.099-.515-.17-.525.532v-3.32l-.316-.875Zm7.924.503c.26 0 .475.219.475.483a.474.474 0 0 1-.475.473.466.466 0 0 1-.466-.473c0-.264.206-.483.466-.483Zm-1.11 1.127c.26 0 .476.219.476.483a.474.474 0 0 1-.476.473.474.474 0 0 1-.475-.473c0-.264.215-.483.475-.483Zm-1.11 1.127c.26 0 .466.219.466.483a.474.474 0 0 1-.465.482.483.483 0 0 1 0-.965Z"
    />
  </svg>
)
export default PendulumRide
