import * as React from "react"
const SimulatorRide = (props:any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={10}
    viewBox="0 0 18 10"
    fill="none"
    {...props}
  >
    <g fill="currentColor">
      <path d="M.75 4v4.217C.75 9.2 1.496 10 2.415 10H7.5c.243 0 .451-.13.572-.353l.556-1.04a.412.412 0 0 1 .746 0l.555 1.04c.121.223.33.353.572.353h5.084c.92 0 1.666-.799 1.666-1.783V4H.75Zm6.575 3.752c0 .483-.364.873-.815.873H3.127c-.451 0-.816-.39-.816-.873V6.266c0-.483.365-.873.816-.873h3.366c.45 0 .815.39.815.873v1.486h.017Zm8.346 0c0 .483-.364.873-.816.873H11.49c-.45 0-.815-.39-.815-.873V6.266c0-.483.364-.873.816-.873h3.365c.452 0 .816.39.816.873v1.486ZM.75 4 5.949.12a.646.646 0 0 1 .687-.04l1.448.894c.204.122.223.447.037.609l-.148.122c-.093.06-.204.101-.297.06L6.432 1.36 3.776 4H.75ZM17.25 4 12.386.12a.572.572 0 0 0-.643-.04l-1.338.894c-.191.122-.209.447-.035.609l.122.102c.087.06.19.101.278.06l1.164-.406L14.418 4h2.832Z" />
    </g>
  </svg>
)
export default SimulatorRide
