import * as React from "react"
const LongPasses = (props:any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={27}
    height={27}
    fill="none"
    viewBox="0 0 27 27"
    {...props}
  >
    <g stroke="currentColor" strokeWidth={1.4} clipPath="url(#a)">
      <path
        strokeLinecap="round"
        strokeMiterlimit={10}
        d="M17.023 5.743h4.426c1.087 0 1.968.92 1.968 2.053v13.31c0 1.134-.881 2.053-1.968 2.053h-9.053m.692 0h-7.87c-1.087 0-1.968-.919-1.968-2.053V7.796c0-1.134.881-2.053 1.968-2.053h12.336"
      />
      <ellipse cx={13.104} cy={12.233} rx={2.005} ry={1.908} />
      <path d="M9.667 19.344a2.75 2.75 0 0 1 2.75-2.75h1.375a2.75 2.75 0 0 1 2.75 2.75c0 .506-.41.916-.917.916h-5.041a.917.917 0 0 1-.917-.916Z" />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m10.584 2.993 2.75 3.666M16.084 2.993l-2.75 3.666"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M2.333 2.076h22v22h-22z" />
      </clipPath>
    </defs>
  </svg>
)
export default LongPasses
