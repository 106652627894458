import { dispatch } from "./events";

const _localStorage = {};

const setToLocalStorage = (name, settings) => {
  try {
    if (typeof window !== "undefined" && window.localStorage) {
      const aux = JSON.stringify(settings);
      localStorage.setItem(name, aux);
    }
  } catch (err) {
    _localStorage[name] = settings;
  }
};

const getFromLocalStorage = (name) => {
  try {
    if (typeof window !== "undefined" && window.localStorage) {
      const item = localStorage.getItem(name);
      if (item) {
        try {
          return JSON.parse(item);
        } catch (e) {
          return item;
        }
      }
    }
  } catch (err) {
    return _localStorage[name];
  }
};

const setSession = (session) => {
  setToLocalStorage("session", session);
  dispatch('setSession', session);
};

const setCurrentStep = (step) => {
  setToLocalStorage("step", step);
}

const getCurrentStep = () => {
  return getFromLocalStorage('step');
}

const setCurrentCurrency = (step) => {
  setToLocalStorage("currency", step);
  dispatch('currency-changed', step);
}

const getCurrentCurrency = (defaultValue) => {
  const value = getFromLocalStorage('currency');
  if (defaultValue) {
    if (!value || (value && value === 'undefined')) { // extra condition to check if we stored undefined as string
      setCurrentCurrency(defaultValue);
      return defaultValue;
    }
  }
  return value;
}

const setCurrencyExchange = (data) => {
  setToLocalStorage("currency_exchange", data);
}

const getCurrencyExchange = () => {
  return getFromLocalStorage("currency_exchange");
}

const setCurrentUnits = (units) => {
  setToLocalStorage("units", units);
}

const getCurrentUnits = (defaultValue) => {
  const value = getFromLocalStorage('units');
  if (!value && defaultValue) { return defaultValue; }
  return value;
}

const getSession = () => {
  return getFromLocalStorage("session");
};

const setOldSession = (session) => {
  setToLocalStorage("oldSession", session);
};

const getOldSession = () => {
  return getFromLocalStorage("oldSession");
};

const getAramonForm = () => {
  return getFromLocalStorage("aramonForm");
};

const setAramonForm = (form) => {
  setToLocalStorage("aramonForm", form);
};

const getCart = () => {
  const cart = getFromLocalStorage("session");
  if (cart?.cart) {
    return cart.cart
  }

  return false;
}

const getCartImages = (isPromise = false) => {
  return getFromLocalStorage("cartImages", isPromise);
}

const setCartImages = (images) => {
  return setToLocalStorage("cartImages", images);
}

const getUserIpGeoInfo = (isPromise = false) => {
  return getFromLocalStorage("userIpGeoInfo", isPromise);
}

const setUserIpGeoInfo = (store) => {
  return setToLocalStorage("userIpGeoInfo", store);
}

const getChangeStoreDisclaimer = () => {
  return getFromLocalStorage("changeStoreDisclaimer");
}

const setChangeStoreDisclaimer = (data) => {
  setToLocalStorage("changeStoreDisclaimer", data);
  dispatch('change-store-disclaimer', data);
}
const clearStorage = () => {
  localStorage.clear();
}

export {
  getCurrentStep,
  setCurrentStep,
  setCurrentCurrency,
  getCurrentCurrency,
  setSession,
  getSession,
  setOldSession,
  getOldSession,
  setAramonForm,
  getAramonForm,
  clearStorage,
  setToLocalStorage,
  getFromLocalStorage,
  setCurrentUnits,
  getCurrentUnits,
  getCart,
  setCurrencyExchange,
  getCurrencyExchange,
  getCartImages,
  setCartImages,
  getUserIpGeoInfo,
  setUserIpGeoInfo,
  getChangeStoreDisclaimer,
  setChangeStoreDisclaimer,
};
