import * as React from "react";
const LongPasses2 = (props:any) => (
  <svg
    width={26}
    height={26}
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_2260_2896)">
      <path
        d="M16.689 4.6665H21.1158C22.2024 4.6665 23.0833 5.58588 23.0833 6.71984V20.0298C23.0833 21.1638 22.2024 22.0832 21.1158 22.0832H12.062M12.754 22.0832H4.8841C3.79756 22.0832 2.91663 21.1638 2.91663 20.0298V6.71984C2.91663 5.58588 3.79756 4.6665 4.8841 4.6665H17.2209"
        stroke="currentColor"
        strokeWidth={1.4}
        strokeMiterlimit={10}
        strokeLinecap="round"
      />
      <ellipse
        cx={12.9874}
        cy={11.1577}
        rx={2.00521}
        ry={1.90766}
        stroke="currentColor"
        strokeWidth={1.4}
      />
      <path
        d="M9.54993 18.2681C9.54993 16.7493 10.7811 15.5181 12.2999 15.5181H12.9874H13.6749C15.1937 15.5181 16.4249 16.7493 16.4249 18.2681V18.2681C16.4249 18.7743 16.0145 19.1847 15.5083 19.1847H10.4666C9.96033 19.1847 9.54993 18.7743 9.54993 18.2681V18.2681Z"
        stroke="currentColor"
        strokeWidth={1.4}
      />
      <path
        d="M10.25 1.9165L13 5.58317"
        stroke="currentColor"
        strokeWidth={1.4}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.75 1.9165L13 5.58317"
        stroke="currentColor"
        strokeWidth={1.4}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2260_2896">
        <rect width={22} height={22} fill="white" transform="translate(2 1)" />
      </clipPath>
    </defs>
  </svg>
);
export default LongPasses2;
