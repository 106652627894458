import React from "react";

export default function USD(props: any) {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x="1" y="18.5" width="17.5" height="20" transform="rotate(-90 1 18.5)" fill="#F5F7F8"/>
    <rect x="11" y="6" width="2.5" height="10" transform="rotate(-90 11 6)" fill="#DD2033"/>
    <rect x="11" y="11" width="2.5" height="10" transform="rotate(-90 11 11)" fill="#DD2033"/>
    <rect x="1" y="16" width="2.5" height="20" transform="rotate(-90 1 16)" fill="#DD2033"/>
    <rect x="1" y="21" width="2.5" height="20" transform="rotate(-90 1 21)" fill="#DD2033"/>
    <rect x="1" y="11" width="10" height="10" transform="rotate(-90 1 11)" fill="#004692"/>
    <path d="M8.88895 7.11112L8.39216 8.23482L7.22228 8.38434L8.08515 9.22837L7.85887 10.4445L8.88894 9.75001L9.91902 10.4445L9.69273 9.22837L10.5556 8.38434L9.38572 8.23482L8.88895 7.11112Z" fill="#F5F7F8"/>
    <path d="M8.88895 0.444458L8.39216 1.56816L7.22228 1.71767L8.08515 2.56171L7.85887 3.77779L8.88894 3.08335L9.91902 3.77779L9.69273 2.56171L10.5556 1.71767L9.38572 1.56816L8.88895 0.444458Z" fill="#F5F7F8"/>
    <path d="M6.66673 3.77779L6.16994 4.90149L5.00005 5.051L5.86293 5.89504L5.63665 7.11112L6.66672 6.41668L7.6968 7.11112L7.47051 5.89504L8.33339 5.051L7.1635 4.90149L6.66673 3.77779Z" fill="#F5F7F8"/>
    <path d="M4.16673 7.11112L3.66994 8.23482L2.50005 8.38434L3.36293 9.22837L3.13665 10.4445L4.16672 9.75001L5.1968 10.4445L4.97051 9.22837L5.83339 8.38434L4.6635 8.23482L4.16673 7.11112Z" fill="#F5F7F8"/>
    <path d="M4.16673 0.444458L3.66994 1.56816L2.50005 1.71767L3.36293 2.56171L3.13665 3.77779L4.16672 3.08335L5.1968 3.77779L4.97051 2.56171L5.83339 1.71767L4.6635 1.56816L4.16673 0.444458Z" fill="#F5F7F8"/>
    <path d="M1.94451 3.77779L1.44772 4.90149L0.277832 5.051L1.14071 5.89504L0.914424 7.11112L1.9445 6.41668L2.97457 7.11112L2.74829 5.89504L3.61117 5.051L2.44128 4.90149L1.94451 3.77779Z" fill="#F5F7F8"/>
    </svg>
  );
}
