import React, { startTransition } from "react";

type ImageProps = {
    id?: string;
    src: string;
    alt?: string;
    style?: React.CSSProperties;
    className?: string;
    useImageKit?: boolean;
    asSvgIcon?: boolean;
    notMapSvg?: boolean;
    width?: number;
    height?: number;
    imageFormat?: string;
    onClick?: () => void;
}

type ImageState = {
    filecontents: string | undefined;
    fallback: boolean;
}

export default class ImageV2 extends React.Component<ImageProps, ImageState> {

    public state: ImageState = {
        filecontents: undefined,
        fallback: false
    }

    static assetsLocation = "https://public-assets.spotliodata.com/";

    static defaultProps: Partial<ImageProps> = {
        className: "",
        useImageKit: false,
    }

    static getImageUrl(
        src: string,
        useImageKit = false,
        width : number | string | undefined = "1920",
        height: number | string | undefined = undefined,
        imageFormat: string | undefined = undefined,
    ) {
        let prefix = "";
        if (src.match(/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}\.(?:.{3}|.{4})/)) prefix = ImageV2.assetsLocation;
        let fullsrc = prefix + src;
        if (!useImageKit) return fullsrc;
        let operations = [];
        if (width) operations.push(`w-${width}`); // resize width
        if (height) operations.push(`h-${height}`); // resize height
        if (width && height) operations.push('c-at_max'); // if resizing w and h, no cropping.
        if (imageFormat) operations.push('f-' + imageFormat);
        const transformation = operations.length ? '/tr:' + operations.join(",") : "";

        return `https://ik.imagekit.io/spotlio/fetch${transformation}/${fullsrc}`;
    }

    static getImageSize(src: string, useImageKit = false, width = "1920"): Promise<{width: number; height: number}> {
        return new Promise((resolve, reject) => {
            if (!src) return resolve({width: 0, height: 0});
            const image_url = ImageV2.getImageUrl(src, useImageKit, width);
            const img = new window.Image();
            img.onload = () => { resolve({width: img.width, height: img.height}); };
            img.src = image_url;
        });
    }

    private get src(): string {
        if (this.props.src) return ImageV2.getImageUrl(this.props.src, this.props.useImageKit, this.props.width, this.props.height, this.props.imageFormat);
        return this.props.src;
    }

    async loadSvgFile() {
        const src = this.src;
        if (src.includes("public-assets.spotliodata.com") && src.endsWith(".svg")) {
            const result = await fetch(this.src);
            const text = (await result.text());
            const clean = text.replace(/javascript|document|write|function|\=\>|\{|\}/i, "").replace("<svg ", "<svg class=\"" + this.props.className || '' + "\" ")
            const ok = clean.indexOf("<svg ") !== -1 && clean.indexOf("script") === -1;
            if (ok) return this.setState({ filecontents: clean });
        }
        return this.setState({fallback: true})
    }

    componentDidMount(): void {
        if (this.props.asSvgIcon) startTransition(() => { this.loadSvgFile(); })
    }

    renderAsSvg() {
        const contents = this.state.filecontents || "";
        const myId = this.props.id || ("img-id-" + this.src).replace(/[\W]/ig, "-");
        const csscontents = [`#${myId} svg { max-width: 100%; max-height: 100%; }`];
        if (!this.props.notMapSvg) csscontents.push(`#${myId} svg [fill] { fill: currentColor; }`);
        if (!this.props.notMapSvg) csscontents.push(`#${myId} svg [stroke] { stroke: currentColor; }`);
        return <>
                <style dangerouslySetInnerHTML={{__html: csscontents.join("\n")}}></style>
                <div style={this.props.style} className={this.props.className} id={myId}>
                <div dangerouslySetInnerHTML={{__html: contents}} />
            </div>
        </>
    }

    render() {
        if (this.state.fallback === false && this.props.asSvgIcon) return this.renderAsSvg();
        return <img src={this.src} style={this.props.style} alt={this.props.alt} className={this.props.className} onClick={this.props.onClick}/>
    }

}
