import * as React from "react"
const SecurePros = (props:any) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={86} height={87} viewBox="0 0 86 87" {...props}>
    <g fill="none" fillRule="nonzero">
      <path
        fill="#FFF"
        d="M43 86.06c23.702-.039 42.888-19.278 42.86-42.98C85.832 19.378 66.602.184 42.9.2 19.198.217-.005 19.438 0 43.14a42.92 42.92 0 0 0 43 42.92"
      />
      <path
        fill="#231F20"
        d="M2.33 43.13C2.33 21.33 20.52 3.67 43 3.67s40.57 17.66 40.57 39.46S65.39 82.6 43 82.6 2.33 64.93 2.33 43.13"
      />
      <path
        fill="#FFF"
        d="M42.93 4.9C21.23 4.9 3.64 22 3.64 43.06c0 21.06 17.59 38.17 39.29 38.17s39.29-17.09 39.29-38.17c0-21.08-17.59-38.16-39.29-38.16M50.84 74a4.2 4.2 0 0 1-3.79 2.4 4.14 4.14 0 0 1-3.15-1.46h-1.85a4.14 4.14 0 0 1-6.81-.85c-14.44-3.32-25.19-15.92-25.19-31C10.05 26.6 23 13 39.5 11.42a6.23 6.23 0 0 1 3.5-1.08 6.16 6.16 0 0 1 3.57 1.13c16.34 1.82 29 15.28 29 31.63C75.6 58 65.07 70.5 50.84 74"
      />
      <path
        fill="#FFF"
        d="M60.69 28.57a2.6 2.6 0 1 1 2.59 2.52 2.56 2.56 0 0 1-2.59-2.52"
      />
      <path
        fill="#FFF"
        d="M56.57 32.91A1.12 1.12 0 1 0 57.72 34a1.12 1.12 0 0 0-1.15-1.11m.06-1.74h.07L60.12 29a3.14 3.14 0 0 0 2.32 2.61l-3.13 3.79a3 3 0 0 1-2.68 1.6 2.92 2.92 0 1 1 0-5.83M38.88 56.62a1.12 1.12 0 1 0 0 2.23 1.12 1.12 0 1 0 0-2.23Zm1.43 13.07A3 3 0 0 1 42 72.37a3.08 3.08 0 0 1-6.16 0 3 3 0 0 1 1.7-2.68V60.4a3 3 0 0 1-1.7-2.67 3.08 3.08 0 0 1 6.16 0 3 3 0 0 1-1.69 2.67v9.29Z"
      />
      <path
        fill="#FFF"
        d="M43 21.3a5.08 5.08 0 0 0 5.13-5 5.13 5.13 0 0 0-10.26 0 5.07 5.07 0 0 0 5.13 5m13.6 9.29c-2-2.23-4.52-5.12-6.38-7.13a2.43 2.43 0 0 0-1.54-.66H37.4c-.8 0-1.22.41-1.84 1.11l-6.36 7-5.05-3.89c-2.73-2.1-5.7 2.15-3 4.28l7.1 5.59a2.55 2.55 0 0 0 3.29-.52l3.93-3.8.1 23.57a3.78 3.78 0 0 1 6.64-.14v-6.77h1.56v22.44c0 5 6.57 5 6.57 0l.06-39.47c.32.29 2 2.16 2.76 2.93l.06.07a3.51 3.51 0 0 1-.22-1.12 3.55 3.55 0 0 1 3.58-3.49"
      />
    </g>
  </svg>
)
export default SecurePros
