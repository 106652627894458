import React from "react";

export default function Mountain(props: any) {
  return (
    <svg
    width={48}
    height={48}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#a)">
      <rect
        x={-1}
        y={24}
        width={35.355}
        height={35.355}
        rx={7}
        transform="rotate(-45 -1 24)"
        fill="#fff"
      />
      <rect
        x={3}
        y={24}
        width={29.698}
        height={29.698}
        rx={4}
        transform="rotate(-45 3 24)"
        fill="#2B2B2B"
      />
      <path
        d="m5.769 24.731 18.243.012 18.242.011L25.5 41.5l-.094.084a2 2 0 0 1-2.748-.067L5.767 24.731Z"
        fill="#CFCFCF"
      />
      <path
        d="m37.938 18.734-1.063 1.22L34.594 18l-6.788-5.691a.5.5 0 0 0-.692.047l-3.692 4.066-2.808-2.043a1 1 0 0 0-1.08-.062L8.594 20.5 22.626 6.476l.093-.083a2 2 0 0 1 2.74.061l12.479 12.28Z"
        fill="#fff"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h48v48H0z" />
      </clipPath>
    </defs>
  </svg>
  )

}