

import React from "react";

export default function GuestCard(props: any) {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M1.33325 4C1.33325 2.89543 2.22868 2 3.33325 2H21.3333C22.4378 2 23.3333 2.89543 23.3333 4V20C23.3333 21.1046 22.4378 22 21.3333 22H3.33325C2.22868 22 1.33325 21.1046 1.33325 20V4Z" stroke="currentColor" strokeWidth="1.8" strokeLinejoin="round"/>
      <circle cx="8.33325" cy="14.5" r="3" stroke="currentColor" strokeWidth="1.8"/>
      <path d="M19.8333 12.5L14.8333 12.5" stroke="currentColor" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M19.8333 16.5L14.8333 16.5" stroke="currentColor" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M22.8333 7L1.83325 7" stroke="currentColor" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
}