import React from "react";

export default function Account(props: any) {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M16.515 20.7199C15.5441 21.0531 14.7982 21.5 12.4179 21.5C10.0231 21.5 9.27049 21.0477 8.30313 20.7138C7.7361 20.5181 7.33258 20.0556 7.13902 19.5014L6.55208 17.8208C5.92031 16.0119 7.31669 14.1387 9.28036 14.1609L12.4179 14.2077L15.5163 14.1608C17.4641 14.1385 18.859 15.9834 18.2569 17.7858L17.6907 19.4807C17.5013 20.0474 17.0927 20.5217 16.515 20.7199Z" stroke="currentColor" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12.4179 10.8846C14.5141 10.8846 16.2133 9.23151 16.2133 7.19231C16.2133 5.1531 14.5141 3.5 12.4179 3.5C10.3218 3.5 8.6226 5.1531 8.6226 7.19231C8.6226 9.23151 10.3218 10.8846 12.4179 10.8846Z" stroke="currentColor" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
}
