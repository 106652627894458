import React, { useState, useEffect} from "react";

import ImageV2 from "../image/image.v2";
import Icon, { MaterialIcon } from "src/components/icon";
import { getSession } from "src/utils/localStorage";
import { isRunTime } from "src/utils/isRunTime";
import { INNTOPIA_SALES_ID } from "src/utils/definitions";

const Cart = ({
  badgeBorderColor = "#fff",
  badgeBackgroundColor = "#f6ca1c",
  badgeColor = "#242426",
  type = "",
  icon = ""
  // iconColor = '#242526'
}) => {
  const [ totalProducts, setTotalProducts ] = useState(0);

  const rerenderCart = () => {
    const session = getSession();
    let quantity = 0;
    if (INNTOPIA_SALES_ID) {
      if (session?.cart && session?.cart?.items && session?.cart?.items.length) {
        session.cart.items.forEach(item => {
          const { items } = item;
          items.forEach(i => {
            quantity += i.Quantity;
          });
        });
      }

      return setTotalProducts(quantity);
    }

    if(session && session.products && Array.isArray(session.products)) {
      session.products.forEach(prod => {
        if (prod.quantity) quantity += prod.quantity;
        if (prod.bundleProducts && Array.isArray(prod.bundleProducts)) {
          prod.bundleProducts.forEach(bundle => {
            if (bundle.quantity) {
              quantity += bundle.quantity;
            }
          })
        }
      })
      setTotalProducts(quantity);
    }
  };

  useEffect(() => {
    rerenderCart();
  }, []);

  useEffect(() => {
    if (isRunTime()) {
      window.addEventListener("setSession", rerenderCart);
      return () => {
        window.removeEventListener("setSession", rerenderCart);
      };
    }
  });
  let cartIcon = <Icon icon="Basket" className="block h-6 w-6 stroke-cyan-200"  />
  if (type === "material") cartIcon =  <MaterialIcon className="w-6 h-6" icon={icon} />;
  if (type === "custom") cartIcon =  <ImageV2 src={icon} className="w-6 h-6" />

  return (
    <div className="relative h-8 flex items-center">
      {cartIcon}
      {
        totalProducts && totalProducts > 0
        ?
        <div
          className={`absolute text-xs p-1 rounded-full border-2 w-4 h-4 top-[-4px] right-0 flex items-center justify-center`}
          style={{ borderColor: badgeBorderColor, backgroundColor: badgeBackgroundColor, color: badgeColor }}
        >
          {totalProducts}
        </div>
        : null
      }
    </div>
  );
}

export default Cart;
