import * as React from "react"
const DoubleBlueSquare = (props:any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22}
    height={22}
    viewBox="0 0 22 22"
    fill="none"
    {...props}
  >
    <g fill="#3560DA" fillRule="evenodd" clipPath="url(#a)" clipRule="evenodd">
      <path d="M1.108 6.723h7.857c.362 0 .655.293.655.654v7.858a.655.655 0 0 1-.655.654H1.108a.655.655 0 0 1-.655-.654V7.377c0-.361.293-.654.655-.654ZM13.038 6.723h7.857c.361 0 .654.293.654.654v7.858a.655.655 0 0 1-.654.654h-7.857a.655.655 0 0 1-.655-.654V7.377c0-.361.293-.654.655-.654Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h22v22H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default DoubleBlueSquare
