import * as React from "react"

const OpenStatus = (props: any) => (
  <svg
    width={23}
    height={22}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x={0.5} width={22} height={22} rx={11} fill="#31AC0D" />
    <path
      d="M17.167 7.667 9.667 15l-3.834-3.667"
      stroke="#fff"
      strokeWidth={1.8}
      strokeLinecap="round"
    />
  </svg>
)

export default OpenStatus
