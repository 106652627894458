import * as React from "react"
const BlueSquareNew = (props:any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22}
    height={22}
    viewBox="0 0 22 22"
    fill="none"
    {...props}
  >
    <path
      fill="#3560DA"
      fillRule="evenodd"
      d="M5.5 4.583h11c.506 0 .917.41.917.917v11c0 .506-.41.916-.917.916h-11a.917.917 0 0 1-.917-.916v-11c0-.507.41-.917.917-.917Z"
      clipRule="evenodd"
    />
  </svg>
)
export default BlueSquareNew
