import * as React from "react"
const TransportationRide = (props:any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      d="M7.396 2a.66.66 0 0 0-.095.01H3.43A2.447 2.447 0 0 0 1 4.453V14.669C1 16.5 2.492 18 4.314 18c1.595 0 2.937-1.15 3.246-2.665h.135C8.005 16.85 9.346 18 10.94 18c.864 0 1.65-.34 2.242-.888h6.153a.668.668 0 0 0 .61-.928l-.742-1.737H15.36a.664.664 0 0 1-.663-.667c0-.368.296-.666.663-.666h3.314v-2.443a3.775 3.775 0 0 0-3.535-3.764v-2.01a.668.668 0 0 0-.663-.667h-1.325a.661.661 0 0 0-.663.667v1.999H8.069V3.342h.663a.66.66 0 0 0 .621-.409.67.67 0 0 0-.364-.875.66.66 0 0 0-.257-.048H7.515A.66.66 0 0 0 7.396 2Zm5.313.01a.66.66 0 0 0-.621.409.671.671 0 0 0 .364.875.66.66 0 0 0 .257.048h2.209a.66.66 0 0 0 .62-.409.67.67 0 0 0-.364-.875.66.66 0 0 0-.256-.048h-2.21ZM3.43 3.342h3.314v3.932c-.08.05-.162.1-.237.16l-.682.55a1.1 1.1 0 0 1-.691.244H2.326V4.453c0-.622.486-1.11 1.104-1.11Zm.884 9.328c.87 0 1.597.555 1.868 1.332H4.314a.66.66 0 0 0-.621.41.67.67 0 0 0 .364.875c.082.033.169.05.257.048h1.868a1.973 1.973 0 0 1-1.868 1.332 1.985 1.985 0 0 1-1.988-1.998c0-1.111.883-2 1.988-2Zm6.627 0c1.105 0 1.989.888 1.989 1.999 0 1.11-.884 1.998-1.989 1.998-.87 0-1.596-.555-1.868-1.332h1.868a.657.657 0 0 0 .621-.41.669.669 0 0 0-.62-.923H9.072a1.973 1.973 0 0 1 1.868-1.332Z"
    />
  </svg>
)
export default TransportationRide
