

import React from "react";

export default function LiftTicket(props: any) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M10.439 8H4" stroke="currentColor" strokeWidth="1.8" strokeMiterlimit="10" strokeLinecap="round"/>
      <path d="M8.82926 12H4" stroke="currentColor" strokeWidth="1.8" strokeMiterlimit="10" strokeLinecap="round"/>
      <path d="M20 12H17" stroke="currentColor" strokeWidth="1.8" strokeMiterlimit="10" strokeLinecap="round"/>
      <path d="M20 8H17" stroke="currentColor" strokeWidth="1.8" strokeMiterlimit="10" strokeLinecap="round"/>
      <path d="M20 16H17" stroke="currentColor" strokeWidth="1.8" strokeMiterlimit="10" strokeLinecap="round"/>
      <path d="M14 9V10" stroke="currentColor" strokeWidth="1.8" strokeMiterlimit="10" strokeLinecap="round"/>
      <path d="M14 14V15" stroke="currentColor" strokeWidth="1.8" strokeMiterlimit="10" strokeLinecap="round"/>
      <path d="M14 4.52C14 3.12833 14.9482 2 16.1176 2H20.8824C22.0518 2 23 3.12833 23 4.52V19.48C23 20.8717 22.0518 22 20.8824 22H16.1176C14.9482 22 14 20.8717 14 19.48" stroke="currentColor" strokeWidth="1.8" strokeMiterlimit="10" strokeLinecap="round"/>
      <path d="M14 19.48C14 20.8717 13.0299 22 11.8333 22H3.16667C1.97012 22 1 20.8717 1 19.48V4.52C1 3.12833 1.97012 2 3.16667 2H11.8333C13.0299 2 14 3.12833 14 4.52" stroke="currentColor" strokeWidth="1.8" strokeMiterlimit="10" strokeLinecap="round"/>
    </svg>
  );
}