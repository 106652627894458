import * as React from "react";
const DayPasses = (props:any) => (
  <svg
    width={26}
    height={26}
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.5691 9.3335H5.66663"
      stroke="currentColor"
      strokeWidth={1.4}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <path
      d="M10.0934 13H5.66663"
      stroke="currentColor"
      strokeWidth={1.4}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <path
      d="M20.3334 13H17.5834"
      stroke="currentColor"
      strokeWidth={1.4}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <path
      d="M20.3334 9.3335H17.5834"
      stroke="currentColor"
      strokeWidth={1.4}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <path
      d="M20.3334 16.6665H17.5834"
      stroke="currentColor"
      strokeWidth={1.4}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <path
      d="M14.8334 10.25V11.1667"
      stroke="currentColor"
      strokeWidth={1.4}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <path
      d="M14.8334 14.8335V15.7502"
      stroke="currentColor"
      strokeWidth={1.4}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <path
      d="M14.8334 6.1435C14.8334 4.8678 15.7025 3.8335 16.7746 3.8335H21.1422C22.2142 3.8335 23.0834 4.8678 23.0834 6.1435V19.8568C23.0834 21.1325 22.2142 22.1668 21.1422 22.1668H16.7746C15.7025 22.1668 14.8334 21.1325 14.8334 19.8568"
      stroke="currentColor"
      strokeWidth={1.4}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <path
      d="M14.8333 19.8568C14.8333 21.1325 13.944 22.1668 12.8472 22.1668H4.90274C3.80591 22.1668 2.91663 21.1325 2.91663 19.8568V6.1435C2.91663 4.8678 3.80591 3.8335 4.90274 3.8335H12.8472C13.944 3.8335 14.8333 4.8678 14.8333 6.1435"
      stroke="currentColor"
      strokeWidth={1.4}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
  </svg>
);
export default DayPasses;