import React from "react";

export default function CheckCircle(props: any) {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.5001 11.5699V12.4999C21.4975 16.9286 18.5824 20.8281 14.3354 22.0838C10.0885 23.3395 5.52145 21.6522 3.11101 17.937C0.700573 14.2217 1.02092 9.36353 3.89833 5.99696C6.77574 2.63039 11.5248 1.55741 15.5701 3.35989"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.5 3.5L11.5 14.5L8.5 11.5"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
