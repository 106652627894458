import * as React from "react";

const Red = (props: any) => (
  <svg
    width={22}
    height={22}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M3.49 19.25a1 1 0 0 1-.885-1.466l7.51-14.27a1 1 0 0 1 1.77 0l7.51 14.27a1 1 0 0 1-.885 1.466H3.49Z"
      stroke="#E10003"
      strokeWidth={1.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Red;
