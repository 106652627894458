const READONLY_CONSOLE = "nEgpx32h_Lwx9RWxCu7Cqi-Jhn4OgcD9";
const READONLY_WONDERL = "sKqiGzdVnqWz2UfY2gL2_ptusEPzrhst";

/**
 * Converts any html into div with dangerouslySetInnerHTML
 * Does not clean the html.
 *
 * @param {string} string
 * @returns React div with dangerouslySetInnerHTML
 */
function convertToHTML(string) {
    // return <div dangerouslySetInnerHTML={{ __html: string }} />;
    //TODO add workarround...
    return string;
}


/**
 * Map common html elements to our styling
 * @param {string} input Input string
 * @returns replaced elements
 */
function mapHTMLElements(input, htmlClass = "") {
    if (!input) return input;
    if (typeof input !== "string") return input;
    let replaced = input.replace(/<h1>/ig, '<h1 class="headline1 mb-4">');
    replaced  = replaced.replace(/<h1 (style=".*")>/ig, '<h1 class="headline1 mb-4" $1>');
    replaced  = replaced.replace(/<h2>/ig, '<h2 class="headline2 mb-3">');
    replaced  = replaced.replace(/<h2 (style=".*")>/ig, '<h2 class="headline2 mb-3" $1>');
    replaced  = replaced.replace(/<h3>/ig, '<h3 class="headline3 mb-2">');
    replaced  = replaced.replace(/<h3 (style=".*")>/ig, '<h3 class="headline3 mb-2" $1>');
    replaced  = replaced.replace(/<h4>/ig, '<h4 class="headlineGeneralText mb-2">');
    replaced  = replaced.replace(/<h4 (style=".*")>/ig, '<h4 class="headlineGeneralText mb-2" $1>');
    replaced  = replaced.replace(/<ol>/ig, '<ol style="list-style: decimal; margin-left: 1.5em; margin-top: 1em; margin-bottom: 1em;">');
    replaced  = replaced.replace(/<ul>/ig, '<ul style="list-style: initial; margin-left: 1.5em; margin-top: 1em; margin-bottom: 1em;">');
    replaced  = replaced.replace(/<hr>/ig, '<hr style=" margin-top: 1em; margin-bottom: 1em;">');
    replaced  = replaced.replace(/<p>/ig, '<p class="generalText2 mb-4">');
    replaced  = replaced.replace(/<p styl/ig, '<p class="generalText2 mb-4" styl');
    replaced  = replaced.replace(/console.spotliodata.com/ig, "console.spotliowebsites.com");
    replaced  = replaced.replace(/<span class="cta-button">(.*?)<\/span>/ig, function (match, inner) {
        if (inner.indexOf("<a href=") !== -1)  return inner.replace("<a href=", '<a class="cta-button" href=');
        return match;
    });
    replaced  = replaced.replace(/<span class="cta-secondary-button">(.*?)<\/span>/ig, function (match, inner) {
        if (inner.indexOf("<a href=") !== -1)  return inner.replace("<a href=", '<a class="cta-secondary-button" href=');
        return match;
    });
    replaced  = replaced.replace(/<img src="(.*?)"/ig, function (match, src) {
        src = src.replace(READONLY_WONDERL, READONLY_CONSOLE) // switch tokens to production
        if (src.includes(":8057/assets")) src = `https://console.spotliowebsites.com/assets/${src.split("/assets/")[1]}` // switch assets to production
        if (src.includes(":8056/assets")) src = `https://console.spotliowebsites.com/assets/${src.split("/assets/")[1]}` // switch assets to production
        return `<img class="mt-4 mb-4" src="${src}"`
    });

    return `<div class="html ${htmlClass}">`+replaced+`</div>`;
}


/**
 * Get language field of translations.
 *
 * @param {any[]} translations
 * @param {string} language
 * @param {string} field
 * @param {boolean} isHTML default value false
 * @returns {string | React.ReactNode}
 */
module.exports = function getLanguageField(translations, language, field, isHTML = false, mapHtml = false, htmlClass = "", tweakFunction = (s) => s ) {
    if(!translations) return '';
    for (const translation of translations) {
        let translationLanguageCode = "";

        // Check if translation exists and set the translationLanguageCode
        if (translation && translation.language && translation.language.code) {
            translationLanguageCode = translation.language.code;
        } else if (translation && translation.languages_code && translation.languages_code.code) {
            translationLanguageCode = translation.languages_code.code;
        } else if (translation && translation.languages_code) {
            translationLanguageCode = translation.languages_code;
        } else if (translation && translation.language) {
            translationLanguageCode = translation.language;
        }

        // temporary solution to prevent Norwegian divergence
        if (translationLanguageCode === "no-NO") translationLanguageCode = "nb-NO";

        // Continue if translationLanguageCode does not match the language
        if (translationLanguageCode !== language) continue;
        if (translation[field] === null) translation[field] = '';
        if (isHTML && mapHtml)
        {
            if(!translation[field]) return null;
            return convertToHTML(tweakFunction(mapHTMLElements(translation[field], htmlClass)));
        }
        if (isHTML) return tweakFunction(convertToHTML(translation[field]));
        return translation[field];
    }
    return '';
}