import React from "react";

export default function Youtube(props: any) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22.5395 5.81759C22.4207 5.26503 22.1788 4.75875 21.8381 4.34989C21.4975 3.94103 21.0703 3.64407 20.5996 3.489C18.8796 3 12 3 12 3C12 3 5.12036 3 3.40045 3.53558C2.92972 3.69064 2.50247 3.9876 2.16186 4.39646C1.82124 4.80532 1.57933 5.3116 1.46055 5.86417C1.14578 7.89651 0.991808 9.95819 1.00057 12.0233C0.989351 14.1039 1.14333 16.1813 1.46055 18.229C1.5915 18.7644 1.83883 19.2514 2.17865 19.643C2.51847 20.0346 2.93929 20.3175 3.40045 20.4644C5.12036 21 12 21 12 21C12 21 18.8796 21 20.5996 20.4644C21.0703 20.3094 21.4975 20.0124 21.8381 19.6035C22.1788 19.1947 22.4207 18.6884 22.5395 18.1358C22.8518 16.1188 23.0058 14.0728 22.9994 12.0233C23.0107 9.94264 22.8567 7.86524 22.5395 5.81759V5.81759Z"
        stroke="currentColor"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 15L15 12L10 9V15Z"
        stroke="currentColor"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}