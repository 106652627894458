
import React from "react";

export default function Flight(props: any) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M3.47801 4.74883C3.19176 4.58526 3.1394 4.19428 3.37252 3.96116L4.25833 3.07535C4.37921 2.95447 4.55347 2.90373 4.72035 2.94081L9.5842 4.02167C9.75108 4.05875 9.92534 4.00801 10.0462 3.88713L11.3557 2.57764C11.3818 2.55151 11.408 2.52734 11.4366 2.50401C11.7309 2.26445 13.504 0.876288 14.2233 1.59562C14.9427 2.31495 13.5545 4.08803 13.315 4.38234C13.2916 4.411 13.2675 4.43713 13.2413 4.46326L11.9318 5.77274C11.811 5.89362 11.7602 6.06788 11.7973 6.23476L12.8782 11.0986C12.9152 11.2655 12.8645 11.4398 12.7436 11.5606L11.8578 12.4464C11.6247 12.6796 11.2337 12.6272 11.0701 12.341L9.25077 9.15707C9.09274 8.88052 8.71987 8.82026 8.48277 9.03295L5.94939 11.3055C5.81833 11.4231 5.75877 11.6009 5.79257 11.7737L6.07263 13.2051C6.10459 13.3685 6.05318 13.537 5.93549 13.6547L5.28931 14.3009C5.04698 14.5432 4.63821 14.4755 4.48704 14.1679L3.62765 12.4195C3.5789 12.3203 3.49866 12.2401 3.39948 12.1913L1.65107 11.3319C1.34351 11.1807 1.27574 10.772 1.51808 10.5296L2.16425 9.88347C2.28194 9.76578 2.45047 9.71437 2.61381 9.74633L4.04523 10.0264C4.21802 10.0602 4.39585 10.0006 4.51342 9.86957L6.78601 7.33619C6.9987 7.09909 6.93844 6.72622 6.66189 6.56819L3.47801 4.74883Z" stroke="currentColor" strokeWidth="1.5" />
    </svg>
  );
}
