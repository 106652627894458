import * as React from "react"
const RemovePros = (props:any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    data-name="Layer 1"
    viewBox="0 0 85.8 85.9"
    {...props}
  >
    <defs>
      <style>{".cls-2{stroke-width:0;fill:#fff}"}</style>
    </defs>
    <path
      d="M43 86.1c23.7 0 42.9-19.2 42.9-42.9S66.7.2 43 .2 0 19.4 0 43.1 19.2 86 42.9 86"
      className="cls-2"
    />
    <path
      d="M2.3 43.1C2.3 21.3 20.5 3.7 43 3.7s40.6 17.7 40.6 39.5S65.4 82.7 43 82.7 2.3 64.9 2.3 43.1"
      style={{
        strokeWidth: 0,
        fill: "#ec2127",
      }}
    />
    <path
      d="M42.9 4.9C21.2 4.9 3.6 22 3.6 43.1s17.6 38.2 39.3 38.2 39.3-17.1 39.3-38.2S64.6 4.9 42.9 4.9M50.8 74c-.8 1.6-2.3 2.4-3.8 2.4s-2.3-.5-3.2-1.5H41.9c-.7.9-1.9 1.4-3.1 1.4s-3-.9-3.7-2.3C20.7 70.7 9.9 58.1 9.9 43s12.9-30.1 29.5-31.7c1-.7 2.2-1.1 3.5-1.1s2.6.4 3.6 1.1c16.3 1.8 29 15.3 29 31.6S65 70.3 50.7 73.8"
      className="cls-2"
    />
    <path
      d="M60.7 28.6c0-1.4 1.2-2.5 2.6-2.5s2.6 1.1 2.6 2.5-1.2 2.5-2.6 2.5-2.6-1.1-2.6-2.5"
      className="cls-2"
    />
    <path
      d="M56.6 32.9c-.6 0-1.1.5-1.1 1.1s.5 1.1 1.1 1.1 1.1-.5 1.1-1.1-.5-1.1-1.1-1.1m0-1.7L60 29c.2 1.3 1.1 2.3 2.3 2.6l-3.1 3.8c-.5 1-1.5 1.6-2.7 1.6-1.7 0-3-1.3-3-2.9s1.3-2.9 3-2.9M38.9 56.6c-.6 0-1.2.5-1.2 1.1s.5 1.1 1.2 1.1 1.1-.5 1.1-1.1-.5-1.1-1.1-1.1m1.4 13.1c1 .5 1.7 1.5 1.7 2.7s-1.4 3-3.1 3-3.1-1.3-3.1-3 .7-2.2 1.7-2.7v-9.3c-1-.5-1.7-1.5-1.7-2.7 0-1.7 1.4-3 3.1-3s3.1 1.3 3.1 3-.7 2.2-1.7 2.7v9.3Z"
      className="cls-2"
    />
    <path
      d="M43 21.3c2.8 0 5.1-2.2 5.1-5s-2.3-5-5.1-5-5.1 2.2-5.1 5 2.3 5 5.1 5m13.6 9.3c-2-2.2-4.5-5.1-6.4-7.1-.2-.3-.9-.7-1.5-.7H37.4c-.8 0-1.2.4-1.8 1.1l-6.4 6.9-5.1-3.9c-2.7-2.1-5.7 2.1-3 4.3l7.1 5.6c.9.7 2.3.5 3.3-.5l3.9-3.8v23.6c.7-1.2 2-2 3.4-2s2.7.8 3.3 1.9v-6.8h1.6v22.4c0 5 6.6 5 6.6 0V32.1c.4.3 2.1 2.2 2.8 2.9-.1-.3-.2-.7-.2-1 0-1.9 1.6-3.5 3.6-3.5"
      className="cls-2"
    />
  </svg>
)
export default RemovePros
