import React from "react";

export default function Car(props: any) {
  return (
    <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M13.666 10.1667H11.666" stroke="currentColor" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M4.33203 10.1667H6.33203" stroke="currentColor" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M11.2003 2.5H6.7984C6.09587 2.5 5.44486 2.8686 5.08341 3.47101L4.038 5.21335C3.80722 5.59799 3.39156 5.83333 2.943 5.83333H2.49006C2.03495 5.83333 1.66602 6.20227 1.66602 6.65738V6.70727C1.66602 6.98882 1.82509 7.2462 2.07692 7.37212C2.53072 7.59902 2.62886 8.20382 2.2701 8.56258L1.97256 8.86012C1.77628 9.0564 1.66602 9.32261 1.66602 9.60019V11.3619C1.66602 11.6638 1.78592 11.9532 1.99935 12.1667C2.21278 12.3801 2.33268 12.6696 2.33268 12.9714V13.0858C2.33268 13.8668 2.96585 14.5 3.74689 14.5H4.66602C5.2183 14.5 5.66602 14.0523 5.66602 13.5C5.66602 12.9477 6.11373 12.5 6.66601 12.5H11.3327C11.885 12.5 12.3327 12.9477 12.3327 13.5C12.3327 14.0523 12.7804 14.5 13.3327 14.5H14.2518C15.0329 14.5 15.666 13.8668 15.666 13.0858V12.9714C15.666 12.6696 15.7859 12.3801 15.9993 12.1667C16.2128 11.9532 16.3327 11.6638 16.3327 11.3619V9.60019C16.3327 9.32261 16.2224 9.0564 16.0261 8.86012L15.7286 8.56258C15.3698 8.20382 15.468 7.59902 15.9218 7.37212C16.1736 7.2462 16.3327 6.98882 16.3327 6.70727V6.65738C16.3327 6.20227 15.9637 5.83333 15.5086 5.83333H15.0557C14.6071 5.83333 14.1915 5.59799 13.9607 5.21335L12.9153 3.47101C12.5538 2.8686 11.9028 2.5 11.2003 2.5Z" stroke="currentColor" strokeWidth="1.8"/>
    </svg>
  );
}
