import * as React from "react"
const Money = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={17}
    fill="none"
    viewBox="0 0 16 17"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.3}
      d="M2.135 3.833h11.733c.807 0 1.467.525 1.467 1.167v7c0 .641-.66 1.166-1.467 1.166H2.135c-.807 0-1.467-.525-1.467-1.166V5c0-.642.66-1.167 1.467-1.167Z"
      clipRule="evenodd"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.3}
      d="M8.001 9.834a1.333 1.333 0 1 0 0-2.667 1.333 1.333 0 0 0 0 2.667Z"
      clipRule="evenodd"
    />
    <circle
      cx={4.001}
      cy={8.5}
      r={0.333}
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={1.3}
    />
    <circle
      cx={12.001}
      cy={8.5}
      r={0.333}
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={1.3}
    />
    <circle
      cx={14.333}
      cy={4.833}
      r={0.333}
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={1.3}
    />
    <circle
      cx={14.333}
      cy={12.166}
      r={0.333}
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={1.3}
    />
    <circle
      cx={1.665}
      cy={4.833}
      r={0.333}
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={1.3}
    />
    <circle
      cx={1.665}
      cy={12.166}
      r={0.333}
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={1.3}
    />
  </svg>
)
export default Money
