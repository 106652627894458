import { isRunTime } from "./isRunTime";
import { findKey } from "./lodash";

const BROWSER = {
    messenger: /\bFB[\w_]+\/(Messenger|MESSENGER)/,
    facebook: /\bFB[\w_]+\//,
    twitter: /\bTwitter/i,
    line: /\bLine\//i,
    wechat: /\bMicroMessenger\//i,
    puffin: /\bPuffin/i,
    miui: /\bMiuiBrowser\//i,
    instagram: /\bInstagram/i,
    chrome: /Chrome\/[.0-9]* (Mobile)?/,
    safari: /Version.*Mobile.*Safari|Safari.*Mobile|MobileSafari/,
    ie: /IEMobile|MSIEMobile/,
    firefox: /fennec|firefox.*maemo|(Mobile|Tablet).*Firefox|Firefox.*Mobile|FxiOS/,
    ios: /iPhone|iPod|iPad/,
};

export function isMobile() {
    return isRunTime()
        ? /(iPad|iPhone|Android|Mobile)/i.test(window.navigator.userAgent) || false
        : false;
}

export function whichBrowser() {
    return isRunTime()
        ? findKey(BROWSER, regex => regex.test(window.navigator.userAgent)) || 'other'
        : 'other';
}
