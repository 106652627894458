import React, { startTransition } from "react";

type SuspenseProps = {
    /** what to show while loads */
    fallback?: React.ReactNode;

    /** children to suspend */
    children: React.ReactNode;
}

type SuspenseState = {
    /** is this suspense loaded? */
    loaded: boolean;
    /** is this suspense loading? */
    loading: boolean;
}

/**
 * Our suspense component.
 * Prevents loading until client side rendering (componentDidMount + startTransition)
 */
export default class Suspense extends React.Component<SuspenseProps, SuspenseState> {

    public state: SuspenseState = {
        loaded: false,
        loading: true,
    }

    componentDidMount(): void {
        startTransition(() => { this.setState({loaded: true}) })
    }

    renderLoading() {
        if (this.props.fallback) return this.props.fallback;
        return <>
            <div className="w-full animate-pulse">
                
            </div>
        </>
    }
  
    render() {
        if (this.state.loaded) return <React.Suspense fallback={this.props.fallback}>{this.props.children}</React.Suspense>;
        return this.renderLoading();
    }
}