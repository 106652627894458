import * as React from "react"

const BlueSquare = (props:any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22}
    height={22}
    fill="none"
    {...props}
  >
    <path
      stroke="#3560DA"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.8}
      d="M18.332 3.667v14.667H3.665V3.667h14.667Z"
      clipRule="evenodd"
    />
  </svg>
)
export default BlueSquare
