import React from "react";

export default function Instagram(props: any) {
  return <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 7C2 4.23858 4.23858 2 7 2H17C19.7614 2 22 4.23858 22 7V17C22 19.7614 19.7614 22 17 22H7C4.23858 22 2 19.7614 2 17V7Z"
      stroke="currentColor"
      strokeWidth="1.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.0001 11.37C16.2532 13.077 15.3831 14.7538 13.8417 15.5297C12.3003 16.3055 10.4351 16.0054 9.2149 14.7852C7.99469 13.5649 7.69455 11.6998 8.47038 10.1584C9.24621 8.61697 10.9231 7.74687 12.6301 7.99999C14.373 8.25846 15.7416 9.62702 16.0001 11.37Z"
      stroke="currentColor"
      strokeWidth="1.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.9946 6.42667C18.0262 6.64003 17.9174 6.84963 17.7248 6.94661C17.5321 7.04358 17.299 7.00607 17.1465 6.85355C16.9939 6.70102 16.9564 6.46789 17.0534 6.27522C17.1504 6.08255 17.36 5.97379 17.5733 6.00543C17.7912 6.03774 17.9623 6.2088 17.9946 6.42667Z"
      stroke="currentColor"
      strokeWidth="1.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
}