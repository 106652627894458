import { isRunTime } from "./isRunTime";
import { scrollTo } from "./scroll";

const updateViewportHeight = () => {
  if (!isRunTime()) return null;
  let vh = window.innerHeight * 0.01;
  let vwh =
    window && window.visualViewport && window.visualViewport.height
      ? window.visualViewport.height
      : window.innerHeight;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
  document.documentElement.style.setProperty("--vwh", `${vwh}px`);
};

const addViewportHeightResizeEventListener = () => {
  if (isRunTime()) {
    updateViewportHeight();
    window.addEventListener("resize", updateViewportHeight);
  }
};
const removeViewportHeightResizeEventListener = () => {
  if (isRunTime()) window.removeEventListener("resize", updateViewportHeight);
};


function scrollToAnchor() {
  if (!isRunTime()) return false;
  if (window.location.hash) {
    let yOffset = 0;
    if (document.getElementById('topbar')) {
      const rect = document.getElementById('topbar').getBoundingClientRect();
      yOffset += rect.top + rect.height;
    }
    const elementId = window.location.hash.substring(1);
    const element = document.getElementById(elementId);
    if (element) {
      const y = element.getBoundingClientRect().top + window.scrollY - yOffset;
      scrollTo({ top: y});
      return true;
    }
  }
  return false;
}

function goToAnchor() {
  scrollToAnchor();
}

export {
  updateViewportHeight,
  addViewportHeightResizeEventListener,
  removeViewportHeightResizeEventListener,
  goToAnchor,
};
