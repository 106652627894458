import * as React from "react"
const Pencil = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={10}
    height={10}
    fill="none"
    viewBox="0 0 10 10"
    {...props}
  >
    <g stroke="currentColor" strokeMiterlimit={10} clipPath="url(#a)">
      <path
        strokeLinejoin="round"
        d="m.834 9.166.707-2.474a.475.475 0 0 1 .12-.206l5.27-5.27a1.31 1.31 0 0 1 1.853 1.852l-5.27 5.27a.478.478 0 0 1-.206.122l-2.474.706Z"
      />
      <path d="m6.217 1.93 1.852 1.853" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h10v10H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default Pencil
