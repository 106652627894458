import React from "react";

import * as styles from "./styles.module.scss";

const DotSvg = () => {
  return (
    <svg
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
    >
      <path
        d="M15 25.625C20.868 25.625 25.625 20.868 25.625 15C25.625 9.13197 20.868 4.375 15 4.375C9.13197 4.375 4.375 9.13197 4.375 15C4.375 20.868 9.13197 25.625 15 25.625Z"
        stroke="#098A00"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const DotsAnimation = ({ className }) => {
  return (
    <span className={`${styles.circleWrapper} ${className}`}>
      <span>
        <DotSvg />
      </span>
      <span>
        <DotSvg />
      </span>
      <span>
        <DotSvg />
      </span>
    </span>
  );
};

export default DotsAnimation;
