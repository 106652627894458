import React from "react";
import Image from "src/components/image";

import FooterSimpleCentered from "./simple-centered/simple-centered.v1";
import FooterTwoColumns from "./two-columns/two-columns.v1";
import FooterThreeColumns from "./three-columns/three-columns.v1";
import FooterFourColumns from "./four-columns/four-columns.v1";

type FooterProps = {
    language: string;
    defaultLanguage: string;
    footer: TFooterSimpleCentered | TFooterTwoColumns | TFooterThreeColumns | TFooterFourColumns | TFooter;
}

export default class FooterModule extends React.Component<FooterProps> {

    shouldComponentUpdate(nextProps: Readonly<FooterProps>, nextState: Readonly<{}>, nextContext: any): boolean {
        if (nextProps.footer !== this.props.footer) return true;
        if (nextProps.language !== this.props.language) return true;
        return false;
    }

    renderFooterHeadImage() {
        if (!this.props.footer.footer_head_image) return null;
        const background = this.props.footer.footer_head_background_color?.value;
        const imageUrl = Image.getImageUrl(this.props.footer.footer_head_image.imageFile, false)
        return <div style={{background}}><div className="flex flex-row max-w-7xl mx-auto">
            <Image backgroundColor="transparent" imgStyle={{}} className="object-fill translate-y-px" src={imageUrl} alt="" />
        </div></div>
    }

    renderFooterComponent() {
        if (this.props.footer?.type === 'simple-centered')
            return <FooterSimpleCentered language={this.props.language} defaultLanguage={this.props.defaultLanguage} footer={this.props.footer as TFooterSimpleCentered} />;
        if (this.props.footer?.type === 'two-columns')
            return <FooterTwoColumns language={this.props.language} defaultLanguage={this.props.defaultLanguage} footer={this.props.footer as TFooterTwoColumns} />;
        if (this.props.footer?.type === 'three-columns')
            return <FooterThreeColumns language={this.props.language} defaultLanguage={this.props.defaultLanguage} footer={this.props.footer as TFooterThreeColumns} />;
        if (this.props.footer?.type === 'four-columns')
            return <FooterFourColumns language={this.props.language} defaultLanguage={this.props.defaultLanguage} footer={this.props.footer as TFooterFourColumns} />;
        return null;
    }

    render() {
        return <div id="footer">
            {this.renderFooterHeadImage()}
            {this.renderFooterComponent()}
        </div>;
    }

}