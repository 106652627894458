import React from "react";

import CurrencyPicker from "../currencypicker/currency.picker";
import LanguagePicker from "../languagepicker/language.picker";
import Link from "src/components/link";
import ImageV2 from "src/components/image/image.v2";
import Icon, { MaterialIcon } from "src/components/icon";
import Cart from "src/components/cart";

import { getSession } from "src/utils/localStorage";
import { getLanguageField } from "src/utils/language";
import { getSlug } from "src/utils/slugs";


type Props = {
    language: string;
    defaultLanguage: string;
    languages: Array<{code: string; name: string; icon: null | string}>;

    defaultCurrency: null | string;
    currencies: Array<{id: number; code: string; name: null | string;}>;

    pages: {
        account_page: null | TTranslations<{slug: string}>;
        shopping_cart_page: null | TTranslations<{slug: string}>;
    }

    toolbar: TToolbar | null;
    navigation_bar: TNavigationBar;
    page_translations: TTranslations<{slug: string;}>;
    onLoginButtonClick: (open: boolean) => void;
}

export default class NavigationFooter extends React.Component<Props> {
    currencyRef = React.createRef<CurrencyPicker>();
    languageRef = React.createRef<LanguagePicker>();

    currencyOpen(open: boolean) {
        if (this.languageRef.current && this.languageRef.current.isOpen) {
            this.languageRef.current.close();
        }
    }

    languageOpen(open: boolean) {
        if (this.currencyRef.current && this.currencyRef.current.isOpen) {
            this.currencyRef.current.close();
        }
    }

    renderRightIcon(item: TNavigationRightIcon, index: number) {
        let icon = item.icon_type === 'custom'
            ? <ImageV2 className="h-6 w-6" src={item.custom_icon?.imageFile} />
            : <MaterialIcon className="h-6 w-6" icon={item.material_icon || ""} />;

        const link = item.link_type === 'external'
            ? getLanguageField(item.translations, this.props.language, 'link_href')
            : getSlug(this.props.language, this.props.defaultLanguage, item.link_page?.translations || [])

        return <Link className={"cursor-pointer h-6 w-6"} title={""} href={link} key={`navrightic-${index}`}>
            {icon}
        </Link>
    }

    renderMyAccount() {
        const withProfile = this.props.toolbar?.user_profile || this.props.navigation_bar.user_profile;
        if (!withProfile) return null;

        const session = getSession();
        const isUserLogged = session && session.customer;
        const accountpage = this.props.pages?.account_page?.find(translation => translation.language === this.props.language);
        if (isUserLogged) return <Link title="" href={accountpage?.slug} className="self-center"><Icon icon="UserRounded" className="w-6 h-6" /></Link>;

        return <button onClick={() => this.props.onLoginButtonClick(true)}>
            <Icon icon="UserRounded" className="w-6 h-6" />
        </button>;
    }

    renderCart() {
        const withCart = this.props.toolbar?.shopping_cart || this.props.navigation_bar.shopping_cart;
        if (!withCart) return null;
        const cart_page = this.props.pages?.shopping_cart_page?.find(translation => translation.language === this.props.language);
        return <Link title="" href={cart_page?.slug} className="">
            <Cart badgeBackgroundColor="F00" badgeColor="#FFF" badgeBorderColor="#F00" />
        </Link>
    }

    renderRightIcons() {
        const navbar = this.props.navigation_bar;
        const items = (navbar.navigation_bar_right_icons || []).map(row => row.item);
        return items.map((item, idx) => this.renderRightIcon(item, idx));
    }

    public get style(): any {
        /** no toolbar, no colors */
        if (!this.props.toolbar) return undefined;

        /** by inheritance, navbar colors are configured on MobileBackdrop. */
        if (this.props.toolbar?.same_as_navigation_bar) return undefined;

        /** use toolbar colors */
        return {
            '--dropdown-background': this.props.toolbar.dropdown_menu_background_color_when_attached_on_mobile?.value || '#fff',
            '--dropdown-text-color': this.props.toolbar.unselected_item_color_when_attached_on_mobile?.value || '#000',
            '--dropdown-active-color': this.props.toolbar.text_color_when_attached_on_mobile?.value || '#000',
            '--dropdown-hover-color': this.props.toolbar.hover_color_when_attached_on_mobile?.value || '#000',
            '--text-color': this.props.toolbar.text_color_when_attached_on_mobile?.value,
            '--hover-color': this.props.toolbar.hover_color_when_attached_on_mobile?.value,
        }
    }

    renderLanguagePickerFromNavbar() {
        return <LanguagePicker
                ref={this.languageRef}
                modalInTop={true}
                labelClassName="caption"
                itemsClassName="caption"
                language={this.props.language}
                languages={this.props.languages}
                page_translations={this.props.page_translations}
                language_selector_on_navigation_bar={this.props.navigation_bar.language_selector_on_navigation_bar}
                language_selector_on_dropdown={this.props.navigation_bar.language_selector_on_dropdown}
                language_selector_icon={this.props.navigation_bar.language_selector_icon}
                language_flag_style={this.props.navigation_bar.language_flag_style}
                language_selector_dropdown_chevron_icon={this.props.navigation_bar.language_selector_dropdown_chevron_icon}
                onToggleDropdown={(open) => this.languageOpen(open)}
        />;
    }

    renderLanguagePickerFromToolbar() {
        const toolbar = this.props.toolbar;
        if (!toolbar) return null;
        return <LanguagePicker
            modalInTop={true}
            labelClassName="caption"
            itemsClassName="caption"
            language={this.props.language}
            languages={this.props.languages}
            page_translations={this.props.page_translations}
            language_selector_on_navigation_bar={toolbar.language_selector_on_toolbar}
            language_selector_on_dropdown={toolbar.language_selector_on_dropdown}
            language_selector_icon={toolbar.language_selector_icon}
            language_flag_style={toolbar.language_flag_style}
            language_selector_dropdown_chevron_icon={toolbar.language_selector_dropdown_chevron_icon}
            onToggleDropdown={(open) => this.languageOpen(open)}
        />;
    }

    render(): React.ReactNode {
        const toolbarWithCurrency = this.props.toolbar && this.props.toolbar.with_currency_selector;
        const navbarWithCurrency = this.props.navigation_bar && this.props.navigation_bar.currency_selector;
        const withCurrency = this.props.defaultCurrency && (toolbarWithCurrency || navbarWithCurrency);

        const toolbarWithlanguage = this.props.toolbar && this.props.toolbar.with_language_selector;
        const navbarWithLanguage = this.props.navigation_bar && this.props.navigation_bar.language_selector;

        //FLM-6536: Add min height to prevent cookies from overflowing over in mobile.
        return <>
            <div className="min-h-[44px] flex flex-row flex-wrap gap-4 px-4 items-center text-[color:var(--text-color)] navigation-footer" style={this.style}>
                {withCurrency
                    ? <CurrencyPicker
                        ref={this.currencyRef}
                        modalInTop={true}
                        labelClassName="caption"
                        itemsClassName="caption"
                        defaultCurrency={this.props.defaultCurrency}
                        currencies={this.props.currencies}
                        onToggleDropdown={(open) => this.currencyOpen(open)}
                        />
                    : null}
                {navbarWithLanguage ? this.renderLanguagePickerFromNavbar() : null}
                {!navbarWithLanguage && toolbarWithlanguage ? this.renderLanguagePickerFromToolbar() : null}
                <div className="flex flex-row gap-4 items-center">
                    {this.renderRightIcons()}
                    {this.renderMyAccount()}
                    {this.renderCart()}
                </div>
            </div>
        </>
    }
}