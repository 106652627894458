
/**
 * Get computed style for property
 * @param element Element
 * @param property property
 * @returns 
 */
export function getCssStyle(element: HTMLElement, property: string) {
    return window.getComputedStyle(element, null).getPropertyValue(property);
}

/**
 * Get canvas font for element
 * @param el Element
 * @returns String canvas font
 */
export function getCanvasFont(el: HTMLElement): string {
    if (!el) return "";
    
    const fontWeight = getCssStyle(el, 'font-weight') || 'normal';
    const fontSize = getCssStyle(el, 'font-size') || '16px';
    const fontFamily = getCssStyle(el, 'font-family') || 'arial';
    
    return `${fontWeight} ${fontSize} ${fontFamily}`;
}