import React from 'react';

const READONLY_CONSOLE = "nEgpx32h_Lwx9RWxCu7Cqi-Jhn4OgcD9";
const READONLY_WONDERL = "sKqiGzdVnqWz2UfY2gL2_ptusEPzrhst";

/**
 * Converts any html into div with dangerouslySetInnerHTML
 * Does not clean the html.
 *
 * @param {string} string
 * @returns React div with dangerouslySetInnerHTML
 */
export function convertToHTML(string) {
    return <div dangerouslySetInnerHTML={{ __html: string }} />;
}


/**
 * Map common html elements to our styling
 * @param {string} input Input string
 * @returns replaced elements
 */
export function mapHTMLElements(input, htmlClass = "") {
    if (!input) return input;
    if (typeof input !== "string") return input;
    let replaced = input.replace(/<h1>/ig, '<h1 class="headline1 mb-4">');
    replaced  = replaced.replace(/<h1 (style=".*")>/ig, '<h1 class="headline1 mb-4" $1>');
    replaced  = replaced.replace(/<h2>/ig, '<h2 class="headline2 mb-3">');
    replaced  = replaced.replace(/<h2 (style=".*")>/ig, '<h2 class="headline2 mb-3" $1>');
    replaced  = replaced.replace(/<h3>/ig, '<h3 class="headline3 mb-2">');
    replaced  = replaced.replace(/<h3 (style=".*")>/ig, '<h3 class="headline3 mb-2" $1>');
    replaced  = replaced.replace(/<h4>/ig, '<h4 class="headlineGeneralText mb-2">');
    replaced  = replaced.replace(/<h4 (style=".*")>/ig, '<h4 class="headlineGeneralText mb-2" $1>');
    replaced  = replaced.replace(/<ol>/ig, '<ol style="list-style: decimal; margin-left: 1.5em; margin-top: 1em; margin-bottom: 1em;">');
    replaced  = replaced.replace(/<ul>/ig, '<ul style="list-style: initial; margin-left: 1.5em; margin-top: 1em; margin-bottom: 1em;">');
    replaced  = replaced.replace(/<hr>/ig, '<hr style=" margin-top: 1em; margin-bottom: 1em;">');
    replaced  = replaced.replace(/<p>/ig, '<p class="generalText2 mb-4">');
    replaced  = replaced.replace(/<p styl/ig, '<p class="generalText2 mb-4" styl');
    replaced  = replaced.replace(/console.spotliodata.com/ig, "console.spotliowebsites.com");
    replaced  = replaced.replace(/<span class="cta-button">(.*?)<\/span>/ig, function (match, inner) {
        if (inner.indexOf("<a href=") !== -1)  return inner.replace("<a href=", '<a class="cta-button" href=');
        return match;
    });
    replaced  = replaced.replace(/<span class="cta-secondary-button">(.*?)<\/span>/ig, function (match, inner) {
        if (inner.indexOf("<a href=") !== -1)  return inner.replace("<a href=", '<a class="cta-secondary-button" href=');
        return match;
    });
    replaced  = replaced.replace(/<img src="(.*?)"/ig, function (match, src) {
        src = src.replace(READONLY_WONDERL, READONLY_CONSOLE) // switch tokens to production
        if (src.includes(":8057/assets")) src = `https://console.spotliowebsites.com/assets/${src.split("/assets/")[1]}` // switch assets to production
        if (src.includes(":8056/assets")) src = `https://console.spotliowebsites.com/assets/${src.split("/assets/")[1]}` // switch assets to production
        return `<img class="mt-4 mb-4" src="${src}"`
    });

    return `<div class="html ${htmlClass}">`+replaced+`</div>`;
}
export function strippTags (html){
  return html.replace(/<[^>]+>/g, '');
}

export function transformHTMLEntities(htmlText) {
  const entities = {
    "&amp;": "&",
    "&lt;": "<",
    "&gt;": ">",
    "&quot;": "\"",
    "&apos;": "'",
    "&nbsp;": " ",
    "&aring;": "å",
    "&oslash;": "ø",
    "&aelig;": "æ"
  };

  const regex = /&[^;\s]+;/g;
  return htmlText.replace(regex, function (match) {
    return entities[match] || match;
  });
}

/**
 * Return padded string.
 * @param {string} str
 * @returns
 */
export function pad2(num) {
  const str = num.toString();
  if (str.length === 1) return '0' + str;
  return str;
}

/**
 * Return padded string.
 * @param {string} str
 * @returns
 */
export function pad3(num) {
  const str = num.toString();
  if (str.length === 1) return '00' + str;
  if (str.length === 2) return '0' + str;
  return str;
}

/**
 * Returns string is empty
 * @param {string} text
 * @returns boolean
 */
export function is_empty(text) {
  if (!text) return true;
  if (text === "") return true;
  return text.length === 0;
}

/**
 * Capitalizes the first letter of a string.
 * @param {string} text - The input string to capitalize.
 * @returns {string} - The string with the first letter capitalized.
 */
export function capitalizeFirstLetter(text) {
  // Check if the input string is not empty
  if (text.length === 0) {
    return text;
  }

  // Capitalize the first letter and concatenate the rest of the string
  return text.charAt(0).toUpperCase() + text.slice(1);
}


export function removeHTMLtags(text = "") {
  const clean = text.replace(/<\/[^>]+(>|$)/g, "").replace(/<p>/g, "").replace(/<br \/>/g, "");
  return clean;
}