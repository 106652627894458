import React from "react";

export default function Pool(props: any) {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_14530_30740)">
        <path d="M0.666016 12.5C0.666016 12.5 1.33268 13.8333 3.16602 13.8333C4.99935 13.8333 5.66602 12.5 5.66602 12.5C5.66602 12.5 6.66602 13.8333 7.99935 13.8333C9.33268 13.8333 10.3327 12.5 10.3327 12.5C10.3327 12.5 11.3327 13.8333 12.8327 13.8333C14.3327 13.8333 15.3327 12.5 15.3327 12.5" stroke="currentColor" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M13.332 13.5001V5.16675C13.332 4.06218 14.2275 3.16675 15.332 3.16675V3.16675" stroke="currentColor" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M8 9.16675H13.3333" stroke="currentColor" strokeWidth="1.8"/>
        <path d="M8 5.8335H13.3333" stroke="currentColor" strokeWidth="1.8"/>
        <path d="M8 13.5001V5.16675C8 4.06218 8.89543 3.16675 10 3.16675V3.16675" stroke="currentColor" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round"/>
      </g>
      <defs>
        <clipPath id="clip0_14530_30740">
          <rect width="16" height="16" fill="white" transform="translate(0 0.5)"/>
        </clipPath>
      </defs>
    </svg>
  );
}





