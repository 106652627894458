import { getSalesId } from './general';

import request from 'src/utils/request';
import { CONNECT } from 'src/utils/environment';

export function generateSession ({ salesId }) {
  return new Promise((resolve, reject) => {
    const inntopiaSalesId = getSalesId(salesId);

    request({
      url: `${CONNECT}/inntopia/session`,
      method: 'GET',
      params: { salesid: inntopiaSalesId }
    })
    .then((response) => {
      const data = response.data || null;

      if (data.SessionId) {
        resolve(data);
      }

      reject(null);
    })
    .catch(err => {
      console.log('error service/generateSession', err);
      reject(err)
    });
  })
};