import * as React from "react"
const Restaurant = (props:any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={31}
    height={31}
    viewBox="0 0 31 31"
    fill="none"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.8}
      d="m8.624 16.125-3.537-3.662c-1.43-1.48-1.859-3.707-1.085-5.642.773-1.934 2.597-3.196 4.62-3.196 2.022 0 3.845 1.262 4.619 3.196.774 1.935.345 4.162-1.085 5.642l-3.532 3.662Z"
      clipRule="evenodd"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.8}
      d="M8.623 27.375v-11.25M22.374 27.375v-22.5M17.374 4.875v9.25c0 1.105.899 2 2.003 2h5.993a2.003 2.003 0 0 0 2.004-2v-9.25"
    />
  </svg>
)
export default Restaurant
