
/**
 * Read formatted date and returns JS Date.
 * @param date formatted date
 * @param format format
 */
export default function fromFormat(date: string, format: string = "dd/MM/yyyy"): Date {
  const read = {
    year: 0,
    month: 0,
    day: 0,
  }
  for (let i = 0; i < format.length;) {
    const buffer = format.substring(i);

    if(buffer.indexOf("yyyy")=== 0){
      const toklen = "yyyy".length;
      const num = date.substring(i, i + toklen);
      read.year = parseInt(num, 10);
      i += toklen;
      continue;
    }
    if(buffer.indexOf("MM")=== 0){
      const toklen = "MM".length;
      const num = date.substring(i, i + toklen);
      read.month = parseInt(num, 10) -1;
      i += toklen;
      continue;
    }
    if(buffer.indexOf("dd")=== 0){
      const toklen = "dd".length;
      const num = date.substring(i, i + toklen);
      read.day = parseInt(num, 10);
      i += toklen;
      continue;
    }

    i++;
  }

  return new Date(read.year, read.month, read.day, 0, 0, 0);
  



  
}

