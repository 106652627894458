import React from "react";
import Modal from "src/@components/modal.common";
import Icon from "src/components/icon";

export default class ModalDialog extends Modal<TModalDialogProps> {
    static defaultProps: Partial<TModalDialogProps> = {
        titleCentered: false,
        fillHeight: true,
        size: "medium",
        alignedCenter: false,
        hideCloseButton: false,
        removePaddings: false
    }

    render(): React.ReactNode {
        const { titleCentered, fillHeight, size, alignedCenter, hideCloseButton, open, title, style, removePaddings } = this.props;
        if (!open) return null;

        const headerStyle = titleCentered ? { justifyContent: "center"} : {}
        const sizeClassname = size === "large" ? "sm:w-3/4" : size === "small" ? "sm:w-[24rem]" : "sm:w-[48rem]"
        const alignClassname = alignedCenter ? "md:m-auto" : "sm:my-8"

        return <>
            <div
              className="fixed z-[200] inset-0 overflow-y-auto font-manrope touch-events-none"
              aria-labelledby="modal-title"
              role="dialog"
              title={title}
              aria-label={title}
              aria-modal="true"
              style={style}
            >
              <div className="flex items-end justify-center min-h-[calc(var(--vh,_1vh)_*_100)] pb-4 pt-[5rem] px-4 text-center sm:p-0">
                <div
                  tabIndex={-1}
                  onKeyDown={() => this.modalDidClose()}
                  onClick={() => this.modalDidClose()}
                  className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                  aria-hidden="true"
                />
                <span className="hidden sm:inline-block sm:align-middle sm:h-[calc(var(--vh,_1vh)_*_100)]" aria-hidden="true">&#8203;</span>
                <div
                  className={
                    `w-full ${sizeClassname} ${alignClassname} mb-safe-bottom lg:w- relative align-bottom bg-white rounded-2xl text-left shadow-xl transform transition-all sm:align-middle overflow-auto overscroll-contain max-h-[calc(100vh_-_7rem)] inline-flex flex-col`
                  }
                  style={fillHeight ? {alignSelf: 'strecth'} : undefined}
                >
                  {
                    title !== '' || !hideCloseButton
                    ?
                    <div className={`sticky top-0 z-10 bg-white p-6 flex items-center gap-2`}>
                      {title !== '' ? <span className="flex-1 pt-[0.375rem] text-[1.0625rem] leading-[1.33125rem] font-[800] flex" style={headerStyle}>{title}</span> : null}
                      {
                        !hideCloseButton ?
                        <button className="p-2 border-2 border-[#EAEAF2] rounded-lg" onClick={() => this.modalDidClose()}>
                          <Icon icon="X" className="w-4 h-4" />
                        </button>
                        : null
                      }
                    </div>
                    : <div className="pt-6" />
                  }
                  <div className={`${removePaddings ? "" : "px-6 pb-6"}`}>{this.props.children}</div>
                  {this.props.footer ? <div className={`sticky bottom-0 bg-white ${removePaddings ? "" : "p-6"} mt-auto z-10`}>{this.props.footer}</div> : null}
                </div>
              </div>
            </div>
        </>
    }
}