import * as React from "react"

const Groomed = (props: any) => (
  <svg
    width={16}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M2.33 3.333h1.823c.561 0 1.085.305 1.392.81L7.06 6.562c.504.827.289 1.937-.48 2.479a1.58 1.58 0 0 1-.911.292H2.33c-.919 0-1.663-.801-1.663-1.79v-2.42c0-.988.744-1.79 1.663-1.79Z"
      stroke="currentColor"
      strokeWidth={1.2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.159 9.333H2.841c-1.2 0-2.174.97-2.174 2.167a2.17 2.17 0 0 0 2.174 2.167H7.16c1.2 0 2.174-.97 2.174-2.167A2.17 2.17 0 0 0 7.16 9.333ZM1.056 6.082c1.614 0 2.95.4 3.284 2.787M9.333 12.609h3.122M9.204 10.442h2.438M12.513 7c-1.128 2.835-.412 6.456 2.397 7.268"
      stroke="currentColor"
      strokeWidth={1.2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default Groomed
