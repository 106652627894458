import * as React from "react"
const Heart = (props:any) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={86} height={87} viewBox="0 0 86 87" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        fill="#FFF"
        fillRule="nonzero"
        d="M43 86.06c23.702-.039 42.888-19.278 42.86-42.98C85.832 19.378 66.602.184 42.9.2 19.198.217-.005 19.438 0 43.14a42.92 42.92 0 0 0 43 42.92"
      />
      <path
        fill="#010101"
        fillRule="nonzero"
        d="M43 63.66s-12.62-10.95-18.27-21a15.1 15.1 0 0 1-2-8.31c-.017-6.444 5.176-11.69 11.62-11.74A11.49 11.49 0 0 1 43 26.52a11.42 11.42 0 0 1 8.56-3.91c6.459.028 11.68 5.271 11.68 11.73a15 15 0 0 1-2.05 8.31C55.53 52.71 43 63.66 43 63.66"
      />
      <path
        fill="#ED1C24"
        d="M71.39 14.87A38.53 38.53 0 0 1 83 43.11a38.91 38.91 0 0 1-11.7 28.34A38.64 38.64 0 0 1 43 83.15 38.63 38.63 0 0 1 14.66 71.5 38.61 38.61 0 0 1 2.91 43.21a38.87 38.87 0 0 1 11.75-28.4A38.57 38.57 0 0 1 42.9 3.12a38.66 38.66 0 0 1 28.44 11.69l.05.06ZM68.75 64.4a32.63 32.63 0 0 0 7.44-22.77 32.27 32.27 0 0 0-9.65-22 32.63 32.63 0 0 0-22.16-9.82 32.29 32.29 0 0 0-22.72 7.51l47 47 .09.08ZM64.14 69 17 22a32.92 32.92 0 0 0-7.5 22.69 32.62 32.62 0 0 0 9.82 22.17 32 32 0 0 0 22 9.6A32.72 32.72 0 0 0 64 69h.14Z"
      />
    </g>
  </svg>
)
export default Heart
