import React from "react";
import { navigate } from "gatsby";
import Accordion from "src/components/accordion";
import CurrencyIcon from "src/components/currencyIcon";
import Icon from "src/components/icon";
import { getLanguageField } from "src/utils/language";
import { getSlug } from "src/utils/slugs";
import { ApplicationContext } from "src/context";

import "./toolbar.scss";


type ToolbarProps = {
    toolbar: TToolbar;
    language: string;
    defaultLanguage: string;
    scrolledDown: boolean;
    onLinkClick?: () => void;
}

export default class ToolbarMobileV1 extends React.Component<ToolbarProps> {
    static contextType = ApplicationContext;
    /** @ts-ignore */
    context!: React.ContextType<typeof ApplicationContext>;

    private accordionRef = React.createRef<Accordion>();

    private findNameForCurrencyCode(code: string | undefined) {
        const currencyItem = this.props.toolbar.destination.currencies.find((item) => item.currency.code === code );
        if (currencyItem) return currencyItem.currency.name;
        return "-";
    }

    public pickCurrency(currencyCode: string) {
        this.context.setCurrency(currencyCode);
        this.accordionRef.current?.close();
    }

    private renderCurrencySelector() {
        if (this.props.toolbar.with_currency_selector !== true) return null;
        return <>
        <div className="relative">
            <Accordion
                ref={this.accordionRef}
                removeChildrenWhenClosed={true} 
                opener={<div className="toolbar-currency-opener caption flex flex-row items-center">
                    <CurrencyIcon className="w-5 h-5" icon={this.context.currency as any} />
                    <span className="mx-2">{this.findNameForCurrencyCode(this.context.currency)}</span>
                    <Icon className="w-4 h-4" icon="ChevronDown" />
                </div>}>
                <div className="toolbar-currency-modal inverted absolute rounded-lg shadow-md py-6 flex flex-col gap-4 bg-white">
                    {this.props.toolbar.destination.currencies.map((currency) => {
                        const {code, name} = currency.currency;
                        const isSelected = code === this.context.currency;
                        return <div onClick={(ev) => this.pickCurrency(code)} className={`caption flex flex-row items-center mx-6 toolbar-currency-item ${isSelected ? 'selected': ''}`}>
                            <CurrencyIcon className="w-5 h-5" icon={code as any} />
                            <span className="ml-2">{name}</span>
                        </div>
                    })}
                </div>
            </Accordion>
        </div>
        </>
    }

    private navigateTo(url: string) {
        navigate(url);
        if (this.props.onLinkClick) this.props.onLinkClick();
    }

    private renderToolbarItem(item: TToolbarItem, index: number) {
        const icon = item.item.icon;
        const label = getLanguageField(item.item.translations, this.props.language, 'label');
        const link = item.item.link_type === 'external'
            ? item.item.link_href as string
            : getSlug(this.props.language, this.props.defaultLanguage, item.item.link_page?.translations || []);

        return <>
        <div key={`toolbaritem-${index}`} className="mr-6 caption toolbar-item flex flex-row" onClick={() => this.navigateTo(link)}>
            { icon ? <Icon className="h-[14px] w-[14px]" icon={icon as any} /> : null}
            { label && label !== "" ? <span className="ml-2">{label}</span> : null}
        </div>
        </>;
    }
    
    public render() {
        return <>
            <div className="toolbar mob-toolbar flex flex-col p-6 gap-6">
                {this.props.toolbar.items.map((item, index) => this.renderToolbarItem(item, index))}
            </div>
            <div className="toolbar mob-toolbar flex flex-col px-6 pb-6">
                {this.renderCurrencySelector()}
            </div>
        </>
    }
}