import * as React from "react"
const Casts = (props:any) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={86} height={87} viewBox="0 0 86 87" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        fill="#FFF"
        fillRule="nonzero"
        d="M43 86.06c23.702-.039 42.888-19.278 42.86-42.98C85.832 19.378 66.602.184 42.9.2 19.198.217-.005 19.438 0 43.14a42.92 42.92 0 0 0 43 42.92"
      />
      <path
        fill="#231F20"
        d="M43.23 12.55a4.76 4.76 0 1 1-4.76 4.76 4.77 4.77 0 0 1 4.76-4.76"
      />
      <path
        stroke="#231F20"
        strokeWidth={0.01}
        d="M43.23 12.55a4.76 4.76 0 1 1-4.76 4.76 4.77 4.77 0 0 1 4.76-4.76Z"
      />
      <path fill="#FFF" fillRule="nonzero" d="M39.38 33.85h6.47v7.8h-6.47z" />
      <path
        fill="#231F20"
        d="M42.71 34.94c-2.89.82-1.76 3.72-1.76 3.72.49 2.3 3.14 1.86 3.14 1.86l6-1.37v31c0 4.73-6.09 4.73-6.09 0V48.73h-1.85v21.41c.38 4.76-5.74 4.76-5.75.11l-.1-38h-.41l-6 12.1a2.38 2.38 0 0 1-4.44-1.21c0-.343.067-.683.2-1l8.09-16.53a4.92 4.92 0 0 1 3.7-2.1h11.05a4.39 4.39 0 0 1 4 2.9l1.71 8.48a2.14 2.14 0 0 1-1.77 2.94l-7.84 1.87s-2.69 1-3.23-1.42a2.25 2.25 0 0 1 1.61-2.89l6.62-1.67-.06-.72-6.82 1.9"
      />
      <path
        stroke="#231F20"
        strokeWidth={0.01}
        d="M42.71 34.94c-2.89.82-1.76 3.72-1.76 3.72.49 2.3 3.14 1.86 3.14 1.86l6-1.37v31c0 4.73-6.09 4.73-6.09 0V48.73h-1.85v21.41c.38 4.76-5.74 4.76-5.75.11l-.1-38h-.41l-6 12.1a2.38 2.38 0 0 1-4.44-1.21c0-.343.067-.683.2-1l8.09-16.53a4.92 4.92 0 0 1 3.7-2.1h11.05a4.39 4.39 0 0 1 4 2.9l1.71 8.48a2.14 2.14 0 0 1-1.77 2.94l-7.84 1.87s-2.69 1-3.23-1.42a2.25 2.25 0 0 1 1.61-2.89l6.62-1.67-.06-.72-6.82 1.9"
      />
      <path fill="#FFF" d="m39.97 23.42 12.1 14.6-6.56 1.58z" />
      <path fill="#FFF" fillRule="nonzero" d="M35.78 51.91h7.06v18.24h-7.06z" />
      <path
        stroke="#231F20"
        strokeLinejoin="round"
        strokeWidth={0.17}
        d="M35.78 51.91h7.06v18.24h-7.06z"
      />
      <path
        fill="#ED1C24"
        d="M71.39 14.87A38.53 38.53 0 0 1 83 43.11a38.91 38.91 0 0 1-11.7 28.34A38.64 38.64 0 0 1 43 83.15 38.63 38.63 0 0 1 14.66 71.5 38.61 38.61 0 0 1 2.91 43.21a38.87 38.87 0 0 1 11.75-28.4A38.57 38.57 0 0 1 42.9 3.12a38.66 38.66 0 0 1 28.44 11.69l.05.06ZM68.75 64.4a32.63 32.63 0 0 0 7.44-22.77 32.27 32.27 0 0 0-9.65-22 32.63 32.63 0 0 0-22.16-9.82 32.29 32.29 0 0 0-22.72 7.51l47 47 .09.08ZM64.14 69 17 22a32.92 32.92 0 0 0-7.5 22.69 32.62 32.62 0 0 0 9.82 22.17 32 32 0 0 0 22 9.6A32.72 32.72 0 0 0 64 69h.14Z"
      />
    </g>
  </svg>
)
export default Casts
