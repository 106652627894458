import React from "react";

export default function CAD(props: any) {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <rect width="20" height="20" fill="#DD2033"/>
        <rect x="15" y="20" width="10" height="20" transform="rotate(180 15 20)" fill="#F5F7F8"/>
        <path d="M11.1417 6.83093L9.97536 5.27783L8.80906 6.83093L7.88625 6.54904L8.62977 9.82515L7.70537 8.33728L7.37401 8.97054L5.97679 8.7626L6.18279 10.1731L5.55566 10.5074L8.00599 12.0584L7.70597 13.0427L9.54294 12.5451L9.54295 14.7223L10.3622 14.7223L10.3622 12.5275L12.2642 13.0427L11.968 12.0711L11.9702 12.0733L14.4446 10.5017L13.8283 10.1731L14.0343 8.76263L12.6371 8.97054L12.3115 8.34829L11.3187 9.94067L11.2734 9.79197L12.0057 6.567L11.1417 6.83093Z" fill="#DD2033"/>
    </svg>
  );
}
