import * as React from "react"
const Wifi = (props:any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={17}
    viewBox="0 0 16 17"
    fill="none"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.8}
      d="M3.334 8.532a7.333 7.333 0 0 1 9.387 0M.947 6.165a10.667 10.667 0 0 1 14.107 0M5.688 10.905a4 4 0 0 1 4.633 0"
    />
    <circle cx={8.28} cy={13.5} r={0.667} fill="currentColor" />
  </svg>
)
export default Wifi
