import React from 'react'

const Ticket = (props: any) => (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M14.3577 9.8333C13.9281 10.2629 13.2316 10.2629 12.8021 9.8333C12.3725 9.40372 12.3725 8.70724 12.8021 8.27767C13.2316 7.84809 13.9281 7.84809 14.3577 8.27767C14.7873 8.70724 14.7873 9.40372 14.3577 9.8333Z" fill="currentColor"/>
        <path d="M12.095 12.096C11.6654 12.5256 10.9689 12.5256 10.5393 12.096C10.1097 11.6665 10.1097 10.97 10.5393 10.5404C10.9689 10.1108 11.6654 10.1108 12.095 10.5404C12.5245 10.97 12.5245 11.6665 12.095 12.096Z" fill="currentColor"/>
        <path d="M9.83222 14.3588C9.40264 14.7884 8.70616 14.7884 8.27658 14.3588C7.847 13.9292 7.847 13.2327 8.27658 12.8032C8.70616 12.3736 9.40264 12.3736 9.83222 12.8032C10.2618 13.2327 10.2618 13.9292 9.83222 14.3588Z" fill="currentColor"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M8.95643 1.22056C9.69843 0.478562 10.9014 0.478565 11.6434 1.22056L24.7292 14.3063C25.4712 15.0483 25.4712 16.2514 24.7292 16.9934L23.5468 18.1757C23.0397 18.6829 22.2699 18.8197 21.6191 18.5184C19.645 17.6047 17.5979 19.6479 18.5117 21.6276C18.8119 22.2781 18.6752 23.0474 18.1682 23.5544L16.9855 24.737C16.2435 25.479 15.0405 25.479 14.2985 24.737L1.21275 11.6512C0.47075 10.9092 0.470752 9.70624 1.21275 8.96424L2.39456 7.78243C2.90257 7.27442 3.67378 7.13843 4.32472 7.4409C6.30581 8.36148 8.35473 6.3087 7.43414 4.3333C7.13058 3.68192 7.26674 2.91025 7.7749 2.40209L8.95643 1.22056ZM10.3706 2.49335C10.3316 2.4543 10.2683 2.4543 10.2292 2.49335L9.09203 3.63054C10.6493 7.0955 7.08547 10.6513 3.62325 9.09932L2.48554 10.237C2.44649 10.2761 2.44649 10.3394 2.48554 10.3785L15.5713 23.4642C15.6104 23.5033 15.6737 23.5033 15.7127 23.4642L16.8517 22.3253C15.3116 18.8666 18.8566 15.3135 22.318 16.859L23.4564 15.7206C23.4955 15.6815 23.4955 15.6182 23.4564 15.5791L10.3706 2.49335ZM3.66735 9.05522C3.66731 9.05526 3.6674 9.05518 3.66735 9.05522V9.05522Z" fill="currentColor"/>
    </svg>
    
)

export default Ticket