import * as React from "react"

const GroomedStatus = (props: any) => (
  <svg
    width={23}
    height={22}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x={0.5} width={22} height={22} rx={11} fill="#31AC0D" />
    <path
      clipRule="evenodd"
      d="M5.83 6.333h1.823c.561 0 1.084.305 1.392.81l1.516 2.419c.504.827.289 1.937-.48 2.479-.27.19-.587.292-.911.292H5.83c-.919 0-1.663-.801-1.663-1.79V8.124c0-.989.744-1.79 1.663-1.79Z"
      stroke="#fff"
      strokeWidth={1.2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.659 12.333H6.34c-1.2 0-2.174.97-2.174 2.167a2.17 2.17 0 0 0 2.174 2.167h4.318c1.2 0 2.174-.97 2.174-2.167a2.17 2.17 0 0 0-2.174-2.167ZM4.556 9.082c1.614 0 2.95.4 3.284 2.787M12.833 15.609h3.122M12.704 13.442h2.438M16.013 10c-1.128 2.835-.412 6.456 2.397 7.268"
      stroke="#fff"
      strokeWidth={1.2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default GroomedStatus
