import React from "react";

export default function YoutubeFilled(props: any) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <g clipPath="url(#clip0_16055_389155)">
            <path d="M12.0765 3H12.21C13.443 3.0045 19.6905 3.0495 21.375 3.5025C21.8842 3.64075 22.3483 3.91023 22.7207 4.28401C23.0932 4.65779 23.361 5.12278 23.4975 5.6325C23.649 6.2025 23.7555 6.957 23.8275 7.7355L23.8425 7.8915L23.8755 8.2815L23.8875 8.4375C23.985 9.8085 23.997 11.0925 23.9985 11.373V11.4855C23.997 11.7765 23.9835 13.1475 23.8755 14.5755L23.8635 14.733L23.85 14.889C23.775 15.747 23.664 16.599 23.4975 17.226C23.3615 17.7359 23.0938 18.2011 22.7212 18.575C22.3487 18.9488 21.8844 19.2182 21.375 19.356C19.635 19.824 13.0215 19.857 12.105 19.8585H11.892C11.4285 19.8585 9.5115 19.8495 7.5015 19.7805L7.2465 19.7715L7.116 19.7655L6.8595 19.755L6.603 19.7445C4.938 19.671 3.3525 19.5525 2.622 19.3545C2.11273 19.2168 1.6486 18.9477 1.27609 18.5741C0.903575 18.2005 0.63577 17.7357 0.4995 17.226C0.333 16.6005 0.222 15.747 0.147 14.889L0.135 14.7315L0.123 14.5755C0.0489714 13.5591 0.0079498 12.5406 0 11.5215L0 11.337C0.003 11.0145 0.015 9.9 0.096 8.67L0.1065 8.5155L0.111 8.4375L0.123 8.2815L0.156 7.8915L0.171 7.7355C0.243 6.957 0.3495 6.201 0.501 5.6325C0.63704 5.12257 0.904743 4.65737 1.27727 4.28352C1.6498 3.90968 2.11405 3.64034 2.6235 3.5025C3.354 3.3075 4.9395 3.1875 6.6045 3.1125L6.8595 3.102L7.1175 3.093L7.2465 3.0885L7.503 3.078C8.93056 3.03206 10.3587 3.00656 11.787 3.0015H12.0765V3ZM9.6 7.815V15.042L15.8355 11.43L9.6 7.815Z" fill="currentColor"/>
        </g>
        <defs>
            <clipPath id="clip0_16055_389155">
                <rect width="24" height="24" fill="white"/>
            </clipPath>
        </defs>
    </svg>
  );
}