import { isRunTime } from "./isRunTime";

type TEventName = "currency-changed"
    | "contents-changed"
    | "dropdown-open"
    | "resize"
    | "unload"
    | "scroll"
    | "login-modal"
    ;

export function dispatch(eventName: TEventName, eventData?: any) {
    if (!isRunTime()) return;
    const event = new CustomEvent(eventName, { detail: eventData });
    window.dispatchEvent(event);
}

export function addListener(eventName: TEventName, handler: (ev: any) => void) {
    if (!isRunTime()) return;
    window.addEventListener(eventName, handler);
}

export function removeListener(eventName: TEventName, handler: (ev: any) => void) {
    if (!isRunTime()) return;
    window.removeEventListener(eventName, handler);
}
