import * as React from "react"
const Twitter = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.2}
      d="M15.335 1.999a7.266 7.266 0 0 1-2.094 1.02 2.987 2.987 0 0 0-5.24 2v.667a7.107 7.107 0 0 1-6-3.02s-2.666 6 3.334 8.666a7.76 7.76 0 0 1-4.667 1.334c6 3.333 13.333 0 13.333-7.667 0-.186-.018-.37-.053-.553a5.147 5.147 0 0 0 1.387-2.447Z"
      clipRule="evenodd"
    />
  </svg>
)
export default Twitter
