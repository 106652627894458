import * as React from "react"
const FearOfHeights = (props:any) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={86} height={87} viewBox="0 0 86 87" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        fill="#FFF"
        fillRule="nonzero"
        d="M43 86.06c23.702-.039 42.888-19.278 42.86-42.98C85.832 19.378 66.602.184 42.9.2 19.198.217-.005 19.438 0 43.14a42.92 42.92 0 0 0 43 42.92"
      />
      <path
        fill="#231F20"
        d="M47.65 13.57a1 1 0 0 0-.73 1.4 6.23 6.23 0 0 0-3.14 1c-.67.74-.32 1.33.35 1.9a13.2 13.2 0 0 0 4.45 1.7c0-.15 0-.29-.07-.44A16.28 16.28 0 0 1 45 17.94c-.4-.25-1.07-.61-1.2-1.13-.18-1 2.46-1.37 3.22-1.45a.14.14 0 0 1 0 .06 11.15 11.15 0 0 0 1.68 2.23c.04-.147.09-.29.15-.43a13.29 13.29 0 0 1-1.08-1.3c-.1-.16-.22-.36-.33-.58h2.91c.21-.127.426-.24.65-.34-.85-.08-1.71-.12-2.56-.12h-1.16a1.17 1.17 0 0 1 0-.55c.36-1.08 3.21.07 3.66.25.19.08.36.18.54.26.21-.06.424-.108.64-.14a8.06 8.06 0 0 0-4.46-1.18"
      />
      <path
        stroke="#231F20"
        strokeWidth={0.01}
        d="M47.65 13.57a1 1 0 0 0-.73 1.4 6.23 6.23 0 0 0-3.14 1c-.67.74-.32 1.33.35 1.9a13.2 13.2 0 0 0 4.45 1.7c0-.15 0-.29-.07-.44A16.28 16.28 0 0 1 45 17.94c-.4-.25-1.07-.61-1.2-1.13-.18-1 2.46-1.37 3.22-1.45a.14.14 0 0 1 0 .06 11.15 11.15 0 0 0 1.68 2.23c.04-.147.09-.29.15-.43a13.29 13.29 0 0 1-1.08-1.3c-.1-.16-.22-.36-.33-.58h2.91c.21-.127.426-.24.65-.34-.85-.08-1.71-.12-2.56-.12h-1.16a1.17 1.17 0 0 1 0-.55c.36-1.08 3.21.07 3.66.25.19.08.36.18.54.26.21-.06.424-.108.64-.14a8.06 8.06 0 0 0-4.47-1.13Z"
      />
      <path
        fill="#231F20"
        d="M58.36 19.22a1.09 1.09 0 0 0 .35-1.73 7.83 7.83 0 0 0-3.4-1.72c.198.183.38.384.54.6l.68.24c.46.2 2.46 1 2 1.85a1.66 1.66 0 0 1-.51.47c-.45-.44-.91-.84-1.34-1.19l-.17-.13c.06.2.1.42.14.62.33.29.66.58 1 .89a6 6 0 0 1-1.07.31 3.38 3.38 0 0 1-.09.41 8.65 8.65 0 0 0 1.51-.42l.67.69c.3.4 1.67 2.08.93 2.59-.74.51-2.74-.24-3.48-.54l-.72-.33-.36.28a10.54 10.54 0 0 0 3.66 1.18c1 0 1.58-.32 1.48-1.38a7.27 7.27 0 0 0-1.78-2.69"
      />
      <path
        stroke="#231F20"
        strokeWidth={0.01}
        d="M58.36 19.22a1.09 1.09 0 0 0 .35-1.73 7.83 7.83 0 0 0-3.4-1.72c.198.183.38.384.54.6l.68.24c.46.2 2.46 1 2 1.85a1.66 1.66 0 0 1-.51.47c-.45-.44-.91-.84-1.34-1.19l-.17-.13c.06.2.1.42.14.62.33.29.66.58 1 .89a6 6 0 0 1-1.07.31 3.38 3.38 0 0 1-.09.41 8.65 8.65 0 0 0 1.51-.42l.67.69c.3.4 1.67 2.08.93 2.59-.74.51-2.74-.24-3.48-.54l-.72-.33-.36.28a10.54 10.54 0 0 0 3.66 1.18c1 0 1.58-.32 1.48-1.38a7.27 7.27 0 0 0-1.82-2.69Z"
      />
      <path
        fill="#231F20"
        d="M51.3 15.61a3.22 3.22 0 1 1-1.56 4.28 3.24 3.24 0 0 1 1.56-4.28"
      />
      <path
        stroke="#231F20"
        strokeWidth={0.01}
        d="M51.3 15.61a3.22 3.22 0 1 1-1.56 4.28 3.24 3.24 0 0 1 1.56-4.28Z"
      />
      <path
        fill="#231F20"
        d="m45.24 33.22 6.82-9a1.09 1.09 0 0 0-.22-1.78l-2.65-1.84a2.34 2.34 0 0 0-2.42.22L41 23.56c-1 .49-1.22 1.17-.75 2l3.52 4.65c1.19 1.56 3 .06 2-1.31l-2.47-3.32 4.93-2.11.25.61-4.08 1.76 2.25 3c1.07 1.76-1.77 3.93-3.22 2.09l-2-2.65-1 1.2 4.81 3.74Z"
      />
      <path
        stroke="#231F20"
        strokeWidth={0.01}
        d="m45.24 33.22 6.82-9a1.09 1.09 0 0 0-.22-1.78l-2.65-1.84a2.34 2.34 0 0 0-2.42.22L41 23.56c-1 .49-1.22 1.17-.75 2l3.52 4.65c1.19 1.56 3 .06 2-1.31l-2.47-3.32 4.93-2.11.25.61-4.08 1.76 2.25 3c1.07 1.76-1.77 3.93-3.22 2.09l-2-2.65-1 1.2 4.81 3.74Z"
      />
      <path
        fill="#231F20"
        d="M29.09 51.29H29v25.8a38.685 38.685 0 0 0 11.36 1.65 39.865 39.865 0 0 0 9.05-1.06V38.41a1.63 1.63 0 0 0 1.91-1.28h1.54v-1.45h-1.81l-1.92-6.31-2.34 2.92 1.07 3.39h-3.41c-.26-.34-.51-.84-.34-1.09l.64-.76L40 30.09a4 4 0 0 0-1.48 2.65V32.97l-.14 2.42v.3H25.75v1.45h3.34v14.15Zm17.65-9a4.25 4.25 0 0 0 0-3l-1.4-2.14h2.32v14.14h-4.85a1.72 1.72 0 0 0 .61-.69l3.32-8.31Zm-6 1.49a4.55 4.55 0 0 0 1-2.8v-3.57l1.21 2.31c.44.49.29 1.28-.09 2.08l-2.87 7.4a1.62 1.62 0 0 0 .71 2.09h-6a1.84 1.84 0 0 0 .8-.59l5.24-6.92Zm-9.83-6.65h7.22l-.41 3.19a3 3 0 0 1-.61 2l-4.58 5.93a2 2 0 0 0 .9 3h-2.54l.02-14.12Z"
      />
      <path
        stroke="#231F20"
        strokeWidth={0.01}
        d="M29.09 51.29H29v25.8a38.685 38.685 0 0 0 11.36 1.65 39.865 39.865 0 0 0 9.05-1.06V38.41a1.63 1.63 0 0 0 1.91-1.28h1.54v-1.45h-1.81l-1.92-6.31-2.34 2.92 1.07 3.39h-3.41c-.26-.34-.51-.84-.34-1.09l.64-.76L40 30.09a4 4 0 0 0-1.48 2.65V32.97l-.14 2.42v.3H25.75v1.45h3.34v14.15Zm17.65-9a4.25 4.25 0 0 0 0-3l-1.4-2.14h2.32v14.14h-4.85a1.72 1.72 0 0 0 .61-.69l3.32-8.31Zm-6 1.49a4.55 4.55 0 0 0 1-2.8v-3.57l1.21 2.31c.44.49.29 1.28-.09 2.08l-2.87 7.4a1.62 1.62 0 0 0 .71 2.09h-6a1.84 1.84 0 0 0 .8-.59l5.24-6.92Zm-9.83-6.65h7.22l-.41 3.19a3 3 0 0 1-.61 2l-4.58 5.93a2 2 0 0 0 .9 3h-2.54l.02-14.12Z"
      />
      <path
        fill="#ED1C24"
        d="M71.39 14.87A38.53 38.53 0 0 1 83 43.11a38.91 38.91 0 0 1-11.7 28.34A38.64 38.64 0 0 1 43 83.15 38.63 38.63 0 0 1 14.66 71.5 38.61 38.61 0 0 1 2.91 43.21a38.87 38.87 0 0 1 11.75-28.4A38.57 38.57 0 0 1 42.9 3.12a38.66 38.66 0 0 1 28.44 11.69l.05.06ZM68.75 64.4a32.63 32.63 0 0 0 7.44-22.77 32.27 32.27 0 0 0-9.65-22 32.63 32.63 0 0 0-22.16-9.82 32.29 32.29 0 0 0-22.72 7.51l47 47 .09.08ZM64.14 69 17 22a32.92 32.92 0 0 0-7.5 22.69 32.62 32.62 0 0 0 9.82 22.17 32 32 0 0 0 22 9.6A32.72 32.72 0 0 0 64 69h.14Z"
      />
    </g>
  </svg>
)
export default FearOfHeights
