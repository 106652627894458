import React from "react";

import ToolbarCommon from "./toolbar.common";

export default class ToolbarMobileV2 extends ToolbarCommon {

    public get style(): any {
        const {toolbar} = this.props;
        return {
            '--text-color': toolbar.text_color?.value,
            '--hover-color': toolbar.hover_color?.value,
            '--background': this.props.toolbar?.background_color_when_attached_on_mobile?.value ||
                            this.props.toolbar?.background_color?.value || '#fff',
        }
    }

    get items(){
        return this.props.toolbar.items.filter(item => item.item.mobile_fixed_at_top === false).map((item, index) => this.renderToolbarItem(item, index))
    }

    public render() {
        return <>
        {this.items.length
            ?
                <div className="toolbar mob-toolbar flex flex-col p-4 gap-6 bg-[color:var(--background)]" style={this.style}>
                    {this.items}
                </div>
            :
            null
        }
        </>
    }
}