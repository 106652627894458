import React from "react";

export default function SkiSchool(props: any) {
  return (

<svg version="1.1" id="ico_activities" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24px" height="24px" viewBox="0 0 24 24" {...props}>
  <path id="Path_799" fill="none" d="M0,0h24v24H0V0z"/>
  <path fill="currentColor" d="M21.5,17.4L18.3,12c0.4-0.9,0.7-1.9,0.7-3c0-3.9-3.1-7-7-7C8.1,2,5,5.1,5,9c0,1.1,0.2,2.1,0.7,3l-3.1,5.4
    c-0.2,0.3-0.2,0.7,0,1c0.2,0.3,0.5,0.5,0.9,0.5l2.9-0.2l1.3,2.6c0.2,0.3,0.5,0.5,0.9,0.6c0,0,0,0,0,0c0.4,0,0.7-0.2,0.9-0.5L12,17
    l2.5,4.4c0.2,0.3,0.5,0.5,0.9,0.5c0,0,0,0,0,0c0.4,0,0.7-0.2,0.9-0.6l1.3-2.6l2.9,0.2c0.4,0,0.7-0.2,0.9-0.5
    C21.6,18.1,21.6,17.7,21.5,17.4z M12,4c2.8,0,5,2.2,5,5s-2.2,5-5,5s-5-2.2-5-5S9.2,4,12,4z M8.7,18.8l-0.8-1.6
    c-0.2-0.4-0.6-0.6-1-0.6l-1.7,0.1l1.7-3c0.9,1,2.1,1.7,3.5,2L8.7,18.8z M17.1,16.7c-0.4,0-0.8,0.2-1,0.6l-0.8,1.6l-1.7-3
    c1.3-0.3,2.5-1,3.5-2l1.7,3L17.1,16.7z"/>
</svg>

  );
}