import React from "react";

export default function Boot(props: any) {
  return (
    <svg version="1.1" id="ico_activities" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24px" height="24px" viewBox="0 0 24 24" {...props}>
      <path id="Path_794" fill="none" d="M0,0h24v24H0V0z"/>
      <path fill="currentColor" d="M18.2,9.9l-4.7-1.1c-0.5-0.1-1-0.5-1.3-0.9L11.2,6c-0.4-0.6-1-1-1.7-1H4C2.9,5,2,5.9,2,7v10c0,1.1,0.9,2,2,2h16
        c1.1,0,2-0.9,2-2v-2.3C22,12.4,20.4,10.4,18.2,9.9z M4,14L4,14c1.7,0,3,1.3,3,3H4L4,14z M20,17H9c0-2.8-2.2-5-5-5h0l0-5h0l5.4,0
        l1.1,1.8l-1.4,2.7c-0.2,0.5,0,1.1,0.4,1.3C9.7,13,9.8,13,10,13c0.4,0,0.7-0.2,0.9-0.6l1.1-2.2c0.3,0.2,0.7,0.3,1,0.4l0.9,0.2
        l-0.8,1.6c-0.2,0.5,0,1.1,0.4,1.3C13.7,14,13.8,14,14,14c0.4,0,0.7-0.2,0.9-0.6l1-2c0,0,0,0,0-0.1l1.8,0.4c1.4,0.3,2.3,1.5,2.3,2.9
        V17z"/>
    </svg>
  );
}