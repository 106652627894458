import React from "react";

export default class MaterialIcon extends React.Component<{ icon: string, /* rest props */ [key: string]: any  }> {
    
    public get fontSize() {
        const className = this.props.className || "";
        if (className.indexOf("w-4") !== -1) return '16px';
        if (className.indexOf("w-5") !== -1) return '20px';
        if (className.indexOf("w-6") !== -1) return '24px';
        return undefined;
    }
    
    render() {
        return <span style={{fontSize: this.fontSize}} {...this.props} className={`material-symbols-outlined ${this.props.className || ''}`}>{this.props.icon}</span>
    }
}
