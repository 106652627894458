import { isRunTime } from "./isRunTime";

export function pushState(state: Object, newUrl?: string) {
    if (!isRunTime()) return;
    const newState: Record<string, any> = {...state};
    newState.key = (window.history.state || {}).key;
    window.history.pushState(newState, '', newUrl);
}

export function readState() {
    if (isRunTime()) return window.history.state || {};
    return {};
}

export function getCurrentPath() {
    if (isRunTime()) return window.location.pathname;
    return '';
}

export function getCurrentUri() {
    if (isRunTime()) return window.location.href;
    return '';
}