import * as React from "react"
const HelpCircle = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={25}
    viewBox="0 0 24 25"
    fill="none"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.8}
      d="M12 22.77c5.523 0 10-4.478 10-10 0-5.524-4.477-10-10-10s-10 4.476-10 10c0 5.522 4.477 10 10 10Z"
      clipRule="evenodd"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.8}
      d="M9.09 9.77a3 3 0 0 1 5.83 1c0 2-3 3-3 3"
    />
    <circle cx={12} cy={17.769} r={1} fill="currentColor" />
  </svg>
)
export default HelpCircle;
