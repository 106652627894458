import * as React from "react"

const StarOutline = (props: any) => (
  <svg
    width={16}
    height={16}
    viewBox={"0 0 16 16"}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m8 1.333 2.06 4.174 4.606.673-3.333 3.247.787 4.587L8 11.847l-4.12 2.167.786-4.587L1.333 6.18l4.607-.673L8 1.334Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default StarOutline
