import React, { useState } from "react";

import ModalDialog from "src/@components/modal.dialog/modal.dialog.component";
import CTAButton from "src/components/ctabutton/ctabutton";
import { changeStoreSession } from "src/services/session";

const ChangeStoreDisclaimerModal = ({ open, data }) => {
  const [ closing, setClosing ] = useState(false);

  if (!open) {
    return null;
  }

  const onClose = () => {
    setClosing(true);

    changeStoreSession(data);
  }

  return (
  <ModalDialog
    open={open}
    titleCentered={true}
    alignedCenter
    hideCloseButton
    onClose={onClose}
    title={``}
    size="small"
  >
    <div className="flex flex-col gap-2">
      <p className="text-lg flex text-center font-semibold">
        It looks like you’re visiting our website from the USA
      </p>
      <p className="text-lg flex text-center">
        We’ll tailor the content to give you a better experience{" "}
      </p>
      <div className="flex flex-row items-center gap-4 w-full mt-4">
        <CTAButton loading={closing} onClick={() => onClose()} type="primary" useButton text={`Continue`} className="w-full"/>
      </div>
    </div>
  </ModalDialog>
  );
}

export default ChangeStoreDisclaimerModal;