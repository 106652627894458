import React from 'react'

const Mountains = (props: any) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M6.71928 3.6793C7.11195 2.74316 7.86886 2.1001 8.88657 2.1001C9.9687 2.1001 10.6842 2.93718 11.0306 3.62905C11.0373 3.64238 11.0436 3.65588 11.0496 3.66952L13.8238 9.97526C14.0239 10.4302 13.8173 10.9613 13.3624 11.1615C12.9074 11.3616 12.3763 11.1551 12.1761 10.7001L9.4119 4.41684C9.31362 4.2257 9.19862 4.08349 9.09312 3.99698C8.98888 3.9115 8.92114 3.9001 8.88657 3.9001C8.75858 3.9001 8.55999 3.94213 8.37796 4.37846L8.37321 4.38984L1.96682 19.1729C1.9002 19.3355 1.88266 19.5294 1.91721 19.7281C1.94307 19.8769 1.99405 20.0046 2.05054 20.1001H10.1302C10.6272 20.1001 11.0302 20.503 11.0302 21.0001C11.0302 21.4972 10.6272 21.9001 10.1302 21.9001H1.90341C1.59628 21.9001 1.36467 21.7981 1.20164 21.7004C1.14118 21.6642 1.08342 21.6241 1.05108 21.6017C1.0477 21.5994 1.04459 21.5972 1.04179 21.5953C1.00417 21.5693 0.98311 21.5552 0.962483 21.5428C0.875926 21.4909 0.798708 21.4249 0.73406 21.3474C0.156649 20.6553 -0.120356 19.5008 0.307508 18.4752L0.312254 18.4639L6.71928 3.6793ZM2.1269 20.1565C2.12689 20.1565 2.12691 20.1565 2.1269 20.1565V20.1565Z" fill="currentColor"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M15.9778 5.1001H16.0696C17.039 5.1001 17.659 5.81395 17.9942 6.36676L18.0211 6.41108L23.7304 18.8579L23.7382 18.8771C23.9001 19.2775 23.9001 19.5364 23.9001 19.8078V19.8096C23.9001 20.7649 23.3343 21.9001 22.1337 21.9001H9.90915C9.90405 21.9001 9.89848 21.9001 9.89247 21.9001C9.809 21.9003 9.64095 21.9007 9.47582 21.8715C9.32182 21.8443 9.0702 21.7755 8.84927 21.5753C8.45287 21.2829 8.23678 20.8339 8.15051 20.4285C8.05317 19.971 8.08934 19.4414 8.32459 18.9505L13.9927 6.59358C14.3261 5.79313 15.0891 5.28329 15.8879 5.11863L15.9778 5.1001ZM16.1191 6.91683C15.8528 7.0053 15.7015 7.16901 15.6526 7.28981L15.6449 7.30895L9.95308 19.7175L9.94883 19.7263C9.89958 19.8278 9.88876 19.9489 9.91109 20.0539C9.91486 20.0715 9.9192 20.087 9.92361 20.1001H22.0466C22.0694 20.0532 22.1001 19.9607 22.1001 19.8096C22.1001 19.7465 22.1 19.7093 22.099 19.6776C22.0981 19.6492 22.0967 19.6371 22.0961 19.6322C22.0956 19.6289 22.095 19.6251 22.0927 19.6169C22.0905 19.6089 22.0858 19.5936 22.0759 19.5683L16.43 7.25972C16.3313 7.1044 16.2403 7.00548 16.1689 6.95026C16.149 6.93491 16.1326 6.92424 16.1191 6.91683Z" fill="currentColor"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M5.71555 10.795L5.72063 10.7951L5.74076 10.7955C5.75913 10.7958 5.78722 10.7965 5.82424 10.7975C5.89831 10.7996 6.00802 10.8032 6.14702 10.8095C6.4253 10.8219 6.81945 10.8446 7.2791 10.8854C8.20717 10.9679 9.36582 11.1212 10.3709 11.3998L10.372 11.4001C11.3812 11.6784 11.9707 12.0304 12.633 12.4275L12.6402 12.4318C13.3308 12.8458 14.0949 13.304 15.3474 13.6496C16.4834 13.9659 17.5309 14.0262 18.2908 14.0093C18.6725 14.0008 18.9863 13.9727 19.2084 13.9461C19.3196 13.9327 19.4082 13.9197 19.4713 13.9095C19.5028 13.9044 19.528 13.9001 19.5465 13.8968L19.5692 13.8926L19.5766 13.8912L19.5794 13.8906L19.5805 13.8904C19.5807 13.8904 19.5815 13.8902 19.3984 13.0624L19.5805 13.8904C20.0617 13.7954 20.3645 13.3195 20.2558 12.8278C20.1471 12.3363 19.6692 12.0147 19.1882 12.1095L19.1892 12.1093L19.182 12.1106C19.1736 12.1121 19.1588 12.1147 19.138 12.118C19.0963 12.1247 19.0307 12.1345 18.944 12.1449C18.7703 12.1658 18.5134 12.1891 18.1958 12.1962C17.5571 12.2104 16.6914 12.1584 15.7684 11.9013L15.7661 11.9007C14.7569 11.6223 14.1674 11.2703 13.5051 10.8733L13.4979 10.8689C12.8072 10.4548 12.0429 9.9966 10.7901 9.65102C9.63225 9.33022 8.35073 9.16504 7.3793 9.07875C6.88912 9.03521 6.46864 9.01099 6.16974 8.99762C6.02015 8.99093 5.90063 8.98695 5.81763 8.98462C5.77612 8.98345 5.74371 8.9827 5.7212 8.98224L5.69496 8.98173L5.6876 8.98161L5.68438 8.98157C5.68427 8.98156 5.68416 8.98156 5.69993 9.88822L5.68438 8.98157C5.19118 8.97471 4.79821 9.37511 4.80692 9.87587C4.81563 10.3766 5.22242 10.7881 5.71555 10.795Z" fill="currentColor"/>
  </svg>
)

export default Mountains


