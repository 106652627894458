import * as React from "react"
const MotionSickness = (props:any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    data-name="Layer 1"
    viewBox="0 0 85.8 85.9"
    {...props}
  >
    <defs>
      <style>
        {
          ".cls-2,.cls-3{fill-rule:evenodd;stroke-width:0}.cls-5{fill:none;stroke:#231f20;stroke-miterlimit:2.6;stroke-width:0}.cls-2{fill:#231f20}.cls-3{fill:#fff}"
        }
      </style>
    </defs>
    <path
      d="M43 86.1c23.7 0 42.9-19.2 42.9-42.9S66.7.2 43 .2 0 19.4 0 43.1 19.2 86 42.9 86"
      style={{
        fill: "#fff",
        strokeWidth: 0,
      }}
    />
    <path
      d="M67.2 72c-10.8 8.3-25.8 10.2-38.4 4.2.7.4 4.3-2 5.4-3.5 1.8 1.4 3.4 2.4 7.2 2.4 2.3 0 5.6-2.1 6.5-3.5 1.9 1.3 3.1 2.1 6.9 2.2 2.9 0 6.1-2.1 7.2-3.8 1.4 1.3 3.1 2 5.2 2.2"
      className="cls-2"
    />
    <path
      d="M67.2 72c-10.8 8.3-25.8 10.2-38.4 4.2.7.4 4.3-2 5.4-3.5 1.8 1.4 3.4 2.4 7.2 2.4 2.3 0 5.6-2.1 6.5-3.5 1.9 1.3 3.1 2.1 6.9 2.2 2.9 0 6.1-2.1 7.2-3.8 1.4 1.3 3.1 2 5.2 2.2Z"
      className="cls-5"
    />
    <path
      d="M64.2 14.6c2.6 0 4.8 2.1 4.8 4.8s-2.1 4.8-4.8 4.8-4.8-2.2-4.8-4.8 2.2-4.8 4.8-4.8"
      className="cls-2"
    />
    <path
      d="M64.2 14.6c2.6 0 4.8 2.1 4.8 4.8s-2.1 4.8-4.8 4.8-4.8-2.2-4.8-4.8 2.2-4.8 4.8-4.8Z"
      className="cls-5"
    />
    <path
      d="M66.4 27.8c.4 0 .8.4.8.8s-.4.8-.8.8-.8-.3-.8-.8.3-.8.8-.8"
      className="cls-2"
    />
    <path
      d="M66.4 27.8c.4 0 .8.4.8.8s-.4.8-.8.8-.8-.3-.8-.8.3-.8.8-.8Z"
      className="cls-5"
    />
    <path
      d="M65.8 29.7c.3 0 .5.2.5.5s-.2.5-.5.5-.5-.2-.5-.5.2-.5.5-.5"
      className="cls-2"
    />
    <path
      d="M65.8 29.7c.3 0 .5.2.5.5s-.2.5-.5.5-.5-.2-.5-.5.2-.5.5-.5Z"
      className="cls-5"
    />
    <path
      d="M70.1 25.5c.3 0 .5.2.5.5s-.2.5-.5.5-.5-.2-.5-.5.2-.5.5-.5"
      className="cls-2"
    />
    <path
      d="M70.1 25.5c.3 0 .5.2.5.5s-.2.5-.5.5-.5-.2-.5-.5.2-.5.5-.5Z"
      className="cls-5"
    />
    <path
      d="M67 24.1c.3 0 .6.2.6.6s-.3.6-.6.6-.6-.2-.6-.6.3-.6.6-.6"
      className="cls-2"
    />
    <path
      d="M67 24.1c.3 0 .6.2.6.6s-.3.6-.6.6-.6-.2-.6-.6.3-.6.6-.6Z"
      className="cls-5"
    />
    <path
      d="M68.4 26.9c.2 0 .3.1.3.3s-.2.3-.3.3-.3-.2-.3-.3.2-.3.3-.3"
      className="cls-2"
    />
    <path
      d="M68.4 26.9c.2 0 .3.1.3.3s-.2.3-.3.3-.3-.2-.3-.3.2-.3.3-.3Z"
      className="cls-5"
    />
    <path
      d="M73 30.1c.2 0 .3.2.3.3s-.1.3-.3.3-.3-.2-.3-.3.1-.3.3-.3"
      className="cls-2"
    />
    <path
      d="M73 30.1c.2 0 .3.2.3.3s-.1.3-.3.3-.3-.2-.3-.3.1-.3.3-.3Z"
      className="cls-5"
    />
    <path
      d="M70.3 28c.2 0 .3.1.3.3s-.2.3-.3.3-.3-.1-.3-.3.1-.3.3-.3"
      className="cls-2"
    />
    <path
      d="M70.3 28c.2 0 .3.1.3.3s-.2.3-.3.3-.3-.1-.3-.3.1-.3.3-.3Z"
      className="cls-5"
    />
    <path
      d="M69.6 29.5c.2 0 .3.1.3.3s-.1.3-.3.3-.3-.1-.3-.3.1-.3.3-.3"
      className="cls-2"
    />
    <path
      d="M69.6 29.5c.2 0 .3.1.3.3s-.1.3-.3.3-.3-.1-.3-.3.1-.3.3-.3Z"
      className="cls-5"
    />
    <path
      d="M68.1 32c.1 0 .2.1.2.2s-.1.2-.2.2-.2-.1-.2-.2.1-.2.2-.2"
      className="cls-2"
    />
    <path
      d="M68.1 32c.1 0 .2.1.2.2s-.1.2-.2.2-.2-.1-.2-.2.1-.2.2-.2Z"
      className="cls-5"
    />
    <path
      d="M64.2 27c.1 0 .2.1.2.2s-.1.2-.2.2-.2-.1-.2-.2 0-.2.2-.2"
      className="cls-2"
    />
    <path
      d="M64.2 27c.1 0 .2.1.2.2s-.1.2-.2.2-.2-.1-.2-.2 0-.2.2-.2Z"
      className="cls-5"
    />
    <path
      d="m42.1 26.6 3.8 7.3 14.5-8c1-.5 1.3-1.2.7-2.3l-2.2-3.7c-.5-.7-1.4-1.2-3.1-1.1l-9.5.5c-1.4 0-2.1.7-2 2l1.7 8.2c.5 2.6 3.9 1.7 3.5-.6l-1.2-6h7.8v.5H49l1.1 5.5c.6 2.6-3.8 4-4.5.9l-1-4.8-2.5 1.5Z"
      className="cls-2"
    />
    <path
      d="m42.1 26.6 3.8 7.3 14.5-8c1-.5 1.3-1.2.7-2.3l-2.2-3.7c-.5-.7-1.4-1.2-3.1-1.1l-9.5.5c-1.4 0-2.1.7-2 2l1.7 8.2c.5 2.6 3.9 1.7 3.5-.6l-1.2-6h7.8v.5H49l1.1 5.5c.6 2.6-3.8 4-4.5.9l-1-4.8-2.5 1.5Z"
      className="cls-5"
    />
    <path
      d="m48.8 32.7 4.5-2.2-1.5 10.4c-.3 2.4-4.1 1.6-3.8 0v-.4l1.6.5c1.4.4 2.2-1.6.7-2.1l-2.2-.9.7-5.2Z"
      className="cls-2"
    />
    <path
      d="m48.8 32.7 4.5-2.2-1.5 10.4c-.3 2.4-4.1 1.6-3.8 0v-.4l1.6.5c1.4.4 2.2-1.6.7-2.1l-2.2-.9.7-5.2Z"
      className="cls-5"
    />
    <path
      d="m41.3 27.1 3.7 7.2-1.6.7c-.4.3-.5.6-.4 1.1l-5.3-1.8-.4-1.7c0-3.3 1.6-4.9 4-5.6"
      className="cls-2"
    />
    <path
      d="m41.3 27.1 3.7 7.2-1.6.7c-.4.3-.5.6-.4 1.1l-5.3-1.8-.4-1.7c0-3.3 1.6-4.9 4-5.6Z"
      className="cls-5"
    />
    <path d="m37 34.2-1.7-.6 1.1-1.9.6 2.5z" className="cls-2" />
    <path d="m37 34.2-1.7-.6 1.1-1.9.6 2.5z" className="cls-5" />
    <path
      d="M71.3 68.5c-2.7-.2-5.2-4.2-5.2-4.2s-2.2 3.4-7.1 3.6c-3.8 0-5.4-1.5-7.5-3.9-3.4 5.1-9.2 2.6-9.2 2.6l-4.4 6.5s5.8 2.5 10-3.8c3.2 2.8 4.3 3.1 7 3 4.2 0 6.6-3.9 7.3-4.4 1.6 1.6 3.6 2.8 6.9 2.8l2.3-2.2Z"
      className="cls-2"
    />
    <path
      d="M71.3 68.5c-2.7-.2-5.2-4.2-5.2-4.2s-2.2 3.4-7.1 3.6c-3.8 0-5.4-1.5-7.5-3.9-3.4 5.1-9.2 2.6-9.2 2.6l-4.4 6.5s5.8 2.5 10-3.8c3.2 2.8 4.3 3.1 7 3 4.2 0 6.6-3.9 7.3-4.4 1.6 1.6 3.6 2.8 6.9 2.8l2.3-2.2Z"
      className="cls-5"
    />
    <path
      d="m19.4 30.1 27.1 9.2-6.4 19.9-27.3-8.9 6.6-20.2ZM7.3 48.2l4 1.6 6.5-20.2-8-2.7.4-1.2 39.6 13.5c.7.2.5 1.3-.4 1l-1.7-.6-6.3 20 1.7.6c.2 1.1-5.5 11.4-7.3 12.2-.7-.3-1.5-.9-2.2-2-2.6 4.1-6.8 4.8-6.8 4.8-7.7-2.8-18.5-15.6-19.6-26.9"
      className="cls-2"
    />
    <path
      d="m19.4 30.1 27.1 9.2-6.4 19.9-27.3-8.9 6.6-20.2ZM7.3 48.2l4 1.6 6.5-20.2-8-2.7.4-1.2 39.6 13.5c.7.2.5 1.3-.4 1l-1.7-.6-6.3 20 1.7.6c.2 1.1-5.5 11.4-7.3 12.2-.7-.3-1.5-.9-2.2-2-2.6 4.1-6.8 4.8-6.8 4.8-7.7-2.8-18.5-15.6-19.6-26.9Z"
      className="cls-5"
    />
    <path
      d="m38.2 37.1 5.2 1.8.8 5.4c.3 1.5-.5 2.7-1.4 3.8l-8.7 8.2c-2.4 1.9-5.7-1.6-3.4-3.7l7.7-7.3c.9-.8 1-1.6.7-2.4l-.8-5.7Z"
      className="cls-2"
    />
    <path
      d="m38.2 37.1 5.2 1.8.8 5.4c.3 1.5-.5 2.7-1.4 3.8l-8.7 8.2c-2.4 1.9-5.7-1.6-3.4-3.7l7.7-7.3c.9-.8 1-1.6.7-2.4l-.8-5.7Z"
      className="cls-5"
    />
    <path
      d="m34.5 35.8 2.7.9.6 4.6-1.7 3.5c-.5 1.2-1.4 2.1-2.7 2.9l-10.3 5.4c-2.6 1.3-4.8-3.2-2.1-4.6l8.9-4.6c.7-.4 1.4-1 1.9-2.1l2.8-5.9Z"
      className="cls-2"
    />
    <path
      d="m34.5 35.8 2.7.9.6 4.6-1.7 3.5c-.5 1.2-1.4 2.1-2.7 2.9l-10.3 5.4c-2.6 1.3-4.8-3.2-2.1-4.6l8.9-4.6c.7-.4 1.4-1 1.9-2.1l2.8-5.9Z"
      className="cls-5"
    />
    <path
      d="M15.5 30.2c5.3 0 9.7 4.4 9.7 9.7s-4.4 9.7-9.7 9.7-7.2-2.3-8.8-5.6c-.3-3 .1-6.6.6-9.3C9 32 12 30.2 15.5 30.2m0 5.3c2.4 0 4.4 2 4.4 4.4s-2 4.4-4.4 4.4-4.4-2-4.4-4.4 2-4.4 4.4-4.4"
      className="cls-2"
    />
    <path
      d="M15.5 30.2c5.3 0 9.7 4.4 9.7 9.7s-4.4 9.7-9.7 9.7-7.2-2.3-8.8-5.6c-.3-3 .1-6.6.6-9.3C9 32 12 30.2 15.5 30.2Zm0 5.3c2.4 0 4.4 2 4.4 4.4s-2 4.4-4.4 4.4-4.4-2-4.4-4.4 2-4.4 4.4-4.4Z"
      className="cls-5"
    />
    <path
      d="M11.6 37.9c.6-1.1 1.6-2 2.9-2.3l-3.3-4.4c-1.3.6-2.4 1.5-3.2 2.6l3.6 4.1Z"
      className="cls-3"
    />
    <path
      d="M11.6 37.9c.6-1.1 1.6-2 2.9-2.3l-3.3-4.4c-1.3.6-2.4 1.5-3.2 2.6l3.6 4.1Z"
      className="cls-5"
    />
    <path
      d="M19.2 37.5c.7 1 .9 2.4.6 3.6l5.4-.7c0-1.4-.2-2.8-.7-4.1l-5.3 1.2Z"
      className="cls-3"
    />
    <path
      d="M19.2 37.5c.7 1 .9 2.4.6 3.6l5.4-.7c0-1.4-.2-2.8-.7-4.1l-5.3 1.2Z"
      className="cls-5"
    />
    <path
      d="M15.9 44.3c-1.2.1-2.5-.3-3.5-1.2l-1.9 5.1c1.2.7 2.6 1.2 3.9 1.3l1.5-5.2Z"
      className="cls-3"
    />
    <path
      d="M15.9 44.3c-1.2.1-2.5-.3-3.5-1.2l-1.9 5.1c1.2.7 2.6 1.2 3.9 1.3l1.5-5.2Z"
      className="cls-5"
    />
    <path
      d="M71.4 14.9C79.1 22.6 83 32.1 83 43.1c0 11.1-3.9 20.6-11.7 28.3C63.5 79.2 54 83.1 43 83.1s-20.5-3.9-28.3-11.6C6.9 63.7 2.9 54.2 2.9 43.2s3.9-20.6 11.8-28.4C22.4 7.1 31.9 3.1 42.9 3.1 54 3.1 63.5 7 71.3 14.8Zm-2.7 49.5c5.3-6.5 7.9-14.2 7.4-22.8-.4-8.6-3.6-16-9.6-22-6.1-6.1-13.6-9.4-22.2-9.8-8.6-.4-16.2 2.1-22.7 7.5l47 47ZM64.1 69 17 22c-5.3 6.5-7.9 14.2-7.5 22.7.4 8.6 3.7 16 9.8 22.2 6 6 13.4 9.2 22 9.6 8.6.4 16.2-2.1 22.8-7.5Z"
      style={{
        strokeWidth: 0,
        fillRule: "evenodd",
        fill: "#ed1c24",
      }}
    />
  </svg>
)
export default MotionSickness
