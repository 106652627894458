import React from "react";

export default function Facebook(props: any) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 2H14C11.2386 2 9 4.23858 9 7V10H6V14H9V22H13V14H16L17 10H13V7C13 6.44772 13.4477 6 14 6H17V2Z"
        stroke="currentColor"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}