import * as React from "react"

const DoubleBlackDiamond = (props:any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22}
    height={22}
    viewBox="0 0 22 22"
    fill="none"
    {...props}
  >
    <path
      stroke="#242426"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.8}
      d="m10.999 6.21-2.98-3.46L.916 11l7.105 8.25 2.979-3.46"
    />
    <path
      stroke="#242426"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.8}
      d="M13.98 2.75 21.085 11l-7.105 8.25L6.876 11l7.104-8.25Z"
      clipRule="evenodd"
    />
  </svg>
)
export default DoubleBlackDiamond
