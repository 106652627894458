import * as React from "react"
const RedSquare = (props:any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22}
    height={22}
    viewBox="0 0 22 22"
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="#E10003"
        fillRule="evenodd"
        d="M3.929 4.583H18.07c.651 0 1.179.381 1.179.851V15.65c0 .47-.528.85-1.179.85H3.93c-.651 0-1.179-.38-1.179-.85V5.434c0-.47.528-.851 1.179-.851Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h22v22H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default RedSquare