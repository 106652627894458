export default [
    {
        language: "es-ES",
        "subscribed": "Te has suscrito correctamente",
        "already.subscribed": "Este correo electrónico ya está suscrito",
        "bad.email.format": "Por favor, escribe un formato de dirección de correo electrónico correcto",
        "error": "Lo siento, algo salió mal. Por favor, inténtalo de nuevo"
    },
    {
        language: "en-US",
        "subscribed": "You have been successfully subscribed",
        "already.subscribed": "This email is already subscribed",
        "bad.email.format": "Please, type a correct email address format",
        "error": "Sorry, something went wrong. Please try again"
    },
    {
        language: "nb-NO",
        "subscribed": "Du har blitt vellykket abonnert",
        "already.subscribed": "Denne e-posten er allerede abonnert",
        "bad.email.format": "Vennligst skriv inn en riktig e-postadresseformat",
        "error": "Beklager, noe gikk galt. Vennligst prøv igjen"
    },
    {
        language: "no-NO",
        "subscribed": "Du har blitt vellykket abonnert",
        "already.subscribed": "Denne e-posten er allerede abonnert",
        "bad.email.format": "Vennligst skriv inn en riktig e-postadresseformat",
        "error": "Beklager, noe gikk galt. Vennligst prøv igjen"
    },
    
    {
        language: "it-IT",
        "subscribed": "Ti sei iscritto con successo",
        "already.subscribed": "Questa email è già iscritta",
        "bad.email.format": "Si prega di digitare un formato corretto per l’indirizzo email",
        "error": "Spiacenti, si è verificato un errore. Si prega di riprovare"
    },
    {
        language: "fr-FR",
        "subscribed": "Vous avez été inscrit avec succès",
        "already.subscribed": "Cette adresse e-mail est déjà abonnée",
        "bad.email.format": "Veuillez saisir un format d’adresse e-mail correct",
        "error": "Désolé, une erreur s’est produite. Veuillez réessayer"
    },
    {
        language: "de-DE",
        "subscribed": "Du bist erfolgreich abonniert worden",
        "already.subscribed": "Diese E-Mail-Adresse ist bereits abonniert",
        "bad.email.format": "Bitte geben Sie ein korrektes E-Mail-Adressformat ein",
        "error": "Entschuldigung, etwas ist schiefgelaufen. Bitte versuchen Sie es erneut"
    },
] as Array<Record<NewsletterFeedback & "language", string>>;
