import React from 'react'
import Icon from "src/components/icon";
import { addListener, dispatch, removeListener } from 'src/utils/events'


interface AccordionProps {
  children: any
  className?: string
  responsive?: boolean
  removeChildrenWhenClosed?: boolean
  alignment?: 'vertical' | 'horizontal'
  extraHeight?: number;
  opener: any
  initiallyOpen?: boolean
  onToggle?: (open: boolean) => void
  closeOther?: boolean;
  doNotCloseByOthers?: boolean;
  doNotCloseByClicks?: boolean;
  doNotCloseByKeyboard?: boolean;
  openerWithIcons?: boolean;
}

interface AccordionState {
  open: boolean;
}

export default class Accordion extends React.Component<AccordionProps, AccordionState> {
  static uuid = 0;
  public uuid = Accordion.uuid++;
  
  public accordionRef = React.createRef<HTMLDivElement>()
  static defaultProps = { initiallyOpen: false, onToggle: () => void 0 }

  state: AccordionState = {
    open: false,
  }

  get accordionMaxHeight() {
    if (this.props.responsive) {
      if (this.state.open) return ''
      return '0px'
    }
    if (this.state.open) return `${this.currentHeight}px`
    return '0px'
  }

  get accordionMaxWidth() {
    if (this.props.responsive) {
      if (this.state.open) return ''
      return '0px'
    }
    if (this.state.open) return `${this.accordionRef.current && this.accordionRef.current.scrollWidth}px`
    return '0px'
  }

  get currentHeight() {
    if (this.accordionRef.current) {
      return this.accordionRef.current.offsetHeight + this.accordionRef.current.scrollHeight + (this.props.extraHeight || 0);
    }
    return 0;
  }

  public getBoundingClientRect() {
    if (this.accordionRef.current) return this.accordionRef.current.getBoundingClientRect();
    return { left: 0 }
  }

  public open() {
    this.setState({ open: true }, this.notify)
    if (this.props.closeOther) { dispatch('dropdown-open', this.uuid); }
  }

  public close() {
    this.setState({ open: false }, this.notify)
  }

  public toggle() {
    this.setState({ open: !this.state.open }, this.notify)
    if (!this.state.open) dispatch('dropdown-open', this.uuid);
  }

  notify = () => {
    if (!this.props.onToggle) return
    this.props.onToggle(this.state.open || false)
  }

  clickTrapper = (ev: MouseEvent) => {
    if (this.props.doNotCloseByClicks) return;
    const clickedInsideOfAccordionChildren = this.accordionRef.current && this.accordionRef.current.contains(ev.target as any)
    if (clickedInsideOfAccordionChildren && this.state.open) return
    const clickedOnParentOfAccordion = ev.currentTarget && (ev.currentTarget as any).contains && (ev.currentTarget as any).contains(this.accordionRef.current) && this.state.open
    if (clickedOnParentOfAccordion && this.state.open) {
      this.close()
      return
    }
    const traversedToBody = ev.currentTarget === ev.target && ev.target === document.body
    if (traversedToBody && this.state.open) {
      this.close()
    }
  }

  escTrapper = (ev: KeyboardEvent) => {
    if (this.props.doNotCloseByKeyboard) return;
    if (ev.code === 'Escape' && this.state.open) {
      this.close()
    }
  }

  onOtherDropdownOpen = (id: number) => {
    if (this.props.doNotCloseByOthers) return;
    if (id !== this.uuid && this.state.open) this.close();
  }

  componentDidMount() {
    addListener('dropdown-open', this.onOtherDropdownOpen);
    document.body.addEventListener('click', this.clickTrapper)
    document.body.addEventListener('keydown', this.escTrapper)
    if (this.props.initiallyOpen) setTimeout(() => this.open(), 200);
  }

  componentWillUnmount(): void {
    removeListener('dropdown-open', this.onOtherDropdownOpen);
    document.body.removeEventListener('click', this.clickTrapper)
    document.body.removeEventListener('keydown', this.escTrapper)
  }

  stopEvent(ev: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>) {
    ev.preventDefault()
    ev.stopPropagation()
    ev.nativeEvent.stopImmediatePropagation()
    return false
  }

  renderOpener() {
    const { opener, openerWithIcons } = this.props
    const { open } = this.state

    const onClick = (ev: any) => {
      if (this.state.open) { this.close(); } else { this.open(); }
      return this.stopEvent(ev)
    };

    const onKeyDown: React.KeyboardEventHandler<HTMLDivElement> = (e) => {
      if (e.nativeEvent.code === "Space" || e.nativeEvent.code === "Enter") {
        if (this.state.open) this.close();
      }
      return this.stopEvent(e);
    }

    if (!openerWithIcons) {
      const openerWithProps = React.Children.map([opener], child => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, { onClick, onKeyDown } as any);
        }
        return child;
      });

      return openerWithProps;
    }

    return <div className="flex flex-row justify-between items-center cursor-pointer text-[color:var(--filter-title-color)]" onClick={onClick}>
      {opener}
      <Icon icon={open ? "Minus" : "Plus"} className="w-4 h-4" />
    </div>;
  }

  render() {
    const { children, className = '', responsive = false, alignment = 'vertical', removeChildrenWhenClosed = false } = this.props
    const isVertical = alignment === 'vertical'
    const { open } = this.state

    return (
      <>
        {this.renderOpener()}
        <div
          data-testid='Accordion'
          ref={this.accordionRef}
          style={{ maxHeight: (isVertical && this.accordionMaxHeight) || undefined, maxWidth: (!isVertical && this.accordionMaxWidth) || undefined }}
          className={`spqr_Accordion overflow-y-auto ${className} ${responsive && 'responsive'} ${responsive && !open && 'collapsed'}`}
        >
          {removeChildrenWhenClosed ? this.state.open && children : children}
        </div>
      </>
    )
  }
}
