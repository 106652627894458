import * as React from "react"
const DarkRide = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    {...props}
  >
    <g fill="currentColor" clipPath="url(#a)">
      <path d="M19.832 1.467C18.675 1.273 18.524.22 18.518.176L18.497 0h-6.795v.401h.919c.112.098.397.375.607.816.344.724.339 1.54-.016 2.423l-1.545.79.183.357 1.578-.807c.2-.019 1.305-.09 2 .58.468.453.662 1.157.578 2.095l-.728 1.464.36.178.725-1.46c.184-.15 1.212-.95 2.145-.782.443.08.81.377 1.09.88v1.398H20V1.496l-.167-.029Zm-.234 2.161a1.201 1.201 0 0 0-.517-.25c-.378-.082-.765.017-1.07.146l.973-1.956c.168.109.371.201.614.26v1.8ZM17.3 2.771a1.624 1.624 0 0 0-.78-.371l2.142-1.095.003.003-1.005 2.021a1.294 1.294 0 0 0-.36-.558ZM18.39.953l.019.03-2.203 1.126c.068-.23.078-.462.03-.695C16.15.986 15.88.64 15.631.4h2.526c.036.138.105.34.233.552Zm-4.809.073a3.08 3.08 0 0 0-.403-.625h1.816c.163.108.733.524.85 1.095.061.302-.016.61-.23.916l-1.887.965c.304-1.041.101-1.84-.146-2.35Zm2.123 3.244c-.46-.443-1.05-.614-1.524-.674l1.63-.833c.15-.015.823-.059 1.221.305.22.201.324.503.311.9l-.935 1.88c-.065-.662-.3-1.19-.703-1.579Zm2.874 1.39c-.678-.122-1.364.158-1.847.437l.968-1.945c.153-.106.764-.498 1.298-.382.249.054.446.211.602.48v1.993a1.781 1.781 0 0 0-1.02-.582Z" />
      <path
        fillRule="evenodd"
        d="M6.324 7.284a1.225 1.225 0 1 0 0-2.45 1.225 1.225 0 0 0 0 2.45Zm-1.226 2.45h-.98v5.883h12.254a5.883 5.883 0 0 0-5.392-5.862V9.49a1.226 1.226 0 0 0-2.45 0v.245h-.98V9a1.225 1.225 0 1 0-2.452 0v.735ZM10.98 6.55a1.226 1.226 0 1 1-2.45 0 1.226 1.226 0 0 1 2.45 0Zm7.353 10.122H1.667v.98h16.666v-.98ZM8.5 19.277a1.315 1.315 0 1 1-2.63 0 1.315 1.315 0 0 1 2.63 0Zm8.681 1.315a1.315 1.315 0 1 0 0-2.63 1.315 1.315 0 0 0 0 2.63Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="transparent" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default DarkRide
