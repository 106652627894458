import React from "react";

export default class TikTokIcon extends React.Component<{ className?: string }> {
    render() {
        return <>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={this.props.className}>
            <path d="M6.01 10.99C4.35 10.99 3 12.33 3 13.99C3 15.65 4.34 16.99 6 16.99C7.66 16.99 9 15.65 9 13.99C9 12.33 7.66 10.99 6 10.99H6.01ZM6.01 16.14C4.82 16.14 3.86 15.18 3.86 13.99C3.86 12.8 4.82 11.84 6.01 11.84C7.2 11.84 8.16 12.81 8.16 13.99C8.16 15.17 7.2 16.14 6.01 16.14Z" fill="currentColor"/>
            <path d="M6.02003 15.48C6.84293 15.48 7.51003 14.8129 7.51003 13.99C7.51003 13.1671 6.84293 12.5 6.02003 12.5C5.19712 12.5 4.53003 13.1671 4.53003 13.99C4.53003 14.8129 5.19712 15.48 6.02003 15.48Z" fill="currentColor"/>
            <path d="M18 10.99C16.34 10.99 15 12.33 15 13.99C15 15.65 16.34 16.99 18 16.99C19.66 16.99 21 15.65 21 13.99C21 12.33 19.66 10.99 18 10.99ZM18 16.14C16.81 16.14 15.85 15.18 15.85 13.99C15.85 12.8 16.81 11.84 18 11.84C19.19 11.84 20.15 12.81 20.15 13.99C20.15 15.17 19.19 16.14 18 16.14Z" fill="currentColor"/>
            <path d="M18.01 15.48C18.8329 15.48 19.5 14.8129 19.5 13.99C19.5 13.1671 18.8329 12.5 18.01 12.5C17.1871 12.5 16.52 13.1671 16.52 13.99C16.52 14.8129 17.1871 15.48 18.01 15.48Z" fill="currentColor"/>
            <path d="M22.22 9.74L23.99 7.99H20.41C18.41 6.36 15.08 5 11.99 5C8.9 5 5.59 6.36 3.59 7.99H0L1.77 9.74C0.68 10.83 0 12.33 0 13.99C0 17.31 2.69 20 6.01 20C7.53 20 8.92 19.43 9.98 18.5L12.01 19.99L14.02 18.53L14 18.47C15.06 19.42 16.46 19.99 18 19.99C21.32 19.99 24.01 17.3 24.01 13.98C24.01 12.32 23.33 10.82 22.23 9.73L22.22 9.74ZM17.24 8.03C14.4 8.39 12.17 10.73 12 13.63C11.83 10.73 9.59 8.38 6.74 8.03C8.17 7 10 6.49 11.99 6.49C13.98 6.49 15.81 7 17.24 8.03ZM6.01 18.5C3.52 18.5 1.5 16.48 1.5 13.99C1.5 11.5 3.52 9.49 6.01 9.49C8.5 9.49 10.52 11.51 10.52 14C10.52 16.49 8.5 18.51 6.01 18.51V18.5ZM18 18.5C15.51 18.5 13.49 16.48 13.49 13.99C13.49 11.5 15.51 9.48 18 9.48C20.49 9.48 22.51 11.5 22.51 13.99C22.51 16.48 20.49 18.5 18 18.5Z" fill="currentColor"/>
        </svg>
        </>
    }
}