import React from "react";

export default function Avalanche3(props: any) {
  return (
    <svg
    width={60}
    height={48}
    viewBox='0 0 60 48'
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        d="M3.95 28.95a7 7 0 0 1 0-9.9L19.03 3.97a7 7 0 0 1 9.92.021L39.03 14.156l1.453-1.123a6.273 6.273 0 0 1 8.744 1.055l.428.537.694.204c4.654 1.368 5.99 7.32 2.369 10.546l-.1.092a7.002 7.002 0 0 1-1.806 1.219l-.27.126a2.001 2.001 0 0 1-.847.188H45.25L28.948 43.877a7 7 0 0 1-9.984.087L3.95 28.95Z"
        fill="#fff"
      />
      <path
        d="M5.828 26.829a4 4 0 0 1 0-5.657L21.19 5.81a4 4 0 0 1 5.638-.02l11.235 11.085h2.156l1.073-1.199c1.743-1.948 4.857-1.728 6.308.447l.502.752 1.219.136a3.11 3.11 0 0 1 2.367 1.567 3.672 3.672 0 0 1-.595 4.847l-.32.281a4 4 0 0 1-2.642.997h-3.525c-.366 0-.716.153-.965.422L26.828 42.138a4 4 0 0 1-5.673.017L5.828 26.829Z"
        fill="#2B2B2B"
      />
      <path
        d="m5.769 24.731 18.243.012 18.242.012L25.5 41.5l-.094.085a2 2 0 0 1-2.748-.068L5.767 24.731Z"
        fill="#FF8264"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.53 18.36h3.955a2.703 2.703 0 0 1 5.405 0h1.696c1.32 0 2.383 1.1 2.383 2.438 0 1.337-1.07 2.422-2.392 2.422h-10.64L26.143 11.44a.71.71 0 0 0-1.065.934l.78 1.018-2.437 3.03-2.808-2.042a1 1 0 0 0-1.08-.062L8.593 20.5 22.625 6.477l.091-.082a2 2 0 0 1 2.742.063L37.532 18.36Z"
        fill="#fff"
      />
      <path
        d="M21.066 27.512h1.86v1.172l-.282 4.593H21.38l-.313-4.593v-1.172Z"
        fill="#1E2127"
      />
      <circle cx={21.996} cy={35.137} r={1.148} fill="#1E2127" />
      <path
        d="M25.082 27.512h1.86v1.172l-.282 4.593h-1.266l-.312-4.593v-1.172Z"
        fill="#1E2127"
      />
      <circle cx={26.012} cy={35.137} r={1.148} fill="#1E2127" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m27.937 9.94.002-.002 9.685 9.778a.822.822 0 0 1 .321.652.83.83 0 0 1-.835.827.836.836 0 0 1-.525-.184l-.001.002-.014-.014a.833.833 0 0 1-.106-.108l-9.587-9.679a.826.826 0 0 1 .514-1.478c.209 0 .4.078.546.206Zm13.755 8.985c-.655.237-.612 1.176.061 1.353l.08.02a2 2 0 0 1 1.386 1.239l.096.257c.256.67 1.206.667 1.47 0l.07-.282.015-.132a1.54 1.54 0 0 0-.05-.598l-.036-.124-.061-.152a2 2 0 0 0-.184-.353l-.067-.102-.193-.249a2.002 2.002 0 0 0-.584-.508l-.239-.137-.117-.063a2.003 2.003 0 0 0-.36-.152l-.128-.04-.172-.027a2 2 0 0 0-.324-.027h-.154c-.142 0-.284.016-.422.049l-.026.006-.061.022Z"
        fill="#353535"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h60v48H0z" />
      </clipPath>
    </defs>
  </svg>

  )
}