import React from "react";

export default function Avalanche2(props: any) {
  return (
    <svg
    width={48}
    height={48}
    viewBox='0 0 48 48'
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        d="M3.95 28.95a7 7 0 0 1 0-9.9L18.88 4.12a7 7 0 0 1 10.064.17l5.775 6.179h1.336a4.1 4.1 0 0 1 1.687.363c.43.194.889.312 1.358.35l.514.042a4.559 4.559 0 0 1 4.148 3.914l.05.362.923 1.01a4.388 4.388 0 0 1 1.115 3.494l-.02.163a4.91 4.91 0 0 1-.16.777l-.326 1.119.143.277a5.274 5.274 0 0 1-.956 6.158L28.95 44.058a7 7 0 0 1-9.896-.004L3.95 28.95Z"
        fill="#fff"
      />
      <path
        d="M5.828 26.828a4 4 0 0 1 0-5.656L21.154 5.846a4 4 0 0 1 5.674.018L34 13.125a.621.621 0 0 0 .788.12l.523-.316a1.35 1.35 0 0 1 1.514.076l.675.51h1.527a2.178 2.178 0 0 1 2.046 1.438l.06.166c.098.273.148.56.148.85v.719l.84.624a2.595 2.595 0 0 1 1.021 1.719l.087.614a4.001 4.001 0 0 1-.096 1.596l-.32 1.197a2.12 2.12 0 0 1 .508 3.382L26.829 42.192a4 4 0 0 1-5.646-.01L5.828 26.828Z"
        fill="#2B2B2B"
      />
      <path
        d="m5.769 24.731 18.243.012 18.242.011L25.5 41.5l-.094.084a2 2 0 0 1-2.748-.067L5.767 24.731Z"
        fill="#FFD646"
      />
      <path
        d="M39.987 16.555a.837.837 0 0 1-.065.135l-.179.3a.59.59 0 0 0 .405.885l.126.032c1.634.422 2.044 2.552.684 3.55l-.184.135a1.865 1.865 0 0 1-2.236-.023l-.897-.686-1.32-.946-2.282-1.953-6.764-5.67a.5.5 0 0 0-.712.071l-3.196 4.01-2.808-2.043a1 1 0 0 0-1.08-.063l-10.94 6.184L22.57 6.449l.054-.048a2 2 0 0 1 2.779.1l7.357 7.643a1.08 1.08 0 0 1 .301.749v.935a.766.766 0 0 0 .877.759l.106-.016a.643.643 0 0 0 .55-.6l.016-.309c.024-.425.251-.812.611-1.04l.044-.027a1.298 1.298 0 0 1 1.907.64l.257.68.04.08a.805.805 0 0 0 1.466-.06l.005-.013a.86.86 0 0 0 .044-.498l-.079-.377a1.03 1.03 0 0 1 1.037.633l.018.043a.98.98 0 0 1 .086.401v.107a.91.91 0 0 1-.057.317l-.003.007Z"
        fill="#fff"
      />
      <path
        d="M23.082 28.094h1.86v1.172l-.282 4.593h-1.266l-.312-4.593v-1.172Z"
        fill="#1E2127"
      />
      <circle cx={24.012} cy={35.719} r={1.148} fill="#1E2127" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h48v48H0z" />
      </clipPath>
    </defs>
  </svg>

  )
}