import React from "react";

export default function Conditions(props: any) {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.3126 9.77796C13.2477 9.77796 14.0057 9.0199 14.0057 8.08478C14.0057 7.14966 13.2477 6.3916 12.3126 6.3916C11.3774 6.3916 10.6194 7.14966 10.6194 8.08478C10.6194 9.0199 11.3774 9.77796 12.3126 9.77796Z"
        stroke="#242426"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.8979 3.49934C16.5463 3.14806 15.9764 3.14838 15.6251 3.50005C15.2739 3.85171 15.2742 4.42156 15.6258 4.77284L16.8979 3.49934ZM15.6258 11.4073C15.2742 11.7586 15.2739 12.3285 15.6251 12.6801C15.9764 13.0318 16.5463 13.0321 16.8979 12.6808L15.6258 11.4073ZM7.72714 12.6713C8.0788 13.0226 8.64865 13.0222 8.99993 12.6706C9.35121 12.3189 9.35089 11.7491 8.99922 11.3978L7.72714 12.6713ZM8.99922 4.76328C9.35089 4.412 9.35121 3.84215 8.99993 3.49049C8.64865 3.13882 8.0788 3.1385 7.72714 3.48978L8.99922 4.76328ZM19.5344 0.8637C19.1829 0.512175 18.6131 0.512089 18.2616 0.863508C17.91 1.21493 17.91 1.78478 18.2614 2.1363L19.5344 0.8637ZM18.2614 14.0344C17.91 14.3859 17.91 14.9558 18.2616 15.3072C18.6131 15.6586 19.1829 15.6585 19.5344 15.307L18.2614 14.0344ZM5.09066 15.307C5.44208 15.6585 6.01193 15.6586 6.36346 15.3072C6.71498 14.9558 6.71507 14.3859 6.36365 14.0344L5.09066 15.307ZM6.36365 2.1363C6.71507 1.78478 6.71498 1.21493 6.36346 0.863508C6.01193 0.512089 5.44208 0.512175 5.09066 0.8637L6.36365 2.1363ZM15.6258 4.77284C16.5063 5.65229 17.001 6.84567 17.001 8.09009H18.801C18.801 6.36793 18.1163 4.71641 16.8979 3.49934L15.6258 4.77284ZM17.001 8.09009C17.001 9.33451 16.5063 10.5279 15.6258 11.4073L16.8979 12.6808C18.1163 11.4638 18.801 9.81225 18.801 8.09009H17.001ZM8.99922 11.3978C8.11879 10.5183 7.62409 9.32495 7.62409 8.08053H5.82409C5.82409 9.80269 6.50871 11.4542 7.72714 12.6713L8.99922 11.3978ZM7.62409 8.08053C7.62409 6.83611 8.11879 5.64273 8.99922 4.76328L7.72714 3.48978C6.50871 4.70685 5.82409 6.35837 5.82409 8.08053H7.62409ZM18.2614 2.1363C21.5462 5.42216 21.5462 10.7485 18.2614 14.0344L19.5344 15.307C23.5219 11.3182 23.5219 4.85246 19.5344 0.8637L18.2614 2.1363ZM6.36365 14.0344C3.07878 10.7485 3.07878 5.42216 6.36365 2.1363L5.09066 0.8637C1.10311 4.85246 1.10311 11.3182 5.09066 15.307L6.36365 14.0344Z"
        fill="#242426"
      />
      <path d="M6 22.4V17.6H6.94384V21.5533H9.09525V22.4H6Z" fill="black" />
      <path
        d="M9.75466 18.3V17.5H10.6985V18.3H9.75466ZM9.75466 22.4V18.8H10.6985V22.4H9.75466Z"
        fill="black"
      />
      <path
        d="M12.752 22.4L11.3918 18.8H12.3356L13.224 21.2933L14.1123 18.8H15.0561L13.6959 22.4H12.752Z"
        fill="black"
      />
      <path
        d="M17.2896 22.5C16.9056 22.5 16.5667 22.4211 16.2729 22.2633C15.9814 22.1033 15.7524 21.8844 15.5858 21.6067C15.4216 21.3267 15.3395 21.0067 15.3395 20.6467C15.3395 20.2533 15.4204 19.9111 15.5824 19.62C15.7443 19.3289 15.9675 19.1033 16.2521 18.9433C16.5366 18.7811 16.864 18.7 17.2341 18.7C17.6274 18.7 17.9616 18.7889 18.2369 18.9667C18.5122 19.1444 18.7158 19.3944 18.8476 19.7167C18.9795 20.0389 19.0258 20.4178 18.9864 20.8533H18.053V20.52C18.053 20.1533 17.9917 19.89 17.8691 19.73C17.7488 19.5678 17.551 19.4867 17.2757 19.4867C16.9542 19.4867 16.7171 19.5811 16.5644 19.77C16.414 19.9567 16.3388 20.2333 16.3388 20.6C16.3388 20.9356 16.414 21.1956 16.5644 21.38C16.7171 21.5622 16.9403 21.6533 17.2341 21.6533C17.4192 21.6533 17.5776 21.6144 17.7095 21.5367C17.8413 21.4589 17.942 21.3467 18.0114 21.2L18.9552 21.46C18.8141 21.7889 18.5909 22.0444 18.2855 22.2267C17.9825 22.4089 17.6505 22.5 17.2896 22.5ZM16.0474 20.8533V20.18H18.5319V20.8533H16.0474Z"
        fill="black"
      />
    </svg>
  );
}
