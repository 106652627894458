import React from "react";

export default function StarFilled(props: any) {
  return (
    <svg
    width={16}
    height={15}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m8 1.334 2.06 4.173 4.607.674-3.333 3.246.787 4.587L8 11.847l-4.12 2.167.786-4.587-3.333-3.246 4.607-.674L8 1.334Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>

  );
}