import * as React from "react"
const Beer = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={26}
    height={27}
    viewBox="0 0 26 27"
    fill="none"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.4}
      d="M19.034 9.879v9.074c0 1.694-1.46 3.067-3.261 3.067H8.707c-1.802 0-3.262-1.373-3.262-3.067V9.879M20.13 17.982h-1.01V10.41h2.523c.836 0 1.513.678 1.513 1.514v3.03a3.027 3.027 0 0 1-3.026 3.028Z"
    />
    <path
      stroke="currentColor"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.4}
      d="M12.576 9.07c-.52.907-1.429 1.51-2.465 1.51-.904 0-1.712-.458-2.25-1.176-.407.359-.916.582-1.476.582-1.323 0-2.395-1.196-2.395-2.67 0-1.476 1.072-2.672 2.395-2.672.56 0 1.069.224 1.476.583.538-.719 1.346-1.176 2.25-1.176 1.037 0 1.945.602 2.465 1.51.44-.558 1.075-.917 1.793-.917.757 0 1.424.4 1.863 1.011.44-.611 1.106-1.01 1.863-1.01 1.323 0 2.395 1.195 2.395 2.67 0 1.475-1.072 2.671-2.395 2.671-.757 0-1.424-.4-1.863-1.01.131.965.216 1.304.695 3.7 0 1.147-.958 2.396-2.396 2.396-1.437 0-2.395-1.249-2.395-2.396.44-2.396.306-2.645.44-3.605Z"
    />
  </svg>
)
export default Beer
