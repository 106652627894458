
import React from "react";

export default function User(props: any) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none"  width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" aria-hidden="true" className="icon" {...props}>
      <path strokeLinecap="round" strokeLinejoin="round" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"></path>
    </svg>
  );
}
