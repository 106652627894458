import React from "react";
import Link from "src/components/link";

import { getLanguageField } from "src/utils/language";
import { getSlug } from "src/utils/slugs";
import { getCanvasFont } from "src/utils/styles";
import { getTextWidth } from "src/utils/text";

type Props = {
    depth: number;
    language: string;
    defaultLanguage: string;
    item: TNavigationItem;

    onClick?: () => void;
    onDimension?: (width: number) => void;
}

export default class NavigationBarLink extends React.Component<Props> {
    private linkRef = React.createRef<HTMLElement>();

    public get label(): string {
        return getLanguageField(this.props.item.translations, this.props.language, 'label') as string;
    }

    public get link(): string {
        return this.props.item.link_type === 'external'
            ? getLanguageField(this.props.item.link_translations, this.props.language, 'link_href') as string
            : getSlug(this.props.language, this.props.defaultLanguage, this.props.item.link_page?.translations || []);
    }

    public hasColor() {
        if (this.props.item.item_color) return true;
        return false;
    }

    public get colors() {
        if (this.props.depth >= 2 && !this.hasColor()) return "text-[color:var(--link-subitems-text-color)] hover:text-[color:var(--dropdown-hover-color)]";
        if (this.props.depth === 1 && !this.hasColor()) return "text-[color:var(--text-color)] hover:text-[color:var(--dropdown-hover-color)]";
        return "text-[color:var(--text-color)] hover:text-[color:var(--hover-color)]";
    }

    public get textClass() {
        if (this.props.depth >= 2) return "navBarSubItems";
        if (this.props.depth === 1) return "navBarItems";
        return "navBarText";
    }

    private get style(): any {
        return {
            '--text-color': this.props.item.item_color?.value,
            '--hover-color': this.props.item.item_hover_color?.value,
        }
    }

    componentDidMount(): void {
        if (this.linkRef.current && this.props.onDimension) {
            this.props.onDimension(getTextWidth(this.label, getCanvasFont(this.linkRef.current)));
        }
    }
    
    render(): React.ReactNode {
        const {label, link} = this;
        return <Link style={this.style} linkRef={this.linkRef} title={label} href={link} onClick={this.props.onClick} className={`${this.textClass} navigation-bar-link navigation-item-depth-${this.props.depth} ${this.colors}`}>
            <span className="truncate">{label}</span>
        </Link>
    }
}