import React from "react";

export default function HairDryer(props: any) {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M2 15.4999H4.66667C5.77124 15.4999 6.66667 14.6045 6.66667 13.4999V12.8333" stroke="currentColor" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M1.33398 5.16667C1.33398 2.74092 3.53686 0.911203 5.92141 1.35632L13.0343 2.68406C13.981 2.86078 14.6673 3.68706 14.6673 4.6501V5.68323C14.6673 6.64627 13.981 7.47256 13.0343 7.64927L5.92142 8.97701C3.53686 9.42213 1.33398 7.59241 1.33398 5.16667V5.16667Z" stroke="currentColor" strokeWidth="1.8"/>
      <ellipse cx="5.33333" cy="5.16659" rx="1.33333" ry="1.33333" stroke="currentColor" strokeWidth="1.8"/>
      <path d="M4.33398 9.16659L5.42795 12.175C5.57166 12.5702 5.94724 12.8333 6.36775 12.8333H7.27989C7.96245 12.8333 8.44442 12.1646 8.22858 11.517L7.33398 8.83325" stroke="currentColor" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
}



