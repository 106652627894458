import React from "react";

export default function SortBy(props: any) {
  return (
    <svg {...props} width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_19726_17054)">
        <path d="M5.67474 12.6998L4.09974 14.1998V1.4998C4.09974 1.1998 3.84974 0.924805 3.52474 0.924805C3.19974 0.924805 2.94974 1.1748 2.94974 1.4998V14.1998L1.37474 12.6998C1.14974 12.4748 0.799738 12.4998 0.574738 12.7248C0.349739 12.9498 0.374739 13.2998 0.599739 13.5248L3.12474 15.9248C3.22474 16.0248 3.37474 16.0748 3.49974 16.0748C3.62474 16.0748 3.77474 16.0248 3.87474 15.9248L6.44974 13.4998C6.67474 13.2748 6.67474 12.9248 6.47474 12.6998C6.27474 12.4748 5.89974 12.4748 5.67474 12.6998Z" fill="currentColor"/>
        <path d="M15.3749 3.49971L12.8499 1.09971C12.6249 0.899707 12.2999 0.899707 12.0749 1.09971L9.54994 3.49971C9.32494 3.72471 9.32494 4.07471 9.52494 4.29971C9.62494 4.42471 9.77494 4.47471 9.92494 4.47471C10.0749 4.47471 10.1999 4.42471 10.2999 4.32471L11.8749 2.82471V15.4997C11.8749 15.7997 12.1249 16.0747 12.4499 16.0747C12.7749 16.0747 13.0249 15.8247 13.0249 15.4997V2.79971L14.5999 4.29971C14.8249 4.52471 15.1749 4.49971 15.3999 4.27471C15.6249 4.04971 15.5999 3.69971 15.3749 3.49971Z" fill="currentColor"/>
      </g>
      <defs>
        <clipPath id="clip0_19726_17054">
          <rect width="16" height="16" fill="white" transform="translate(0 0.5)"/>
        </clipPath>
      </defs>
    </svg>
  );
}