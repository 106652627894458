
import React from 'react'

const Skier = (props: any) => (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M7 2.07715C6.46957 2.07715 5.96086 2.28786 5.58579 2.66293C5.21071 3.03801 5 3.54672 5 4.07715C5 4.60758 5.21071 5.11629 5.58579 5.49136C5.96086 5.86643 6.46957 6.07715 7 6.07715C7.53043 6.07715 8.03914 5.86643 8.41421 5.49136C8.78929 5.11629 9 4.60758 9 4.07715C9 3.54672 8.78929 3.03801 8.41421 2.66293C8.03914 2.28786 7.53043 2.07715 7 2.07715Z" stroke="currentColor" strokeWidth="1.8" strokeMiterlimit="10"/>
        <path d="M14.5 14.0771L9 9.07715M9 9.07715V22.5771M9 9.07715H5.5M5.5 9.07715V22.5771M5.5 9.07715H5L3.5 17.0771" stroke="currentColor" strokeWidth="1.8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M16.5 2.57715V22.5771" stroke="currentColor" strokeWidth="1.8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M13 2.57715V22.5771" stroke="currentColor" strokeWidth="1.8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
)

export default Skier;