import * as React from "react"
const ServiceAnimals = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    data-name="Layer 1"
    viewBox="0 0 85.8 85.9"
    {...props}
  >
    <defs>
      <clipPath id="clippath">
        <path
          d="M12.7 7.2h62.5v71.5H12.7z"
          style={{
            strokeWidth: 0,
            fill: "none",
          }}
        />
      </clipPath>
      <style>
        {".cls-3,.cls-4{stroke-width:0}.cls-3{fill:#231f20}.cls-4{fill:#fff}"}
      </style>
    </defs>
    <rect width={85.9} height={85.9} className="cls-4" rx={12} ry={12} />
    <rect
      width={80.8}
      height={80.8}
      x={2.5}
      y={2.5}
      className="cls-3"
      rx={12}
      ry={12}
    />
    <rect
      width={77.8}
      height={77.8}
      x={4}
      y={4}
      className="cls-4"
      rx={12}
      ry={12}
    />
    <g
      style={{
        clipPath: "url(#clippath)",
      }}
    >
      <path
        d="M25.6 11.5c.5 5.9-2.2 8.3-6 7.8-9.7-1.3-1.3-18.4 6-7.8"
        className="cls-3"
      />
      <path
        d="M24.7 21.9c1.1 2.6.9 6.6.9 10.4-1.9-.1-.4-3.7-.9-5.2-6.1 6.8 7 8.2 6 15.6-6.4.3-12.9-5.9-11.2-16.5-4.3 8.9 3.4 13.3 6 18.2-3.5 7.8-.5 22-3.5 30.3h-4.3c-1.9-7.3-.3-18.1-.9-26.9-.2-1.2-2.6-.3-2.6-1.7.6-10.5-3.9-30.3 10.4-24.3"
        className="cls-3"
      />
    </g>
    <path
      d="M34.2 43.6c4.8 3.8 11.1 6.2 17.3 8.7-.3 4.6 2.2 6.4 1.7 11.3h-6c-.5 4.3 2.9 8.1.9 11.3h-1.7c-4.1-.5-1.9-7.3-3.5-10.4-3.7 1.5-2.1 8.3-5.2 10.4h-2.6c-2-4.6 1.9-10.1 2.6-14.7-3.9.7-3.8 5.4-7.8 6.1-1.8-5.2 6.8-6.9 7.8-12.1 4.1.6 5.9-1 9.5-.9-4.3-3.8-11.1-5-15.5-8.7 1.6-6.3 7.7-1 11.2.9 4.3 2.3 8.5 5.2 12.1 6.1 5.3-1.3 4.8-8.5 9.5-10.4-.8 6.9 6.9 5.2 7.8 10.4-3.9 1-9.3.4-8.6 6.1h-8.6c-2.9-8.7-13.3-9.8-20.7-13.9"
      className="cls-3"
    />
    <path
      d="M55 59.1h7.8c-1.2 3.3.3 11.5 0 15.6-7.4-.4-4.8-10.8-7.8-15.6M26.3 13.3c0 3.4-2.7 6.1-6.1 6.1s-6.1-2.7-6.1-6.1 2.7-6.1 6.1-6.1 6.1 2.7 6.1 6.1"
      className="cls-3"
    />
  </svg>
)
export default ServiceAnimals
