import React from "react";

export default function SnowFlakeIcon() {
    return (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 22.5C17.5228 22.5 22 18.0228 22 12.5C22 6.97715 17.5228 2.5 12 2.5C6.47715 2.5 2 6.97715 2 12.5C2 18.0228 6.47715 22.5 12 22.5Z"
            stroke="#1D1D1B"
            strokeWidth="1.8"
            strokeLinecap="round"
            strokeLinejoin="round"
            />
            <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.2398 8.26L14.1198 14.62L7.75977 16.74L9.87977 10.38L16.2398 8.26Z"
            stroke="#1D1D1B"
            strokeWidth="1.8"
            strokeLinecap="round"
            strokeLinejoin="round"
            />
        </svg>
    );
}