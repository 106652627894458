import * as React from "react"

const Webcam = (props: any) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.333 3.5H4.051A2.055 2.055 0 0 0 2 5.559v6.176c0 1.137.918 2.06 2.051 2.06h11.282a2.055 2.055 0 0 0 2.052-2.06V5.56A2.055 2.055 0 0 0 15.333 3.5Z"
      stroke="currentColor"
      strokeWidth={1.8}
      strokeMiterlimit={10}
      strokeLinejoin="round"
    />
    <path
      d="M2 21h5.641a2.055 2.055 0 0 0 2.051-2.059V14.31M10.718 7.103h3.077"
      stroke="currentColor"
      strokeWidth={1.8}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.385 7.103 22 4.015v9.265l-4.615-3.089"
      stroke="currentColor"
      strokeWidth={1.8}
      strokeMiterlimit={10}
      strokeLinejoin="round"
    />
  </svg>
)

export default Webcam
