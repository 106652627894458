

import React from "react";

export default function SlopeBlue(props: any) {
  return (
    <svg {...props} width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.16666 9.5V15.8333C3.16666 16.7078 3.87554 17.4167 4.74999 17.4167H14.25C15.1244 17.4167 15.8333 16.7078 15.8333 15.8333V9.5" stroke="currentColor" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M12.6667 4.75L9.50001 1.58333L6.33334 4.75" stroke="currentColor" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M9.5 1.58333V11.875" stroke="currentColor" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  );
}