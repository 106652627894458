const translations: any = {
    "EN": {
        "EN": "English",
        "DE": "Deutsch",
        "NO": "Norwegian",
        "IT": "Italian",
        "FR": "French",
        "ES": "Spanish"
    },
    "ES": {
        "EN": "Inglés",
        "DE": "Alemán",
        "NO": "Noruego",
        "IT": "Italiano",
        "FR": "Francés",
        "ES": "Español"
    },
};

export default translations;