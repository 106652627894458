import React from "react";

export default function Gym(props: any) {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_14530_67164)">
        <path d="M0.666016 8.5H1.33268M15.3327 8.5H14.666M5.99935 8.5H9.99935" stroke={"currentColor"} strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round"/>
        <rect x="3.33203" y="4.5" width="2.66667" height="8" rx="1.33333" stroke={"currentColor"} strokeWidth="1.8"/>
        <rect x="1.33203" y="5.83325" width="2" height="5.33333" rx="1" stroke={"currentColor"} strokeWidth="1.8"/>
        <rect x="10" y="4.5" width="2.66667" height="8" rx="1.33333" stroke={"currentColor"} strokeWidth="1.8"/>
        <rect x="12.666" y="5.83325" width="2" height="5.33333" rx="1" stroke={"currentColor"} strokeWidth="1.8"/>
      </g>
      <defs>
        <clipPath id="clip0_14530_67164">
          <rect width="16" height="16" fill="white" transform="translate(0 0.5)"/>
        </clipPath>
      </defs>
    </svg>
  );
}


