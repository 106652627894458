import React, { Profiler as ReactProfiler } from "react";

const DEBUG_ENABLED = false;
const DEVELOPMENT_MODE = process.env.NODE_ENV === 'development';
const FORCE_DEBUG = process.env.GATSBY_PROFILER === '1';
const NoProfiler = ({id, children, onRender}:{id: string, children: any, onRender: any}) => children
const ProfilerComponent = (FORCE_DEBUG || (DEVELOPMENT_MODE && DEBUG_ENABLED)) ? ReactProfiler : NoProfiler;

type ProfilerProps = {
    id: string;
    children?: React.ReactNode;
}

export default class Profiler extends React.Component<ProfilerProps> {
    onRenderCallback = (
        id: any, // the "id" prop of the Profiler tree that has just committed
        phase: any, // either "mount" (if the tree just mounted) or "update" (if it re-rendered)
        actualDuration: any, // time spent rendering the committed update
        baseDuration: any, // estimated time to render the entire subtree without memoization
        startTime: any, // when React began rendering this update
        commitTime: any, // when React committed this update
    ) => {
        const _start = parseInt(startTime);
        const _commit = parseInt(commitTime);
        const _duration = parseInt(actualDuration)
        const _bduration = parseInt(baseDuration);
        console.log( "%cprofiler."+id+"%c " + phase + " " + _start + ":" + _commit + ":" + _bduration + ":" + _duration, 'background-color: #4854c1; color:white; border-radius: 3px; padding: 0 5px;', '');
    }

    render(): React.ReactNode {
        return <ProfilerComponent id={this.props.id} onRender={this.onRenderCallback}>{this.props.children}</ProfilerComponent>
    }
}