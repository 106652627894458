import React from "react";

type SiteContainerProps = {
    fullwidth: boolean | undefined;
    children: React.ReactNode | undefined;
    className?: string;
    style?: React.CSSProperties & TCSSVars;
}

export default class SiteContainer extends React.Component<SiteContainerProps> {
    static defaultProps = { fullwidth: false }
    render() {
        const fullwidth = 'relative w-full';
        const sitewidth = 'relative max-w-7xl mx-auto flex flex-col';

        return <div style={this.props.style} className={`${this.props.fullwidth ? fullwidth : sitewidth} ${this.props.className}`}>{this.props.children}</div>
    }
}