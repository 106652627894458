import * as React from "react"

const CableCar = (props:any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22}
    height={22}
    fill="none"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.8}
      d="m7.25 3.667 7.417-.917M11 7.792V4.125"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.8}
      d="M1.375 9.792a2 2 0 0 1 2-2h15.25a2 2 0 0 1 2 2v1.5a2 2 0 0 1-2 2H3.375a2 2 0 0 1-2-2v-1.5Z"
      clipRule="evenodd"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.8}
      d="M1.375 9.792a2 2 0 0 1 2-2h15.25a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2H3.375a2 2 0 0 1-2-2v-7Z"
      clipRule="evenodd"
    />
  </svg>
)
export default CableCar