import { convertToHTML, mapHTMLElements } from './string';


/**
 * Get language field of translations.
 *
 * @param {any[]} translations
 * @param {string} language
 * @param {string} field
 * @param {boolean} isHTML default value false
 * @returns {string | React.ReactNode}
 */
export function getLanguageField(translations, language, field, isHTML = false, mapHtml = false, htmlClass = "", tweakFunction = (s) => s ) {
    if(!translations) return '';
    for (const translation of translations) {
        let translationLanguageCode = "";

        // Check if translation exists and set the translationLanguageCode
        if (translation && translation.language && translation.language.code) {
            translationLanguageCode = translation.language.code;
        } else if (translation && translation.languages_code && translation.languages_code.code) {
            translationLanguageCode = translation.languages_code.code;
        } else if (translation && translation.languages_code) {
            translationLanguageCode = translation.languages_code;
        } else if (translation && translation.language) {
            translationLanguageCode = translation.language;
        }

        // temporary solution to prevent Norwegian divergence
        if (translationLanguageCode === "no-NO") translationLanguageCode = "nb-NO";

        // Continue if translationLanguageCode does not match the language
        if (translationLanguageCode !== language) continue;
        if (translation[field] === null) translation[field] = '';
        if (isHTML && mapHtml)
        {
            if(!translation[field]) return null;
            return convertToHTML(tweakFunction(mapHTMLElements(translation[field], htmlClass)));
        }
        if (isHTML) return tweakFunction(convertToHTML(translation[field]));
        return translation[field];
    }
    return '';
}
