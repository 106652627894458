import React from "react";

export default function Chat(props: any) {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <g clipPath="url(#clip0_17786_63574)">
        <path d="M3.86225 10H3.86214C3.49752 10.0001 3.14787 10.145 2.89007 10.4028C2.89005 10.4028 2.89004 10.4029 2.89002 10.4029L0.5 12.7929V1.75C0.5 1.41848 0.631696 1.10054 0.866117 0.866117C1.10054 0.631696 1.41848 0.5 1.75 0.5H12.25C12.5815 0.5 12.8995 0.631696 13.1339 0.866116L13.4874 0.512563L13.1339 0.866117C13.3683 1.10054 13.5 1.41848 13.5 1.75V8.75C13.5 9.08152 13.3683 9.39946 13.1339 9.63388C12.8995 9.8683 12.5815 10 12.25 10H3.86225ZM0.393252 12.8996C0.3934 12.8995 0.393547 12.8993 0.393696 12.8992L0.393252 12.8996Z" fill="currentColor" stroke="currentColor"/>
        </g>
        <defs>
        <clipPath id="clip0_17786_63574">
        <rect width="14" height="14" fill="white"/>
        </clipPath>
        </defs>
    </svg>
  )
}