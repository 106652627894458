import React from "react";

import NavigationBarLink from "./navigationbar.link";

import NavigationBarSubMenuWithLink from "./navigationbar.submenu.with.link";
import { getLanguageField } from "src/utils/language";

type Props = {
    key: string;
    depth: number;
    language: string;
    defaultLanguage: string;
    item: TNavigationItem;

    onClick?: () => void;
    onDimension?: (width: number) => void;
}

export default class NavigationBarSubMenu extends React.Component<Props> {

    public get label() {
        return getLanguageField(this.props.item.translations, this.props.language, 'label');
    }

    private get style(): any {
        return {
            '--text-color': this.props.item.item_color?.value,
            '--hover-color': this.props.item.item_hover_color?.value,
        }
    }

    private get items(){
        return (this.props.item?.items || []).filter(item => item.hide !== "yes")
    }

    render(): React.ReactNode {
        const {label} = this;
        const {language, defaultLanguage} = this.props;

        return <>
            <div style={this.style} className="navBarText navigation-bar-submenu-label text-[color:var(--text-color)] hover-[color:var(--hover-color)]">{label}</div>
            {(this.items || []).map((item, idx) => {
                if (item.item_type === "Link") return <NavigationBarLink onDimension={this.props.onDimension} onClick={this.props.onClick} depth={this.props.depth + 1} language={language} defaultLanguage={defaultLanguage} key={`navbar_link_${idx}`} item={item} />
                if (item.item_type === "Menu") return <NavigationBarSubMenu onDimension={this.props.onDimension} onClick={this.props.onClick} depth={this.props.depth + 1} language={language} defaultLanguage={defaultLanguage} key={`navbar_menu_${idx}`} item={item} />
                if (item.item_type === "Menu with link") return <NavigationBarSubMenuWithLink onDimension={this.props.onDimension} onClick={this.props.onClick} depth={this.props.depth + 1} language={language} defaultLanguage={defaultLanguage} key={`navbar_menulink_${idx}`} item={item} />
                return null;
            })}
        </>;
    }
}