import React from "react";

export default function News(props: any) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" id="ico_news" width="24" height="24" x="0" y="0" viewBox="0 0 24 24" version="1.1" {...props}>
            <path id="Path_683" fill="none" d="M0 0h24v24H0V0z" />
            <path id="Path_684" d="M18 21H7c-2.2 0-4-1.8-4-4V5c0-1.1.9-2 2-2h10c1.1 0 2 .9 2 2h2c1.1 0 2 .9 2 2v11c0 1.7-1.3 3-3 3zm0-2c.6 0 1-.4 1-1V7h-2v11c0 .6.4 1 1 1zM5 5v12c0 1.1.9 2 2 2h8.2c-.1-.3-.2-.7-.2-1V5H5z" fill='currentColor' />
            <path id="Line_198" d="M12 9H8c-.6 0-1-.4-1-1s.4-1 1-1h4c.6 0 1 .4 1 1s-.4 1-1 1z" fill='currentColor'/>
            <path id="Line_199" d="M12 13H8c-.6 0-1-.4-1-1s.4-1 1-1h4c.6 0 1 .4 1 1s-.4 1-1 1z" fill='currentColor' />
            <path id="Line_200" d="M12 17H8c-.6 0-1-.4-1-1s.4-1 1-1h4c.6 0 1 .4 1 1s-.4 1-1 1z" fill='currentColor'/>
        </svg>
    );
}