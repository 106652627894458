import * as React from "react"
const List = (props:any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={17}
    fill="none"
    viewBox="0 0 16 17"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.3}
      d="M5.333 4.5H14M5.333 8.5H14M5.333 12.5H14M2.667 4.5H2M2.667 8.5H2M2.667 12.5H2"
    />
  </svg>
)
export default List
