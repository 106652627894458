import * as React from "react"
const LiftTicket2 = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={26}
    height={26}
    fill="none"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={1.4}
      d="M11.57 9.334H5.666M10.093 13H5.667M20.333 13h-2.75M20.333 9.334h-2.75M20.333 16.666h-2.75M14.833 10.25v.917M14.833 14.834v.916M14.833 6.144c0-1.276.87-2.31 1.942-2.31h4.367c1.072 0 1.941 1.034 1.941 2.31v13.713c0 1.276-.869 2.31-1.94 2.31h-4.368c-1.072 0-1.942-1.035-1.942-2.31"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={1.4}
      d="M14.833 19.857c0 1.276-.889 2.31-1.986 2.31H4.903c-1.097 0-1.986-1.035-1.986-2.31V6.144c0-1.276.889-2.31 1.986-2.31h7.944c1.097 0 1.986 1.034 1.986 2.31"
    />
  </svg>
)
export default LiftTicket2
