import * as React from "react"
const PregnancyADA = (props:any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    data-name="Layer 1"
    viewBox="0 0 85.8 85.9"
    {...props}
  >
    <defs>
      <style>
        {
          ".cls-2{fill-rule:evenodd;stroke-width:0;fill:#231f20}.cls-5{fill:none;stroke:#231f20;stroke-miterlimit:2.6;stroke-width:0}"
        }
      </style>
    </defs>
    <path
      d="M42.9 85.9c23.7 0 42.9-19.2 42.9-42.9S66.6 0 42.9 0 0 19.2 0 42.9s19.2 42.9 42.9 42.9"
      style={{
        fill: "#fff",
        strokeWidth: 0,
      }}
    />
    <path
      d="M42.5 6.7c3 0 5.4 2.4 5.4 5.4s-2.4 5.4-5.4 5.4-5.4-2.4-5.4-5.4 2.4-5.4 5.4-5.4"
      className="cls-2"
    />
    <path
      d="M42.5 6.7c3 0 5.4 2.4 5.4 5.4s-2.4 5.4-5.4 5.4-5.4-2.4-5.4-5.4 2.4-5.4 5.4-5.4Z"
      className="cls-5"
    />
    <path
      d="m55.8 50.5-5.7-21.7 6.7 13.6c.5.8 1.4 1.3 2.3 1.3 1.5 0 2.7-1.2 2.7-2.7s0-.8-.2-1.1l-9.1-18.6s-1.6-2.4-4.2-2.4H36.8c-2.6 0-4.2 2.4-4.2 2.4l-9.1 18.6c-.1.3-.2.7-.2 1.1 0 1.5 1.2 2.7 2.7 2.7s1.8-.5 2.3-1.3L35 28.8l-5.7 21.7h26.5Z"
      className="cls-2"
    />
    <path
      d="m55.8 50.5-5.7-21.7 6.7 13.6c.5.8 1.4 1.3 2.3 1.3 1.5 0 2.7-1.2 2.7-2.7s0-.8-.2-1.1l-9.1-18.6s-1.6-2.4-4.2-2.4H36.8c-2.6 0-4.2 2.4-4.2 2.4l-9.1 18.6c-.1.3-.2.7-.2 1.1 0 1.5 1.2 2.7 2.7 2.7s1.8-.5 2.3-1.3L35 28.8l-5.7 21.7h26.5Z"
      className="cls-5"
    />
    <path
      d="M42.5 34.2c3.7 0 6.8 3.1 6.8 6.8s-3.1 6.8-6.8 6.8-6.8-3.1-6.8-6.8 3.1-6.8 6.8-6.8"
      style={{
        fill: "#fff",
        strokeWidth: 0,
        fillRule: "evenodd",
      }}
    />
    <path
      d="M42.5 34.2c3.7 0 6.8 3.1 6.8 6.8s-3.1 6.8-6.8 6.8-6.8-3.1-6.8-6.8 3.1-6.8 6.8-6.8Z"
      className="cls-5"
    />
    <path
      d="m42.9 43.6-1.4-1.8c-.1-.2-.4-.4-.7-.4s-.3 0-.4.1l-3 1.3c-.1.1-.3.3-.3.6 0 .4.3.7.7.7s2.6-.9 2.6-.9l1 2.9c.1.6.6 1.1 1.3 1.1h.1c2.7-.6 4.7-3.5 4.9-5.5v-.3c0-.7-.6-1.3-1.3-1.3s-.7.2-1 .4L43.9 42l-1.3-1.4c-.1-.1-.3-.2-.4-.2-.3 0-.6.3-.6.6v.2l1.5 2v.2c0 .1-.1.3-.3.3h-.2m1.5-8.6c1.5 0 2.7 1.2 2.7 2.7s-1.2 2.7-2.7 2.7-2.7-1.2-2.7-2.7 1.2-2.7 2.7-2.7"
      className="cls-2"
    />
    <path
      d="m42.9 43.6-1.4-1.8c-.1-.2-.4-.4-.7-.4s-.3 0-.4.1l-3 1.3c-.1.1-.3.3-.3.6 0 .4.3.7.7.7s2.6-.9 2.6-.9l1 2.9c.1.6.6 1.1 1.3 1.1h.1c2.7-.6 4.7-3.5 4.9-5.5v-.3c0-.7-.6-1.3-1.3-1.3s-.7.2-1 .4L43.9 42l-1.3-1.4c-.1-.1-.3-.2-.4-.2-.3 0-.6.3-.6.6v.2l1.5 2v.2c0 .1-.1.3-.3.3h-.2Zm1.2-8.5c1.5 0 2.7 1.2 2.7 2.7s-1.2 2.7-2.7 2.7-2.7-1.2-2.7-2.7 1.2-2.7 2.7-2.7Z"
      className="cls-5"
    />
    <path
      d="M40.6 73.3v.5c0 1.9-1.5 3.4-3.4 3.4s-3.4-1.5-3.4-3.3V51.8h6.8v21.6Z"
      className="cls-2"
    />
    <path
      d="M40.6 73.3v.5c0 1.9-1.5 3.4-3.4 3.4s-3.4-1.5-3.4-3.3V51.8h6.8v21.6Z"
      className="cls-5"
    />
    <path
      d="M51.3 73.3v.5c0 1.9-1.5 3.4-3.4 3.4s-3.4-1.5-3.4-3.3V51.8h6.8v21.6Z"
      className="cls-2"
    />
    <path
      d="M51.3 73.3v.5c0 1.9-1.5 3.4-3.4 3.4s-3.4-1.5-3.4-3.3V51.8h6.8v21.6Z"
      className="cls-5"
    />
    <path
      d="M71.4 14.9C79.1 22.6 83 32.1 83 43.1c0 11.1-3.9 20.6-11.7 28.3C63.5 79.2 54 83.1 43 83.1s-20.5-3.9-28.3-11.6C6.9 63.7 2.9 54.2 2.9 43.2s3.9-20.6 11.8-28.4C22.4 7.1 31.9 3.1 42.9 3.1 54 3.1 63.5 7 71.3 14.8Zm-2.7 49.5c5.3-6.5 7.9-14.2 7.4-22.8-.4-8.6-3.6-16-9.6-22-6.1-6.1-13.6-9.4-22.2-9.8-8.6-.4-16.2 2.1-22.7 7.5l47 47ZM64.1 69 17 22c-5.3 6.5-7.9 14.2-7.5 22.7.4 8.6 3.7 16 9.8 22.2 6 6 13.4 9.2 22 9.6 8.6.4 16.2-2.1 22.8-7.5Z"
      style={{
        strokeWidth: 0,
        fillRule: "evenodd",
        fill: "#ed1c24",
      }}
    />
  </svg>
)
export default PregnancyADA
