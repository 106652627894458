import * as React from "react"
const TripleBlackDiamondNew = (props:any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22}
    height={22}
    viewBox="0 0 22 22"
    fill="none"
    {...props}
  >
    <g fill="#242426" clipPath="url(#a)">
      <path d="M4.308 6.71.33 10.952a.524.524 0 0 0 0 .707l3.977 4.242c.183.195.48.195.663 0l3.977-4.242a.524.524 0 0 0 0-.707L4.97 6.71a.448.448 0 0 0-.663 0Z" />
      <path d="m10.668 6.71-3.977 4.242a.524.524 0 0 0 0 .707l3.977 4.242c.183.195.48.195.663 0l3.977-4.242a.524.524 0 0 0 0-.707L11.331 6.71a.448.448 0 0 0-.663 0Z" />
      <path d="m17.028 6.71-3.977 4.242a.524.524 0 0 0 0 .707l3.977 4.242c.183.195.48.195.663 0l3.977-4.242a.524.524 0 0 0 0-.707L17.691 6.71a.448.448 0 0 0-.663 0Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h22v22H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default TripleBlackDiamondNew
