import * as React from "react"
const Pets = (props:any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={15}
    viewBox="0 0 16 15"
    fill="none"
    {...props}
  >
    <path
      fill="#242426"
      d="M13.462 8.167V5.5h-1.5v2.667h1.5Zm0 1.667V8.167h-1.5v1.667h1.5Zm-3.083 3.083a3.083 3.083 0 0 0 3.083-3.083h-1.5c0 .874-.709 1.583-1.583 1.583v1.5ZM7.296 9.834a3.083 3.083 0 0 0 3.083 3.083v-1.5a1.583 1.583 0 0 1-1.583-1.583h-1.5Zm-1.584 3.083a3.083 3.083 0 0 0 3.084-3.083h-1.5c0 .874-.71 1.583-1.584 1.583v1.5ZM2.63 9.834a3.083 3.083 0 0 0 3.083 3.083v-1.5A1.583 1.583 0 0 1 4.13 9.834h-1.5Zm0-1.667v1.667h1.5V8.167h-1.5Zm0-2.667v2.667h1.5V5.5h-1.5ZM8.046.083A5.417 5.417 0 0 0 2.629 5.5h1.5a3.917 3.917 0 0 1 3.917-3.917v-1.5ZM13.462 5.5A5.417 5.417 0 0 0 8.046.083v1.5A3.917 3.917 0 0 1 11.962 5.5h1.5Z"
    />
    <circle
      cx={8.046}
      cy={8.834}
      r={0.667}
      stroke="#242426"
      strokeWidth={1.5}
    />
    <path
      stroke="#242426"
      strokeWidth={1.5}
      d="M12.713 4.167a1.76 1.76 0 0 1 2.298 1.42l.152 1.064c.114.8-.507 1.516-1.315 1.516h-1.135v-4ZM3.379 4.167a1.76 1.76 0 0 0-2.298 1.42L.929 6.651c-.114.8.506 1.516 1.315 1.516h1.135v-4Z"
    />
    <path
      stroke="#242426"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M6.713 5.5v.333M9.379 5.5v.333M6.713 12.167s0 1.667 1.333 1.667c1.334 0 1.334-1.667 1.334-1.667"
    />
  </svg>
)
export default Pets
