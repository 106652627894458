import React from "react";

export default function ThermometerIcon() {
    return (
        <svg
        width="24"
        height="25"
        viewBox="0 0 13 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.35571 19.5413C7.38411 19.4561 7.35571 14.5908 7.35571 14.5C7.35571 14.274 7.26595 14.0572 7.10617 13.8974C6.94639 13.7376 6.72969 13.6479 6.50373 13.6479C6.27777 13.6479 6.06106 13.7376 5.90129 13.8974C5.74151 14.0572 5.65175 14.274 5.65175 14.5C5.65175 14.5965 5.65175 19.4561 5.65175 19.5413C1.67584 20.5127 2.34607 26.4829 6.49237 26.4999C10.6387 26.517 11.3373 20.5127 7.35571 19.5413Z"
          fill="#1D1D1B"
        />
        <path
          d="M3.4433 4.29245V18.3208C1.59596 19.5943 0.742686 21.5 1.06775 24C1.26646 25.5282 3.17947 28.5 6.34641 28.5C9.24952 28.5 11.5811 26.566 12.0083 24C12.5078 21 10.8279 19.0849 9.50832 18.3208V4.29245C9.50832 4.29245 9.7411 1 6.50832 1C3.27554 1 3.4433 4.29245 3.4433 4.29245Z"
          stroke="#1D1D1B"
          strokeWidth="1.5"
        />
      </svg>
    );
}