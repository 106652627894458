import React from "react";

export default function Account(props: any) {
  return (
    <svg width="22" height="17" viewBox="0 0 22 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect x="0.5" y="0.556152" width="20.3794" height="15.8878" rx="3.5" fill="#FFF4D6" stroke="#EC9A38"/>
      <line x1="7.50122" y1="0.0561524" x2="7.50122" y2="12.8772" stroke="#EC9A38"/>
      <line x1="0.23877" y1="4.24414" x2="7.24243" y2="4.24414" stroke="#EC9A38"/>
      <line x1="14.3757" y1="4.24414" x2="21.3794" y2="4.24414" stroke="#EC9A38"/>
      <line x1="14.3757" y1="8" x2="21.3794" y2="8" stroke="#EC9A38"/>
      <line x1="0.23877" y1="8.31055" x2="7.24243" y2="8.31055" stroke="#EC9A38"/>
      <line x1="0.23877" y1="12.377" x2="21.3794" y2="12.377" stroke="#EC9A38"/>
      <line x1="14.5024" y1="0.0561524" x2="14.5024" y2="4.74428" stroke="#EC9A38"/>
      <line x1="14.5024" y1="7.96777" x2="14.5024" y2="12.3719" stroke="#EC9A38"/>
      <line x1="11.1897" y1="12.3721" x2="11.1897" y2="16.6671" stroke="#EC9A38"/>
    </svg>
  );
}
