require("src/@styles/tailwind.css");
require("src/@styles/global.scss");
require("react-datepicker/dist/react-datepicker.css");

const React = require("react");

let initialized = false;

/**
 * @param {import("gatsby").BrowserPluginArgs} args
 * @param {import("gatsby").PluginOptions} options
 */
module.exports.onClientEntry = async function onClientEntry(args, options) {
  if (process.env.NODE_ENV !== 'production') {
    const whyDidYouRender = require('@welldone-software/why-did-you-render')
    whyDidYouRender(React)
  }

  setTimeout(function () { initialized = true; }, 500);

  if (process.env.DEBUG_SETSTATES === '1') {
    // Patch setState:
    const oldSetState = React.Component.prototype.setState;
    React.Component.prototype.setState = function(...args) {
      console.log(" > react.component.setState() called in ", this, args[0]);
      return oldSetState.apply(this, args);
    }

    // Patch useState:
    const oldUseState = React.useState;
    React.useState = function (defaultValue) {
      let componentName = undefined;
      try { throw new Error("debug me"); }
      catch (err) {
        const lines = err.stack.split("\n");
        componentName = lines[2];
      }
      console.log(" > React.useState(%o) in %s", defaultValue, componentName);
      const res = oldUseState(defaultValue); // [var, setvar]
      const updater = res.pop();
      const logupdater = (newval) => {
        console.log(" > react.setState() produced on %s (%o)", componentName, newval);
        return updater(newval);
      }
      res.push(logupdater);
      return res;
    }
  }
}

/** scroll is controlled by layout. not gatsby. ¬¬ */
module.exports.shouldUpdateScroll = function shouldUpdateScroll() { 
  if (!initialized) return false;
  return true;
}

/**
 * Wrap page element (BROWSER) version
 * 
 * @param {import("gatsby").WrapPageElementBrowserArgs} args
 * @param {import("gatsby").PluginOptions} options
 * @return {React.ReactElement}
 */
module.exports.wrapPageElement = function wrapPageElement(args, options) {
  return require('./src-dynamic/layout.template.jsx')(args.props)(args.element, args.props);
}
