import * as React from "react"
const FunHouse = (props:any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11.547 3.983a.3.3 0 0 1-.25.467H8.703a.3.3 0 0 1-.25-.467L9.75 2.037a.3.3 0 0 1 .5 0l1.297 1.946ZM5.152 9.905a.303.303 0 1 1 0-.607.303.303 0 0 1 0 .607Zm5.454 4.242v1.818H9.394v-1.818a.606.606 0 0 1 1.212 0ZM9.696 9.6a.303.303 0 1 0 .607 0 .303.303 0 0 0-.606 0Zm2.122 4.546v1.818h-.606v-1.818a1.212 1.212 0 0 0-2.424 0v1.818h-.606v-1.818a1.818 1.818 0 0 1 3.636 0Zm.303-4.546a.303.303 0 1 0 .606 0 .303.303 0 0 0-.606 0Zm1.515.304a.303.303 0 1 1 0-.606.303.303 0 0 1 0 .606Zm.91-.304a.303.303 0 1 0 .605 0 .303.303 0 0 0-.606 0ZM10 11.722a2.424 2.424 0 0 0-2.424 2.425v1.212a.606.606 0 0 1-.606.606H5.849a1 1 0 0 1-1-1V11.51a1 1 0 0 1 1-1h8.303a1 1 0 0 1 1 1v3.455a1 1 0 0 1-1 1H13.03a.606.606 0 0 1-.606-.606v-1.212A2.424 2.424 0 0 0 10 11.722Zm3.684 1.213a.35.35 0 0 0-.35.35c0 .142.114.256.255.256h.047a.303.303 0 0 0 .304-.303v-.303h.303a.303.303 0 1 0-.303-.303v.303h-.256Zm-7.32 0a.303.303 0 1 0-.303-.303v.303h-.303a.303.303 0 1 0 .303.303v-.303h.303Zm6.666 3.636H6.97v1.819h6.06V16.57Zm-9.697 0h3.03v1.819h-3.03V16.57Zm13.334 0h-3.03v1.819h3.03V16.57Zm-9.394-6.97a.303.303 0 1 0 .606 0 .303.303 0 0 0-.606 0Zm-.91.304a.303.303 0 1 1 0-.607.303.303 0 0 1 0 .607ZM7.88 8.693h.303a.303.303 0 0 1 .303.303v.909h.606v-.91a.303.303 0 0 1 .303-.302h1.212a.303.303 0 0 1 .303.303v.909h.606v-.91a.303.303 0 0 1 .303-.302h.303V5.056H7.88v3.637ZM5.454 5.256a.2.2 0 0 0-.2-.2H3.941a.2.2 0 0 0-.141.342l.99.99a.2.2 0 0 1 .058.14v1.965c0 .11.09.2.2.2h.206a.2.2 0 0 0 .2-.2V5.256Zm9.091 0c0-.11.09-.2.2-.2H16.058a.2.2 0 0 1 .142.342l-.99.99a.2.2 0 0 0-.059.14v1.965a.2.2 0 0 1-.2.2h-.206a.2.2 0 0 1-.2-.2V5.256Z"
      clipRule="evenodd"
    />
  </svg>
)
export default FunHouse
