import React from "react";
import Icon from "src/components/icon";
import { scrollTo } from "src/utils/scroll";

type Props = {
  button: TBackToTopButton;
  standard_cta_button: "square" | "full-rounded" | "rounded";
  scrolled_down: boolean;
  className?: string;
}

type BackToTopButtonState = {
  scrolledDown: boolean;
  mobileMenuOpen: boolean;
  showLoginModal: boolean;
  timestamp: number;
}

export default class BackToTopButton extends React.Component<Props>{

  public state: BackToTopButtonState = {
    scrolledDown: false,
    mobileMenuOpen: false,
    showLoginModal: false,
    timestamp: 0,
  }

  static TIMESPAN_PROCESS_SCROLL_EVENTS = 10;
  static SCROLLGAP_NEEDED_FOR_OPEN = 90;
  static SCROLLGAP_NEEDED_FOR_CLOSE = 30;

  private scrollHandler = () => {
    const now = new Date().getTime();
    const valid = now - this.state.timestamp > BackToTopButton.TIMESPAN_PROCESS_SCROLL_EVENTS;
    if (!valid) return;
    if (this.state.scrolledDown) {
      const scrolled = !(window.scrollY < BackToTopButton.SCROLLGAP_NEEDED_FOR_CLOSE);
      this.setState({ scrolledDown: scrolled, timestamp: new Date().getTime() });
    } else {
      const scrolled = window.scrollY > BackToTopButton.SCROLLGAP_NEEDED_FOR_OPEN;
      this.setState({ scrolledDown: scrolled, timestamp: new Date().getTime() });
    }
  }

  componentDidMount() {
    this.scrollHandler();
    document.addEventListener('scroll', this.scrollHandler);
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.scrollHandler);
  }

  shouldComponentUpdate(nextProps: Readonly<Props>, nextState: Readonly<BackToTopButtonState>, nextContext: any): boolean {
    if (this.state.scrolledDown !== nextState.scrolledDown) return true;
    return false;
  }

  public get scrolledDown() {
    return this.state.scrolledDown;
  }

  get alignment() {
    if (this.props.button.alignment === "center") return "right-1/2 left-[calc(50%_-_16px)]";
    if (this.props.button.alignment === "left") return "left-6";
    return "right-6"
  }

  get colors() {
    let colorsStyles = "bg-[color:var(--backgroundColor)] text-[color:var(--arrowColor)]";
    if (this.props.button.hover) colorsStyles += " hover:bg-[color:var(--hoverColor)]";
    return colorsStyles;
  }

  get hidden() {
    if (this.scrolledDown) return "flex";
    return "hidden";
  }

  get rounded() {
    let { style } = this.props.button;
    if (style === "site-default") style = this.props.standard_cta_button;
    if (style === "full-rounded") return "rounded-full";
    if (style === "rounded") return "rounded-lg";
    return "";
  }

  get shadow() {
    if (this.props.button.shadow) return "shadow-[0px_4px_4px_0px_#00000040]"
    return "";
  }

  get className() {
    return this.props.className || "";
  }

  get cssVars(): any {
    const { background_color, arrow_color, hover_color } = this.props.button;
    return {
      '--backgroundColor': background_color?.value,
      '--hoverColor': hover_color?.value,
      '--arrowColor': arrow_color?.value,
    }
  }

  scrollToTop() {
    scrollTo({ top: 0, behavior: 'smooth' })
  }

  render(): React.ReactNode {
    if (!this.props.button) return null;

    return <button className={`fixed z-50 bottom-8 w-10 h-10 items-center justify-center ${this.hidden} ${this.alignment} ${this.colors} ${this.rounded} ${this.shadow} ${this.className}`} style={this.cssVars} onClick={this.scrollToTop}>
      <Icon icon="ArrowLeft" className="rotate-90" />
    </button>;
  }
}