import React from "react";

type MobileBackdropProps = {
    open: boolean;
    children?: React.ReactNode;
    flyout_promo_box?: React.ReactNode;
    footer?: React.ReactNode;
    navigation_bar: TNavigationBar;
}

type MobileBackdropState = {
    paddingTop: number,
}

/**
 * Mobile Backdrod component.
 * Renders the mobile menu when in open state.
 */
export default class MobileBackdrop extends React.Component<MobileBackdropProps, MobileBackdropState> {

    private layerRef = React.createRef<HTMLDivElement>();
    private footerRef = React.createRef<HTMLDivElement>();

    public state: MobileBackdropState = {
        paddingTop: 0
    }

    shouldComponentUpdate(nextProps: Readonly<MobileBackdropProps>, nextState: Readonly<MobileBackdropState>, nextContext: any): boolean {
        if (nextProps.open !== this.props.open) { this.updatePaddingTop(); return true; }
        if (nextState.paddingTop !== this.state.paddingTop) return true;
        return false;
    }

    updatePaddingTop() {
        const topbar = document.getElementById('topbar');
        if (!topbar) return true;
        const rect = topbar.getBoundingClientRect()
        const offset = rect.top + rect.height;
        if (this.state.paddingTop !== offset) {
            this.setState({ paddingTop: offset });
        }
    }

    public get childrenStyle(): any {
        if (this.footerRef.current) {
            const footerHeight: number = (this.footerRef.current.children[0] as any || this.footerRef.current).offsetHeight;
            const offset = this.state.paddingTop + footerHeight;
            return {
                minHeight: `calc(100% - ${offset + "px"})`,
                maxHeight: `calc(100% - ${offset + "px"})`
            };
        }
        return {};
    }

    componentDidMount() {
        this.updatePaddingTop();
    }

    public get style(): any {
        return {
            '--dropdown-background': this.props.navigation_bar.dropdown_hover_color_when_attached_on_mobile?.value || '#fff',
            '--dropdown-text-color': this.props.navigation_bar.unselected_item_color_when_attached_on_mobile?.value || '#000',
            '--dropdown-active-color': this.props.navigation_bar.dropdown_text_color_when_attached_on_mobile?.value || '#000',
            '--dropdown-hover-color': this.props.navigation_bar.dropdown_hover_color_when_attached_on_mobile?.value || '#000',
            '--text-color': this.props.navigation_bar.text_color_when_attached_on_mobile?.value,
            '--hover-color': this.props.navigation_bar.hover_color_when_attached_on_mobile?.value,
            '--menu-subitems-text-color': this.props.navigation_bar.menu_subitems_text_color_when_attached_on_mobile?.value,
            '--link-subitems-text-color': this.props.navigation_bar.link_subitems_text_color_when_attached_on_mobile?.value,
            '--background': this.props.navigation_bar.background_color_when_attached_on_mobile?.value || '#fff',
            'height': '100dvh',
        }
    }

    render() {
        const {open, flyout_promo_box} = this.props;
        const maxH = open ? 'max-h-screen' : 'max-h-0';
        const rowclass = flyout_promo_box ? 'flex-col sm:flex-row': 'flex-col';

        return <>
        <div className={`fixed lg:hidden left-0 right-0 bottom-0 top-0 z-[60] ${maxH} overflow-hidden touch-none bg-[color:var(--background)]`} style={this.style}>
            <div ref={this.layerRef} className="h-full flex flex-col">
                <div style={{minHeight: this.state.paddingTop}} />
                <div style={this.childrenStyle} className="flex grow w-full">
                    <div className={`w-full flex ${rowclass} overflow-y-scroll overscroll-contain`}>
                        <div className="flex-1">{this.props.children}</div>
                        <div className="h-6 md:hidden" />
                        <div className="flex-1">{flyout_promo_box}</div>
                    </div>
                </div>
                <div ref={this.footerRef} className="flex flex-col w-full shrink">
                    {this.props.footer}
                </div>
            </div>
        </div>
        </>
    }
}