import React from "react";
import CTAButton from "./ctabutton";

type CTAButtonWrapperProps = {
    className?: string;
    useDiv?: boolean;
    useButton?:boolean;
    loading?:boolean;
    disabled?:boolean;
    onClick?: () => void;

    text: string | undefined;
    link?: string | undefined;
    icon?: React.ReactNode | undefined;
    iconPosition: "left" | "right";

    type?: "primary" | "secondary" | "soft";

    open_in_same_tab?: boolean;

    primary_cta_button_label_color?: string;
    primary_cta_button_hover_label_color?: string;

    primary_cta_button_color?: string;
    primary_cta_button_hover_color?: string;

    secondary_cta_button_label_color?: string;
    secondary_cta_button_hover_label_color?: string;

    secondary_cta_button_outline_color?: string;
    secondary_cta_button_hover_outline_color?: string;

    secondary_cta_button_background_color?: string;
    secondary_cta_button_hover_background_color?: string;

    soft_button_label_color?: string;
    soft_button_hover_label_color?: string;
}

export default class CTAButtonWrapper extends React.Component<CTAButtonWrapperProps> {
    static defaultProps: Partial<CTAButtonWrapperProps> = {
        iconPosition: "left"
    }

    render() {
        const iconProps: any = {};
        if (this.props.iconPosition === 'left') iconProps.before = this.props.icon;
        if (this.props.iconPosition === 'right') iconProps.after = this.props.icon;

        if (this.props.type === "primary") {
            return <CTAButton type="primary"
                disabled={this.props.disabled}
                loading={this.props.loading}
                className={this.props.className}
                color={this.props.primary_cta_button_label_color}
                bgcolor={this.props.primary_cta_button_color}
                hovercolor={this.props.primary_cta_button_hover_label_color}
                hoverbgcolor={this.props.primary_cta_button_hover_color}
                link={this.props.link}
                onClick={this.props.onClick}
                text={this.props.text}
                useDiv={this.props.useDiv}
                open_in_same_tab={this.props.open_in_same_tab === true}
                useButton={this.props.useButton}
                {...iconProps} />;
        }

        if (this.props.type === "secondary") {
            return <CTAButton type="secondary"
                className={this.props.className}
                disabled={this.props.disabled}
                loading={this.props.loading}
                color={this.props.secondary_cta_button_label_color}
                border={this.props.secondary_cta_button_outline_color}
                bgcolor={this.props.secondary_cta_button_background_color}
                hovercolor={this.props.secondary_cta_button_hover_label_color}
                hoverborder={this.props.secondary_cta_button_hover_outline_color}
                hoverbgcolor={this.props.secondary_cta_button_hover_background_color}
                link={this.props.link}
                onClick={this.props.onClick}
                text={this.props.text}
                useDiv={this.props.useDiv}
                open_in_same_tab={this.props.open_in_same_tab === true}
                useButton={this.props.useButton}
                {...iconProps} />
        }

        return <CTAButton type="soft"
            className={this.props.className}
            disabled={this.props.disabled}
            loading={this.props.loading}
            padx={'0'}
            color={this.props.soft_button_label_color}
            hovercolor={this.props.soft_button_hover_label_color}
            link={this.props.link}
            onClick={this.props.onClick}
            text={this.props.text}
            useDiv={this.props.useDiv}
            open_in_same_tab={this.props.open_in_same_tab === true}
            useButton={this.props.useButton}
            {...iconProps} />;
    }
}