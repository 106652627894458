import React from "react";

export default class SupportingText extends React.Component<{children: React.ReactNode}> {
    render() {
        return <>
            <div className="no-auto-mb">
                {this.props.children}
            </div>
        </>
    }
}