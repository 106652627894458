import * as React from "react"

const Tow = (props: any) => (
  <svg
    width={22}
    height={22}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11 4.624v12.833M18.302 2.667 3.699 6.58"
      stroke="currentColor"
      strokeWidth={1.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      clipRule="evenodd"
      d="M7.275 16.76a.227.227 0 0 1 .278-.16l6.644 1.78a.227.227 0 0 1 .161.278v0a.227.227 0 0 1-.278.16l-6.645-1.78a.227.227 0 0 1-.16-.278v0Z"
      stroke="currentColor"
      strokeWidth={1.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default Tow
