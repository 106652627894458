import React from "react";

export default function Length(props: any) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-right-bar" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round" {...props}>
      <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
      <path d="M18 15l3 -3l-3 -3" />
      <path d="M3 12h18" />
      <path d="M3 9v6" />
    </svg>
  );
}