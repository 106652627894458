function pad2(num: number | string): string {
  const str = num.toString();
  return str.length === 1 ? '0' + str : str;
}

// Pre-create formatters for reuse
const formattersCache: { [key: string]: { longMonth: Intl.DateTimeFormat, shortMonth: Intl.DateTimeFormat, longDay: Intl.DateTimeFormat, shortDay: Intl.DateTimeFormat } } = {};

/**
 * Helper to get cached formatters based on locale.
 */
function getFormatters(locale: string) {
  if (!formattersCache[locale]) {
    formattersCache[locale] = {
      longMonth: new Intl.DateTimeFormat(locale, { month: "long" }),
      shortMonth: new Intl.DateTimeFormat(locale, { month: "short" }),
      longDay: new Intl.DateTimeFormat(locale, { weekday: "long" }),
      shortDay: new Intl.DateTimeFormat(locale, { weekday: "short" }),
    };
  }
  return formattersCache[locale];
}

/**
 * Formats date in a specified string format with localization.
 * @param date - The date to format.
 * @param format - The desired format (e.g., "dd/MM/yyyy" or "S MMMM yyyy").
 * @param locale - The locale string (e.g., "en-US" or "no-NO").
 */
export default function toFormat(date: Date, format: string = "dd/MM/yyyy", locale: string = "en-US"): string {
  if (!date) return "";

  let result = "";
  let i = 0;

  // Retrieve or create formatters for the locale
  const { longMonth, shortMonth, longDay, shortDay } = getFormatters(locale);

  while (i < format.length) {
    const buffer = format.substring(i);

    switch (true) {
      case buffer.startsWith("yyyy"):
        result += date.getFullYear();
        i += 4;
        break;

      case buffer.startsWith("dddd"):
        result += longDay.format(date);
        i += 4;
        break;

      case buffer.startsWith("ddd"):
        result += shortDay.format(date);
        i += 3;
        break;

      case buffer.startsWith("dd"):
        result += pad2(date.getDate());
        i += 2;
        break;

      case buffer.startsWith("MMMM"):
        result += longMonth.format(date);
        i += 4;
        break;

      case buffer.startsWith("MMM"):
        result += shortMonth.format(date);
        i += 3;
        break;

      case buffer.startsWith("MM"):
        result += pad2(date.getMonth() + 1);
        i += 2;
        break;

      case buffer.startsWith("HH"):
        result += pad2(date.getHours());
        i += 2;
        break;

      case buffer.startsWith("mm"):
        result += pad2(date.getMinutes());
        i += 2;
        break;

      case buffer.startsWith("S"):
        const day = date.getDate();
        let ordinalSuffix;
        if (locale.startsWith("en")) {
          // English ordinal suffixes
          ordinalSuffix = (day % 10 === 1 && day !== 11) ? "st" :
                          (day % 10 === 2 && day !== 12) ? "nd" :
                          (day % 10 === 3 && day !== 13) ? "rd" : "th";
        } else if (locale.startsWith("no")) {
          ordinalSuffix = ".";
        } else {
          ordinalSuffix = "";
        }
        result += day + ordinalSuffix;
        i += 1;
        break;

      default:
        result += format[i];
        i++;
        break;
    }
  }

  return result;
}