import React from "react";

export default function Radio(props: any) {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M12.3126 10.2785C13.2477 10.2785 14.0057 9.52039 14.0057 8.58527C14.0057 7.65015 13.2477 6.89209 12.3126 6.89209C11.3774 6.89209 10.6194 7.65015 10.6194 8.58527C10.6194 9.52039 11.3774 10.2785 12.3126 10.2785Z" stroke="currentColor" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M16.8979 3.99934C16.5463 3.64806 15.9764 3.64838 15.6251 4.00005C15.2739 4.35171 15.2742 4.92156 15.6258 5.27284L16.8979 3.99934ZM15.6258 11.9073C15.2742 12.2586 15.2739 12.8285 15.6251 13.1801C15.9764 13.5318 16.5463 13.5321 16.8979 13.1808L15.6258 11.9073ZM7.72714 13.1713C8.0788 13.5226 8.64865 13.5222 8.99993 13.1706C9.35121 12.8189 9.35089 12.2491 8.99922 11.8978L7.72714 13.1713ZM8.99922 5.26328C9.35089 4.912 9.35121 4.34215 8.99993 3.99049C8.64865 3.63882 8.0788 3.6385 7.72714 3.98978L8.99922 5.26328ZM19.5344 1.3637C19.1829 1.01218 18.6131 1.01209 18.2616 1.36351C17.91 1.71493 17.91 2.28478 18.2614 2.6363L19.5344 1.3637ZM18.2614 14.5344C17.91 14.8859 17.91 15.4558 18.2616 15.8072C18.6131 16.1586 19.1829 16.1585 19.5344 15.807L18.2614 14.5344ZM5.09066 15.807C5.44208 16.1585 6.01193 16.1586 6.36346 15.8072C6.71498 15.4558 6.71507 14.8859 6.36365 14.5344L5.09066 15.807ZM6.36365 2.6363C6.71507 2.28478 6.71498 1.71493 6.36346 1.36351C6.01193 1.01209 5.44208 1.01218 5.09066 1.3637L6.36365 2.6363ZM15.6258 5.27284C16.5063 6.15229 17.001 7.34567 17.001 8.59009H18.801C18.801 6.86793 18.1163 5.21641 16.8979 3.99934L15.6258 5.27284ZM17.001 8.59009C17.001 9.83451 16.5063 11.0279 15.6258 11.9073L16.8979 13.1808C18.1163 11.9638 18.801 10.3122 18.801 8.59009H17.001ZM8.99922 11.8978C8.11879 11.0183 7.62409 9.82495 7.62409 8.58053H5.82409C5.82409 10.3027 6.50871 11.9542 7.72714 13.1713L8.99922 11.8978ZM7.62409 8.58053C7.62409 7.33611 8.11879 6.14273 8.99922 5.26328L7.72714 3.98978C6.50871 5.20685 5.82409 6.85837 5.82409 8.58053H7.62409ZM18.2614 2.6363C21.5462 5.92216 21.5462 11.2485 18.2614 14.5344L19.5344 15.807C23.5219 11.8182 23.5219 5.35246 19.5344 1.3637L18.2614 2.6363ZM6.36365 14.5344C3.07878 11.2485 3.07878 5.92216 6.36365 2.6363L5.09066 1.3637C1.10311 5.35246 1.10311 11.8182 5.09066 15.807L6.36365 14.5344Z" fill="currentColor" />
      <path d="M6 22.9V18.1H6.94384V22.0533H9.09525V22.9H6Z" fill="currentColor" />
      <path d="M9.75466 18.8V18H10.6985V18.8H9.75466ZM9.75466 22.9V19.3H10.6985V22.9H9.75466Z" fill="currentColor" />
      <path d="M12.752 22.9L11.3918 19.3H12.3356L13.224 21.7933L14.1123 19.3H15.0561L13.6959 22.9H12.752Z" fill="currentColor" />
      <path d="M17.2896 23C16.9056 23 16.5667 22.9211 16.2729 22.7633C15.9814 22.6033 15.7524 22.3844 15.5858 22.1067C15.4216 21.8267 15.3395 21.5067 15.3395 21.1467C15.3395 20.7533 15.4204 20.4111 15.5824 20.12C15.7443 19.8289 15.9675 19.6033 16.2521 19.4433C16.5366 19.2811 16.864 19.2 17.2341 19.2C17.6274 19.2 17.9616 19.2889 18.2369 19.4667C18.5122 19.6444 18.7158 19.8944 18.8476 20.2167C18.9795 20.5389 19.0258 20.9178 18.9864 21.3533H18.053V21.02C18.053 20.6533 17.9917 20.39 17.8691 20.23C17.7488 20.0678 17.551 19.9867 17.2757 19.9867C16.9542 19.9867 16.7171 20.0811 16.5644 20.27C16.414 20.4567 16.3388 20.7333 16.3388 21.1C16.3388 21.4356 16.414 21.6956 16.5644 21.88C16.7171 22.0622 16.9403 22.1533 17.2341 22.1533C17.4192 22.1533 17.5776 22.1144 17.7095 22.0367C17.8413 21.9589 17.942 21.8467 18.0114 21.7L18.9552 21.96C18.8141 22.2889 18.5909 22.5444 18.2855 22.7267C17.9825 22.9089 17.6505 23 17.2896 23ZM16.0474 21.3533V20.68H18.5319V21.3533H16.0474Z" fill="currentColor" />
    </svg>
  );
}