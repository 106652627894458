import { isRunTime } from "./isRunTime";

const getQuery = () => {
  if (isRunTime() && window.location.href.indexOf("?") > 0) {
    const queryString = isRunTime() && window.location.href.split("?")[1].split("&");
    if (queryString !== undefined) {
      let ret = {};
      queryString.forEach((idx) => {
        const param = idx.split("=");
        const key = param[0];
        let value = decodeURIComponent(param[1]);
        try {
          value = JSON.parse(value);
        } catch {} // die silently
        ret[key] = value;
      });
      return ret;
    } else {
      return {};
    }
  } else {
    return {};
  }
};

const setQuery = ({ key, value, pushState = false }) => {
  if (isRunTime() && key && value !== undefined) {
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set(key, value);
    if (pushState) {
      window.history.pushState({}, "", `?${urlParams.toString()}`);
    } else {
      window.history.replaceState({}, "", `?${urlParams.toString()}`);
    }
  }
};

// same as resetQuery, but staying in the same page

const removeQueryParams = () => {
  if (isRunTime()) {
    const url = new URL(window.location);
    url.search = ""; // Clear the query strings
    window.history.replaceState({}, "", url.toString());
  }
};

const resetQuery = () => {
  if (isRunTime()) {
    window.history.replaceState({}, "", "/")
  }
}

export { getQuery, setQuery, resetQuery, removeQueryParams };
