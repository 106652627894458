import * as React from "react"
const Arcade = (props:any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      d="M10 1a1.99 1.99 0 0 0-1.446.633 2.226 2.226 0 0 0-.598 1.528c0 .574.215 1.123.598 1.529A1.99 1.99 0 0 0 10 5.323a1.99 1.99 0 0 0 1.445-.633c.383-.406.599-.955.599-1.529 0-.573-.216-1.123-.599-1.528A1.99 1.99 0 0 0 10 1Zm-.41 5.662a4.368 4.368 0 0 0-1.66.493l-6.347 3.399c-.359.191-.582.579-.583 1.01 0 .432.222.82.58 1.012l6.352 3.428a4.363 4.363 0 0 0 4.138.008l6.347-3.398c.359-.192.582-.58.583-1.011 0-.43-.222-.82-.58-1.012l-6.35-3.427a4.359 4.359 0 0 0-1.662-.5v4.494a.42.42 0 0 1-.408.432.421.421 0 0 1-.41-.432V6.662Zm-3.883 4.28c.565 0 1.022.29 1.022.648 0 .358-.457.649-1.022.649s-1.022-.29-1.022-.649c0-.358.457-.648 1.022-.648Zm-4.701 2.276v1.777a1.133 1.133 0 0 0 .576 1.069l6.35 3.406c.66.353 1.367.53 2.075.53.703 0 1.406-.175 2.061-.523l6.348-3.377c.375-.199.598-.602.582-1.052a.527.527 0 0 0-.005-.057v-1.736a1.903 1.903 0 0 1-.206.13l-6.347 3.398c-.748.4-1.59.612-2.432.612h-.001a5.171 5.171 0 0 1-2.448-.62l-6.35-3.429a1.883 1.883 0 0 1-.203-.128Z"
    />
  </svg>
)
export default Arcade
