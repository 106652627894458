import React from "react";

export default class StaticSlider extends React.Component<SliderProps> {
    renderMobileVersion(): React.ReactNode {
        const children = React.Children.toArray(this.props.children).slice(0, 1);
        return <div className={`flex flex-row overflow-hidden w-full`}>
            {children}
        </div>
    }

    renderDesktopVersion(): React.ReactNode {
        const numSlides = this.props.slidesPerView || 4;
        const spaceBetween = (this.props.spaceBetween || 24) * 2;
        const children = React.Children.toArray(this.props.children).slice(0, numSlides === "auto" ? 4 : numSlides);
        return <div style={{gap: spaceBetween}} className={`flex flex-row overflow-hidden w-full`}>
            {children}
        </div>
    }

    render(): React.ReactNode {
        return <>
        <div className='flex md:hidden w-full'>
            {this.renderMobileVersion()}
        </div>
        <div className='hidden md:flex w-full'>
             {this.renderDesktopVersion()}
        </div>
        </>
    }
}