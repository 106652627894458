import React from "react";

export default function AUD(props: any) {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x="0.142822" y="1" width="20" height="20" fill="#004692"/>
    <path d="M7.64282 13.2222L8.25811 14.5419L9.64771 14.2125L9.02537 15.5288L10.1428 16.4377L8.75156 16.7593L8.75541 18.2222L7.64282 17.307L6.53019 18.2222L6.53409 16.7593L5.14282 16.4377L6.26023 15.5288L5.63798 14.2125L7.02749 14.5419L7.64282 13.2222Z" fill="#F5F7F8"/>
    <path d="M14.3095 14.0555L14.7197 14.9354L15.6461 14.7158L15.2312 15.5933L15.9762 16.1993L15.0486 16.4136L15.0512 17.3889L14.3095 16.7787L13.5677 17.3889L13.5703 16.4136L12.6428 16.1993L13.3878 15.5933L12.9729 14.7158L13.8993 14.9354L14.3095 14.0555Z" fill="#F5F7F8"/>
    <path d="M12.365 7.38892L12.7068 8.12212L13.4788 7.93911L13.133 8.67037L13.7539 9.17534L12.9809 9.35401L12.9831 10.1667L12.365 9.65825L11.7468 10.1667L11.749 9.35401L10.9761 9.17534L11.5969 8.67037L11.2512 7.93911L12.0231 8.12212L12.365 7.38892Z" fill="#F5F7F8"/>
    <path d="M15.1428 5.16663L15.4846 5.89983L16.2566 5.71682L15.9109 6.44808L16.5317 6.95305L15.7588 7.13172L15.7609 7.9444L15.1428 7.43596L14.5247 7.9444L14.5268 7.13172L13.7539 6.95305L14.3747 6.44808L14.029 5.71682L14.8009 5.89983L15.1428 5.16663Z" fill="#F5F7F8"/>
    <path d="M17.9206 7.94446L18.2625 8.67766L19.0345 8.49465L18.6887 9.22591L19.3095 9.73088L18.5366 9.90955L18.5387 10.7222L17.9206 10.2138L17.3025 10.7222L17.3047 9.90955L16.5317 9.73088L17.1525 9.22591L16.8068 8.49465L17.5788 8.67766L17.9206 7.94446Z" fill="#F5F7F8"/>
    <circle cx="15.8373" cy="11.6944" r="0.694445" fill="#F5F7F8"/>
    <path d="M3.62131 1.87051L3.03206 2.45976L11.2337 10.6614L11.823 10.0722L3.62131 1.87051Z" fill="#F5F7F8"/>
    <path d="M2.04997 3.44186L1.06787 4.42395L9.26954 12.6256L10.2516 11.6435L2.04997 3.44186Z" fill="#F5F7F8"/>
    <path d="M2.04997 3.44186L3.03206 2.45976L11.2337 10.6614L10.2516 11.6435L2.04997 3.44186Z" fill="#DD2033"/>
    <path d="M10.1427 4.33337V5.72226L4.86496 5.72226L4.86496 11H3.47607V4.33337H10.1427Z" fill="#F5F7F8"/>
    <path d="M10.1428 0.444458V1.83335L1.53171 1.83335L1.53171 11H0.142822V0.444458H10.1428Z" fill="#F5F7F8"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M0.976074 11H3.47608L3.47608 4.33337L10.1427 4.33337V1.83337H3.47608H0.976075L0.976075 4.33337L0.976074 11Z" fill="#DD2033"/>
    </svg>
  );
}
