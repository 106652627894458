import * as React from "react"
const GroomedStatusV2 = (props:any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <rect width={20} height={20} x={2} y={2} fill="#1B427A" rx={4} />
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.2}
      d="M7.126 7h3.739c.549 0 1.06.298 1.36.792l1.484 2.365c.492.809.282 1.894-.47 2.424a1.544 1.544 0 0 1-.89.286H7.125c-.898 0-1.626-.784-1.626-1.75V8.75C5.5 7.784 6.228 7 7.126 7Z"
      clipRule="evenodd"
    />
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.2}
      d="M13.477 12.867H7.301c-.995 0-1.801.876-1.801 1.956s.806 1.956 1.801 1.956h6.176c.995 0 1.801-.876 1.801-1.956s-.806-1.956-1.8-1.956Z"
    />
    <path
      fill="#fff"
      d="M12.67 15.475a.652.652 0 1 0 0-1.304.652.652 0 0 0 0 1.304ZM10.389 15.475a.652.652 0 1 0 0-1.304.652.652 0 0 0 0 1.304ZM8.108 15.475a.652.652 0 1 0 0-1.304.652.652 0 0 0 0 1.304Z"
    />
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.2}
      d="M6.152 10.26c1.8-.032 2.576.808 2.607 2.607M15.279 15.475h2.282M14.953 13.52h1.955M17.317 11.112a4.535 4.535 0 0 0 2.514 5.9"
    />
  </svg>
)
export default GroomedStatusV2
