import React from "react";
import { AppleAppStore, GooglePlayStore } from "src/components/appStoreBadge";
import Link from "src/components/link";

type AppStoresProps = {
    textAlign: "left" | "center";
    badgesAlign: "left" | "center";
    
    googlePlayUrl?: string;
    appleStoreUrl?: string;

    className?: string;
    uid: string;
}

export default class AppStores extends React.Component<AppStoresProps> {
    static defaultProps: Partial<AppStoresProps> = {
        textAlign: 'center',
        badgesAlign: 'center',
        className: '',
        uid: 'gplaybadge'
    }

    public get textAlignClass() {
        if (this.props.textAlign === 'left') return 'text-left';
        return 'text-center';
    }

    public get badgesAlignClass() {
        if (this.props.badgesAlign === 'left') return 'items-start';
        return 'items-center justify-center';
    }

    render() {
        // no stores - no component
        if (!this.props.googlePlayUrl && !this.props.appleStoreUrl) return null;

        return <>
            <div className={`w-full flex-col justify-start items-center gap-4 inline-flex ${this.props.className}`}>
                <div className={`self-stretch ${this.textAlignClass} text-[color:var(--titles-color)] footerTitle leading-normal`}>Download our App</div>
                <div className={`w-full flex flex-row gap-6 ${this.badgesAlignClass}`}>
                    {this.props.appleStoreUrl ? <Link className="" href={this.props.appleStoreUrl} title={"Apple Store"}>
                        <AppleAppStore />
                    </Link> : null}
                    {this.props.googlePlayUrl ? <Link className="" href={this.props.googlePlayUrl} title={"Google Play"}>
                        <GooglePlayStore uid={this.props.uid} />
                    </Link> : null}
                </div>
            </div>
        </>
    }
}