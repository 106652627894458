import * as React from "react"
const FlatRide = (props:any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      d="M18.878 19.467v.412c0 .067-.054.121-.121.121H1.539a.121.121 0 0 1-.121-.12v-.413c0-.444.363-.805.81-.805h15.84c.447 0 .81.361.81.805ZM4.861 18.229c0 .067.055.121.122.121h10.33a.121.121 0 0 0 .122-.12.808.808 0 0 0-.81-.806H5.67a.808.808 0 0 0-.81.805Zm6.097-1.167c.067 0 .122-.072.122-.162V5.723c-.262.256-.583.412-.932.412-.35 0-.67-.156-.932-.412V16.9c0 .09.055.162.122.162h1.62ZM2.038 4.804c.262.508.79.86 1.403.86.403 0 .79-.157 1.083-.43.291.273.678.43 1.082.43.435 0 .84-.176 1.135-.481.295.305.701.481 1.136.481.435 0 .84-.175 1.136-.481.294.306.7.481 1.135.481.435 0 .84-.175 1.136-.481.294.306.7.481 1.135.481.404 0 .791-.157 1.083-.43.291.273.679.43 1.082.43.435 0 .84-.175 1.136-.481a1.58 1.58 0 0 0 2.539-.38.238.238 0 0 0 .179-.228V2.513a.242.242 0 0 0-.243-.241H2.102a.242.242 0 0 0-.243.241v2.062c0 .11.076.2.179.229Zm8.11-2.835c.27 0 .488-.217.488-.484A.487.487 0 0 0 10.148 1a.487.487 0 0 0-.488.485c0 .267.219.484.488.484ZM3.014 13.642c.11 0 .209-.074.236-.184l.13-.528a1.141 1.141 0 0 0-.617-1.303l1.426-5.789-.558.233-1.34 5.44a1.155 1.155 0 0 0-1.154.873l-.13.528a.24.24 0 0 0 .178.292l1.771.43c.02.005.039.008.058.008ZM6.563 5.87l-.212 5.594c-.217.037-.42.138-.585.29a1.135 1.135 0 0 0-.368.798l-.02.543a.24.24 0 0 0 .064.174.244.244 0 0 0 .17.077l1.821.068h.01a.243.243 0 0 0 .242-.232l.02-.543a1.147 1.147 0 0 0-.868-1.154l.212-5.597-.486-.018Zm5.88 6.769.02.543a.24.24 0 0 0 .243.232h.01l1.82-.068a.244.244 0 0 0 .17-.077.24.24 0 0 0 .065-.174l-.02-.542a1.149 1.149 0 0 0-.953-1.087l-.213-5.596-.485.018.211 5.597a1.146 1.146 0 0 0-.868 1.154Zm6.55.273-.13-.528a1.156 1.156 0 0 0-1.154-.872l-1.34-5.44-.549-.196 1.417 5.75a1.141 1.141 0 0 0-.617 1.304l.13.528a.243.243 0 0 0 .294.177l1.77-.43a.243.243 0 0 0 .18-.293Z"
    />
  </svg>
)
export default FlatRide
