import React from "react";

export default function Radio2(props: any) {
  return (
    <svg
      width={24}
      height={24}
      fill="none"
      viewBox="0 0 24 22"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M11.606 13.429a1.928 1.928 0 1 0 0-3.857 1.928 1.928 0 0 0 0 3.857Z"
        stroke="currentColor"
        strokeWidth={1.8}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.74 6.366a.9.9 0 0 0-1.272 1.274l1.272-1.274Zm-1.272 9.007a.9.9 0 1 0 1.272 1.273l-1.272-1.274Zm-8.996 1.263a.9.9 0 0 0 1.272-1.274l-1.272 1.274Zm1.272-9.007a.9.9 0 1 0-1.272-1.273l1.272 1.273Zm11.998-4.265a.9.9 0 0 0-1.273 1.272l1.273-1.272Zm-1.273 15a.9.9 0 0 0 1.273 1.272l-1.273-1.272Zm-15 1.272a.9.9 0 0 0 1.273-1.272L3.47 19.636Zm1.273-15A.9.9 0 0 0 3.47 3.364l1.273 1.272ZM15.468 7.64a5.465 5.465 0 0 1 1.603 3.866h1.8a7.265 7.265 0 0 0-2.131-5.14L15.468 7.64Zm1.603 3.866c0 1.45-.577 2.841-1.603 3.867l1.272 1.273a7.265 7.265 0 0 0 2.13-5.14h-1.8Zm-9.327 3.856a5.465 5.465 0 0 1-1.603-3.866h-1.8c0 1.928.767 3.777 2.13 5.14l1.273-1.274Zm-1.603-3.866c0-1.45.577-2.842 1.603-3.867L6.472 6.356a7.265 7.265 0 0 0-2.13 5.14h1.8Zm12.329-6.86c3.79 3.791 3.79 9.937 0 13.728l1.273 1.272c4.492-4.494 4.492-11.778 0-16.272L18.47 4.636ZM4.742 18.364c-3.79-3.791-3.79-9.937 0-13.728L3.47 3.364c-4.492 4.494-4.492 11.778 0 16.272l1.273-1.272Z"
        fill="currentColor"
      />
    </svg>
  )
}
