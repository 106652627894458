import React from "react";

import MobileNavigationLink from "./mobile.navigation.link";
import MobileNavigationSubMenuWithLink from "./mobile.navigation.submenu.with.link";

import { getLanguageField } from "src/utils/language";

type Props = {
    key: string;
    depth: number;
    language: string;
    defaultLanguage: string;
    item: TNavigationItem;
}

export default class MobileNavigationSubMenu extends React.Component<Props> {
    
    public get label() {
        return getLanguageField(this.props.item.translations, this.props.language, 'label');
    }

    private get style(): any {
        return {
            '--text-color': this.props.item.item_color?.value,
            '--hover-color': this.props.item.item_hover_color?.value,
        }
    }
  
    render(): React.ReactNode {
        const {label} = this;
        const {language, defaultLanguage} = this.props;

        return <>
            <div style={this.style} className={`navBarItems mobile-navigation-submenu-label text-[color:var(--text-color)]`}>{label}</div>
            {(this.props.item.items || []).map((item, idx) => {
                if (item.item_type === "Link") return <MobileNavigationLink depth={this.props.depth + 1} language={language} defaultLanguage={defaultLanguage} key={`mobnavbar_link_${idx}`} item={item} />
                if (item.item_type === "Menu") return <MobileNavigationSubMenu depth={this.props.depth + 1} language={language} defaultLanguage={defaultLanguage} key={`mobnavbar_menu_${idx}`} item={item} />
                if (item.item_type === "Menu with link") return <MobileNavigationSubMenuWithLink depth={this.props.depth + 1} language={language} defaultLanguage={defaultLanguage} key={`mobnavbar_menu_${idx}`} item={item} />
                return null;
            })}
        </>;
    }
}