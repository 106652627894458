import React from "react";
import StaticSlider from "./components/static.slider.component";
import Suspense from "src/components/suspense";
const SwiperEnhancedSlider = React.lazy(() => import(/* webpackChunkName: "enhanced-swiper" */ "./components/swiper.slider.component"));

export default class SliderComponent extends React.Component<SliderProps> {
    static defaultProps : Partial<SliderProps> = {}

    public enhancedSliderRef: React.RefObject<import("./components/swiper.slider.component").default> = React.createRef<import("./components/swiper.slider.component").default>();

    public slideTo(slideNum: number) {
        this.enhancedSliderRef.current?.slideTo(slideNum);
    }

    render() {
         return <>
            <Suspense fallback={this.props.fallback || <StaticSlider {...this.props} />}>
                <SwiperEnhancedSlider {...this.props} ref={this.enhancedSliderRef} />
            </Suspense>
        </>
    }
}