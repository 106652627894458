import * as React from "react"
const WheelChariToECV = (props:any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    data-name="Layer 1"
    viewBox="0 0 180.7 84"
    {...props}
  >
    <defs>
      <style>
        {".cls-1,.cls-2{fill:#231f20;stroke-width:0}.cls-2{fill:#fff}"}
      </style>
    </defs>
    <rect
      width={175.1}
      height={81.3}
      x={2.8}
      y={1.3}
      className="cls-2"
      rx={12}
      ry={12}
    />
    <rect
      width={170.2}
      height={76.5}
      x={5.3}
      y={3.7}
      className="cls-1"
      rx={12}
      ry={12}
    />
    <rect
      width={166.6}
      height={72.9}
      x={7.1}
      y={5.5}
      className="cls-2"
      rx={12}
      ry={12}
    />
    <path
      d="M28.4 73.8c2.4 0 4.3-1.9 4.3-4.3s-2-4.3-4.3-4.3-4.3 2-4.3 4.3 2 4.3 4.3 4.3M64.9 73.8c2.4 0 4.3-1.9 4.3-4.3s-1.9-4.3-4.3-4.3-4.3 2-4.3 4.3 1.9 4.3 4.3 4.3M25 20.4c2.8 0 5.1-2.3 5.1-5.1s-2.3-5.1-5.1-5.1-5.2 2.3-5.2 5.1 2.3 5.1 5.2 5.1M28.6 24.2c-.7-1.6-2.2-2.7-4-2.7s-4.5 2-4.4 4.4c.2 4.4 2 11.2 2 11.2.9 4.6 2.3 6 5.8 6h15.7l1 12.1c.2 2.6 1.9 2.7 2.9 2.7 1.2 0 2.3-1 2.1-3l-1-14c-.2-3.2-1.7-3.9-4-3.9H31.6L30 30.4h15.1c1.3 0 2.4-1.1 2.4-2.4s-1.1-2.4-2.4-2.4H28.9l-.4-1.6Z"
      className="cls-1"
    />
    <path
      d="M71.2 60.4 65 31.7c-.1-.7-.6-1.3-1.3-1.6L54 25.2c-1.2-.6-2.6-.1-3.2 1.1-.2.3-.3.7-.3 1.1 0 .9.5 1.7 1.3 2.1 0 0 7 3.5 8.7 4.4.4 1.8 4.4 20.2 5.4 24.7H27.2V47.7h10.5c1 0 2-.2 2-1.2s-.9-1.5-2.1-1.5H28c-4 0-6.3-2.1-7-5.4 0 0-2-10.4-2.1-10.9-.1-.6-.6-1.5-1.8-1.3-.9.2-1.2 1.1-1 1.7.2.7 2.2 11 2.2 11 .3 1.6 1.5 4.7 4.1 6.4V61c0 1.3 1.1 2.4 2.4 2.4h44c.7 0 1.4-.3 1.9-.9.5-.6.6-1.3.5-2M95.5 51.4l-6.8.1 7.9-8H76v-5.1h20.6l-7.9-7.9h6.8L106 41 95.5 51.4zM121.9 38.5c-3.5 1.3-7.6 4.9-7.6 13.2s7 15.4 14.9 15.4 14.6-4.4 18-10.9l1.7 5.1c-3.3 4.8-10 10.2-19.9 10.2s-19.3-9.1-19.3-19.8 7.2-16 12-17.5l.2 4.4Z"
      className="cls-1"
    />
    <path
      d="M128.1 10.5c3.4 0 6.2 1.7 6.2 5.8s-3 5.9-5.3 5.9l.3 11H144l.2 4.3h-14.6l.2 4.4h20.5l6 17.8 6.8-2.4 1.4 4.4-11.4 4.1-6.6-19.6h-22l-.9-25.4c0-1.9-1.4-2.6-1.5-4.5 0-2.9 2.2-5.8 5.9-5.8"
      className="cls-1"
    />
  </svg>
)
export default WheelChariToECV
