import React from "react";

export default function Monitor(props: any) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M1.33334 4C1.33334 2.89543 2.22877 2 3.33334 2H12.6667C13.7712 2 14.6667 2.89543 14.6667 4V9.33333C14.6667 10.4379 13.7712 11.3333 12.6667 11.3333H3.33334C2.22877 11.3333 1.33334 10.4379 1.33334 9.33333V4Z" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M5.33334 14H10.6667" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M8 11.3333V13.9999" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

  )
}