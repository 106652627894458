import React from "react";

import NavigationBarLink from "./navigationbar.link";
import NavigationBarSubMenu from "./navigationbar.submenu";
import NavigationBarSubMenuWithLink from "./navigationbar.submenu.with.link";

import Icon from "src/components/icon";
import Accordion from "src/components/accordion";

import { getLanguageField } from "src/utils/language";
import { isRunTime } from "src/utils/isRunTime";
import FlyoutPromoBoxV1 from "src/modules/flyoutPromoBox/flyout.promo.box.v1";

type Props = {
    depth: number;
    language: string;
    defaultLanguage: string;
    item: TNavigationItem;
    chevrons_on_menus: boolean;
    flyout_menu_opening: TFlyoutMenuOpening;
}

type State = {
    menuOpen: boolean;
    minWidth: number;
    offsetLeft: number;
}

export default class NavigationBarMenu extends React.Component<Props, State> {

    public accordionRef = React.createRef<Accordion>();
    public popupRef = React.createRef<HTMLDivElement>();

    public state: State = {
        menuOpen: false,
        minWidth: 200,
        offsetLeft: 0,
    };

    public get label(): string {
        return getLanguageField(this.props.item.translations, this.props.language, 'label') as string;
    }

    onClick = () => {
        if (this.accordionRef.current) this.accordionRef.current.close();
    }

    recalc = () => {
        if (this.popupRef.current && this.accordionRef.current) {
            const relative = this.accordionRef.current.getBoundingClientRect();
            const rect = this.popupRef.current.getBoundingClientRect();
            const right = rect.right + 40;
            if (right > screen.availWidth) {
                const desiredSpaceAtLeft = (screen.availWidth - (rect.width + 40));
                this.setState({offsetLeft: (-relative.left + desiredSpaceAtLeft) });
            }
        }
    }

    private observer: ResizeObserver | undefined = isRunTime() && typeof window.ResizeObserver !== "undefined" ? new ResizeObserver(this.recalc) : undefined;

    onDimension = (width: number) => {
        const maxW = Math.max(this.state.minWidth, width);
        const limW = Math.min(maxW, 400);
        this.setState({minWidth: limW});
    }

    public get color() {
        if (this.state.menuOpen) return "text-[color:var(--hover-color)] hover:text-[color:var(--hover-color)]";
        return "text-[color:var(--text-color)] hover:text-[color:var(--hover-color)]";
    }

    public get width() {
        return { minWidth: this.state.minWidth };
    }

    public get left() {
        return { left: this.state.offsetLeft };
    }

    get flyoutMenuOpeningisHover(){
        const { flyout_menu_opening } = this.props;
        return flyout_menu_opening === "hover";
    }

    componentDidMount(): void {
        if (this.popupRef.current && this.observer) {
            this.observer.observe(this.popupRef.current);
        } else {
            setTimeout(() => this.recalc, 100);
        }
    }
    componentWillUnmount(): void {
        if (this.observer) this.observer.disconnect();
    }

    renderWithoutItems(): React.ReactNode {
        return <div style={this.style} className={`flex flex-row items-center navBarText ${this.color}`}>
            <span className="truncate">{this.label}</span>
        </div>
    }

    renderMenuLabel(): React.ReactNode {
        return <div style={this.style} className={`flex flex-row items-center cursor-pointer navBarText ${this.color}`}>
            <span className="truncate">{this.label}</span> {this.props.chevrons_on_menus ? <Icon icon="ChevronDown" className={`h-4 w-4 ml-1 ${this.state.menuOpen ? 'rotate-180' : ''}`} /> : null}
        </div>
    }

    renderAccordionComponent(){
        if (this.flyoutMenuOpeningisHover) return (
            <div className="group mt-1 relative">
                <div className="pb-1">
                    {this.renderMenuLabel()}
                </div>
                <div className="hidden group-hover:flex">
                    {this.renderChildren()}
                </div>
            </div>
        )
        return (
            <Accordion
                ref={this.accordionRef}
                closeOther={true}
                opener={this.renderMenuLabel()}
                removeChildrenWhenClosed={true}
                onToggle={(open) => this.setState({menuOpen: open}, this.recalc)}
            >
                {this.renderChildren()}
            </Accordion>
        )
    }

    renderChildren(){
        const { defaultLanguage, language } = this.props;

        return <div className="absolute top-6 left-[-6] z-50" style={this.left}>
            <div ref={this.popupRef} className="p-6 flex flex-row shadow-md rounded-lg max-h-[80vh] overflow-y-auto border border-[color:var(--dropdown-outline)] bg-[color:var(--dropdown-background)]">
                <div className="flex flex-col justify-start gap-4" style={this.width}>
                    {this.items.map((item, idx) => {
                        if (item.item_type === "Link") return <NavigationBarLink onClick={this.onClick} onDimension={this.onDimension} depth={this.props.depth + 1} language={language} defaultLanguage={defaultLanguage} key={`navbar_link_${idx}`} item={item} />
                        if (item.item_type === "Menu") return <NavigationBarSubMenu onClick={this.onClick} onDimension={this.onDimension} depth={this.props.depth + 1} language={language} defaultLanguage={defaultLanguage} key={`navbar_menu_${idx}`} item={item} />
                        if (item.item_type === "Menu with link") return <NavigationBarSubMenuWithLink onClick={this.onClick} onDimension={this.onDimension} depth={this.props.depth + 1} language={language} defaultLanguage={defaultLanguage} key={`navbar_menu_${idx}`} item={item} />
                        return null;
                    })}
                </div>
                {this.renderFlyoutPromoBox()}
            </div>
        </div>
    }

    private get style(): any {
        return {
            '--text-color': this.props.item.item_color?.value,
            '--hover-color': this.props.item.item_hover_color?.value,
        }
    }

    private get items(){
        return (this.props.item?.items || []).filter(item => item.hide !== "yes")
    }

    renderFlyoutPromoBox() {
        if (!this.props.item.flyout_promo_box) return null;
        const openerWithProps = React.Children.map(
            [<FlyoutPromoBoxV1 flyoutPromoBox={this.props.item.flyout_promo_box} language={this.props.language} defaultLanguage={this.props.defaultLanguage}/>], child => {
            if (React.isValidElement(child)) {
              return React.cloneElement(child, {
                closeNavigation: () => {
                    if (this.accordionRef.current) this.accordionRef.current.close();
                    this.setState({menuOpen: false})
                }} as any);
            }
            return child;
        });

        return openerWithProps;
    }

    render(): React.ReactNode {
        // If no items, render placeholder:
        if ((this.props.item.items || []).length === 0) return this.renderWithoutItems();

        return <>
            <div className="relative">
                {this.renderAccordionComponent()}
            </div>
        </>;
    }
}