import React from "react";

export default class CopyText extends React.Component<{children: React.ReactNode}> {
    render() {
        return <>
            <div className="no-auto-mb grow shrink basis-0 text-center text-[color:var(--text-color)] generalText leading-snug">
                {this.props.children}
            </div>
        </>
    }
}