import * as React from "react"
const BumperCars = (props:any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M6.425 12.488c-.74-.748-1.156-1.285-1.156-1.285v-.352c0-.272.226-.492.506-.492h.483c.21 0 .397.125.472.315L7.777 13.7s-.323-.172-1.352-1.212ZM4.194 3.792l-1.112-.94.27-.302 1.182 1c.045.038.071.093.071.151v7.433h-.411V3.792ZM16.754 15s.028-.43-.073-.629c-.39-.775-1.846-1.805-2.316-2.124a.583.583 0 0 0-.324-.1s-1.025-.014-1.703-.013c-.144 0-.19.208-.151.343.028.1.052.267.06.458l-.515-.32.197-.302a.394.394 0 0 0-.126-.552.418.418 0 0 0-.568.122l-.617.941a.394.394 0 0 0 .127.553.418.418 0 0 0 .568-.123l.197-.302.72.447c-.05.373-.197.733-.53.851-.314.112-.723.167-1.16.178V10.32a1.344 1.344 0 1 0-2.688 0v3.591c-.94-.528-1.635-1.335-2.118-1.895-.235-.272-.419-.485-.556-.58a.768.768 0 0 0-.437-.135H4.09a.71.71 0 0 0-.442.144 1.126 1.126 0 0 0-.431.882V15c0 .304.253.55.565.55H16.19a.558.558 0 0 0 .565-.55ZM9.196 8.442a1.344 1.344 0 1 0 0-2.688 1.344 1.344 0 0 0 0 2.688ZM4 19a1 1 0 1 0 0 2h16a1 1 0 0 0 0-2H4Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="transparent" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default BumperCars
