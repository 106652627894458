import React from "react";
import { isMobile, whichBrowser } from "src/utils/browser";
import { selectCurrency } from "src/utils/currency";
import { getLanguageField } from "src/utils/language";
import { getCurrentCurrency, getCurrentUnits, setCurrentCurrency, setCurrentUnits } from "src/utils/localStorage";
import { changeStoreSession } from "src/services/session";

import ModalDialog from "src/@components/modal.dialog/modal.dialog.component";
import CTAButton from "src/components/ctabutton/ctabutton";

interface ApplicationContextProviderProps {
  /** current destination */
  destinationId: string;
  /** default currency */
  defaultCurrency: string;
  /** current language */
  language: string;
  /** default language */
  defaultLanguage: string;
  /** is in app mode */
  isInApp: boolean;
  /** currencies  */
  currencies: TCurrency[];
  /** application nodes */
  children?: React.ReactNode;
}

interface ApplicationContextProviderState {
  context: IApplicationContext;
  literals: Record<string, string> | undefined;
}

/** Pre-props initial context: */
const initialContextValues: Partial<IApplicationContext> = {
  currency: undefined,
  defaultCurrency: undefined,
  units: undefined,
  destinationId: process.env.CLIENT,
  translate: () => '-',
  literal: () => '-',
}

/**
 * Initial context.
 */
const ApplicationContext = React.createContext<IApplicationContext>(initialContextValues as any);

/**
 * Application Context Provider
 */
class ApplicationContextProvider extends React.Component<ApplicationContextProviderProps, ApplicationContextProviderState> {

  /** helper functions: */
  public helpers = {
    literal: (key: string) => {
      return (this.state.literals || {})[key] || '-';
    },
    translate: (translations: TTranslations<any> = [], field: string, isHtml: boolean = false, mapHtml: boolean = false, htmlClass?: string) => {
      return getLanguageField(translations, this.props.language, field, isHtml, mapHtml, htmlClass);
    },
  }

  /** state modifiers: */
  public setters = {
    setCurrency: (newCurrency: any, updateStorage = true) => {
      const currentCurrency = getCurrentCurrency();
      const currencies = this.props.currencies;

      let currency = newCurrency;
      if (currency && !currency.code) {
        currency = selectCurrency(currencies, currency);
      }

      const next_context = { ...this.state.context, currency: currency, ...this.setters };
      this.setState({context: next_context});

      if (currency.code !== currentCurrency.code) {
        if (currency.change_store && currency.store !== currentCurrency.store) {
          const next_context = { ...this.state.context, showChangeStoreModal: { open: true, currency: currency }, ...this.setters };
          this.setState({context: next_context});
        } else {
          if (updateStorage) {
            setCurrentCurrency(currency);
          }
        }
      }
    },
    setUnitSystem: (newUnitSystem: TUnits) => {
      const next_context = { ...this.state.context, units: newUnitSystem, ...this.setters };
      setCurrentUnits(newUnitSystem);
      this.setState({context: next_context});
    },
  }

  /** initial state */
  public state: ApplicationContextProviderState = {
    context: {
      ready: false,
      destinationId: this.props.destinationId,
      currency: this.props.defaultCurrency,
      defaultCurrency: this.props.defaultCurrency,
      units: getCurrentUnits(undefined),
      browser: 'unknown',
      isMobile: false,
      isInApp: this.props.isInApp,
      language: this.props.language,
      defaultLanguage: this.props.defaultLanguage,
      showChangeStoreModal: { open: false, currency: {} },
      ...this.setters,
      ...this.helpers,
    },
    literals: undefined
  }

  /** Populate context values with run-time values: */
  async componentDidMount() {
    //load currency from ip
    // let currency = this.props.destinationId === ("6c2d8ff4-a154-426b-96d2-614871d42e5b" || "033dbed8-b5b6-4204-9236-1412d5da15a9") ? await currencyFromIp() : "";
    // check for currency in layout configuration
    // const currencyExistsInConfiguration = (this.props.currencies || []).some(c => c.code === currency);
    // if (!currencyExistsInConfiguration) currency = this.state.context.currency;
    const currency = getCurrentCurrency() || this.state.context.currency;

    React.startTransition(() => {
      this
      .loadLocale()
      .then(() => {
        this.setState({
          context: {
            ...this.state.context,
            currency: currency,
            ready: true,
            browser: whichBrowser(),
            isMobile: isMobile(),
          }
        })
      });
    })
  }

  async componentDidUpdate(prevProps: Readonly<ApplicationContextProviderProps>, prevState: Readonly<ApplicationContextProviderState>, snapshot?: any) {
    const languageChanged = prevProps.language !== this.props.language;
    if (languageChanged) {
      await this.loadLocale();
      this.setState({ context: { ...this.state.context, language: this.props.language }})
    }
  }

  // ugly - yes - dynamic imports not possible.
  async loadLocale () {
    const langCode: string = this.props.language;
    const imports: any = {
      "en-US": async () => await import("./../../src-dynamic/translations.en-US.js"),
      "nb-NO": async () => await import("./../../src-dynamic/translations.nb-NO.js"),
      "no-NO": async () => await import("./../../src-dynamic/translations.nb-NO.js"),
      "es-ES": async () => await import("./../../src-dynamic/translations.es-ES.js"),
      "fr-FR": async () => await import("./../../src-dynamic/translations.fr-FR.js"),
      "it-IT": async () => await import("./../../src-dynamic/translations.it-IT.js"),
      "de-DE": async () => await import("./../../src-dynamic/translations.de-DE.js"),
    }
    try {
      const literal_data = await imports[langCode]();
      this.setState({literals: literal_data});
    } catch (err) {
      try {
        const literal_data = await imports[langCode]();
        this.setState({literals: literal_data});
      } catch (err) {
        try {
          const literal_data = await imports["en-US"]();
          this.setState({literals: literal_data});
        } catch (err) {}
      }
    }
  }

  closeChangeStoreModal() {
    this.setState({ context: { ...this.state.context, showChangeStoreModal: { open: false, currency: {} } }})
  }

  changeStoreAction() {
    const currency = this.state.context.showChangeStoreModal.currency;
    changeStoreSession(currency);
  }

  /** render provider */
  render() {
    return (
      <ApplicationContext.Provider value={this.state.context}>
        {this.props.children}
        {
          this.state.context.showChangeStoreModal.open
          ?
          <ModalDialog
            open={this.state.context.showChangeStoreModal.open}
            alignedCenter
            hideCloseButton
            onClose={this.closeChangeStoreModal}
            title={`Important`}
            size={`small`}
            titleCentered={true}
          >
            <div className="flex flex-col gap-2">
              <p className="text-lg flex">
                By changing the currency, you will lose everything currently in your cart. Do you want to continue?
              </p>
              <div className="flex flex-row items-center gap-4 w-full mt-4">
                <button
                  className="w-full cta-secondary-button pointer-events-auto cursor-pointer buttonText flex justify-center items-center m-0 bg-[color:var(--button-background-color)] text-[color:var(--button-text-color)]"
                  onClick={() => this.closeChangeStoreModal()}
                >
                  No
                </button>
                <button
                  className="w-full cta-button pointer-events-auto cursor-pointer buttonText flex justify-center items-center m-0 bg-[color:var(--button-background-color)] text-[color:var(--button-text-color)]"
                  onClick={() => this.changeStoreAction()}
                >
                  Yes
                </button>
              </div>
            </div>
          </ModalDialog>
          : null
        }
      </ApplicationContext.Provider>
    );
  }
}


export { ApplicationContextProvider as default, ApplicationContextProvider, ApplicationContext }
