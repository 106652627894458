import React from "react";
import Link from "src/components/link";

import FacebookIcon from "./icon.facebook";
import InstagramIcon from "./icon.instagram";
import TwitterIcon from "./icon.twitter";
import YoutubeIcon from "./icon.youtube";
import LinkedInIcon from "./icon.linkedin";
import TikTokIcon from "./icon.tiktok";
import TripadvisorIcon from "./icon.tripadvisor";

type SocialMediaProps = {
    className?: string;
    items: Array<TFooterSocialMediaItem>;
}
export default class SocialMedia extends React.Component<SocialMediaProps> {
    static defaultProps: Partial<SocialMediaProps> = {
        className: ""
    }

    renderItem(item: TFooterSocialMediaItem, index: number) {
        switch (item.footer_social_media_item_id.social_media_type) {
            case "twitter": return <Link key={"linktwi"+index} href={item.footer_social_media_item_id.link} className="text-[color:var(--social-media-icons-color)]" title="Twitter"><TwitterIcon className="w-6 h-6" /></Link>
            case "facebook": return <Link key={"linkfac"+index} href={item.footer_social_media_item_id.link} className="text-[color:var(--social-media-icons-color)]" title="Facebook"><FacebookIcon className="w-6 h-6" /></Link>
            case "instagram": return <Link key={"linkins"+index} href={item.footer_social_media_item_id.link} className="text-[color:var(--social-media-icons-color)]" title="Instagram"><InstagramIcon className="w-6 h-6" /></Link>
            case "linkedin": return <Link key={"linklin"+index} href={item.footer_social_media_item_id.link} className="text-[color:var(--social-media-icons-color)]" title="LinkedIn"><LinkedInIcon className="w-6 h-6" /></Link>
            case "youtube": return <Link key={"linkyou"+index} href={item.footer_social_media_item_id.link} className="text-[color:var(--social-media-icons-color)]" title="YouTube"><YoutubeIcon className="w-6 h-6" /></Link>
            case "tiktok": return <Link key={"linktik"+index} href={item.footer_social_media_item_id.link} className="text-[color:var(--social-media-icons-color)]" title="TikTok"><TikTokIcon className="w-6 h-6" /></Link>
            case "tripadvisor": return <Link key={"linktri"+index} href={item.footer_social_media_item_id.link} className="text-[color:var(--social-media-icons-color)]" title="Tripadvisor"><TripadvisorIcon className="w-6 h-6" /></Link>
            default: return null;
        }
    }

    render() {
        return <>
            <div className={`flex flex-row gap-6 ${this.props.className}`}>
                {this.props.items.map((item, index) => this.renderItem(item, index))}
            </div>
        </>
    }
}