import React from "react";

import ImageV2 from "src/components/image/image.v2";
import CTAButton from "src/components/ctabutton/ctabutton";

import { getLanguageField } from "src/utils/language";
import { getSlug } from "src/utils/slugs";


type FlyoutPromoBoxProps = {
    language: string;
    defaultLanguage: string;
    mobile?: boolean;
    flyoutPromoBox: TFlyoutPromoBox;
    closeNavigation?: () => void;
}

export default class FlyoutPromoBoxV1 extends React.Component<FlyoutPromoBoxProps> {

    static defaultProps: Partial<FlyoutPromoBoxProps> = {
        mobile: false
    }

    public renderImage() {
        const {flyoutPromoBox} = this.props;
        if (!(flyoutPromoBox.image?.imageFile)) return null;

        const roundedStyles = flyoutPromoBox.image_style === 'rounded' ? 'rounded-2xl' : '';
        return <>
            <div className="w-full aspect-[344/200] mb-3">
                <ImageV2
                    style={{ objectPosition: "center center", objectFit: "cover"}}
                    className={`${roundedStyles} w-full h-full`}
                    src={flyoutPromoBox.image.imageFile} alt={''}
                />
            </div>
        </>;
    }

    public getCTALink() {
        const {flyoutPromoBox, language, defaultLanguage} = this.props;
        const linkType = getLanguageField(flyoutPromoBox.translations, language, 'CTA_button_link_type') as string || "external";
        if (linkType === 'external') return getLanguageField(flyoutPromoBox.translations, language, 'CTA_button_link') as string;
        const pageTranslations = (getLanguageField(flyoutPromoBox.translations, language, 'CTA_button_page') as any || {}).translations;
        if (!pageTranslations) return undefined;
        const link = getSlug(language, defaultLanguage, pageTranslations);
        return link;
    }

    public renderCTA() {
        const {flyoutPromoBox, language} = this.props;
        const ctaText = getLanguageField(flyoutPromoBox.translations, language, 'CTA_button_text') as string || "";
        const ctaLink = this.getCTALink();
        const linkType = getLanguageField(flyoutPromoBox.translations, language, 'CTA_button_link_type') as string || "external";

        if (ctaText === "") return null;
        if (!ctaLink) return null;

        if (flyoutPromoBox.cta_button_style === 'primary') {
            return <div className="flex flex-row">
                <CTAButton className="w-full md:w-auto" onClick={this.props.closeNavigation} link={ctaLink} type={flyoutPromoBox.cta_button_style} text={ctaText} bgcolor={flyoutPromoBox.primary_cta_button_color?.value} color={flyoutPromoBox.primary_cta_button_label_color?.value} open_in_same_tab={linkType === 'internal'} />
            </div>
        }
        if (flyoutPromoBox.cta_button_style === 'secondary') {
            return <div className="flex flex-row">
                <CTAButton className="w-full md:w-auto" onClick={this.props.closeNavigation} link={ctaLink} type={flyoutPromoBox.cta_button_style} text={ctaText} border={flyoutPromoBox.secondary_cta_button_outline_color?.value} color={flyoutPromoBox.secondary_cta_button_label_color?.value} open_in_same_tab={linkType === 'internal'} />
            </div>
        }
        if (flyoutPromoBox.cta_button_style === 'soft') {
            return <div className="flex flex-row">
                <CTAButton className="w-full md:w-auto" onClick={this.props.closeNavigation} link={ctaLink} type={flyoutPromoBox.cta_button_style} text={ctaText} color={flyoutPromoBox.soft_button_label_color?.value} open_in_same_tab={linkType === 'internal'} />
            </div>
        }
    }

    public get size() {
        if (this.props.mobile) return 'w-[calc(100%-3rem)]';
        return 'w-[392px] max-w-[100vw] sm:max-w-[392px]';
    }

    public get padding() {
        if (this.props.mobile) return 'm-6';
        return 'p-6 lg:p-1 ml-[2em]';
    }

    private get style(): React.CSSProperties {
        return {
            '--title-color': this.props.flyoutPromoBox.title_color?.value,
            '--text-color': this.props.flyoutPromoBox.body_color?.value,
            '--hyperlink-text-color': this.props.flyoutPromoBox.hyperlink_text_color?.value,
            '--hyperlink-hover-color': this.props.flyoutPromoBox.hyperlink_hover_color?.value,
        } as React.CSSProperties;
    }

    public render() {
        const {flyoutPromoBox, language} = this.props;

        return <div className={`flyoutpromobox ${this.padding} ${this.size}`} style={this.style}>
            {this.renderImage()}
            <div className="headline3 text-[color:var(--title-color)] mb-2 truncate"> {getLanguageField(flyoutPromoBox.translations, language, 'title')} </div>
            {getLanguageField(flyoutPromoBox.translations, language, 'body', true, true, 'generalText line-clamp-3 text-[color:var(--text-color)] mb-4')}
            {this.renderCTA()}
        </div>
    }
}