import * as React from "react"

const RopeToe = (props:any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={25}
    fill="none"
    {...props}
  >
    <g
      stroke="currentColor"
      strokeMiterlimit={10}
      strokeWidth={1.8}
      clipPath="url(#a)"
    >
      <path d="M10.194 3.125c-.273.32-.41.732-.381 1.146.028.414.22.797.533 1.064.312.267.72.396 1.134.36.414-.038.8-.238 1.072-.557.272-.32.41-.731.38-1.146a1.535 1.535 0 0 0-.532-1.064 1.534 1.534 0 0 0-1.135-.359c-.414.037-.799.237-1.071.556Z" />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m4.855 22.267 15.396-4.03s1.838-.683 1.64-2.263"
      />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m22 7.577-8.88 2.207-2.36-1.993a1.06 1.06 0 0 0-1.6.288c-.375.657-.682 2.654-.652 3.27m0 0-6.805 1.742m6.805-1.742c.106 2.214 1.007 3.193 3.113 3.494 0 0 1.5 0 1.5 1.05v3.95"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 .154h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default RopeToe
