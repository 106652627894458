import React, { CSSProperties } from "react";
import { isRunTime } from "src/utils/isRunTime";
import { getLanguageField } from "src/utils/language";
import { convertToHTML } from "src/utils/string";
import { getCurrentToastbarState, isAlreadyClosed, markAsClosed } from "src/modules/toastBar/toastBar.service";

import "./toastBar.css";

type Props = {
  data: {
    selectedLanguage: string;
    item: TToastbar;
    defaultLanguage: string;
  },
  className?: string;
  mobileHeight?: number;
  desktopHeight?: number;
}

type State = {
  visible: boolean;
  online: boolean;
  open: boolean;
  text: string;
  device: "desktop" | "mobile";
  topbarHeight: number;
  text_color: string;
  background_color: string;
}

class ConstructionBanner extends React.Component<Props, State> {

  static DESKTOP_HEIGHT = 80;
  static MOBILE_HEIGHT = 64;
  static MOBILE_WIDTH = 1024;

  get desktopHeight () {
    return this.props.desktopHeight || ConstructionBanner.DESKTOP_HEIGHT;
  }
  get mobileHeight () {
    return this.props.mobileHeight || ConstructionBanner.MOBILE_HEIGHT;
  }

  state: State = {
    visible: this.props.data.item?.visible,
    online: this.props.data.item?.online,
    open: false,
    text: getLanguageField(this.props.data.item.translations, this.props.data.selectedLanguage, "text") as string,
    device: isRunTime() ? (window.innerWidth >= ConstructionBanner.MOBILE_WIDTH ? "desktop" : "mobile") : "mobile",
    topbarHeight: 64,
    text_color: this.props.data.item.text_color?.value || '#282828',
    background_color: this.props.data.item.background_color?.value || '#ffffff',
  }


  onResize = () => {
    if (this.state.device === 'desktop' && window.innerWidth < ConstructionBanner.MOBILE_WIDTH) {
      this.setState({device: "mobile"}, this.handleTopbarHeightChange);
    }
    if (this.state.device === 'mobile' && window.innerWidth >= ConstructionBanner.MOBILE_WIDTH) {
      this.setState({device: 'desktop'}, this.handleTopbarHeightChange);
    }
  }

  handleTopbarHeightChange = () => {
    const topbar = document.getElementById('topbar');
    if(topbar && topbar.clientHeight !== this.state.topbarHeight) this.setState({topbarHeight: topbar.clientHeight });
  }

  fetchOnline = async () => {
    try {
      const [state, text, textcolor, bgcolor] = await getCurrentToastbarState(this.props.data.selectedLanguage, this.props.data.item, this.props.data.defaultLanguage);
      if (state === "hidden") return;
      if (state === 'visible' && text !== '') {
        this.setState({ text, visible: true, text_color: textcolor || this.state.text_color, background_color: bgcolor || this.state.background_color }, this.open);
      }
    } catch (err) {}
  }

  getToastbarFromProps = () => {
    const { visible } = this.props.data.item;
    const { text } = this.state;
    if (visible && text !== "") this.setState({ visible: true}, this.open);
    return;
  }

  componentDidMount() {
    window.addEventListener('resize', this.onResize);
    this.setState({ topbarHeight: this.state.device === "desktop" ? this.desktopHeight : this.mobileHeight})
    this.handleTopbarHeightChange();
    if (this.state.online) this.fetchOnline();
    else this.getToastbarFromProps();
  }

  componentWillUnmount(): void {
    window.removeEventListener('resize', this.onResize);
  }

  open() {
    const text = this.state.text;
    const alreadySeen = isAlreadyClosed(text);
    if (alreadySeen) return;
    this.setState({open: true});
  }

  close() {
    markAsClosed(this.state.text);
    this.setState({open: false});
  }

  getStyle(){
    return {
      '--hyperlink-text-color': this.props.data.item.hyperlink_text_color?.value,
      '--hyperlink-hover-color': this.props.data.item.hyperlink_hover_color?.value,
      transition: "max-height 0.3s",
      maxHeight: this.state.open ? "100px" : "0px",
      color: this.state.text_color,
      backgroundColor: this.state.background_color,
      top: this.state.topbarHeight
    } as CSSProperties;
  }

  render() {
    if (!this.state.visible || !this.state.text) return null;
    return (
      <div
        className={`overflow-hidden sticky z-20 ${this.props.className || ''}`}
        style={this.getStyle()}
      >
        <div className="toastbar max-w-7xl mx-auto flex items-start font-semibold px-6 py-4 lg:px-10 space-x-4 text-sm sm:text-lg">
          <span className="flex-1 text-center toastbarText">
            {convertToHTML(this.state.text)}
          </span>
          <button
            onClick={() => this.close()}
            aria-label="Close banner"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19 5L5 19M5 5L19 19"
                stroke={this.state.text_color || "#fff"}
                strokeWidth="1.8"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </div>
      </div>
    );
  }
}

export default ConstructionBanner;