import * as React from "react"

const BlackDiamond = (props:any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={21}
    fill="none"
    {...props}
  >
    <path
      stroke="#242426"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.8}
      d="m10 1.833 8.217 9.13L10 20.093l-8.217-9.13L10 1.833Z"
      clipRule="evenodd"
    />
  </svg>
)
export default BlackDiamond
