const { translate } = require("./utils.language");

// Sorry for dup-ing code. Cannot mix require() and imports().
// Cause gatsby-node is not TYPESCRIPT.

/**
 * Get correct slug
 * @param {string} language current language
 * @param {string} default_language destination default language
 * @param {TTranslations<{slug: string}>} translations translations array
 * @param {string} translation_key key to slug
 * @returns page path
 */
module.exports.getSlug = function getSlug(
    language,
    default_language,
    translations,
    translation_key = "slug",
) {
    const isDefaultLanguage = language === default_language;
    let prefix = isDefaultLanguage ? '/' : `/${language}/`;
    let slug = translate(translations, language, translation_key);
    let slugp = translate(translations, language, 'slug_prefix');
    const link = prefix + (slugp ? slugp + "/" : "") + slug;
    if (link[0] === "/" && link[1] === "/") return link.replace(/\/+/g, "/");
    return link;
}

module.exports.getEntrySlug = function getEntrySlug(entry, selectedLanguage, defaultLanguage) {
    const { view_mode } = entry;
    let slug_prefix = "";
    let slug = "";
    const language = selectedLanguage !== defaultLanguage ? selectedLanguage : "";
    if (view_mode === "external") return translate(entry.external_link_translations, selectedLanguage, "external_link") || "";
    if (view_mode === "page") {
      slug_prefix = translate(entry.translations, selectedLanguage, "slug_prefix") || "";
      slug = translate(entry.translations, selectedLanguage, "slug") || "";
    }
    else if (view_mode === "internal") {
      slug = translate(entry.internal_link.translations, selectedLanguage, "slug") || "";
    }
    let url = `/${language}/${slug_prefix}/${slug}`.replace(/\/+/g, "/");;
    return url;
}
