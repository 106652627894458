import React from "react";

export default function Star(props: any) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M8.00065 1.33325L10.0607 5.50659L14.6673 6.17992L11.334 9.42659L12.1207 14.0133L8.00065 11.8466L3.88065 14.0133L4.66732 9.42659L1.33398 6.17992L5.94065 5.50659L8.00065 1.33325Z" fill="currentColor" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
}


