import * as React from "react"
const MinHeight = (props:any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={13}
    height={15}
    viewBox="0 0 13 15"
    fill="none"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.3}
      d="M6.636 3v8.5M1.636 1h10M4.636 9.5l2 2 2-2M1.636 14h10"
    />
  </svg>
)
export default MinHeight