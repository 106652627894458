import * as React from "react"
const GreyCircle = (props:any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22}
    height={22}
    viewBox="0 0 22 22"
    fill="none"
    {...props}
  >
    <path fill="#D9D9D9" d="M11 16a5 5 0 1 0 0-10 5 5 0 0 0 0 10Z" />
  </svg>
)
export default GreyCircle
