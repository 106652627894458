import React from "react";

export default class Modal<T extends TCommonModalProps> extends React.Component<T> {

    static defaultProps: Partial<TCommonModalProps> = {
        blockBodyScroll: true,
        onOpen: () => null,
        onClose: () => null,
    }

    componentDidUpdate(prevProps: Readonly<T>, prevState: Readonly<{}>, snapshot?: any): void {
        if (prevProps.open === true && this.props.open === false) this.modalDidClose();
        if (prevProps.open === false && this.props.open === true) this.modalDidOpen();
    }

    modalDidOpen() {
        if (this.props.blockBodyScroll) document.body.style.overflow = 'hidden';
        if (this.props.onOpen) this.props.onOpen();
    }

    modalDidClose() {
        if (this.props.blockBodyScroll) document.body.style.overflow = '';
        if (this.props.onClose) this.props.onClose();
    }

    componentWillUnmount(): void {
        if (this.props.blockBodyScroll) document.body.style.overflow = '';
    }

    render(): React.ReactNode {
        throw new Error("Render must be overriden");
    }
}