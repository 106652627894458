import React from "react";

export default function GBP(props: any) {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <rect width="20" height="20" fill="#004692"/>
        <mask id="mask0_17786_63612" style={{maskType: "alpha"} as any} maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
            <rect width="20" height="20" fill="#004692"/>
        </mask>
        <g mask="url(#mask0_17786_63612)">
            <path d="M12.5215 9.16845L11.7359 9.95405L21.1632 19.3814L21.9488 18.5958L12.5215 9.16845Z" fill="#F5F7F8"/>
            <path d="M10.4265 11.2634L9.11719 12.5728L18.5445 22.0001L19.8538 20.6907L10.4265 11.2634Z" fill="#F5F7F8"/>
            <path d="M10.4265 11.2634L11.7359 9.95405L21.1632 19.3814L19.8538 20.6907L10.4265 11.2634Z" fill="#DD2033"/>
            <path d="M10.8386 12.5711L10.053 11.7854L0.625711 21.2128L1.41132 21.9984L10.8386 12.5711Z" fill="#F5F7F8"/>
            <path d="M8.74368 10.4761L7.43433 9.16675L-1.99299 18.5941L-0.683638 19.9034L8.74368 10.4761Z" fill="#F5F7F8"/>
            <path d="M8.74368 10.4761L10.053 11.7854L0.625711 21.2128L-0.683638 19.9034L8.74368 10.4761Z" fill="#DD2033"/>
            <path d="M7.728 11.2908L8.54172 10.4771L-1.22301 0.712342L-2.03673 1.52607L7.728 11.2908Z" fill="#F5F7F8"/>
            <path d="M9.89794 9.12086L11.2541 7.76465L1.48942 -2.00008L0.133207 -0.64387L9.89794 9.12086Z" fill="#F5F7F8"/>
            <path d="M9.89794 9.12086L8.54172 10.4771L-1.22301 0.712342L0.133207 -0.64387L9.89794 9.12086Z" fill="#DD2033"/>
            <path d="M8.70383 7.76608L9.51755 8.57981L19.2823 -1.18492L18.4686 -1.99865L8.70383 7.76608Z" fill="#F5F7F8"/>
            <path d="M10.8738 9.93602L12.23 11.2922L21.9947 1.52751L20.6385 0.171294L10.8738 9.93602Z" fill="#F5F7F8"/>
            <path d="M10.8738 9.93602L9.51755 8.57981L19.2823 -1.18492L20.6385 0.171294L10.8738 9.93602Z" fill="#DD2033"/>
        </g>
        <path fillRule="evenodd" clipRule="evenodd" d="M8.33333 20H6.94444L6.94445 13.0556H0V11.6667H6.94445L6.94445 8.33333H0V6.94444L6.94445 6.94444L6.94445 0H8.33333V6.94444H11.6667L11.6667 0H13.0556L13.0556 6.94444L20 6.94444V8.33333L13.0556 8.33333V11.6667L20 11.6667V13.0556L13.0556 13.0556L13.0556 20H11.6667L11.6667 13.0556H8.33333V20ZM11.6667 8.33333H8.33333L8.33333 11.6667H11.6667V8.33333Z" fill="#F5F7F8"/>
        <path d="M8.33333 20H11.6667L11.6667 11.6667H20V8.33333L11.6667 8.33333L11.6667 0H8.33333V8.33333H0V11.6667H8.33333V20Z" fill="#DD2033"/>
    </svg>
  );
}
