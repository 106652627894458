import React from "react";

export default function UserRounded(props: any) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M16.492 19.677c-.971.351-1.717.823-4.098.823-2.394 0-3.147-.477-4.114-.83-.567-.206-.97-.695-1.164-1.28l-.587-1.774c-.632-1.909.764-3.886 2.728-3.863l3.138.05 3.098-.05c1.948-.024 3.342 1.924 2.74 3.826l-.566 1.79c-.19.598-.598 1.098-1.175 1.308ZM12.395 9.295c2.096 0 3.795-1.745 3.795-3.898 0-2.152-1.7-3.897-3.796-3.897S8.6 3.245 8.6 5.397c0 2.153 1.7 3.898 3.796 3.898Z"
        stroke="currentColor"
        strokeWidth={1.8}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}