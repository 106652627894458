import React from "react";
import Icon from "src/components/icon";
import { isRunTime } from "src/utils/isRunTime";

type Props = {
  button: TInAppBackButton;
  standard_cta_button: "square" | "full-rounded" | "rounded";
  className?: string;
  hasNavigationBar: boolean;
}

type State = {
  visible:boolean;
  historyStateKey: string;
}

export default class BackToTopButton extends React.Component<Props, State>{

  state = {
    historyStateKey: "",
    visible: false,
  }

  get colors() {
    let colorsStyles = "bg-[color:var(--backgroundColor)] text-[color:var(--iconColor)]";
    return colorsStyles;
  }

  get hidden() {
    if (this.state.visible) return "flex lg:hidden";
    return "hidden";
  }

  get rounded() {
    let { style } = this.props.button;
    if (style === "site-default") style = this.props.standard_cta_button;
    if (style === "full-rounded") return "rounded-full";
    if (style === "rounded") return "rounded-lg";
    return "";
  }

  get topPosition() {
    const { hasNavigationBar } = this.props;
    if (hasNavigationBar) return "top-[95px]"
    return "top-[12px]"
  }

  get shadow() {
    if (this.props.button.shadow) return "shadow-[0px_4px_4px_0px_#00000040]"
    return "";
  }

  get className() {
    return this.props.className || "";
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any): void {
    this.checkVisibility();
  }

  shouldComponentUpdate(nextProps: Readonly<Props>, nextState: Readonly<State>, nextContext: any): boolean {
    this.checkVisibility();
    if(this.state.visible !== nextState.visible) return true;
    return false
  }

  get cssVars(): any {
    const { background_color, icon_color } = this.props.button;
    return {
      '--backgroundColor': background_color?.value,
      '--iconColor': icon_color?.value,
    }
  }

  checkVisibility(){
    const hasHistory = isRunTime() && window.history.length > 1;
    const hasHistoryState = isRunTime() && window.history.state && window.history.state.key;
    const isVisible = hasHistory && hasHistoryState && history.state.key !== this.state.historyStateKey;
    const historyStateKey = this.state.historyStateKey ? this.state.historyStateKey : hasHistoryState ? history.state.key : "";
    if(isVisible && !this.state.visible) this.setState({ visible: true })
    if(!isVisible && this.state.visible) this.setState({ visible: false })
    if(!this.state.historyStateKey && historyStateKey) this.setState({ historyStateKey })
  }

  componentDidMount(): void {
    this.checkVisibility();
  }

  onClick() {
    window.history.back();
  }

  render(): React.ReactNode {
    if (!this.props.button) return null;
    return (
      <>
        <button className={`fixed z-50 ${this.topPosition} left-3 w-10 h-10 items-center justify-center ${this.colors} ${this.rounded} ${this.shadow} ${this.className} ${this.hidden}`} style={this.cssVars} onClick={this.onClick}>
          <Icon icon="ArrowLeft" className="w-5 h-5" />
        </button>
      </>
    )
  }
}