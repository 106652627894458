import * as React from "react"

const InPreparationStatus = (props: any) => (
  <svg
    width={23}
    height={22}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x={0.5} width={22} height={22} rx={11} fill="#E87E00" />
    <path
      d="M6.167 11h10.666"
      stroke="#fff"
      strokeWidth={1.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default InPreparationStatus
