import React from "react";

export default function Avalanche5(props: any) {
  return (
    <svg
    width={64}
    height={48}
    viewBox='0 0 64 48'
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        d="M3.95 28.951a7 7 0 0 1 0-9.9L18.977 4.025a7 7 0 0 1 9.972.073l8.676 8.936 1.606-1.31a4.6 4.6 0 0 1 2.906-1.034 4.6 4.6 0 0 0 2.601-.806l.314-.215a5.827 5.827 0 0 1 2.854-1.004l1.059-.08a4.757 4.757 0 0 1 2.799.66l.258.154c.459.273.847.649 1.136 1.097l.02.031a3.381 3.381 0 0 0 2.224 1.492 3.382 3.382 0 0 1 2.573 2.208l.37 1.055c.051.147.115.288.191.423l.073.13c.154.274.371.509.633.685.302.203.543.484.699.813l.293.622c1.697 4.44-1.582 9.203-6.336 9.203h-8.46L28.948 43.959a7 7 0 0 1-9.945.047L3.95 28.95Z"
        fill="#fff"
      />
      <path
        d="M5.828 26.83a4 4 0 0 1 0-5.657L21.182 5.819a4 4 0 0 1 5.646-.01l9.828 9.755H39l.167-.298a4 4 0 0 1 4.445-1.928l1.17.288.166-.302c1.458-2.648 5.215-2.783 6.86-.246l.598.923.796-.122a2.329 2.329 0 0 1 2.663 2.007 2.329 2.329 0 0 0 1.018 1.642l.195.13a3.122 3.122 0 0 1 1.172 2.438v.485a4 4 0 0 1-2.184 3.564l-.273.14a4 4 0 0 1-1.816.435H43.828c.03.111.021.23-.027.334l-.073.162a.989.989 0 0 1-.156.243l-.228.261-16.516 16.462a4 4 0 0 1-5.652-.005L5.828 26.83Z"
        fill="#2B2B2B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m40.811 24.755-4.603-.003a.968.968 0 0 1-.554 1.764.969.969 0 0 1-.553-1.765l-4.727-.003a.968.968 0 1 1-1.096 0l-4.738-.004a.968.968 0 1 1-1.085 0l-4.749-.003a.968.968 0 1 1-1.074 0l-4.76-.004a.968.968 0 0 1-.532 1.779.969.969 0 0 1-.53-1.78l-4.772-.003a.968.968 0 0 1 .095 1.557l1.558 1.549a.969.969 0 1 1 1.37 1.362l1.519 1.51a.969.969 0 1 1 1.365 1.357l1.528 1.517a.968.968 0 0 1 1.75.572.967.967 0 0 1-.392.779l1.553 1.543a.968.968 0 0 1 1.753.569c0 .32-.155.604-.395.78l1.588 1.579a.969.969 0 1 1 1.367 1.359l.757.752c.184.183.395.323.621.42a.97.97 0 0 1 1.8-.112c.116-.068.225-.148.328-.24l.094-.085.917-.916a.969.969 0 0 1 1.353-1.353l1.538-1.537a.969.969 0 0 1 1.368-1.368l1.536-1.534a.969.969 0 0 1 1.367-1.366l1.513-1.513a.969.969 0 0 1 1.36-1.36l1.462-1.46a.969.969 0 0 1 1.31-1.309l1.715-1.715a.965.965 0 0 1 .075-1.315Zm-35.245-.023h.42a.974.974 0 0 0-.221.198l-.2-.198Zm10.657 3.91a.969.969 0 1 1-1.937 0 .969.969 0 0 1 1.937 0Zm16.517.968a.969.969 0 1 0 0-1.937.969.969 0 0 0 0 1.937Zm-1.946 1.71a.969.969 0 1 1-1.937 0 .969.969 0 0 1 1.937 0Zm-12.625.96a.969.969 0 1 0 0-1.938.969.969 0 0 0 0 1.937Zm6.797 4.768a.969.969 0 1 1-1.937 0 .969.969 0 0 1 1.937 0Z"
        fill="#D0021B"
      />
      <path
        d="M34.375 23.345 30 18.955l-5.5-5.531-.123-.107c-.736-.633-1.741.368-1.111 1.106l1.015 1.078-.86.922-2.807-2.043a1 1 0 0 0-1.08-.062l-10.94 6.183L22.626 6.478l.078-.07a2 2 0 0 1 2.755.074l10.635 10.676h2.922c.31 0 .599-.161.762-.426l.196-.316c.253-.41.681-.68 1.16-.732l3.554-.387a.54.54 0 0 1 .27.04c.15.064.32.056.465-.021l.045-.025a.647.647 0 0 0 .173-.136l.063-.069a.474.474 0 0 0 .11-.207l.026-.114a.136.136 0 0 1 .049-.075l.158-.125a.039.039 0 0 1 .062.03c0 .038.049.053.07.022l.203-.295a.15.15 0 0 1 .017-.02l.26-.265a2 2 0 0 1 1.123-.575l.029-.005c.218-.033.44-.03.658.009l1.56.28c.458.082.86.357 1.103.755.297.485.824.78 1.392.78h.096c.892.001 1.65.657 1.784 1.539.071.474.328.903.714 1.188l.913.674a2 2 0 0 1 .813 1.609v.796c0 .13-.013.259-.038.386l-.051.259a2 2 0 0 1-1.962 1.613H34.375Z"
        fill="#fff"
      />
      <ellipse cx={42.859} cy={17.07} rx={2.969} ry={2.492} fill="#fff" />
      <ellipse cx={48.516} cy={15.07} rx={2.688} ry={2.492} fill="#fff" />
      <ellipse cx={21} cy={28.319} rx={1.126} ry={1.13} fill="#1E2127" />
      <path
        fill="#1E2127"
        d="m21.775 27.501 6.028 6.02-1.585 1.599-6.028-6.02z"
      />
      <ellipse
        cx={21.006}
        cy={34.315}
        rx={1.126}
        ry={1.13}
        transform="rotate(-90 21.006 34.315)"
        fill="#1E2127"
      />
      <path
        d="M26.202 27.52a1.133 1.133 0 0 1 1.596-.004 1.118 1.118 0 0 1-.003 1.59l-.796-.793-.797-.794Z"
        fill="#1E2127"
      />
      <path
        fill="#1E2127"
        d="m20.188 33.54 6.02-6.028 1.599 1.586-6.02 6.028zM27.796 33.515a1.135 1.135 0 0 1 .246 1.23 1.12 1.12 0 0 1-1.833.363l.794-.796.793-.797Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m27.951 10.064-.002.002a.828.828 0 1 0-1.06 1.273l9.587 9.678a.84.84 0 0 0 .106.108l.014.014.002-.002a.836.836 0 0 0 .525.184c.46 0 .835-.37.835-.827 0-.264-.126-.5-.322-.652l-9.685-9.778Zm14.642 6.316a.626.626 0 0 0-.608.477l-.02.078a.783.783 0 0 0 .013.418l.006.018a.723.723 0 0 0 .69.509h2.408l.566-.01c1.5-.023 2.174 1.87.997 2.799l-.059.047a.653.653 0 0 0 .003 1.028l.114.089a1.09 1.09 0 0 0 1.137-.286l.042-.045c.402-.428.661-.971.741-1.553l.018-.131c.02-.149.02-.3 0-.448l-.112-.79a1.557 1.557 0 0 0-.11-.396l-.022-.05a2.997 2.997 0 0 0-1.898-1.692 1.502 1.502 0 0 0-.429-.062h-3.477Zm-2.28 2.374c-.654.237-.611 1.176.062 1.353l.08.02a2 2 0 0 1 1.386 1.239l.096.257c.256.67 1.205.667 1.469 0l.07-.282.015-.132a1.533 1.533 0 0 0-.05-.598l-.035-.124-.061-.152a2.003 2.003 0 0 0-.184-.352l-.068-.103-.193-.248a2 2 0 0 0-.583-.508l-.24-.138-.116-.063a2 2 0 0 0-.36-.151l-.128-.04-.172-.028a1.999 1.999 0 0 0-.324-.027h-.154c-.142 0-.284.017-.422.049l-.026.006-.061.022Zm-15.33-5.713a.826.826 0 0 1 .514-1.478c.209 0 .4.078.546.206l.002-.002 9.685 9.779a.822.822 0 0 1 .322.652.83.83 0 0 1-.835.826.836.836 0 0 1-.526-.184l-.001.002-.014-.014a.833.833 0 0 1-.106-.108l-9.586-9.678Z"
        fill="#353535"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h64v48H0z" />
      </clipPath>
    </defs>
  </svg>

  )
}