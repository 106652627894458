import * as React from "react"
const Transfer = (props:any) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={87} height={86} viewBox="0 0 87 86" {...props}>
    <g fill="none" fillRule="evenodd">
      <rect
        width={85.86}
        height={85.86}
        x={0.99}
        fill="#FFF"
        fillRule="nonzero"
        rx={12}
      />
      <rect
        width={80.78}
        height={80.78}
        x={3.53}
        y={2.54}
        fill="#1168B2"
        fillRule="nonzero"
        rx={12}
      />
      <path
        fill="#FFF"
        fillRule="nonzero"
        d="M32.37 50.89c-.09-1.22-.39-5-.71-9h13.8v-3.74H31.37l-.61-7.72h-9v3.71h5.61c.23 2.88 1.61 20.45 1.61 20.45h21.24v-3.7H32.37Z"
      />
      <path
        fill="#FFF"
        fillRule="nonzero"
        d="M46.66 57.33v.85a13.6 13.6 0 1 1-21.19-11.26l-.38-4.08A17.31 17.31 0 1 0 50.4 58.18v-.85h-3.74ZM60 21.88a5.75 5.75 0 1 0 .02-11.5 5.75 5.75 0 0 0-.02 11.5"
      />
      <path
        fill="#FFF"
        fillRule="nonzero"
        d="M69.12 51.91a3.9 3.9 0 0 0-1.54-3.11l-8.67-6.56c.82-3.56 4.18-14.07 4.18-14.07a4.5 4.5 0 0 0-5.85-5.11l-12.36 3.26a4 4 0 0 0-1.82 1.81l-3.35 8a1.943 1.943 0 1 0 3.58 1.51l3-7c.71-.16 3.33-.7 6.12-1.27L50 42.2c-.39 1.7.19 3.21 2.19 4.72l8.28 6.27-6.81 16.44a3.79 3.79 0 0 0-.31 1.49 3.9 3.9 0 0 0 2.4 3.6c.376.169.789.24 1.2.21h8A1.965 1.965 0 0 0 65 71h-5l8.79-17.65a3.84 3.84 0 0 0 .3-1.48M50.39 15.32l-5.88-4.82v2.88c-5.95.29-11.12 3.68-12.74 8.45l3.76 1.28c1.08-3.2 4.71-5.5 9-5.77v2.8l5.86-4.82Z"
      />
      <path d="M8.52 7.16h70.81V78.7H8.52z" />
    </g>
  </svg>
)
export default Transfer
