/**
 * Date from ISO string.
 * @param dateISO date in iso format
 * @returns JS Date
 */
export default function readDateFromISOstring(dateISO: string | Date): Date {
  const _dateISO: string = dateISO.toString();
  return new Date(_dateISO);
}

