import * as React from "react"
const Parking = (props:any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={17}
    height={17}
    viewBox="0 0 17 17"
    fill="none"
    {...props}
  >
    <path
      stroke="#242426"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M6.047 8.696h2.889c.982 0 1.777-.79 1.777-1.765 0-.974-.795-1.764-1.777-1.764h-2.89v3.529Zm0 0v3.137"
    />
    <path
      stroke="#242426"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.8}
      d="M1.38 3.833a2 2 0 0 1 2-2h9.334a2 2 0 0 1 2 2v9.334a2 2 0 0 1-2 2H3.381a2 2 0 0 1-2-2V3.833Z"
      clipRule="evenodd"
    />
  </svg>
)
export default Parking;