
import React from "react";

export default function Cart(props: any) {
    return (
        <svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M2 2.375H3.3536C4.26274 2.375 5.04991 2.98537 5.24713 3.84326L5.87945 6.59375M5.87945 6.59375L7.38479 14.5969C7.63634 15.9342 8.84129 16.9062 10.2475 16.9062H17.6199C18.9784 16.9062 20.1561 15.9976 20.4572 14.7171L21.9632 8.31165C22.1701 7.43167 21.4782 6.59375 20.5446 6.59375H5.87945Z" stroke="currentColor" strokeWidth="1.8" strokeLinecap="round"/>
            <path d="M9.33594 20.1875C9.33594 20.7053 8.88822 21.125 8.33594 21.125C7.78365 21.125 7.33594 20.7053 7.33594 20.1875C7.33594 19.6697 7.78365 19.25 8.33594 19.25C8.88822 19.25 9.33594 19.6697 9.33594 20.1875Z" fill="currentColor" stroke="currentColor" strokeWidth="1.8"/>
            <path d="M20.9727 20.1875C20.9727 20.7053 20.5249 21.125 19.9727 21.125C19.4204 21.125 18.9727 20.7053 18.9727 20.1875C18.9727 19.6697 19.4204 19.25 19.9727 19.25C20.5249 19.25 20.9727 19.6697 20.9727 20.1875Z" fill="currentColor" stroke="currentColor" strokeWidth="1.8"/>
        </svg>
    );
}



