import * as React from "react"

const Black = (props: any) => (
  <svg
    width={22}
    height={22}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m11 1.833 8.217 9.13L11 20.093l-8.217-9.13L11 1.833Z"
      stroke="#242426"
      strokeWidth={1.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default Black
