import * as React from "react"

const Bed = (props:any) => (
    <svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_2019_6432)">
    <rect x="14.9629" y="10.396" width="5.20833" height="4.16667" rx="1.04167" stroke="currentColor" strokeWidth="1.4"/>
    <rect x="6.62939" y="10.396" width="5.20833" height="4.16667" rx="1.04167" stroke="currentColor" strokeWidth="1.4"/>
    <path d="M4.02539 7.27083C4.02539 6.12024 4.95813 5.1875 6.10872 5.1875H20.6921C21.8426 5.1875 22.7754 6.12024 22.7754 7.27083V14.5625H4.02539V7.27083Z" stroke="currentColor" strokeWidth="1.4"/>
    <path d="M2.98389 16.6458C2.98389 15.4952 3.91663 14.5625 5.06722 14.5625H21.7339C22.8845 14.5625 23.8172 15.4952 23.8172 16.6458V19.7708H2.98389V16.6458Z" stroke="currentColor" strokeWidth="1.4"/>
    <path d="M23.8169 19.771V21.8543" stroke="currentColor" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M2.98389 19.771V21.8543" stroke="currentColor" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
    <defs>
    <clipPath id="clip0_2019_6432">
    <rect width="26" height="26" fill="white" transform="translate(0.400391)"/>
    </clipPath>
    </defs>
    </svg>
)

export default Bed
