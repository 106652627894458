const React = require("react");
const Slice = require("gatsby").Slice;

function preferDefault(m) { return m && m.default || m; };

module.exports = function getWrapPageElementComponent(props) {
    const HTMLTemplate = preferDefault(require('src/@layouts/index'));
    
    // Has't layout in pageContext:
    const hasStandardLayout = props.pageContext && props.pageContext.layout;
    if (!hasStandardLayout) return (element, props) => <>
        {/* <Slice alias="before-body-contents" /> */}
        <HTMLTemplate {...props}>
            {element}
        </HTMLTemplate>
        {/* <Slice alias="post-body-contents" /> */}
        </>
    
    const { pageContext } = props;
    const layout = pageContext?.layout;
    const identifier = layout
        ? typeof layout === 'string'
            ? layout
            : layout.identifier
          : 'empty';
    
    
    let LayoutTemplate = (props) => props.children;
    if (!identifier || layout && layout.navigation_bar) LayoutTemplate = preferDefault(require('src/layouts/standard'));

    return (element, props) => <>
        {/* <Slice alias="before-body-contents" /> */}
        <HTMLTemplate {...props}>
            <LayoutTemplate {...props} language={pageContext.language} defaultLanguage={pageContext.defaultLanguage}>
                {element}
            </LayoutTemplate>
        </HTMLTemplate>
        {/* <Slice alias="post-body-contents" /> */}
    </>;
}