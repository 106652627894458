import React from "react";

export default class AppStoreBadge extends React.Component {
    render(): React.ReactNode {
        return <>
        <svg id="appstorebadge" width="123" height="40" viewBox="0 0 123 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <style dangerouslySetInnerHTML={{__html: `
                #appstorebadge .text { fill: var(--app-store-badges-text-color) }
                #appstorebadge .bg { fill: var(--app-store-badges-background-color) }
            `}} />
            <path className="bg" d="M7.91783 39.9979C7.59923 39.9979 7.28832 39.9938 6.97222 39.9867C6.31738 39.9782 5.66408 39.9212 5.01766 39.8162C4.41491 39.7124 3.83101 39.5193 3.28521 39.2432C2.74442 38.9695 2.25118 38.6106 1.82438 38.1802C1.39139 37.7549 1.03092 37.2616 0.757231 36.7199C0.480528 36.1746 0.289034 35.5902 0.189418 34.9869C0.0818408 34.3387 0.0236374 33.6833 0.015309 33.0262C0.00867932 32.8057 0 32.0714 0 32.0714V7.91524C0 7.91524 0.00924401 7.19223 0.0153613 6.97982C0.0233356 6.32383 0.0812007 5.66941 0.188456 5.0222C0.288256 4.41729 0.479899 3.83117 0.75675 3.28415C1.02943 2.7428 1.38791 2.24915 1.81828 1.82232C2.24817 1.39143 2.74299 1.03064 3.2847 0.753121C3.82924 0.477981 4.41204 0.286241 5.01357 0.184325C5.66211 0.0782559 6.31768 0.0209099 6.97479 0.012768L7.91834 0H115.383L116.338 0.0132804C116.989 0.0210177 117.639 0.0778521 118.281 0.1833C118.889 0.286495 119.478 0.479573 120.029 0.756185C121.114 1.31547 121.997 2.20032 122.554 3.2867C122.826 3.82995 123.015 4.41128 123.114 5.01097C123.222 5.66349 123.283 6.32306 123.295 6.98442C123.298 7.28056 123.298 7.59866 123.298 7.91524C123.307 8.30737 123.307 8.68061 123.307 9.05692V30.942C123.307 31.3219 123.307 31.6926 123.298 32.0663C123.298 32.4064 123.298 32.7179 123.294 33.0385C123.282 33.688 123.222 34.3358 123.116 34.9767C123.018 35.5843 122.827 36.1733 122.551 36.723C122.275 37.2588 121.917 37.7478 121.489 38.172C121.062 38.6047 120.568 38.9657 120.025 39.2412C119.476 39.5194 118.888 39.7132 118.281 39.8162C117.635 39.9217 116.982 39.9788 116.327 39.9867C116.02 39.9938 115.7 39.9979 115.388 39.9979L114.255 40L7.91783 39.9979Z" fill="#7B61FF"/>
            <path className="text" d="M25.2266 19.8833C25.2383 18.9756 25.4793 18.0857 25.9274 17.2963C26.3754 16.5069 27.0159 15.8436 27.7891 15.3682C27.2979 14.6667 26.6499 14.0893 25.8965 13.682C25.1431 13.2747 24.3051 13.0487 23.4491 13.0219C21.623 12.8303 19.8528 14.1146 18.9223 14.1146C17.9737 14.1146 16.5411 13.041 14.9983 13.0727C14.0004 13.105 13.0279 13.3951 12.1755 13.915C11.3231 14.4348 10.6199 15.1666 10.1344 16.039C8.03138 19.6802 9.60004 25.0314 11.6146 27.9746C12.6225 29.4158 13.8005 31.0257 15.3418 30.9686C16.8502 30.906 17.4135 30.0068 19.2341 30.0068C21.0379 30.0068 21.5664 30.9686 23.1391 30.9323C24.7576 30.9061 25.7773 29.4847 26.7498 28.0298C27.474 27.0029 28.0313 25.868 28.401 24.667C27.4607 24.2693 26.6583 23.6036 26.0938 22.7529C25.5293 21.9022 25.2277 20.9042 25.2266 19.8833Z" fill="white"/>
            <path className="text" d="M22.2559 11.0863C23.1384 10.0269 23.5732 8.66527 23.4679 7.29052C22.1197 7.43212 20.8743 8.07648 19.9799 9.0952C19.5426 9.59288 19.2077 10.1719 18.9943 10.799C18.7809 11.4262 18.6932 12.0893 18.7362 12.7504C19.4106 12.7574 20.0777 12.6112 20.6874 12.323C21.297 12.0347 21.8334 11.6119 22.2559 11.0863Z" fill="white"/>
            <path className="text" d="M43.3218 27.4649H38.3721L37.1834 30.9748H35.0869L39.7752 17.9893H41.9534L46.6417 30.9748H44.5094L43.3218 27.4649ZM38.8847 25.8453H42.8082L40.874 20.1491H40.8199L38.8847 25.8453Z" fill="white"/>
            <path className="text" d="M56.7667 26.2415C56.7667 29.1836 55.192 31.0738 52.8157 31.0738C52.2138 31.1053 51.6151 30.9666 51.0883 30.6737C50.5615 30.3807 50.1278 29.9454 49.8369 29.4174H49.792V34.1067H47.8486V21.5073H49.7297V23.082H49.7654C50.0697 22.5566 50.5107 22.1234 51.0415 21.8287C51.5723 21.534 52.1731 21.3887 52.7799 21.4082C55.1828 21.4083 56.7667 23.3077 56.7667 26.2415ZM54.7693 26.2415C54.7693 24.3248 53.7787 23.0646 52.2673 23.0646C50.7825 23.0646 49.7838 24.3513 49.7838 26.2415C49.7838 28.1491 50.7825 29.4266 52.2673 29.4266C53.7787 29.4266 54.7693 28.1757 54.7693 26.2415Z" fill="white"/>
            <path className="text" d="M67.1871 26.2415C67.1871 29.1836 65.6119 31.0738 63.2356 31.0738C62.6337 31.1053 62.035 30.9666 61.5082 30.6737C60.9814 30.3807 60.5477 29.9454 60.2568 29.4174H60.2119V34.1067H58.2686V21.5073H60.1496V23.082H60.1853C60.4896 22.5566 60.9306 22.1234 61.4614 21.8287C61.9922 21.534 62.593 21.3887 63.1999 21.4082C65.6028 21.4083 67.1871 23.3077 67.1871 26.2415ZM65.1892 26.2415C65.1892 24.3248 64.1986 23.0646 62.6873 23.0646C61.2025 23.0646 60.2037 24.3513 60.2037 26.2415C60.2037 28.1491 61.2025 29.4266 62.6873 29.4266C64.1986 29.4266 65.1892 28.1757 65.1892 26.2415Z" fill="white"/>
            <path className="text" d="M74.0733 27.3567C74.2173 28.6444 75.4683 29.4899 77.1777 29.4899C78.8157 29.4899 79.9942 28.6444 79.9942 27.4833C79.9942 26.4753 79.2834 25.8718 77.6005 25.4582L75.9176 25.0528C73.5331 24.4769 72.4261 23.3617 72.4261 21.5522C72.4261 19.3117 74.3787 17.7728 77.1502 17.7728C79.8951 17.7728 81.7761 19.3117 81.8395 21.5522H79.8777C79.7603 20.2563 78.6891 19.4741 77.1236 19.4741C75.5581 19.4741 74.4869 20.2655 74.4869 21.4174C74.4869 22.3354 75.1711 22.8757 76.8448 23.2892L78.2755 23.6405C80.9398 24.2706 82.0457 25.3408 82.0457 27.2402C82.0457 29.6696 80.1116 31.1911 77.0337 31.1911C74.1539 31.1911 72.2096 29.7053 72.084 27.3566L74.0733 27.3567Z" fill="white"/>
            <path className="text" d="M86.2412 19.2668V21.5073H88.0415V23.0462H86.2412V28.2655C86.2412 29.0764 86.6017 29.4542 87.3931 29.4542C87.6068 29.4505 87.8202 29.4355 88.0323 29.4092V30.939C87.6765 31.0055 87.3149 31.0356 86.9529 31.0288C85.0362 31.0288 84.2887 30.3089 84.2887 28.4728V23.0462H82.9121V21.5073H84.2886V19.2668H86.2412Z" fill="white"/>
            <path className="text" d="M89.084 26.2415C89.084 23.2627 90.8384 21.3909 93.5741 21.3909C96.3191 21.3909 98.0653 23.2627 98.0653 26.2415C98.0653 29.2285 96.3283 31.0922 93.5741 31.0922C90.821 31.0922 89.084 29.2285 89.084 26.2415ZM96.0852 26.2415C96.0852 24.1981 95.1488 22.9921 93.5741 22.9921C91.9995 22.9921 91.063 24.2073 91.063 26.2415C91.063 28.2931 91.9995 29.4899 93.5741 29.4899C95.1488 29.4899 96.0852 28.2931 96.0852 26.2415Z" fill="white"/>
            <path className="text" d="M99.667 21.5073H101.52V23.1187H101.565C101.691 22.6154 101.986 22.1707 102.4 21.8592C102.815 21.5477 103.324 21.3884 103.843 21.4082C104.067 21.4075 104.29 21.4318 104.508 21.4807V23.2985C104.226 23.2121 103.931 23.1724 103.635 23.181C103.353 23.1696 103.072 23.2193 102.81 23.3269C102.549 23.4345 102.314 23.5974 102.122 23.8043C101.929 24.0112 101.784 24.2574 101.696 24.5258C101.608 24.7942 101.578 25.0785 101.61 25.3592V30.9747H99.667L99.667 21.5073Z" fill="white"/>
            <path className="text" d="M113.469 28.194C113.207 29.9127 111.534 31.0922 109.392 31.0922C106.638 31.0922 104.929 29.2469 104.929 26.2865C104.929 23.3168 106.647 21.3909 109.311 21.3909C111.93 21.3909 113.577 23.1902 113.577 26.0608V26.7266H106.89V26.844C106.86 27.1925 106.903 27.5435 107.018 27.8737C107.134 28.204 107.318 28.506 107.559 28.7596C107.8 29.0133 108.092 29.2127 108.416 29.3448C108.739 29.4769 109.088 29.5385 109.437 29.5257C109.896 29.5687 110.357 29.4623 110.751 29.2224C111.145 28.9825 111.451 28.6218 111.624 28.194L113.469 28.194ZM106.9 25.3684H111.633C111.65 25.0551 111.603 24.7416 111.493 24.4476C111.384 24.1535 111.215 23.8852 110.997 23.6596C110.779 23.434 110.516 23.2559 110.226 23.1364C109.936 23.017 109.624 22.9588 109.311 22.9656C108.994 22.9637 108.68 23.0245 108.388 23.1445C108.095 23.2644 107.829 23.4412 107.604 23.6646C107.38 23.888 107.203 24.1536 107.082 24.446C106.961 24.7385 106.899 25.0519 106.9 25.3684Z" fill="white"/>
            <path className="text" d="M38.6424 8.215C39.0498 8.18577 39.4587 8.24732 39.8394 8.39521C40.2202 8.54311 40.5634 8.77366 40.8442 9.07025C41.1251 9.36684 41.3366 9.72206 41.4636 10.1103C41.5905 10.4985 41.6297 10.9101 41.5783 11.3153C41.5783 13.3087 40.501 14.4545 38.6424 14.4545H36.3887V8.215H38.6424ZM37.3578 13.5721H38.5342C38.8253 13.5895 39.1167 13.5418 39.387 13.4324C39.6574 13.323 39.8999 13.1547 40.097 12.9397C40.2941 12.7248 40.4408 12.4686 40.5264 12.1898C40.612 11.911 40.6344 11.6166 40.5919 11.328C40.6313 11.0407 40.6067 10.7481 40.5198 10.4713C40.4328 10.1946 40.2858 9.94047 40.0891 9.72722C39.8925 9.51397 39.6511 9.34686 39.3823 9.23786C39.1134 9.12886 38.8238 9.08067 38.5342 9.09675H37.3578V13.5721Z" fill="white"/>
            <path className="text" d="M42.6736 12.098C42.644 11.7886 42.6794 11.4764 42.7775 11.1814C42.8756 10.8865 43.0343 10.6153 43.2434 10.3852C43.4525 10.1552 43.7073 9.9714 43.9916 9.84563C44.2759 9.71986 44.5833 9.65489 44.8942 9.65489C45.205 9.65489 45.5124 9.71986 45.7967 9.84563C46.081 9.9714 46.3358 10.1552 46.5449 10.3852C46.754 10.6153 46.9127 10.8865 47.0108 11.1814C47.1089 11.4764 47.1443 11.7886 47.1147 12.098C47.1449 12.4078 47.1099 12.7204 47.0121 13.0159C46.9142 13.3113 46.7556 13.583 46.5465 13.8135C46.3373 14.044 46.0823 14.2282 45.7977 14.3542C45.5132 14.4802 45.2054 14.5454 44.8942 14.5454C44.5829 14.5454 44.2752 14.4802 43.9906 14.3542C43.706 14.2282 43.451 14.044 43.2419 13.8135C43.0327 13.583 42.8741 13.3113 42.7763 13.0159C42.6784 12.7204 42.6434 12.4078 42.6736 12.098ZM46.1589 12.098C46.1589 11.0774 45.7004 10.4805 44.8957 10.4805C44.0879 10.4805 43.6335 11.0774 43.6335 12.098C43.6335 13.1269 44.088 13.7192 44.8957 13.7192C45.7004 13.7192 46.1589 13.1228 46.1589 12.098Z" fill="white"/>
            <path className="text" d="M53.0177 14.4544H52.0537L51.0805 10.9865H51.007L50.0379 14.4544H49.0831L47.7852 9.74572H48.7277L49.5712 13.3387H49.6407L50.6088 9.74572H51.5002L52.4683 13.3387H52.5419L53.3813 9.74572H54.3105L53.0177 14.4544Z" fill="white"/>
            <path className="text" d="M55.4024 9.74572H56.2969V10.4937H56.3663C56.4842 10.2251 56.6828 9.99986 56.9347 9.84948C57.1866 9.6991 57.4791 9.63106 57.7715 9.65482C58.0006 9.6376 58.2307 9.67214 58.4446 9.7559C58.6586 9.83966 58.8509 9.97048 59.0074 10.1387C59.164 10.3069 59.2806 10.5081 59.3488 10.7275C59.417 10.9469 59.4349 11.1789 59.4013 11.4062V14.4544H58.472V11.6395C58.472 10.8828 58.1432 10.5065 57.456 10.5065C57.3004 10.4993 57.1451 10.5257 57.0008 10.5841C56.8564 10.6425 56.7264 10.7314 56.6196 10.8447C56.5128 10.9581 56.4317 11.0932 56.382 11.2407C56.3323 11.3883 56.3151 11.5449 56.3316 11.6998V14.4544H55.4023L55.4024 9.74572Z" fill="white"/>
            <path className="text" d="M60.8818 7.90757H61.8111V14.4544H60.8818V7.90757Z" fill="white"/>
            <path className="text" d="M63.1033 12.098C63.0737 11.7886 63.1091 11.4763 63.2072 11.1814C63.3054 10.8864 63.4641 10.6152 63.6732 10.3852C63.8823 10.1551 64.1372 9.97132 64.4215 9.84554C64.7058 9.71977 65.0132 9.6548 65.3241 9.6548C65.635 9.6548 65.9424 9.71977 66.2267 9.84554C66.511 9.97132 66.7659 10.1551 66.975 10.3852C67.1841 10.6152 67.3428 10.8864 67.4409 11.1814C67.5391 11.4763 67.5745 11.7886 67.5449 12.098C67.575 12.4078 67.54 12.7205 67.4421 13.0159C67.3442 13.3113 67.1856 13.583 66.9765 13.8135C66.7673 14.044 66.5122 14.2281 66.2277 14.3542C65.9431 14.4802 65.6353 14.5453 65.3241 14.5453C65.0129 14.5453 64.7051 14.4802 64.4205 14.3542C64.1359 14.2281 63.8809 14.044 63.6717 13.8135C63.4626 13.583 63.3039 13.3113 63.206 13.0159C63.1081 12.7205 63.0731 12.4078 63.1033 12.098ZM66.5886 12.098C66.5886 11.0774 66.13 10.4805 65.3254 10.4805C64.5176 10.4805 64.0632 11.0774 64.0632 12.098C64.0632 13.1269 64.5176 13.7192 65.3254 13.7192C66.1301 13.7192 66.5886 13.1228 66.5886 12.098Z" fill="white"/>
            <path className="text" d="M68.5225 13.1228C68.5225 12.2752 69.1536 11.7866 70.2738 11.7171L71.5493 11.6436V11.2372C71.5493 10.7399 71.2204 10.459 70.5853 10.459C70.0665 10.459 69.707 10.6495 69.6039 10.9824H68.7042C68.7992 10.1736 69.56 9.65485 70.6282 9.65485C71.8086 9.65485 72.4745 10.2425 72.4745 11.2372V14.4544H71.5799V13.7927H71.5064C71.3571 14.0301 71.1476 14.2236 70.8991 14.3535C70.6506 14.4833 70.3721 14.545 70.092 14.532C69.8944 14.5526 69.6946 14.5315 69.5055 14.4701C69.3165 14.4087 69.1425 14.3084 68.9946 14.1756C68.8467 14.0429 68.7283 13.8806 68.647 13.6992C68.5657 13.5179 68.5232 13.3215 68.5225 13.1228ZM71.5493 12.7204V12.3268L70.3994 12.4003C69.7509 12.4437 69.4568 12.6643 69.4568 13.0794C69.4568 13.5032 69.8245 13.7498 70.33 13.7498C70.4781 13.7648 70.6277 13.7498 70.77 13.7058C70.9122 13.6618 71.0441 13.5896 71.1578 13.4936C71.2716 13.3976 71.3649 13.2796 71.4321 13.1468C71.4994 13.014 71.5392 12.869 71.5493 12.7204Z" fill="white"/>
            <path className="text" d="M73.6953 12.098C73.6953 10.6102 74.4602 9.6676 75.6499 9.6676C75.9441 9.65404 76.2362 9.72453 76.4919 9.87081C76.7476 10.0171 76.9564 10.2331 77.0938 10.4937H77.1633V7.90757H78.0925V14.4544H77.2021V13.7105H77.1286C76.9805 13.9693 76.7644 14.1826 76.5038 14.3274C76.2431 14.4722 75.9478 14.543 75.6499 14.532C74.452 14.5321 73.6953 13.5895 73.6953 12.098ZM74.6553 12.098C74.6553 13.0968 75.126 13.6977 75.9134 13.6977C76.6966 13.6977 77.1807 13.0881 77.1807 12.1021C77.1807 11.1208 76.6915 10.5024 75.9134 10.5024C75.1311 10.5024 74.6553 11.1075 74.6553 12.098Z" fill="white"/>
            <path className="text" d="M81.9382 12.098C81.9086 11.7886 81.944 11.4764 82.0422 11.1814C82.1403 10.8865 82.299 10.6153 82.5081 10.3852C82.7171 10.1552 82.972 9.9714 83.2563 9.84563C83.5405 9.71986 83.848 9.65489 84.1588 9.65489C84.4697 9.65489 84.7771 9.71986 85.0614 9.84563C85.3456 9.9714 85.6005 10.1552 85.8096 10.3852C86.0186 10.6153 86.1773 10.8865 86.2755 11.1814C86.3736 11.4764 86.409 11.7886 86.3794 12.098C86.4096 12.4078 86.3746 12.7204 86.2767 13.0159C86.1788 13.3113 86.0202 13.583 85.8111 13.8135C85.602 14.044 85.3469 14.2282 85.0624 14.3542C84.7778 14.4802 84.47 14.5454 84.1588 14.5454C83.8476 14.5454 83.5398 14.4802 83.2552 14.3542C82.9707 14.2282 82.7156 14.044 82.5065 13.8135C82.2974 13.583 82.1388 13.3113 82.0409 13.0159C81.943 12.7204 81.9081 12.4078 81.9382 12.098ZM85.4235 12.098C85.4235 11.0774 84.965 10.4805 84.1603 10.4805C83.3526 10.4805 82.8982 11.0774 82.8982 12.098C82.8982 13.1269 83.3526 13.7192 84.1603 13.7192C84.965 13.7192 85.4235 13.1228 85.4235 12.098Z" fill="white"/>
            <path className="text" d="M87.626 9.74572H88.5205V10.4937H88.59C88.7078 10.2251 88.9065 9.99986 89.1583 9.84948C89.4102 9.6991 89.7028 9.63106 89.9951 9.65482C90.2243 9.6376 90.4543 9.67214 90.6682 9.7559C90.8822 9.83966 91.0746 9.97048 91.2311 10.1387C91.3876 10.3069 91.5043 10.5081 91.5725 10.7275C91.6406 10.9469 91.6586 11.1789 91.625 11.4062V14.4544H90.6957V11.6395C90.6957 10.8828 90.3669 10.5065 89.6796 10.5065C89.524 10.4993 89.3687 10.5257 89.2244 10.5841C89.08 10.6425 88.95 10.7314 88.8432 10.8447C88.7364 10.9581 88.6554 11.0932 88.6057 11.2407C88.556 11.3883 88.5388 11.5449 88.5553 11.6998V14.4544H87.626V9.74572Z" fill="white"/>
            <path className="text" d="M96.8761 8.5734V9.76717H97.8962V10.5499H96.8761V12.9711C96.8761 13.4644 97.0793 13.6804 97.5419 13.6804C97.6603 13.68 97.7786 13.6728 97.8962 13.6589V14.433C97.7293 14.4628 97.5603 14.4787 97.3907 14.4805C96.3573 14.4805 95.9458 14.1169 95.9458 13.2091V10.5499H95.1982V9.76712H95.9458V8.5734H96.8761Z" fill="white"/>
            <path className="text" d="M99.166 7.90757H100.087V10.5024H100.161C100.284 10.2312 100.488 10.0048 100.745 9.85381C101.002 9.70287 101.299 9.63481 101.596 9.65891C101.824 9.64651 102.052 9.68456 102.264 9.77035C102.475 9.85615 102.665 9.98756 102.82 10.1552C102.975 10.3229 103.091 10.5227 103.16 10.7403C103.229 10.958 103.248 11.1881 103.218 11.4143V14.4544H102.288V11.6436C102.288 10.8915 101.938 10.5106 101.281 10.5106C101.121 10.4975 100.961 10.5194 100.81 10.5749C100.66 10.6304 100.523 10.718 100.411 10.8317C100.298 10.9455 100.211 11.0825 100.156 11.2332C100.102 11.3839 100.081 11.5447 100.095 11.7043V14.4544H99.1661L99.166 7.90757Z" fill="white"/>
            <path className="text" d="M108.636 13.183C108.51 13.6134 108.236 13.9856 107.863 14.2347C107.49 14.4837 107.041 14.5937 106.596 14.5453C106.285 14.5535 105.977 14.4941 105.692 14.3712C105.407 14.2483 105.152 14.0648 104.945 13.8336C104.738 13.6023 104.584 13.3288 104.494 13.032C104.403 12.7353 104.378 12.4223 104.42 12.1149C104.379 11.8065 104.405 11.4929 104.495 11.1952C104.585 10.8975 104.739 10.6227 104.945 10.3895C105.151 10.1562 105.404 9.96989 105.688 9.84315C105.972 9.7164 106.28 9.65218 106.592 9.65483C107.902 9.65483 108.692 10.5499 108.692 12.0286V12.3528H105.367V12.4049C105.353 12.5777 105.374 12.7516 105.431 12.9155C105.487 13.0795 105.578 13.2297 105.696 13.3567C105.814 13.4837 105.957 13.5847 106.116 13.6531C106.276 13.7214 106.448 13.7558 106.621 13.7538C106.843 13.7805 107.069 13.7405 107.268 13.6389C107.468 13.5372 107.632 13.3785 107.741 13.183L108.636 13.183ZM105.367 11.6656H107.745C107.757 11.5075 107.736 11.3488 107.682 11.1995C107.629 11.0503 107.545 10.9139 107.436 10.799C107.327 10.6841 107.195 10.5933 107.049 10.5325C106.902 10.4717 106.745 10.4421 106.586 10.4457C106.426 10.4437 106.266 10.4739 106.117 10.5345C105.968 10.5951 105.833 10.6849 105.72 10.7986C105.606 10.9122 105.516 11.0475 105.456 11.1964C105.395 11.3453 105.365 11.5048 105.367 11.6656Z" fill="white"/>
        </svg>
        </>
    }
}


