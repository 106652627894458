import React from "react";

export default function EUR(props: any) {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect width="20" height="20" fill="#004692"/>
    <path d="M9.99076 2.37964L9.49397 3.50334L8.32408 3.65285L9.18696 4.49689L8.96068 5.71297L9.99075 5.01853L11.0208 5.71297L10.7945 4.49689L11.6574 3.65285L10.4875 3.50334L9.99076 2.37964Z" fill="#F1B434"/>
    <path d="M5.77418 4.12616L5.27739 5.24986L4.1075 5.39937L4.97038 6.24341L4.7441 7.45949L5.77417 6.76505L6.80424 7.45949L6.57796 6.24341L7.44084 5.39937L6.27095 5.24986L5.77418 4.12616Z" fill="#F1B434"/>
    <path d="M4.02776 8.34261L3.53097 9.4663L2.36108 9.61582L3.22396 10.4599L2.99768 11.6759L4.02775 10.9815L5.05783 11.6759L4.83154 10.4599L5.69442 9.61582L4.52453 9.4663L4.02776 8.34261Z" fill="#F1B434"/>
    <path d="M5.77418 12.559L5.27739 13.6827L4.1075 13.8323L4.97038 14.6763L4.7441 15.8924L5.77417 15.1979L6.80424 15.8924L6.57796 14.6763L7.44084 13.8323L6.27095 13.6827L5.77418 12.559Z" fill="#F1B434"/>
    <path d="M9.99076 14.3056L9.49397 15.4293L8.32408 15.5788L9.18696 16.4228L8.96068 17.6389L9.99075 16.9445L11.0208 17.6389L10.7945 16.4228L11.6574 15.5788L10.4875 15.4293L9.99076 14.3056Z" fill="#F1B434"/>
    <path d="M14.2071 12.559L13.7103 13.6827L12.5404 13.8323L13.4033 14.6763L13.177 15.8924L14.2071 15.1979L15.2371 15.8924L15.0108 14.6763L15.8737 13.8323L14.7038 13.6827L14.2071 12.559Z" fill="#F1B434"/>
    <path d="M15.9536 8.34261L15.4568 9.4663L14.2869 9.61582L15.1498 10.4599L14.9235 11.6759L15.9536 10.9815L16.9837 11.6759L16.7574 10.4599L17.6203 9.61582L16.4504 9.4663L15.9536 8.34261Z" fill="#F1B434"/>
    <path d="M14.2071 4.12616L13.7103 5.24986L12.5404 5.39937L13.4033 6.24341L13.177 7.45949L14.2071 6.76505L15.2371 7.45949L15.0108 6.24341L15.8737 5.39937L14.7038 5.24986L14.2071 4.12616Z" fill="#F1B434"/>
    </svg>
  );
}
