import * as React from "react"
const Bus = (props:any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={23}
    height={22}
    viewBox="0 0 23 22"
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.283}
        d="M6.69 2.292h9.953c1.736 0 3.143 1.421 3.143 3.176v12.174a1.58 1.58 0 0 1-1.571 1.588H5.119a1.58 1.58 0 0 1-1.571-1.588V5.468c0-1.755 1.407-3.176 3.143-3.176Z"
      />
      <path
        fill="currentColor"
        d="M16.381 16.848c.579 0 1.048-.474 1.048-1.058 0-.585-.47-1.059-1.048-1.059-.578 0-1.047.474-1.047 1.059 0 .584.469 1.058 1.047 1.058ZM6.953 16.848C7.53 16.848 8 16.374 8 15.79c0-.585-.469-1.059-1.047-1.059-.579 0-1.048.474-1.048 1.059 0 .584.47 1.058 1.048 1.058Z"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.283}
        d="M10.357 4.938h2.62M20.834 7.585v2.647M2.5 7.585v2.647M4.072 12.35h15.19"
      />
      <path
        fill="currentColor"
        d="m20.572 16.849-2.62 2.117v1.059c0 .585.47 1.058 1.048 1.058h.524c.579 0 1.048-.473 1.048-1.058v-3.176ZM2.762 16.849l2.62 2.117v1.059c0 .585-.47 1.058-1.048 1.058H3.81a1.053 1.053 0 0 1-1.048-1.058v-3.176Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="currentColor" d="M.667 0h22v22h-22z" />
      </clipPath>
    </defs>
  </svg>
)
export default Bus
