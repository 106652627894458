import * as React from "react"
const DropTower = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="m10 2 .206.091 1.826.809.316.14V4.474h2.608a.53.53 0 0 1 .522.54V7.71a.53.53 0 0 1 .522.539v.456c0 .344-.27.622-.602.622h-3.05v11.595h1.826c.144 0 .26.12.26.27v.538c0 .15-.116.27-.26.27H5.826a.265.265 0 0 1-.26-.27v-.539c0-.149.116-.27.26-.27h1.826V9.328h-3.05A.612.612 0 0 1 4 8.705V8.25a.53.53 0 0 1 .522-.54V5.014a.53.53 0 0 1 .521-.539h2.61V3.04l.315-.14 1.826-.809L10 2Zm1.304 1.751v.723H8.696v-.723L10 3.173l1.304.578Zm-2.608 6.436v-.86h.83l-.83.86Zm0 1.301v1.934l.935-.967-.935-.967Zm0 3.236v1.934l.935-.967-.935-.967Zm0 3.235v1.934l.935-.967-.935-.967Zm0 2.693v.27h2.608v-.27h-.003L10 19.308l-1.3 1.344h-.004Zm2.608-.759V17.96l-.935.967.935.967Zm0-3.236v-1.933l-.935.967.935.966Zm0-3.235v-1.934l-.935.967.935.967Zm0-3.236v-.859h-.83l.83.86ZM10 9.601l-1.196 1.236L10 12.074l1.196-1.237L10 9.601Zm0 3.235-1.196 1.237L10 15.309l1.196-1.236L10 12.836Zm0 3.236-1.196 1.236L10 18.545l1.196-1.237L10 16.072Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="transparent" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default DropTower
