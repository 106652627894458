import * as React from "react"
const HauntedHouse = (props:any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      d="M15.84 2.004c-.32 0-.628.104-.892.302a.19.19 0 0 0-.075.152c0 .06.028.117.075.153a1.236 1.236 0 0 1 0 1.97.191.191 0 0 0 0 .303c.264.199.572.303.892.303.844 0 1.53-.714 1.53-1.592 0-.877-.687-1.591-1.53-1.591Zm2.148 9.917-1.055-3.35a.24.24 0 0 0-.226-.172h-3.626V6.47h.36a.23.23 0 0 0 .21-.143.247.247 0 0 0-.039-.258L10.16 2.076a.232.232 0 0 0-.344 0L6.362 6.068a.247.247 0 0 0-.04.258.23.23 0 0 0 .211.143h.365v1.93H3.293a.24.24 0 0 0-.226.171l-1.055 3.351a.254.254 0 0 0 .034.222.236.236 0 0 0 .192.103h.395v5.512c0 .133.104.242.232.242h5.272v-.88l3.676-1.27V18h5.333a.238.238 0 0 0 .232-.242v-5.513h.384a.234.234 0 0 0 .193-.102.253.253 0 0 0 .033-.222ZM6.29 16.07H4.444v-1.634c0-.508.396-.92.884-.92h.078c.488 0 .884.412.884.92v1.634Zm3.717-12.14c.34 0 .615.286.615.639 0 .353-.276.64-.615.64a.628.628 0 0 1-.615-.64c0-.353.275-.64.615-.64Zm-.01 5.12c.68 0 1.23.573 1.23 1.28 0 .707-.55 1.28-1.23 1.28-.68 0-1.23-.573-1.23-1.28 0-.707.55-1.28 1.23-1.28Zm-1.86 6.722v-2.256h3.676v.985l-3.676 1.271Zm7.387.289h-1.846v-1.633c0-.509.396-.92.884-.92h.078c.488 0 .884.412.884.92v1.633Z"
    />
  </svg>
)
export default HauntedHouse
