import React from "react";

import * as Iconset from './iconset';

/**
 * Icons load on-demand.
 */
export default class Icon extends React.Component<{ icon: keyof typeof Iconset, /* rest props */ [key: string]: any  }> {
    static ICONS = Iconset;

    render() {
        const IconComp = Icon.ICONS[this.props.icon || "none"];
        if (!IconComp) return null;
        return <IconComp {...this.props} />;
    }
}
