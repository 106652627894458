import React, { Component, lazy } from "react";
import { navigate } from "gatsby";

import pages from "src/../src-dynamic/destination.pages";

// TODO - translations must work differently someday...
import provisionalModuleDataBasedOnLanguage from "./gausta/provisionalModuleData";

import Suspense from "src/components/suspense";

import { getQuery } from "src/utils/query";
import { addListener, removeListener } from "src/utils/events";

// Lazy components splitting:
const LoginModal = lazy(() => import(/* webpackChunkName: "login-modal-component" */ "src/components/loginModal"));

/**
 * Login Modal Watcher V2
 * - used in src/layouts/layout-root.
 */
export default class LoginModalWatcherV2 extends Component {
  
  state = {
    displayLoginModal: false,
    redirected: false
  }

  componentDidUpdate() {
    if (getQuery().redirected && !this.state.redirected) { this.setState({ displayLoginModal: true, redirected: true }); }
  }

  componentDidMount() {
    if (getQuery().redirected && !this.state.redirected) { this.setState({ displayLoginModal: true, redirected: true }); }
    addListener('login-modal', this.onLoginModalEvent);
  }

  componentWillUnmount() {
    removeListener('login-modal', this.onLoginModalEvent);
  }

  onLoginModalEvent = (ev) => {
    this.setState({displayLoginModal: true});
  }

  renderLoginModal() {
    const { displayLoginModal } = this.state;
    const { language } = this.props;
    if (!displayLoginModal) return null;
    const accountpage = pages.account_page
      ? (pages.account_page).find(translation => translation.language === language)
      : null;

    return (
        <LoginModal
          show={displayLoginModal}
          closeFn={() => this.setState({ displayLoginModal: false })}
          onFinishLogin={() => accountpage ? navigate(accountpage.slug) : navigate("/account")}
          moduleData={provisionalModuleDataBasedOnLanguage(this.props.language)}
        />
    )
  }
  
  render() {
    return <Suspense fallback={null}>
        {this.renderLoginModal()}
    </Suspense>
  }
}