import * as React from "react"
const Lightning = (props:any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={17}
    viewBox="0 0 16 17"
    fill="none"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.3}
      d="M8.778 2.333 2.667 9.667h5.5l-.611 4.889 6.11-7.334h-5.5l.612-4.889Z"
      clipRule="evenodd"
    />
  </svg>
)
export default Lightning
