import * as React from "react"

const FreeRide = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22}
    height={22}
    viewBox="0 0 22 22"
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <rect width={22} height={14.502} y={4.055} fill="#FABC1F" rx={1.384} />
      <path
        fill="#242426"
        d="m6.49 5.858-4.713 5.029a.62.62 0 0 0 0 .838l4.714 5.028a.531.531 0 0 0 .786 0l4.714-5.028a.62.62 0 0 0 0-.838L7.277 5.858a.531.531 0 0 0-.786 0Z"
      />
      <path
        fill="#242426"
        d="m14.722 5.858-4.714 5.029a.62.62 0 0 0 0 .838l4.714 5.028a.531.531 0 0 0 .786 0l4.714-5.028a.621.621 0 0 0 0-.838l-4.714-5.029a.531.531 0 0 0-.786 0Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h22v22H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default FreeRide;