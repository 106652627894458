import React from "react";

type MobileToolbarFooterWrapperProps = {
    toolbar?: TToolbar | null;
    children?: React.ReactNode;
}

/**
 * This components wraps the toolbar and navigation footer.
 * Applies toolbar background and shadow.
 */
export default class MobileToolbarFooterWrapper extends React.Component<MobileToolbarFooterWrapperProps> {
    render() {
        return <div style={{
            backgroundColor: this.props.toolbar?.background_color_when_attached_on_mobile?.value,
            boxShadow: this.props.toolbar?.mobile_shadow
                        ? '0px -4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)'
                        : undefined
        }}>
            {this.props.children}
        </div>
    }
}