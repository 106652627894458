import * as React from "react"

const Water = (props:any) => (
    <svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_2019_6413)">
    <path d="M4.87393 4.6001C4.87393 6.46676 3.40869 7.97998 1.6001 7.97998" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M19.9335 7.97998C18.1249 7.97998 16.6597 6.46676 16.6597 4.6001" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M10.7669 4.6001C10.7669 6.46676 9.44821 7.97998 7.82047 7.97998C6.19274 7.97998 4.87402 6.46676 4.87402 4.6001" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M16.6595 4.6001C16.6595 6.46676 15.34 7.97998 13.713 7.97998C12.0853 7.97998 10.7666 6.46676 10.7666 4.6001" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M4.87393 18.02C4.87393 19.8867 3.40869 21.3999 1.6001 21.3999" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M19.9335 21.3999C18.1249 21.3999 16.6597 19.8867 16.6597 18.02" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M10.7669 18.02C10.7669 19.8867 9.44821 21.3999 7.82047 21.3999C6.19274 21.3999 4.87402 19.8867 4.87402 18.02" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M16.6595 18.02C16.6595 19.8867 15.34 21.3999 13.713 21.3999C12.0853 21.3999 10.7666 19.8867 10.7666 18.02" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M10.5238 11.3599C10.5238 13.2265 9.05859 14.7397 7.25 14.7397" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M25.5834 14.7397C23.7748 14.7397 22.3096 13.2265 22.3096 11.3599" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M16.4168 11.3599C16.4168 13.2265 15.0981 14.7397 13.4704 14.7397C11.8426 14.7397 10.5239 13.2265 10.5239 11.3599" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M22.3094 11.3599C22.3094 13.2265 20.9899 14.7397 19.363 14.7397C17.7352 14.7397 16.4165 13.2265 16.4165 11.3599" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
    <defs>
    <clipPath id="clip0_2019_6413">
    <rect width="26" height="26" fill="white" transform="translate(0.600098)"/>
    </clipPath>
    </defs>
    </svg>
)

export default Water
