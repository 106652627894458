import React from "react";

import * as Iconset from './iconset';

export default class CurrencyIcon extends React.Component<{ icon: keyof typeof Iconset, /* rest props */ [key: string]: any  }> {
    static ICONS = Iconset;

    render() {
        const IconComp = CurrencyIcon.ICONS[this.props.icon || "none"];
        if (!IconComp) return null;
        return <IconComp {...this.props} />;
    }
}
