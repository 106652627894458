import React from "react";

import FooterCommonController from "../footer.v1.common";

export default class FooterFourColumns extends FooterCommonController<TFooterFourColumns> {

    private renderFirstColumn() {
        return this.renderColumn(
            this.props.footer.four_columns_translations,
            'first_column_title',
            this.props.footer.first_column_items
        );
    }

    private renderSecondColumn() {
        return this.renderColumn(
            this.props.footer.four_columns_translations,
            'second_column_title',
            this.props.footer.second_column_items
        );
    }

    private renderThirdColumn() {
        return this.renderColumn(
            this.props.footer.four_columns_translations,
            'third_column_title',
            this.props.footer.third_column_items
        );
    }

    private renderFourthColumn() {
        return this.renderColumn(
            this.props.footer.four_columns_translations,
            'fourth_column_title',
            this.props.footer.fourth_column_items
        );
    }

    render(): React.ReactNode {
        return <div style={this.style as any}>
        <div className="w-full py-8 lg:py-12 px-4 xl:px-0 bg-[color:var(--background-color)]" style={this.mainBackgroundStyle}>
            <div className="max-w-7xl mx-auto flex flex-col items-center gap-12">

                <div className="w-full flex flex-col lg:grid lg:grid-cols-3 gap-8 xl:gap-6">

                    <div className={`flex flex-col gap-6 items-center lg:items-start`}>
                        {this.renderLogo()}
                        {this.renderSupportingText(false)}
                        {this.renderSocialMedia()}
                        {this.renderNewsletter('left', 'hidden lg:flex')}
                        {this.renderAppStores('left', 'left', 'hidden lg:flex', 'footer_stores_top')}
                    </div>

                    <div className="grid grid-cols-2 md:flex md:flex-row flex flex-row gap-8 text-[color:var(--link-labels-color)]">
                        {this.renderFirstColumn()}
                        {this.renderSecondColumn()}
                    </div>

                    <div className="grid grid-cols-2 md:flex md:flex-row flex flex-row gap-8 text-[color:var(--link-labels-color)]">
                        {this.renderThirdColumn()}
                        {this.renderFourthColumn()}
                    </div>
                </div>

                {this.renderNewsletter('center', 'flex lg:hidden')}
                {this.renderAppStores('center', 'left', 'flex lg:hidden', 'footer_stores_bottom')}
            </div>
        </div>

        {/* logos grid section */}
        <div className="w-full bg-[color:var(--logo-slider-background-color)]">
            <div className="max-w-7xl mx-auto flex flex-col items-center ">
                {this.renderLogosGrid()}
            </div>
        </div>

        {/* legal section */}
        <div className="w-full bg-[color:var(--legal-background-color)]">
            <div className="max-w-7xl mx-auto flex flex-col items-center">
                {this.renderBottomText()}
            </div>
        </div>
        
        </div>
    }
}