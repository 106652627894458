import * as React from "react"

const TripleBlackDiamond = (props:any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22}
    height={22}
    fill="none"
    {...props}
  >
    <path
      stroke="#242426"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.8}
      d="M8.93 5.188 7.102 2.75.914 11l6.188 8.25 1.842-2.456M13.2 4.947l1.648-2.197L21.035 11l-6.187 8.25-1.846-2.46"
    />
    <path
      stroke="#242426"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.8}
      d="M11.09 2.75 17.277 11l-6.187 8.25L4.902 11l6.188-8.25Z"
      clipRule="evenodd"
    />
  </svg>
)
export default TripleBlackDiamond
