import React from 'react';

import LogoSlider from 'src/@components/slider.logos/slider.logos.component';

import BackgroundImageWrapper from 'src/components/backgroundImageWrapper/backgroundImageWrapper';
import ImageV3 from 'src/components/image/image.v3';
import Link from 'src/components/link';
import { ModuleWrapperV2 } from 'src/components/moduleWrapper/moduleWrapper.v2';
import SiteContainer from 'src/components/siteContainer/siteContainer';

export default class LogosGridFooterVersion extends React.Component<LogosGridModuleProps> {
  public get style(): React.CSSProperties {
    return {
      '--hyperlink-text-color': this.props.hyperlink_text_color,
      '--hyperlink-hover-color': this.props.hyperlink_hover_color,
      '--swiper-pagination-color': this.props.selected_bubble_indicator_color,
      '--swiper-pagination-bullet-inactive-color': this.props.unselected_bubble_indicator_color,
      '--swiper-navigation-color': this.props.arrow_color || "#fff",
      '--swiper-navigation-bg': this.props.arrow_bubble_color
    } as React.CSSProperties;
  }

  renderLogoSlide(logo: LogoItem, index: number): React.ReactNode {
    return (
      <div key={'footer-logo-'+index} className="relative col-span-1 flex justify-center py-8 px-4" style={{backgroundColor: this.props.items_background_color }}>
        <Link href={logo.href} title={logo.name}>
          <ImageV3 imgStyle={{objectFit: 'contain'}} image={logo.image} className="w-40 max-h-[70px]" alt={logo.name} />
        </Link>
      </div>
    );
  }

  renderGridVersion(): React.ReactNode {
    return <>
    <div className="w-full grid grid-cols-2 gap-0.5 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6">
      {this.props.logos.map((logo, index) => this.renderLogoSlide(logo, index))}
    </div>
    </>
  }

  renderSliderVersion(): React.ReactNode {
    return <LogoSlider logos={this.props.logos} style={this.style} renderLogoSlide={(logo, index) => this.renderLogoSlide(logo, index)} />
  }

  render(): React.ReactNode {
    const isFullScreen = this.props.width === 'full_screen';

    const innerPaddings = isFullScreen ? "px-6 xl:px-0" : "px-4 sm:px-6 lg:px-8 py-8"
    const outterMargin = isFullScreen ? "" : "mx-4 sm:mx-6 xl:mx-0 my-8 lg:my-12";

    return <div style={this.props.style} className='w-full overflow-x-hidden'>
    <BackgroundImageWrapper backgroundColor={this.props.background_color}>
      <SiteContainer fullwidth={isFullScreen}>
        <ModuleWrapperV2 isFullWidth={isFullScreen} anchor={this.props.anchor} backgroundColor={this.props.boxed_background_color} name={this.props.name} roundedCorners={this.props.rounded_corners} className={`relative ${innerPaddings} ${outterMargin}`} style={this.style} paddings={{top: this.props.padding_top, bottom: this.props.padding_bottom}}>
          <div className="max-w-7xl mx-auto">

            { this.props.title || this.props.description ?
                <div className="flex-col justify-start items-center gap-2 flex">
                    {this.props.title ? <div className="self-stretch text-center text-[color:var(--titles-color)] headlineGeneralText leading-normal">
                        {this.props.title}
                    </div> : null }
                    {this.props.description ? <div className="w-full text-center text-[color:var(--text-color)] generalText leading-snug">
                        {this.props.description}
                    </div> : null }
                </div>
            : null }

            {/* MODULE CONTENTS */}
            {this.props.mode === 'grid'
              ? this.renderGridVersion()
              : this.renderSliderVersion()
            }
          </div>
        </ModuleWrapperV2>
      </SiteContainer>
    </BackgroundImageWrapper>
    </div>
  }
}